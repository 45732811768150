import { rawSrcFromCDN } from 'src/utils/files';

type ImageLoaderProps = {
  src: string;
  width: number;
  quality?: number;
};

const ImageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  const imageSrc = rawSrcFromCDN(src);
  if (quality) {
    return `${imageSrc}?format=auto&quality=${quality}&width=${width}`;
  }
  return `${imageSrc}?format=auto&width=${width}`;
};

export default ImageLoader;
