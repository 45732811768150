import {
  FormControl,
  FormLabel,
  Divider,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  Switch,
  Stack,
  Textarea,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import { AuctionInfo } from 'src/api/v1-api/helpers';
import InfoTooltip from 'src/components/atoms/info-tooltip';
import { MINIMUM_BID_INCREMENT, MINIMUM_PRICE_ALLOWED } from 'src/constants/marketplace';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';
import { formatDateTime, localToUtcISO, utcISOToLocalDateISO } from 'src/utils/common';
import { useEffectOnce } from 'usehooks-ts';

type ListingAuctionFormProps = {
  auctionInfo: AuctionInfo;
  setAuctionInfo: Dispatch<SetStateAction<AuctionInfo>>;
};

const ListingAuctionForm = ({ auctionInfo, setAuctionInfo }: ListingAuctionFormProps) => {
  const { hasAdmin } = useAuth();
  const { marketplace } = useMarketplaceContext();
  const isAdmin = hasAdmin(marketplace?.id);

  const saveStartLocal = (value: string) => {
    if (!value) return;
    const start = localToUtcISO(value);
    setAuctionInfo({
      ...auctionInfo,
      start: start, // save as UTC ISO
      startLocal: value, // display as local ISO
    });
  };

  const saveEndLocal = (value: string) => {
    if (!value) return;
    const end = localToUtcISO(value);
    setAuctionInfo({
      ...auctionInfo,
      end: end, // save as UTC ISO
      endLocal: value, // display as local ISO
    });
  };

  useEffectOnce(() => {
    if (isAdmin) {
      if (!auctionInfo.startLocal || !auctionInfo.endLocal) {
        const defaultLocal = utcISOToLocalDateISO(new Date().toISOString());
        const defaultUtc = localToUtcISO(defaultLocal);
        setAuctionInfo({
          ...auctionInfo,
          start: auctionInfo.start ?? defaultUtc,
          end: auctionInfo.end ?? defaultUtc,
          startLocal: auctionInfo.startLocal ?? defaultLocal,
          endLocal: auctionInfo.endLocal ?? defaultLocal,
        });
      }
    }
  });

  return (
    <>
      <Stack spacing={{ base: '6', md: '8' }}>
        <Text fontSize="md" fontWeight={600}>
          Auction Details
        </Text>
        <FormControl>
          <FormLabel>
            Reserve Price (optional) &nbsp;
            <InfoTooltip description="Set the minimum acceptable price for a successful auction. This field is optional." />
          </FormLabel>
          <InputGroup>
            <InputLeftAddon>$</InputLeftAddon>
            <NumberInput
              name="reserve_price"
              value={auctionInfo.reserve_price ?? undefined}
              precision={2}
              min={MINIMUM_PRICE_ALLOWED}
              onChange={(v) =>
                setAuctionInfo({
                  ...auctionInfo,
                  reserve_price: v.toString(),
                })
              }
            >
              <NumberInputField borderLeftRadius={0} />
            </NumberInput>
          </InputGroup>
        </FormControl>

        <FormControl>
          <FormLabel>
            Show Reserve Price? &nbsp;
            <InfoTooltip description="Show the reserve price to prospective bidder. If not shown, bidders will only know that a reserve price exists." />
          </FormLabel>
          <Switch
            key="show_reserve"
            name="show_reserve"
            colorScheme="primary"
            isChecked={!!auctionInfo.show_reserve}
            onChange={(e) =>
              setAuctionInfo({
                ...auctionInfo,
                show_reserve: !auctionInfo.show_reserve,
              })
            }
            disabled={!auctionInfo.reserve_price}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            Bid Increment &nbsp;
            <InfoTooltip description="Minimum amount for an increased bid, in dollars." />
          </FormLabel>
          <InputGroup>
            <InputLeftAddon>$</InputLeftAddon>
            <NumberInput
              name="bid_increment"
              min={MINIMUM_BID_INCREMENT}
              precision={2}
              value={auctionInfo.bid_increment}
              onChange={(v) =>
                setAuctionInfo({
                  ...auctionInfo,
                  bid_increment: parseFloat(v),
                })
              }
            >
              <NumberInputField borderLeftRadius={0} />
            </NumberInput>
          </InputGroup>
        </FormControl>

        <FormControl>
          <FormLabel>
            Auction Start Date &nbsp;
            <InfoTooltip description="This date and time will be set by the marketplace admin. If you have specific restrictions or requests, please leave a note to the admin." />
          </FormLabel>
          {isAdmin ? (
            <Input
              key="start"
              name="startLocal"
              size="md"
              type="datetime-local"
              value={auctionInfo.startLocal ?? undefined}
              onChange={(e) => saveStartLocal(e.target.value)}
            />
          ) : (
            <Text ml={4} pt={1} fontWeight="semibold">
              {auctionInfo.startLocal
                ? formatDateTime(auctionInfo.startLocal)
                : 'Not scheduled yet'}
            </Text>
          )}
        </FormControl>

        <FormControl>
          <FormLabel>
            Auction End Date &nbsp;
            <InfoTooltip description="This date and time will be set by the marketplace admin. If you have specific restrictions or requests, please leave a note to the admin." />
          </FormLabel>
          {isAdmin ? (
            <Input
              key="end"
              name="endLocal"
              size="md"
              type="datetime-local"
              value={auctionInfo.endLocal ?? undefined}
              onChange={(e) => saveEndLocal(e.target.value)}
            />
          ) : (
            <Text ml={4} pt={1} fontWeight="semibold">
              {auctionInfo.endLocal ? formatDateTime(auctionInfo.endLocal) : 'Not scheduled yet'}
            </Text>
          )}
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>
            Notes for Auction Admin &nbsp;
            <InfoTooltip description="If you have any date restrictions or other information necessary for the auction, please inform the marketplace admin here." />
          </FormLabel>
          {isAdmin ? (
            <Text ml={4} pt={1} fontWeight="semibold">
              {auctionInfo.notes_for_admin ?? 'No notes from seller.'}
            </Text>
          ) : (
            <Textarea
              name="notes_for_admin"
              placeholder=""
              value={auctionInfo.notes_for_admin || ''}
              onChange={(e) =>
                setAuctionInfo({
                  ...auctionInfo,
                  notes_for_admin: e.target.value,
                })
              }
            />
          )}
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Notes for Auction Seller</FormLabel>
          {!isAdmin ? (
            <Text ml={4} pt={1} fontWeight="semibold">
              {auctionInfo.notes_for_seller ?? 'No notes from admin.'}
            </Text>
          ) : (
            <Textarea
              name="notes_for_seller"
              placeholder=""
              value={auctionInfo.notes_for_seller || ''}
              onChange={(e) =>
                setAuctionInfo({
                  ...auctionInfo,
                  notes_for_seller: e.target.value,
                })
              }
            />
          )}
        </FormControl>
      </Stack>

      <Divider my={{ base: 4, lg: 6 }} />
    </>
  );
};

export default ListingAuctionForm;
