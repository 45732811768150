import { isEmpty } from 'lodash';
import { ShippingInfo } from 'src/api/v1-api';

export const isShippingInfoValid = (shippingInfo: ShippingInfo | undefined) => {
  if (!shippingInfo) {
    return false;
  }
  return (
    !isEmpty(shippingInfo.city) &&
    !isEmpty(shippingInfo.name) &&
    !isEmpty(shippingInfo.street_address1) &&
    !isEmpty(shippingInfo.state) &&
    !isEmpty(shippingInfo.zip_code) &&
    !isEmpty(shippingInfo.country)
  );
};
