import React from 'react';
import { Text } from '@chakra-ui/react';

const MobileRowLabel = ({ label }: { label: string }) => (
  <Text
    color="gray.600"
    textTransform="uppercase"
    fontSize="xs"
    fontWeight="bold"
    mb={0}
    pb={0}
    display={{ base: 'block', lg: 'none' }}
  >
    {label}
  </Text>
);

export default MobileRowLabel;
