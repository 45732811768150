import { uniq, groupBy } from 'lodash';
import { SetStateAction } from 'react';
import {
  Attribute,
  AttributeOption,
  BasicAttributeValue,
  FlatAttributeValuesType,
  Listing,
  MarketplaceInfo,
  User,
  ValueObject,
  Variant,
} from 'src/api/v1-api';
import { FilePath } from 'src/components/organisms/listing-form';

export type ValueWithPosition = {
  value: string | number;
  position: number;
};

export const RANGE_FORMATS = ['number', 'decimal', 'date', 'datetime', 'time', 'year', 'media'];

export const getValueObjectValue = (valueObject: any): ValueWithPosition[] => {
  return [
    {
      value: valueObject?.value ?? null,
      position: 0,
    },
  ];
};

export const getSelectValueObjectValues = (valueObject: {
  values: { value: string | number; position: number }[];
}): ValueWithPosition[] => {
  return valueObject.values.map((v) => ({ value: v.value, position: v.position }));
};

export const getSingleValueFromSelectValueObjects = (valueObject: {
  values: { value: string | number; position: number }[];
}): string | number | null => {
  const sortedValues = valueObject.values.sort((a, b) => a.position - b.position);
  return sortedValues[0]?.value ?? null;
};

export const getAttributeOptions = (
  variants: Variant[],
): { [key: string]: (string | number)[] } => {
  const attributeValuesGroup = groupBy(
    variants.reduce((total: { name: string; values: ValueWithPosition[] }[], variant) => {
      const variantAttributeValues = (variant.variant_attribute_values ?? []).map((item) => {
        if (item.value_object?.values) {
          return {
            name: item.attribute.name,
            // TODO(Sean): Fix this type
            // @ts-ignore
            values: getSelectValueObjectValues(item.value_object),
          };
        } else {
          return {
            name: item.attribute.name,
            values: getValueObjectValue(item.value_object),
          };
        }
      });

      return total.concat(variantAttributeValues);
    }, []),
    (item) => item.name,
  );

  const reducedValues = Object.keys(attributeValuesGroup).reduce((total, name) => {
    const flattenedValues = attributeValuesGroup[name]
      .flatMap((item) => item.values)
      .sort((a, b) => a.position - b.position)
      .map((v) => v.value);

    return {
      ...total,
      [name]: uniq(flattenedValues.filter((value) => value !== null)),
    };
  }, {});

  return reducedValues;
};

export function convertBooleanValues(
  value: string | number | boolean | (string | number)[] | null,
): string | number | null {
  if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
}

export const checkAttributeValidity = (
  attributes: Attribute[] | undefined,
  listingAttributeValues: FlatAttributeValuesType,
): string => {
  let value: any = null;
  let min: any = null;
  let max: any = null;
  let message = '';

  attributes?.map((attributeValue) => {
    if (Object.keys(listingAttributeValues).includes(attributeValue.id.toString())) {
      const attributeId = attributeValue.id;

      switch (attributeValue.format.toLowerCase()) {
        case 'date':
          value = new Date(listingAttributeValues[attributeId] as any).toLocaleDateString();
          min = new Date(attributeValue.min_value as any).toLocaleDateString();
          max = new Date(attributeValue.max_value as any).toLocaleDateString();
          break;
        case 'datetime':
          value = new Date(listingAttributeValues[attributeId] as any).toLocaleString();
          min = new Date(attributeValue.min_value as any).toLocaleString();
          max = new Date(attributeValue.max_value as any).toLocaleString();
          break;
        case 'time':
          value = new Date(listingAttributeValues[attributeId] as any).toLocaleTimeString();
          min = new Date(attributeValue.min_value as any).toLocaleTimeString();
          max = new Date(attributeValue.max_value as any).toLocaleTimeString();
          break;
        case 'year':
          value = listingAttributeValues[attributeId]?.toString().replace(/[,.]/g, '');
          min = attributeValue.min_value?.toString().replace(/[,.]/g, '');
          max = attributeValue.max_value?.toString().replace(/[,.]/g, '');
          break;
        case 'number':
          value = parseInt(listingAttributeValues[attributeId]!.toString());
          min = parseInt(attributeValue.min_value!.toString());
          max = parseInt(attributeValue.max_value!.toString());
          break;
        case 'decimal':
          value = parseFloat(listingAttributeValues[attributeId]!.toString());
          min = parseFloat(attributeValue.min_value!.toString());
          max = parseFloat(attributeValue.max_value!.toString());
          break;
        case 'media':
          value = (listingAttributeValues[attributeId] as FilePath[]).length;
          min = parseInt(attributeValue.min_value!.toString());
          max = parseInt(attributeValue.max_value!.toString());
      }
    }

    if (attributeValue.format === 'media') {
      if (min && value < min) {
        const minPlural = min > 1 ? 's' : '';
        message += `${attributeValue.name}: Please upload at least ${min} piece${minPlural} of media. `;
      } else if (max && value > max) {
        const maxPlural = max > 1 ? 's' : '';
        message += `${attributeValue.name}: You may only uplaod ${max} piece${maxPlural} of media. `;
      }
    } else if (['date', 'datetime', 'time'].includes(attributeValue.format)) {
      if (min && value < min) {
        message += `${attributeValue.name} must be after ${min}. `;
      } else if (max && value > max) {
        message += `${attributeValue.name} must be before ${max}. `;
      }
    } else if (RANGE_FORMATS.includes(attributeValue.format)) {
      if (min && value < min) {
        message += `${attributeValue.name} must be greater than or equal to ${min}. `;
      } else if (max && value > max) {
        message += `${attributeValue.name} must be less than or equal to ${max}. `;
      }
    }
  });

  if (message !== '') {
    return message;
  } else {
    return '';
  }
};

export const flattenAttributeValues = (
  attributeValues: BasicAttributeValue[],
): FlatAttributeValuesType => {
  return attributeValues.reduce((acc: FlatAttributeValuesType, item) => {
    if (item.attribute.format === 'url') {
      acc[item.attribute.id] = item.value_object;
    } else if (item.attribute.format === 'media' && item.value_object) {
      if (item.value_object.value && (item.value_object.value as ValueObject[]).length > 0) {
        acc[item.attribute.id] = item.value_object.value as ValueObject[];
      } else if (
        item.value_object.values &&
        (item.value_object.values as ValueObject[]).length > 0
      ) {
        acc[item.attribute.id] = item.value_object.values as ValueObject[];
      } else {
        acc[item.attribute.id] = [];
      }
    } else if (item.attribute.format === 'multiselect') {
      acc[item.attribute.id] = item.value_object.values as AttributeOption[];
    } else if (item.value_object && item.value_object.value) {
      acc[item.attribute.id] = item.value_object.value;
    } else if (
      item.value_object &&
      item.value_object.values &&
      (item.value_object.values as ValueObject[]).length > 0
    ) {
      // Take the first value if there are multiple. Change this in the future.
      acc[item.attribute.id] = (item.value_object.values as ValueObject[])[0].value;
    }
    return acc;
  }, {});
};

export const handleAttributeChange = (
  attributeValues: BasicAttributeValue[],
  setAttributeValues: (value: SetStateAction<BasicAttributeValue[]>) => void,
  attributes: Attribute[],
  attributeId: number | string,
  value: string | number | ValueObject | FilePath[] | FilePath | AttributeOption[],
) => {
  // Check if the attribute already exists
  const existingAttributeIndex = attributeValues.findIndex(
    (attributeValue) => attributeValue.attribute.name === attributeId,
  );

  if (existingAttributeIndex !== -1) {
    // If it exists, update the value
    setAttributeValues((prevAttributeValues) =>
      prevAttributeValues.map((attributeValue) => {
        if (attributeValue.attribute.format === 'url') {
          if (attributeValue.attribute.name === attributeId) {
            return {
              ...attributeValue,
              value_object: { ...attributeValue.value_object, ...(value as ValueObject) },
            };
          } else {
            return attributeValue;
          }
        } else if (attributeValue.attribute.format === 'media') {
          return {
            ...attributeValue,
            value_object: { ...attributeValue.value_object, value: [...(value as FilePath[])] },
          };
        } else {
          if (attributeValue.attribute.name === attributeId) {
            return { ...attributeValue, value_object: { ...attributeValue.value_object, value } };
          } else {
            return attributeValue;
          }
        }
      }),
    );
  } else {
    // If it doesn't exist, add a new attribute value with null ids
    const currentAttribute = attributes.find((attribute) => attribute.name === attributeId);
    if (!currentAttribute) {
      return;
    }

    setAttributeValues((prevAttributeValues) => [
      ...prevAttributeValues,
      {
        id: null,
        attribute: currentAttribute,
        value_object: { id: null, value: value },
      },
    ]);
  }
};

export const getMediaFileName = (url: string | undefined) => {
  if (!url && !url?.includes('.pdf')) {
    return '';
  }
  const urlParts = url.split('_');
  const fileName = urlParts.slice(1).join();
  const shortenFilename = truncateString(fileName, 5, 9, 3);
  return shortenFilename;
};

export function truncateString(
  str: string,
  firstCharCount = str.length,
  endCharCount = 0,
  dotCount = 3,
) {
  if (str.length <= firstCharCount + endCharCount) {
    return str; // No truncation needed
  }

  const firstPortion = str.slice(0, firstCharCount);
  const endPortion = str.slice(-endCharCount);
  const dots = '.'.repeat(dotCount);

  return `${firstPortion}${dots}${endPortion}`;
}

export function get_location_from_listing(listing: Listing) {
  const location = listing?.listing_attribute_values.find(
    (attributeValue) => attributeValue.attribute?.name === 'Location',
  );
  if (!location) return '';
  if (location.value_object.city) {
    if (location.value_object.state) {
      return `${location.value_object.city}, ${location.value_object.state}`;
    } else {
      return `${location.value_object.state}`;
    }
  } else if (location.value_object.city) {
    return `${location.value_object.city}`;
  }
  return '';
}

export const isDisplayAttribute = (
  attributeValue: BasicAttributeValue,
  listing: Listing | null | undefined,
  marketplace: MarketplaceInfo | null | undefined,
  hasAdmin: (marketplaceId: string | number | null | undefined) => boolean,
  hasStaff: () => boolean,
  user: User | null,
) => {
  if (!attributeValue?.attribute?.name) return false;
  if (
    attributeValue.attribute.sensitive &&
    (attributeValue.attribute.content_type_name === 'Category' ||
      attributeValue.attribute.content_type_name === 'Listing' ||
      attributeValue.attribute.content_type_name === 'Variant')
  ) {
    if (!listing) return false;
    if (!marketplace) return false;
    if (
      !hasAdmin(marketplace?.id) &&
      !hasStaff() &&
      listing?.marketplace_account?.user?.id !== user?.id
    )
      return null;
  }

  // Hide these attribute formats for now
  if (['file', 'json', 'phone'].includes(attributeValue?.attribute?.format)) return false;
  return true;
};

export function checkFileFormat(filename: string) {
  const fileExtension = filename?.split('.')?.pop()?.toLowerCase();

  const fileTypes = {
    pdf: ['pdf'],
    video: ['mp4', 'avi', 'mov', 'mkv', 'flv'],
    image: ['jpg', 'jpeg', 'png', 'gif', 'bmp'],
    document: ['doc', 'docx', 'txt', 'rtf'],
    audio: ['mp3', 'wav', 'aac', 'flac'],
    archive: ['zip', 'rar', 'tar', 'gz'],
  };

  for (const [type, extensions] of Object.entries(fileTypes)) {
    if (!fileExtension) return 'unknown';
    if (extensions.includes(fileExtension)) {
      return type;
    }
  }

  return 'unknown';
}
