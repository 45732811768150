import { ChakraProvider, extendTheme, ToastProviderProps } from '@chakra-ui/react';
import { Fragment, useContext } from 'react';
import chroma from 'chroma-js';
import { MarketplaceContext } from 'src/contexts/marketplace';
import {
  DEFAULT_PRIMARY_COLOR_SCHEME,
  DEFAULT_SECONDARY_COLOR_SCHEME,
  WHITE,
  BLACK,
} from 'src/constants/ui';
import { getTextColor, getColorSwatch } from 'src/utils/color';
import { MarketplaceInfo } from 'src/api/v1-api';

const ThemeProvider = ({
  children,
  marketplaceProvider = null,
}: {
  children: React.ReactNode;
  marketplaceProvider?: MarketplaceInfo | null;
}): JSX.Element => {
  let marketplace: MarketplaceInfo | null = marketplaceProvider;
  const marketplaceContext = useContext(MarketplaceContext);
  const { marketplace: marketplaceInfo } = marketplaceContext;

  if (!marketplace) {
    marketplace = marketplaceInfo;
  }

  const mainBG = chroma.valid(marketplace?.theme?.background_color)
    ? marketplace?.theme?.background_color
    : WHITE;
  const mainText = getTextColor(mainBG);
  const navBG = chroma.valid(marketplace?.theme?.nav_background_color)
    ? marketplace?.theme?.nav_background_color
    : mainBG;
  const navText = getTextColor(navBG);

  const overrides = {
    name: marketplace?.theme?.name,
    styles: {
      global: {
        body: {
          color: mainText,
          bgColor: mainBG,
          fontFamily: "'Inter', sans-serif",
        },
        '.fr-box .fr-toolbar': {
          border: `1px solid rgb(226, 232, 240) !important`,
          borderBottom: '0 !important',
        },
        '.fr-toolbar.fr-top': {
          borderTopLeftRadius: `${marketplace?.theme?.border_radius_1} !important`,
          borderTopRightRadius: `${marketplace?.theme?.border_radius_1} !important`,
        },
        '.fr-wrapper': {
          border: `1px solid rgb(226, 232, 240) !important`,
          borderBottomColor: '#efefef',
          borderTop: '0 !important',
          borderBottom: '0 !important',
        },
        '.fr-second-toolbar': {
          border: `1px solid rgb(226, 232, 240) !important`,
          borderBottomLeftRadius: `${marketplace?.theme?.border_radius_1} !important`,
          borderBottomRightRadius: `${marketplace?.theme?.border_radius_1} !important`,
        },
      },
    },
    fonts: {
      heading: marketplace?.theme?.font_1 ?? "'Inter', sans-serif",
      body: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
      font_1: marketplace?.theme?.font_1 ?? "'Inter', sans-serif",
      font_2: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
    },
    border_radius: {
      border_radius_1: `${marketplace?.theme?.border_radius_1}`,
      border_radius_2: `${marketplace?.theme?.border_radius_2}`,
    },
    colors: {
      text: getColorSwatch(mainText),
      mainBG,
      mainText,
      navBG,
      navText,
      primary: chroma.valid(marketplace?.theme?.primary_color)
        ? getColorSwatch(marketplace?.theme?.primary_color)
        : DEFAULT_PRIMARY_COLOR_SCHEME,
      secondary: chroma.valid(marketplace?.theme?.secondary_color)
        ? getColorSwatch(marketplace?.theme?.secondary_color)
        : DEFAULT_SECONDARY_COLOR_SCHEME,
    },
    components: {
      Alert: {
        baseStyle: {
          container: {
            fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
            borderRadius: `${marketplace?.theme?.border_radius_2}`,
          },
        },
      },
      Heading: {
        baseStyle: {
          fontFamily: marketplace?.theme?.font_1 ?? "'Inter', sans-serif",
        },
      },
      Input: {
        baseStyle: {
          field: {
            borderRadius: `${marketplace?.theme?.border_radius_1}`,
            fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
          },
          addon: {
            borderRadius: `${marketplace?.theme?.border_radius_1}`,
          },
        },
      },
      NumberInput: {
        baseStyle: {
          field: {
            borderRadius: `${marketplace?.theme?.border_radius_1}`,
            fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
          },
        },
      },
      Textarea: {
        baseStyle: {
          borderRadius: `${marketplace?.theme?.border_radius_1}`,
          fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
        },
      },
      FormLabel: {
        baseStyle: {
          field: {
            fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
          },
        },
      },
      Menu: {
        baseStyle: {
          item: {
            color: BLACK,
            fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
          },
        },
      },
      ButtonGroup: {
        baseStyle: {
          Button: {
            color: BLACK,
            borderRadius: `${marketplace?.theme?.border_radius_1}`,
            fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
          },
        },
      },
      Button: {
        baseStyle: {
          color: BLACK,
          borderRadius: `${marketplace?.theme?.border_radius_1}`,
          fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
        },
      },
      Select: {
        baseStyle: {
          field: {
            borderRadius: `${marketplace?.theme?.border_radius_1}`,
            fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
          },
        },
      },
      Drawer: {
        baseStyle: {
          dialog: {
            color: BLACK,
          },
        },
      },
      Tag: {
        baseStyle: {
          container: {
            fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
          },
          label: {
            textTransform: 'uppercase',
          },
        },
      },
      Popover: {
        baseStyle: {
          content: {
            color: BLACK,
            borderRadius: `${marketplace?.theme?.border_radius_2}`,
            fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
          },
        },
      },
      Switch: {
        baseStyle: {
          track: {
            _checked: {
              bg: `${marketplace?.theme?.primary_color}`,
            },
          },
        },
      },
      Breadcrumb: {
        baseStyle: {
          item: {
            fontFamily: marketplace?.theme?.font_2 ?? "'Inter', sans-serif",
          },
        },
      },
      Modal: {
        baseStyle: {
          dialog: {
            borderRadius: `${marketplace?.theme?.border_radius_2}`,
          },
        },
      },
      Card: {
        baseStyle: {
          container: {
            borderRadius: `${marketplace?.theme?.border_radius_2}`,
          },
        },
      },
    },
  };
  const theme = extendTheme(overrides);

  const toastOptions = {
    defaultOptions: {
      containerStyle: {
        bordeRadius: `${marketplace?.theme?.border_radius_2}`,
        fontFamily: "'Inter', sans-serif",
      },
    },
  } as ToastProviderProps;

  return (
    <ChakraProvider theme={theme} toastOptions={toastOptions}>
      {Array.isArray(children)
        ? children.map((child, index) => <Fragment key={index}>{child}</Fragment>)
        : children}
    </ChakraProvider>
  );
};

export default ThemeProvider;
