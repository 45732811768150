import { AddListingBanner, CollectEmailsModule } from 'src/components/atoms';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';

interface Props {
  page: string;
}

export const GeneralCtaBanner = ({ page }: Props) => {
  const { hasRole } = useAuth();
  const { marketplace } = useMarketplaceContext();
  const isSubscriber = hasRole(marketplace?.id?.toString() ?? '', 'Subscriber');

  const collectEmailModule = marketplace?.functional?.collect_email_module;
  const addListingBanner = marketplace?.functional?.collect_email_module;

  const showEmailModule =
    !isSubscriber &&
    collectEmailModule &&
    collectEmailModule.title &&
    collectEmailModule.pages.includes(page);
  const showAddListingBanner =
    !showEmailModule &&
    addListingBanner &&
    addListingBanner.title &&
    addListingBanner.pages.includes(page);

  return (
    <>
      {showEmailModule && <CollectEmailsModule page={page} />}
      {showAddListingBanner && <AddListingBanner page={page} />}
    </>
  );
};

export default GeneralCtaBanner;
