import {
  Avatar,
  AspectRatio,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  VStack,
  useTheme,
} from '@chakra-ui/react';
import { MdOutlineDelete } from 'react-icons/md';
import { CartItemInterface } from 'src/api/v1-api';
import { formatPrice } from 'src/utils/common';
import { useAuth } from 'src/utils/auth';
import { useContext, useState } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { apiRequest } from 'src/utils/fetchUtils';
import { srcFromCDN } from 'src/utils/files';
import AttributeValuesDisplay from '../attribute-values-display';
import { getListingImage } from 'src/utils/listingUtils';

interface CartItemProps {
  item: CartItemInterface;
  onUpdate: () => void;
}

export const CartItem = ({ item, onUpdate }: CartItemProps): JSX.Element => {
  const theme = useTheme();
  const { getToken, getLomaSessionId } = useAuth();
  const { marketplace } = useContext(MarketplaceContext);

  const [quantity, setQuantity] = useState(item?.quantity);
  const variant_quantity = item?.variant?.quantity;
  const maxQuantity = 10 + quantity > variant_quantity ? variant_quantity : 10 + quantity;
  const [isLoading, setLoading] = useState(false);
  const [quantityErrorMessage, setQuantityErrorMessage] = useState<string | null>(null);
  const [removeErrorMessage, setRemoveErrorMessage] = useState<string | null>(null);

  const handleQuantityChange = async (value: string) => {
    setLoading(true);
    setQuantityErrorMessage(null);
    const newQuantity = Number(value);
    if (newQuantity > 0 && newQuantity <= maxQuantity) {
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/cart/modify/${marketplace?.id}/${item.variant.id}/`;
      const token = await getToken();
      const body = {
        quantity: newQuantity,
        lomasessionid: getLomaSessionId(),
      };
      try {
        const response = await apiRequest('PUT', url, token, body);
      } catch (error) {
        setQuantityErrorMessage('Error updating quantity');
      }
      setQuantity(newQuantity);
      onUpdate();
    }
    setLoading(false);
  };

  const handleRemove = async () => {
    setLoading(true);
    setRemoveErrorMessage(null);
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/cart/remove/${marketplace?.id}/${item.variant.id}/`;
    const token = await getToken();
    const body = {
      lomasessionid: getLomaSessionId(),
    };
    try {
      const response = await apiRequest('DELETE', url, token, body);
    } catch (error) {
      setRemoveErrorMessage('Error removing item');
    }
    onUpdate();
    setLoading(false);
  };

  const featuredImage = getListingImage(item?.variant?.listing?.listing_images);

  return (
    <Flex direction={{ base: 'column', md: 'row' }} justify="space-between">
      <Stack direction="row" spacing="3" w={{ base: 'full', md: 3 / 4 }}>
        <AspectRatio
          ratio={1}
          minWidth="140px"
          maxWidth="140px"
          minHeight="120px"
          maxHeight="120px"
        >
          <Image
            align="center"
            src={srcFromCDN(featuredImage, 140)}
            alt={item?.variant?.listing?.title}
            style={{ objectFit: 'cover' }}
            draggable="false"
            borderRadius={theme.border_radius.border_radius_2}
          />
        </AspectRatio>
        <VStack alignItems="flex-start" justifyContent="space-between">
          <VStack alignItems="flex-start">
            <HStack spacing="1">
              <Image
                borderRadius="full"
                boxSize="6"
                src={
                  item?.variant?.listing?.marketplace_account?.image_url ||
                  item?.variant?.listing?.marketplace_account?.icon_url
                    ? srcFromCDN(
                        item?.variant?.listing?.marketplace_account?.image_url ||
                          item?.variant?.listing?.marketplace_account?.icon_url,
                        32,
                      )
                    : undefined
                }
                alt="Seller Avatar"
                fallback={<Avatar size="xs" />}
              />
              <Text fontSize="xs" fontWeight="semibold">
                {item?.variant?.listing?.marketplace_account?.handle}
              </Text>
            </HStack>
            <Stack spacing="0.5">
              <Text fontWeight="medium" data-test="listing-title">
                {item?.variant?.listing?.title}
              </Text>
            </Stack>
          </VStack>

          <AttributeValuesDisplay
            noMedia={true}
            attributeValues={item?.variant?.variant_attribute_values || []}
          />

          {maxQuantity > 1 && (
            <Flex columnGap={2} fontSize={{ base: 'xs', md: 'sm' }} flexWrap="wrap">
              <HStack>
                <Text>Quantity:</Text>
                <NumberInput
                  defaultValue={quantity}
                  min={1}
                  max={maxQuantity}
                  size="xs"
                  w="14"
                  onChange={handleQuantityChange}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </HStack>
              {quantityErrorMessage && (
                <Text fontSize="xs" color="red.500">
                  {quantityErrorMessage}
                </Text>
              )}
            </Flex>
          )}
        </VStack>
      </Stack>
      <Stack
        direction={{ base: 'row', md: 'column' }}
        alignItems={{ base: 'center', md: 'flex-end' }}
        justifyContent={{ base: 'space-between', md: 'flex-end' }}
        spacing={6}
        px={4}
        pt={2}
      >
        <Text>
          {formatPrice(Number(item?.variant?.offered_price || item?.variant?.regular_price))}
        </Text>
        <Button onClick={handleRemove} isDisabled={isLoading}>
          <Icon as={MdOutlineDelete} boxSize={6} color="red.400" cursor="pointer" hideBelow="md" />
          {/* TODO(Sean): Delete button text on mobile is missing when wrapped with Button */}
          <Text fontSize="sm" textDecor="underline" cursor="pointer" hideFrom="md">
            Delete
          </Text>
        </Button>
        {removeErrorMessage && (
          <Text fontSize="xs" color="red.500">
            {removeErrorMessage}
          </Text>
        )}
      </Stack>
    </Flex>
  );
};
