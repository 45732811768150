import React from 'react';
import dynamic from 'next/dynamic';

const TextEditor = dynamic(
  () => {
    // @ts-ignore
    import('froala-editor/js/plugins/link.min.js');
    // @ts-ignore
    import('froala-editor/js/plugins/paragraph_format.min.js');
    // @ts-ignore
    import('froala-editor/js/plugins/emoticons.min.js');
    // @ts-ignore
    import('froala-editor/js/plugins/lists.min.js');
    // @ts-ignore
    import('froala-editor/js/plugins/align.min.js');
    return import('react-froala-wysiwyg');
  },
  { ssr: false },
);

interface RichTextEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const TOOLBAR_BUTTONS = [
  'alignLeft',
  'alignCenter',
  'alignRight',
  'alignJustify',
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'subscript',
  'superscript',
  'formatOL',
  'formatUL',
  'insertLink',
  'undo',
  'redo',
];

const RichTextEditor = ({ value, onChange }: RichTextEditorProps) => {
  return (
    <TextEditor
      tag="textarea"
      model={value}
      onModelChange={(newValue: string) => onChange(newValue)}
      config={{
        fontFamilyDefaultSelection: 'inherit',
        fontSizeDefaultSelection: 16,
        toolbarButtons: TOOLBAR_BUTTONS,
      }}
    />
  );
};

export default RichTextEditor;
