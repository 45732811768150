import { Button, useToast } from '@chakra-ui/react';
import { useAuth } from 'src/utils/auth';
import { apiRequest, handleNextRevalidation } from 'src/utils/fetchUtils';
import { Listing } from 'src/api/v1-api';
import { useContext, useState } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useRouter } from 'next/router';

interface DeactivateListingButtonProps {
  listing: Listing | null;
}

const DeactivateListingButton = ({ listing }: DeactivateListingButtonProps) => {
  const { getToken } = useAuth();
  const { marketplace } = useContext(MarketplaceContext);
  const toast = useToast();
  const router = useRouter();

  const [isDeleting, setIsDeleting] = useState(false);

  const deactivateListing = async () => {
    const confirmed = confirm('Are you sure you want to remove this listing?');
    if (!confirmed) return;

    setIsDeleting(true);
    const fetchUrl = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${listing?.id}/${marketplace?.id}/deactivate/`;
    const body = { active: false };
    const token = await getToken();

    try {
      await apiRequest('POST', fetchUrl, token, body);
      router.push('/?success=true&message=Listing+removed');
    } catch (err) {
      toast({
        title: 'An unexpected error occurred.',
        description: 'Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
      setIsDeleting(false);
    }
  };

  return (
    <Button
      colorScheme="red"
      variant="outline"
      size="xs"
      onClick={deactivateListing}
      isLoading={isDeleting}
    >
      Remove
    </Button>
  );
};

export default DeactivateListingButton;
