export const CATEGORY_TYPE_MARKETPLACE = 'marketplace';
export const CATEGORY_TYPE_LEADS = 'leads';
export const CATEGORY_TYPE_DIRECTORY = 'directory';
export const CATEGORY_TYPE_AUCTION = 'auction';

export const FACEBOOK = 'facebook';
export const CRAIGSLIST = 'craigslist';

// minimum in dollars
export const MINIMUM_PRICE_ALLOWED = 2;
export const MINIMUM_BID_INCREMENT = 5;

export const LISTINGS_PER_PAGE = 12;
export const MAP_LISTINGS_PER_PAGE = 100;

export const PREFETCHED_LISTING_STATUSES = [
  'active',
  'sold',
  'candidate',
  'broken_image_active',
  'broken_image_candidate',
];
export const ADMIN_ONLY_LISTING_STATUSES = ['deleted', 'inactive'];
export const AUTHOR_ONLY_LISTING_STATUSES = [
  'admin_deactivated',
  'candidate_rejected',
  'draft',
  'pending',
  'pending_payment',
  'expired',
];
