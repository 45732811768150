import React, { ReactNode, ErrorInfo } from 'react';
import * as Sentry from '@sentry/nextjs';
import { ErrorPage, ToastComponent } from 'src/components/system';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: ReactNode;
  displayAsToast?: boolean;
  toastId?: string;
  toastTitle?: string;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Gather additional context
    const errorContext = {
      error: {
        name: error.name,
        message: error.message,
        stack: error.stack,
      },
      errorInfo,
      componentStack: errorInfo.componentStack,
      location: {
        href: window?.location?.href,
        pathname: window?.location?.pathname,
        search: window?.location?.search,
        hash: window?.location?.hash,
      },
      timestamp: new Date().toISOString(),
      userAgent: window?.navigator?.userAgent,
    };

    // Log the error to Sentry with enhanced context
    Sentry.captureException(error, {
      extra: errorContext,
      tags: {
        component: 'ErrorBoundary',
        errorType: error.name,
        path: window?.location?.pathname,
      },
    });

    console.error('Error Boundary Caught Error:', errorContext);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.displayAsToast) {
        Sentry.captureMessage('Error toast displayed', {
          level: 'error',
          extra: {
            location: {
              href: window?.location?.href,
              pathname: window?.location?.pathname,
              search: window?.location?.search,
            },
            toastId: this.props.toastId,
            toastTitle: this.props.toastTitle,
            timestamp: new Date().toISOString(),
            userAgent: window?.navigator?.userAgent,
          },
          tags: {
            errorType: 'Toast',
            component: 'ErrorBoundary',
          },
        });
        return <ToastComponent id={this.props.toastId} title={this.props.toastTitle} />;
      } else {
        Sentry.captureMessage('Error page displayed', {
          level: 'error',
          extra: {
            location: {
              href: window?.location?.href,
              pathname: window?.location?.pathname,
              search: window?.location?.search,
            },
            errorState: this.state,
            originalError: (this as any)._lastError,
            timestamp: new Date().toISOString(),
            userAgent: window?.navigator?.userAgent,
          },
          tags: {
            errorType: 'Page',
            component: 'ErrorBoundary',
          },
        });
        return <ErrorPage />;
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
