import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Spinner, Fade } from '@chakra-ui/react';
import { Category, Listing, RootCategory } from 'src/api/v1-api';
import { apiRequest } from 'src/utils/fetchUtils';
import { useAuth } from 'src/utils/auth';
import ListingCarousel from 'src/components/atoms/listing-carousel';

interface FeaturedListingsBarProps {
  requiresAuth?: boolean;
  actionButtons?: string;
  category?: Category | RootCategory | null;
}

const shuffleArray = (array: Listing[]) => {
  const shuffled = array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  return shuffled;
};

const FeaturedListingsBar: React.FC<FeaturedListingsBarProps> = ({
  requiresAuth,
  actionButtons = '',
  category,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [listings, setListings] = useState<Listing[]>([]);
  const { getToken } = useAuth();

  const shuffle_featured_listings = category?.functional?.shuffle_featured_listings || false;
  const inventoryDisplay = category?.functional?.inventory_display;
  let columns = 4;
  if (inventoryDisplay === '3_3_grid') {
    columns = 3;
  } else if (inventoryDisplay === '2_4_grid') {
    columns = 2;
  }

  const fetchListings = useCallback(async () => {
    if (!loading) {
      setLoading(true);
      try {
        let token = null;
        if (requiresAuth) {
          token = await getToken();
        }

        const fetchUrl = `${process.env.NEXT_PUBLIC_API_HOST}/listings/category/${category?.id}/?is_featured=true`;
        const response = await apiRequest('GET', fetchUrl, token);
        let data = await response.results;
        if (data.length > 2 && shuffle_featured_listings) {
          data = shuffleArray(data);
        }
        setListings(data);
      } catch (error) {
        console.error(error);
      }

      setLoading(false);
    }
  }, [getToken, loading, requiresAuth, category?.id, shuffle_featured_listings]);

  useEffect(() => {
    fetchListings();
  }, []);

  const displayCssProp = {
    base: listings.length >= 2 ? 'block' : 'none',
    md: listings.length >= 2 ? 'block' : 'none',
    lg: listings.length >= columns ? 'block' : 'none',
  };

  return (
    <Fade in={!loading && listings.length >= 2} style={{ transitionDuration: '1s' }}>
      <Flex direction="column" mb={8} position="relative" display={displayCssProp}>
        {loading && (
          <Box
            pt={8}
            pb={8}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={'rgba(255,255,255,0.7)'}
          >
            <Spinner />
          </Box>
        )}
        <ListingCarousel
          listings={listings}
          columns={columns}
          loading={loading}
          isFeatured={true}
          actionButtons={actionButtons}
          category={category}
        />
      </Flex>
    </Fade>
  );
};

export default FeaturedListingsBar;
