import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { EmailListForm } from 'src/components/molecules';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const EmailListModal: React.FC<LoginModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="gray.100" maxHeight="80vh">
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <EmailListForm onClose={onClose} />
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default EmailListModal;
