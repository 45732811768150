import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  Stack,
  AspectRatio,
  Box,
  Text,
  Select,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Lead, Listing } from 'src/api/v1-api';
import {
  CategoryAttributesDisplay,
  DateSinceAdded,
  ImageWithFallback,
  PriceTag,
} from 'src/components/atoms';
import { getListingImage } from 'src/utils/listingUtils';
import { useRouter } from 'next/router';
import AdminLeadForm from '../admin-lead-form';
import { filterVariants, getAvailableVariants, getSelectedVariant } from 'src/utils/listingUtils';
import { getAttributeOptions } from 'src/utils/attributeUtils';
import { useDeepEffect } from 'src/utils/hooks';
import useRequest from 'src/utils/hooks/useRequest';
import { categoryTypeHasPrice } from 'src/utils/categoryUtils';

interface LeadConciergeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdateLead: (leadForm: AdminLeadForm) => Promise<void>;
  lead: Lead | null;
  leadForm: AdminLeadForm;
  setLeadForm: React.Dispatch<React.SetStateAction<AdminLeadForm>>;
  disabled: boolean;
  marketplaceId?: number;
}

const LeadConciergeModal = (props: LeadConciergeModalProps) => {
  const { isOpen, onClose, onUpdateLead, lead, leadForm, setLeadForm, disabled, marketplaceId } =
    props;
  const router = useRouter();
  const { api } = useRequest();
  const [selectedAttributeValues, setSelectedAttributeValues] = useState<{
    [key: string]: string | number;
  }>({});
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);

  useEffect(() => {
    setLeadForm({
      ...leadForm,
      listingId: selectedListing?.id ?? 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListing, setLeadForm]);

  useEffect(() => {
    const fetchConciergeListing = async () => {
      if (marketplaceId && listings.length === 0) {
        const response = await api.marketplace.concierge_listings.get(marketplaceId);
        setListings(response);
      }
    };

    if (!lead) {
      fetchConciergeListing();
    } else {
      setSelectedListing(lead?.listing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead, marketplaceId]);

  const featuredImage = selectedListing ? getListingImage(selectedListing?.listing_images) : '';
  const availableVariants = getAvailableVariants(selectedListing?.variants);
  const attributeOptions = getAttributeOptions(availableVariants);

  useDeepEffect(() => {
    if (Object.keys(attributeOptions).length > 0) {
      Object.keys(attributeOptions).forEach((item) => {
        if (attributeOptions[item].length === 1) {
          setSelectedAttributeValues((prevValue) => ({
            ...prevValue,
            [item]: attributeOptions[item][0],
          }));
        }
      });
    }
  }, [attributeOptions]);

  const filteredVariants = filterVariants(availableVariants, selectedAttributeValues);
  const selectedVariant = getSelectedVariant(
    selectedListing?.variants,
    filteredVariants,
    selectedAttributeValues,
    attributeOptions,
  );

  const onUpdateHandler = () => {
    if (!selectedListing) {
      return;
    }

    onUpdateLead && onUpdateLead(leadForm);
    onCloseHandler();
  };

  const onCloseHandler = () => {
    setSelectedListing(null);
    onClose && onClose();
  };

  const modalTitle = lead ? 'Review Lead' : 'Add Lead';
  return (
    <Modal isCentered isOpen={isOpen} onClose={onCloseHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {selectedListing ? (
            <>
              <Box
                position="relative"
                maxWidth="250px"
                onClick={() => router.push(`/listing/${selectedListing?.id}`)}
                style={{ cursor: 'pointer' }}
              >
                <AspectRatio ratio={4 / 3}>
                  <Box borderRadius={{ base: 'md', md: 'xl' }} overflow="hidden">
                    <ImageWithFallback
                      key={featuredImage}
                      src={featuredImage ?? ''}
                      alt={selectedListing?.title ?? 'Listing Image'}
                      width={250}
                    />
                  </Box>
                </AspectRatio>
              </Box>
              <Stack height="full" pb={4}>
                <Stack height="full">
                  <Text fontWeight="medium" noOfLines={2}>
                    {selectedListing?.title ?? ''}
                  </Text>
                  <CategoryAttributesDisplay
                    attributeValues={
                      // only display location here, if it exists
                      selectedListing?.listing_attribute_values.filter(
                        (attr) => attr?.attribute?.name === 'Location',
                      ) ?? []
                    }
                  />
                  {selectedListing &&
                    categoryTypeHasPrice(selectedListing?.category_type) &&
                    !!selectedVariant?.offered_price && (
                      <Box mt="auto !important">
                        <PriceTag
                          regularPrice={selectedVariant?.regular_price}
                          offeredPrice={selectedVariant?.offered_price}
                          currency={selectedListing?.currency}
                        />
                        <DateSinceAdded listing={selectedListing} />
                      </Box>
                    )}
                </Stack>
              </Stack>

              <AdminLeadForm
                lead={lead}
                leadForm={leadForm}
                setLeadForm={setLeadForm}
                disabled={disabled}
              />
            </>
          ) : (
            <Select
              name="concierge_listings"
              placeholder="Select Concierge Listing"
              value={leadForm.listingId || undefined}
              onChange={(e) => {
                const newSelectedListingId = e.target.value;
                const newSelectedListing = listings?.find(
                  (option) => option.id === parseInt(newSelectedListingId),
                );
                setSelectedListing(newSelectedListing || null);
              }}
            >
              {listings?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.title}
                </option>
              ))}
            </Select>
          )}
        </ModalBody>
        <ModalFooter gap="2">
          <Button onClick={onCloseHandler}>Close</Button>
          {!disabled && selectedListing && (
            <Button colorScheme="primary" onClick={onUpdateHandler}>
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LeadConciergeModal;
