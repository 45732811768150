import {
  Alert,
  AlertDescription,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useToast,
  FormHelperText,
} from '@chakra-ui/react';
import { useAuth } from 'src/utils/auth';
import { useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import GoogleSigninButton from 'src/components/authentication/GoogleSigninButton';
import NextLink from 'next/link';
import { gaEvent } from 'src/utils/gtag';
import { MarketplaceContext } from 'src/contexts/marketplace';

interface SignupFormProps {
  onClose?: () => void;
  setShowSignUp?: (show: boolean) => void;
}

const SignupForm: React.FC<SignupFormProps> = ({ onClose, setShowSignUp }) => {
  const { signup, isAuthenticated } = useAuth();
  const router = useRouter();
  const toast = useToast();
  const { marketplace, marketplaceSlug } = useContext(MarketplaceContext);
  const hideGoogleAuth = Boolean(marketplace?.domain?.hide_google_auth);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleSuccessfulSignup = () => {
    if (onClose) {
      onClose();
    } else {
      router.push('/');
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleSuccessfulSignup();
    }
  }, [isAuthenticated, router]);

  const handleSignup = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    const names = name.split(' ');
    const firstName = names.shift();
    const lastName = names.join(' ');

    if (!marketplaceSlug) return;

    try {
      const response = await signup(email, password, firstName, lastName, marketplaceSlug);
      if (response.access === undefined) {
        if (response?.detail === 'The account is inactive') {
          setSuccessMessage('Verification Email has been sent to your email address.');
          setLoading(false);
          setEmail('');
          setPassword('');
          setName('');
        } else {
          toast({
            title: 'Error',
            description: 'Something went wrong. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setLoading(false);
        }
      } else {
        gaEvent({ action: 'sign_up', category: 'engagement', label: 'method', value: `${email}` });
        if (response.has_user_roles !== undefined && response.has_user_roles > 0) {
          router.push('/account/info');
        } else {
          handleSuccessfulSignup();
        }
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Stack spacing="6">
      <Stack spacing="6" align="center">
        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
          <Heading size={{ base: 'xs', md: 'sm' }}>Create an account</Heading>
          <HStack justify="center" spacing="1">
            <Text color="muted">Already have an account?</Text>
            {setShowSignUp ? (
              <Button
                variant="link"
                colorScheme="primary"
                onClick={() => setShowSignUp && setShowSignUp(false)}
                data-test="login-link"
              >
                Log in
              </Button>
            ) : (
              <NextLink href="/login">
                <Button variant="link" colorScheme="primary" size="sm">
                  Log in
                </Button>
              </NextLink>
            )}
          </HStack>
        </Stack>
      </Stack>
      <Box py={{ base: '0', sm: '8' }} px={{ base: '4', sm: '10' }} bg="white">
        <form onSubmit={handleSignup}>
          {!hideGoogleAuth && <GoogleSigninButton isRegister={true} />}
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl isRequired>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  id="name"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  data-test="name-input"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  data-test="email-input"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  id="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  data-test="password-input"
                />
                <FormHelperText color="muted">At least 8 characters long</FormHelperText>
              </FormControl>
            </Stack>
            <Stack spacing="4">
              <Button
                disabled={!marketplaceSlug}
                colorScheme="primary"
                size="md"
                type="submit"
                isLoading={loading}
                data-test="submit-btn"
              >
                Create account
              </Button>
            </Stack>
            {errorMessage && (
              <Alert status="error">
                <AlertDescription>{errorMessage}</AlertDescription>
              </Alert>
            )}
            {successMessage && (
              <Alert status="success">
                <AlertDescription>{successMessage}</AlertDescription>
              </Alert>
            )}
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};

export default SignupForm;
