function cleanStateInput(state: string) {
  if (state.length > 2) {
    const words = state.split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    state = words.join(' ');
  } else {
    state = state.toUpperCase();
  }

  return state;
}

function getStateTaxRate(state: string) {
  if (state === null || state === undefined) {
    return 0.0;
  }

  const stateInput = cleanStateInput(state);

  switch (stateInput) {
    case 'Alabama':
    case 'AL':
      return 0.04;
    case 'Alaska':
    case 'AK':
      return 0.0;
    case 'Arizona':
    case 'AZ':
      return 0.056;
    case 'Arkansas':
    case 'AR':
      return 0.065;
    case 'California':
    case 'CA':
      return 0.0725;
    case 'Colorado':
    case 'CO':
      return 0.029;
    case 'Connecticut':
    case 'CT':
      return 0.0635;
    case 'Delaware':
    case 'DE':
      return 0.0;
    case 'Florida':
    case 'FL':
      return 0.06;
    case 'Georgia':
    case 'GA':
      return 0.04;
    case 'Hawaii':
    case 'HI':
      return 0.0416;
    case 'Idaho':
    case 'ID':
      return 0.06;
    case 'Illinois':
    case 'IL':
      return 0.0625;
    case 'Indiana':
    case 'IN':
      return 0.07;
    case 'Iowa':
    case 'IA':
      return 0.06;
    case 'Kansas':
    case 'KS':
      return 0.065;
    case 'Kentucky':
    case 'KY':
      return 0.06;
    case 'Louisiana':
    case 'LA':
      return 0.0445;
    case 'Maine':
    case 'ME':
      return 0.055;
    case 'Maryland':
    case 'MD':
      return 0.06;
    case 'Massachusetts':
    case 'MA':
      return 0.0625;
    case 'Michigan':
    case 'MI':
      return 0.06;
    case 'Minnesota':
    case 'MN':
      return 0.06875;
    case 'Mississippi':
    case 'MS':
      return 0.07;
    case 'Missouri':
    case 'MO':
      return 0.04225;
    case 'Montana':
    case 'MT':
      return 0.0;
    case 'Nebraska':
    case 'NE':
      return 0.055;
    case 'Nevada':
    case 'NV':
      return 0.0685;
    case 'New Hampshire':
    case 'NH':
      return 0.0;
    case 'New Jersey':
    case 'NJ':
      return 0.06625;
    case 'New Mexico':
    case 'NM':
      return 0.05125;
    case 'New York':
    case 'NY':
      return 0.04;
    case 'North Carolina':
    case 'NC':
      return 0.0475;
    case 'North Dakota':
    case 'ND':
      return 0.05;
    case 'Ohio':
    case 'OH':
      return 0.0575;
    case 'Oklahoma':
    case 'OK':
      return 0.045;
    case 'Oregon':
    case 'OR':
      return 0.0;
    case 'Pennsylvania':
    case 'PA':
      return 0.06;
    case 'Rhode Island':
    case 'RI':
      return 0.07;
    case 'South Carolina':
    case 'SC':
      return 0.06;
    case 'South Dakota':
    case 'SD':
      return 0.045;
    case 'Tennessee':
    case 'TN':
      return 0.07;
    case 'Texas':
    case 'TX':
      return 0.0625;
    case 'Utah':
    case 'UT':
      return 0.047;
    case 'Vermont':
    case 'VT':
      return 0.06;
    case 'Virginia':
    case 'VA':
      return 0.053;
    case 'Washington':
    case 'WA':
      return 0.065;
    case 'West Virginia':
    case 'WV':
      return 0.06;
    case 'Wisconsin':
    case 'WI':
      return 0.05;
    case 'Wyoming':
    case 'WY':
      return 0.04;
    default:
      return 0.0;
  }
}

function getStateDigitalTaxRate(state: string) {
  if (state === null || state === undefined) {
    return 0.0;
  }

  const stateInput = cleanStateInput(state);

  switch (stateInput) {
    case 'Alabama':
    case 'AL':
      return 0.04;
    case 'Alaska':
    case 'AK':
      return 0.0;
    case 'Arizona':
    case 'AZ':
      return 0.056;
    case 'Arkansas':
    case 'AR':
      return 0.0;
    case 'California':
    case 'CA':
      return 0.0;
    case 'Colorado':
    case 'CO':
      return 0.029;
    case 'Connecticut':
    case 'CT':
      return 0.01;
    case 'Delaware':
    case 'DE':
      return 0.0;
    case 'Florida':
    case 'FL':
      return 0.0;
    case 'Georgia':
    case 'GA':
      return 0.0;
    case 'Hawaii':
    case 'HI':
      return 0.0416;
    case 'Idaho':
    case 'ID':
      return 0.06;
    case 'Illinois':
    case 'IL':
      return 0.0;
    case 'Indiana':
    case 'IN':
      return 0.07;
    case 'Iowa':
    case 'IA':
      return 0.0;
    case 'Kansas':
    case 'KS':
      return 0.0;
    case 'Kentucky':
    case 'KY':
      return 0.06;
    case 'Louisiana':
    case 'LA':
      return 0.0445;
    case 'Maine':
    case 'ME':
      return 0.055;
    case 'Maryland':
    case 'MD':
      return 0.06;
    case 'Massachusetts':
    case 'MA':
      return 0.0;
    case 'Michigan':
    case 'MI':
      return 0.0;
    case 'Minnesota':
    case 'MN':
      return 0.06875;
    case 'Mississippi':
    case 'MS':
      return 0.07;
    case 'Missouri':
    case 'MO':
      return 0.0;
    case 'Montana':
    case 'MT':
      return 0.0;
    case 'Nebraska':
    case 'NE':
      return 0.055;
    case 'Nevada':
    case 'NV':
      return 0.0;
    case 'New Hampshire':
    case 'NH':
      return 0.0;
    case 'New Jersey':
    case 'NJ':
      return 0.06625;
    case 'New Mexico':
    case 'NM':
      return 0.05125;
    case 'New York':
    case 'NY':
      return 0.0;
    case 'North Carolina':
    case 'NC':
      return 0.0475;
    case 'North Dakota':
    case 'ND':
      return 0.0;
    case 'Ohio':
    case 'OH':
      return 0.0575;
    case 'Oklahoma':
    case 'OK':
      return 0.0;
    case 'Oregon':
    case 'OR':
      return 0.0;
    case 'Pennsylvania':
    case 'PA':
      return 0.06;
    case 'Rhode Island':
    case 'RI':
      return 0.0;
    case 'South Carolina':
    case 'SC':
      return 0.0;
    case 'South Dakota':
    case 'SD':
      return 0.045;
    case 'Tennessee':
    case 'TN':
      return 0.07;
    case 'Texas':
    case 'TX':
      return 0.0625;
    case 'Utah':
    case 'UT':
      return 0.047;
    case 'Vermont':
    case 'VT':
      return 0.06;
    case 'Virginia':
    case 'VA':
      return 0.0;
    case 'Washington':
    case 'WA':
      return 0.065;
    case 'West Virginia':
    case 'WV':
      return 0.0;
    case 'Wisconsin':
    case 'WI':
      return 0.05;
    case 'Wyoming':
    case 'WY':
      return 0.04;
    default:
      return 0.0;
  }
}

function cleanAmountInput(amount: string) {
  if (amount === null || amount === undefined) {
    return 0.0;
  }

  const amountInput = amount.replace(/[^0-9.]/g, '');
  return parseFloat(amountInput);
}

export const calculateTaxAmount = (state: string, amount: number | string | undefined) => {
  if (amount === null || amount === undefined) {
    return 0.0;
  }
  const taxableAmount = cleanAmountInput(amount.toString());
  const taxRate = getStateTaxRate(state);
  return (taxableAmount * taxRate).toFixed(2);
};

export const calculateDigitalTaxAmount = (state: string, amount: number | string | undefined) => {
  if (amount === null || amount === undefined) {
    return 0.0;
  }
  const taxableAmount = cleanAmountInput(amount.toString());
  const taxRate = getStateDigitalTaxRate(state);
  return (taxableAmount * taxRate).toFixed(2);
};
