import React, { useState } from 'react';
import { Alert, AlertTitle, Box, Flex, Text, Button } from '@chakra-ui/react';
import { LoginModal } from 'src/components/organisms';
import { FiUser } from 'react-icons/fi';

interface LoginPromptProps {
  message: string;
  showWithModalOpen?: boolean;
  showWithSignup?: boolean;
}

const LoginPrompt: React.FC<LoginPromptProps> = ({
  message,
  showWithModalOpen = true,
  showWithSignup = false,
}) => {
  const [showModal, setShowModal] = useState(showWithModalOpen);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Flex mb={{ base: 2, md: 4, lg: 6 }} justify="center">
      <Alert
        status="info"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        minHeight="150px"
        width={{ base: '90%', md: '60%', lg: '40%' }}
        borderRadius="lg"
        p={4}
        backgroundColor="#f1f2f4"
      >
        <FiUser size={40} />
        <AlertTitle mt={4} mb={1} fontSize="lg" lineHeight="1.2">
          <Box as="span" display="inline-block" data-test="login-prompt-message">
            Please&nbsp;
            <Button variant="link" onClick={openModal}>
              <Text as="span" textDecoration="underline">
                log in/sign up
              </Text>
            </Button>
            &nbsp;{message}
          </Box>
        </AlertTitle>
      </Alert>

      <LoginModal isOpen={showModal} onClose={closeModal} showSignUpOnLoad={showWithSignup} />
    </Flex>
  );
};

export default LoginPrompt;
