import regexifyString from 'regexify-string';
import { Link } from '@chakra-ui/react';
import NextLink from 'next/link';

const RenderLinks = ({ text }: { text: string }): JSX.Element => {
  const result = regexifyString({
    pattern: /([])|\[(.*?)\]\((.*?)\)/gm,
    decorator: (match, index, result) => {
      return (
        <Link isExternal textDecor="underline" as={NextLink} href={String(result?.[3])}>
          {result?.[2]}
        </Link>
      );
    },
    input: text,
  });
  return <>{result}</>;
};

export default RenderLinks;
