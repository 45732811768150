import { all, call, put, takeLatest } from 'redux-saga/effects';
import axios from 'src/utils/axios';
import { Post, TestApi } from 'src/api/test';
import { globalActions } from '../global';
import { testActions } from './test.slice';
import { GET_POSTS, GET_POST } from './test.helpers';

export default function* testSaga() {
  yield all([
    takeLatest(GET_POSTS, function* (action: ReturnType<typeof testActions.getPosts>) {
      try {
        yield put(globalActions.setLoading({ actionType: action.type, loading: true }));
        const res: { data: Post[] } = yield call(() => axios.get(TestApi.getPosts()));
        yield put(testActions.setPosts(res.data));
      } catch (error) {
        yield put(globalActions.handleError({ actionType: action.type, error }));
      } finally {
        yield put(globalActions.setLoading({ actionType: action.type, loading: false }));
      }
    }),
    takeLatest(GET_POST, function* (action: ReturnType<typeof testActions.getPost>) {
      try {
        yield put(globalActions.setLoading({ actionType: action.type, loading: true }));
        const res: { data: Post } = yield call(() =>
          axios.get(`${TestApi.getPosts()}/${action.payload}`),
        );
        yield put(testActions.setState({ post: res.data }));
      } catch (error) {
        yield put(globalActions.handleError({ actionType: action.type, error }));
      } finally {
        yield put(globalActions.setLoading({ actionType: action.type, loading: false }));
      }
    }),
  ]);
}
