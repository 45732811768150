import {
  Box,
  Flex,
  SimpleGrid,
  Spinner,
  Text,
  Button,
  Icon,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  useToast,
  useTheme,
} from '@chakra-ui/react';
import { FiPlusCircle } from 'react-icons/fi';
import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Pagination } from 'src/components/atoms';
import { ListingCard } from 'src/components/molecules';
import {
  Category,
  RootCategory,
  Listing,
  AttributeOptions,
  ListingSelected,
  PaginatedListingsResponse,
} from 'src/api/v1-api';
import { apiRequest } from 'src/utils/fetchUtils';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';
import { useAuth } from 'src/utils/auth';
import { useRouter } from 'next/router';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { FaChevronDown } from 'react-icons/fa';
import CategorySelectorModal from 'src/components/molecules/category-selector-modal';
import { LISTINGS_PER_PAGE } from 'src/constants/marketplace';

interface Props {
  propListings?: PaginatedListingsResponse;
  fetchUrl: string;
  requiresAuth?: boolean;
  inventoryDisplay?: string;
  paginationStyle?: string;
  resultsPerPage?: number;
  actionButtons?: string;
  category?: Category | RootCategory | null;
  propNumDisplayListings?: number;
  userChangedPriceRange?: boolean;
  deactivatedListings?: boolean;
}

const ListingsGrid = ({
  propListings,
  fetchUrl,
  userChangedPriceRange,
  requiresAuth = false,
  inventoryDisplay = '4_2_grid',
  paginationStyle = 'prev_next',
  resultsPerPage = LISTINGS_PER_PAGE,
  actionButtons = '',
  category = null,
  propNumDisplayListings = LISTINGS_PER_PAGE,
  deactivatedListings,
}: Props): JSX.Element => {
  const router = useRouter();
  const { selectedAttributes, priceRange, selectedSort, page, setPage, maxPrice } =
    useAttributeSelector();
  const { getToken } = useAuth();
  const theme = useTheme();

  const listingsGridRef = useRef<HTMLDivElement>(null);

  if (typeof resultsPerPage === 'string') {
    resultsPerPage = parseInt(resultsPerPage);
  }

  const toast = useToast();
  const [listings, setListings] = useState<Listing[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [haveFetched, setHaveFetched] = useState<boolean>(false);
  const [categorySelectorSelectedId, setCategorySelectorSelectedId] = useState<
    string | undefined
  >();
  const [categorySelectorListingId, setCategorySelectorListingId] = useState<string | undefined>();
  const [rerouting, setRerouting] = useState<boolean>(false);
  const [nextLink, setNextLink] = useState<string | null>(null);
  const [prevLink, setPrevLink] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [selectedListings, setSelectedListings] = useState<ListingSelected[]>([]);
  const [displayedListings, setDisplayedListings] = useState<Listing[]>([]);
  const [numDisplayListings, setNumDisplayListings] = useState<number>(propNumDisplayListings);

  const { marketplace } = useContext(MarketplaceContext);

  let columns = 4;
  if (inventoryDisplay === '3_3_grid') {
    columns = 3;
  } else if (inventoryDisplay === '2_4_grid') {
    columns = 2;
  }

  const shouldUsePropListings =
    !!propListings?.results &&
    page === 1 &&
    !Object.keys(selectedAttributes).length &&
    priceRange[0] === 0 &&
    priceRange[1] === 0 &&
    !selectedSort;

  useEffect(() => {
    if (propListings?.results && shouldUsePropListings) {
      setListings(propListings.results);
      setNextLink(propListings.next);
      setPrevLink(propListings.previous);
      setTotalPages(propListings.count / resultsPerPage);
      setPage(propListings.page_number);
      setHaveFetched(true);
    }
  }, [propListings, shouldUsePropListings]);

  const handleChangeSelected = (id: number, checked: boolean) => {
    setSelectedListings((st) =>
      st.map((l) => {
        if (l.listingId === id) {
          return { ...l, isSelected: checked };
        }
        return l;
      }),
    );
  };

  const fetchListings = useCallback(
    async (
      url: string,
      filters: AttributeOptions = {},
      priceRange: [number, number],
      page: number,
    ) => {
      if (!loading && page > 0) {
        setLoading(true);

        try {
          const params = new URLSearchParams();

          // If there's a price range and the user has set a value, add it to the params
          if (priceRange[0] !== 0 || (priceRange[1] !== 0 && priceRange[1] != maxPrice)) {
            params.append('price', `${priceRange[0]}-${priceRange[1]}`);
          }

          // Convert filters object to query parameters
          for (const [key, values] of Object.entries(filters)) {
            if (key === 'page' && url.includes('page')) {
              continue;
            }
            for (const value of values) {
              if (value.toString().length > 0) {
                params.append(key, value.toString());
              }
            }
          }
          const paramsString = params.toString();
          const fullUrl = paramsString ? `${url}&${paramsString}` : url;

          let token = null;
          if (requiresAuth) {
            token = await getToken();
          }

          const response = await apiRequest('GET', fullUrl, token);
          setListings(response.results);
          setSelectedListings((st) => {
            const ids = new Set(st.map((d) => d.listingId));
            let responseFiltered = response.results.filter((x: Listing) => !ids.has(x.id));
            responseFiltered = responseFiltered.map((x: Listing) => ({
              listingId: x.id,
              isSelected: false,
            }));
            return [...st, ...responseFiltered];
          });
          setNextLink(response.next);
          setPrevLink(response.previous);
          setTotalPages(response.count / resultsPerPage);
          setPage(response.page_number);
          setHaveFetched(true);
        } catch (error) {
          console.error(error);
        }
        setLoading(false);
        if (userChangedPriceRange) {
          listingsGridRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    [getToken, loading, requiresAuth, userChangedPriceRange],
  );

  const addListingToDisplayedListings = () => {
    setNumDisplayListings((num) => num + 1);
  };

  useEffect(() => {
    setDisplayedListings(listings.slice(0, numDisplayListings));
  }, [listings, numDisplayListings]);

  useEffect(() => {
    // We need to avoid loading when page is 0. This means that the app is
    // currently loading and waiting the page to be updated from query string.
    // After this is set to page = 1 or page = query params page, it should
    // request the data from the api.
    if (page === 0) {
      return;
    }
    setRerouting(false);

    const url = `${fetchUrl}/?page_size=${resultsPerPage}&page=${page}${
      selectedSort ? `&sort=${selectedSort}` : ''
    }`;

    const shouldFetchListings =
      !url.includes('undefined') &&
      inventoryDisplay !== 'None' &&
      !shouldUsePropListings &&
      !haveFetched;

    if (shouldFetchListings) {
      fetchListings(url, selectedAttributes, priceRange, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedListings,
    selectedAttributes,
    priceRange,
    fetchUrl,
    page,
    selectedSort,
    haveFetched,
    shouldUsePropListings,
  ]);

  useEffect(() => {
    // Reset the page to 1 when the filters change
    setPage(1);
    setHaveFetched(false);
  }, [selectedAttributes, priceRange, selectedSort]);

  const updateQueryParamAndContextPage = (link?: string | null) => {
    if (link) {
      let pageNumber = '';
      try {
        pageNumber = new URL(link).searchParams.get('page') ?? '1';
      } catch (error) {
        pageNumber = link.split('page=')[1];
      }
      let pageQueryName = 'page';
      if (deactivatedListings) {
        pageQueryName = 'deactivated_listings_page';
      }
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set(pageQueryName, pageNumber);
      setPage(parseInt(pageNumber));
      setNumDisplayListings(propNumDisplayListings);
      const searchParams = new URLSearchParams();
      currentUrl.searchParams.forEach((value, key) => {
        searchParams.append(key, value);
      });
      const newUrl = `${currentUrl.origin}${currentUrl.pathname}?${searchParams.toString()}`;
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
      setHaveFetched(false);
    }
  };

  const handleNextPage = () => {
    if (nextLink) {
      setRerouting(true);
      updateQueryParamAndContextPage(nextLink);
    }
  };

  const handlePrevPage = () => {
    if (prevLink) {
      setRerouting(true);
      updateQueryParamAndContextPage(prevLink);
    }
  };

  const removeListing = (id: number) => {
    setSelectedListings(selectedListings.filter((selListing) => selListing.listingId !== id));
    setListings(listings.filter((listing) => listing.id !== id));
  };

  const handleApproval = async (action: string) => {
    const selectedListingsIds = selectedListings
      .filter((x) => x.isSelected)
      .map((x) => x.listingId);
    if (selectedListingsIds && selectedListingsIds.length > 0) {
      const token = await getToken();
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${marketplace?.id}/bulk-${action}/`;
      try {
        setLoading(true);
        await apiRequest('POST', url, token, {
          listing_ids: selectedListingsIds,
          action,
        });
        setLoading(false);
        router.reload();
      } catch (error) {
        toast({
          title: 'Error performing action',
          description: `Could not bulk ${action} listings.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      setLoading(false);
    }
  };

  const updateCategoryListingHandler = async (categoryId: string) => {
    if (!categoryId || !categorySelectorListingId) return;

    setLoading(true);
    const token = await getToken();

    try {
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${categorySelectorListingId}/${marketplace?.id}/update_category/`;
      const payload = {
        category_id: categoryId,
      };
      const updatedListing: Listing = await apiRequest('PUT', url, token, payload);
      const updatedListings = listings.map((listing: Listing) =>
        listing.id === updatedListing.id
          ? {
              ...listing,
              category_name: updatedListing?.category?.name,
              category_id: updatedListing?.category?.id,
              category_type: updatedListing?.category?.type,
            }
          : listing,
      );
      setListings(updatedListings);

      toast({
        title: 'Success',
        description: 'Re-populating listing attributes may take a minute',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || 'Error updating listing category';
      toast({
        title: 'Something went wrong',
        status: errorMessage,
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const anySelected = selectedListings.some((x: ListingSelected) => x.isSelected);

  return (
    <Flex direction="column" width="100%" gap={{ base: 6, md: 10 }} pb={8} data-test="listing-view">
      <Box ref={listingsGridRef} position="relative">
        {actionButtons && actionButtons === 'pendingListings' && (
          <Flex
            id="bulk-actions-bar"
            marginBottom={6}
            position={'sticky'}
            paddingY={'8px'}
            paddingLeft={'4px'}
            top={0}
            zIndex={4}
            w="100%"
            alignItems="center"
            bgColor={'blackAlpha.300'}
            borderRadius={4}
          >
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<FaChevronDown />}
                isDisabled={!anySelected}
                sx={{ opacity: '1 !important' }}
              >
                Bulk Actions
              </MenuButton>
              <MenuList zIndex={3}>
                <MenuItem
                  onClick={() => {
                    handleApproval('approve');
                  }}
                >
                  Approve Selected
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleApproval('deny');
                  }}
                >
                  Deny Selected
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        )}
        <SimpleGrid
          position="relative"
          columns={{
            base: 2,
            md: 2,
            lg: 4,
          }}
          columnGap={{ base: 2, md: 4 }}
          rowGap={{ base: 2, md: 4 }}
          minHeight={14}
        >
          {!loading && haveFetched && listings.length === 0 && (
            <Box
              gridColumn="span 4/span 4"
              textAlign="center"
              p={8}
              mt={4}
              border="1px solid"
              borderRadius={theme.border_radius.border_radius_2}
              borderColor="gray.300"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize="lg" color="gray">
                No listings yet
              </Text>
              {category?.functional?.show_create_listing_button && (
                <Button
                  mt={8}
                  colorScheme="primary"
                  onClick={() => router.push(`/listing/create?category=${category?.id}`)}
                >
                  <Icon as={FiPlusCircle} mr="2" />
                  {category?.functional?.text_overrides?.add_listing_btn || 'Add Listing'}
                </Button>
              )}
            </Box>
          )}
          {displayedListings.length > 0 &&
            displayedListings.map((listing) => (
              <ListingCard
                key={listing.id}
                listing={listing}
                actionButtons={actionButtons}
                removeListing={removeListing}
                category={category}
                loading={loading}
                handleChangeSelected={handleChangeSelected}
                listingSelected={selectedListings.find((x) => x.listingId === listing.id)}
                addListingToDisplayedListings={addListingToDisplayedListings}
                onCategoryEditClick={(
                  listingId: number | undefined,
                  categoryId: number | undefined,
                ) => {
                  setCategorySelectorListingId(listingId?.toString());
                  setCategorySelectorSelectedId(categoryId?.toString());
                }}
                columns={columns}
              />
            ))}
        </SimpleGrid>
        {(loading || rerouting) && (
          <Box
            pt={8}
            pb={8}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={'rgba(255,255,255,0.7)'}
          >
            <Spinner />
          </Box>
        )}
      </Box>
      <Pagination
        nextLink={nextLink}
        prevLink={prevLink}
        onNext={handleNextPage}
        onPrev={handlePrevPage}
        pageNumber={page.toString()}
        totalPages={totalPages}
      />
      <CategorySelectorModal
        isOpen={!!categorySelectorSelectedId}
        onClose={() => setCategorySelectorSelectedId(undefined)}
        onUpdateCategory={updateCategoryListingHandler}
        selectedCategoryId={categorySelectorSelectedId}
      />
    </Flex>
  );
};

export default ListingsGrid;
