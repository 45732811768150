import * as Sentry from '@sentry/nextjs';

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
};

export const isApexDomain = (hostname: string) => {
  return !(
    hostname.endsWith('.loma.tech') ||
    hostname.endsWith('.localhost:3030') ||
    hostname.endsWith('.localhost')
  );
};

export const makeHeaders = (token?: string, skipContentType?: boolean, accessToken?: string) => {
  const headers: HeadersInit = new Headers();

  if (!skipContentType) {
    headers.append('Content-Type', 'application/json');
  }
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }
  if (accessToken) {
    headers.append('X-USERACCESSTOKEN', accessToken);
  }

  // only add cross domain headers if we are on an apex domain
  const hostname = typeof window !== 'undefined' ? window.location.hostname : undefined;
  if (hostname && isApexDomain(hostname)) {
    headers.append('Access-Control-Allow-Credentials', 'true');
    headers.append('Access-Control-Allow-Origin', `https://${hostname}`);
    headers.append(
      'Access-Control-Allow-Methods',
      'GET,OPTIONS,PATCH,DELETE,POST,PUT,HEAD,authorization',
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Request-Methods, Access-Control-Request-Headers, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, X-HTTP-Method-Override, X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version, Vary, Authorization, Origin, baggage, sentry-trace, newrelic, traceparent, tracestate',
    );
    headers.append('Vary', 'Origin');
  }

  return headers;
};

export const apiRequest = async (
  method: string,
  url: string,
  token: string | null,
  body?: any,
  hasFiles?: boolean,
  urlParams?: any,
  accessToken?: string,
) => {
  if (!hasFiles) {
    body = body ? JSON.stringify(body) : body;
  }

  const headers = makeHeaders(token ?? undefined, hasFiles, accessToken);

  // Add the CSRF token to the headers for unsafe methods
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase())) {
    const csrftoken = getCookie('csrftoken');
    if (csrftoken) {
      headers.append('X-CSRFToken', csrftoken);
    }
  }

  const searchParams = urlParams ? `?${new URLSearchParams(urlParams).toString()}` : '';
  const fetchUrl = `${url}${searchParams}`;

  const response = await fetch(fetchUrl, {
    method,
    headers,
    body,
    credentials: 'include',
  });

  if (!response.ok) {
    let errorData;
    try {
      errorData = await response.json();
    } catch {
      errorData = null;
    }

    const error = new Error(
      `API request failed: ${response.status} ${response.statusText}${
        errorData?.error ? `. ${errorData.error}` : ''
      }`,
    );

    // Capture the exception with Sentry only in non-development environments
    if (process.env.NODE_ENV !== 'development') {
      Sentry.captureException(error, {
        extra: {
          url: url,
          method: method,
          status: response.status,
          errorData: errorData,
        },
      });
    }

    throw error;
  }

  try {
    return await response.json();
  } catch (err) {
    return response;
  }
};

export const handleNextRevalidation = async (path: string) => {
  const response = await fetch(`/api/revalidate`, {
    method: 'POST',
    headers: makeHeaders(),
    body: JSON.stringify({
      path: path,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const errorMsg = (errorData.message || 'Failed to revalidate') + ' | path: ' + path;
    throw new Error(errorMsg);
  }

  return response.json();
};
