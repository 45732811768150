import { Box, Checkbox, CheckboxGroup, Popover, Portal, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FilterPopoverButton, FilterPopoverContent } from 'src/components/atoms';
import { ListingFilterItemProps } from '.';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';

const CheckboxFilter = ({
  attributeId,
  isMobileView = false,
}: ListingFilterItemProps): JSX.Element => {
  const defaultValue: number[] | string[] | boolean[] = [];
  const [inputValue, setInputValue] = useState<(number | string | boolean)[]>(defaultValue);
  const {
    attributes,
    selectedAttributes,
    setSelectedAttributes,
    stagedAttributes,
    setStagedAttributes,
  } = useAttributeSelector();
  const attribute = attributes.find((a) => a.name === attributeId);

  const handleMobileChange = (value: (number | string)[]) => {
    setInputValue(value);
    setStagedAttributes({
      ...stagedAttributes,
      [attributeId]: value,
    });
  };

  useEffect(() => {
    if (attributeId in selectedAttributes) {
      setInputValue(selectedAttributes[attributeId]);
    }
  }, [selectedAttributes]);

  const noFilter =
    !selectedAttributes[attributeId] ||
    selectedAttributes[attributeId].length === 0 ||
    (selectedAttributes[attributeId].length === 1 && selectedAttributes[attributeId][0] === '');
  return isMobileView ? (
    <Box maxHeight="320px" overflowY="auto" overflowX="hidden">
      <Stack as="fieldset" spacing={2}>
        <CheckboxGroup
          onChange={handleMobileChange}
          value={inputValue as unknown as number[] | string[]}
        >
          {attribute?.options && Array.isArray(attribute.options)
            ? attribute.options
                .sort((a, b) => a.position - b.position)
                .map((option) => (
                  <Checkbox key={option.value} value={option.value} colorScheme="primary">
                    <span>{option.name}</span>
                  </Checkbox>
                ))
            : null}
        </CheckboxGroup>
      </Stack>
    </Box>
  ) : (
    <Popover placement="bottom-start">
      <FilterPopoverButton label={attribute?.name} hasFilter={!noFilter} />
      <Portal>
        <FilterPopoverContent
          isCancelDisabled={!(defaultValue !== inputValue)}
          onClickApply={() => {
            setSelectedAttributes({
              ...selectedAttributes,
              [attributeId]: inputValue,
            });
          }}
          onClickCancel={() => {
            setInputValue(defaultValue);
            setSelectedAttributes({
              ...selectedAttributes,
              [attributeId]: defaultValue,
            });
          }}
        >
          <Box maxHeight="320px" overflowY="auto" overflowX="hidden">
            <Stack as="fieldset" spacing={2}>
              <CheckboxGroup
                onChange={(v) => setInputValue(v)}
                value={inputValue as unknown as number[] | string[]}
              >
                {attribute?.options && Array.isArray(attribute.options)
                  ? attribute.options
                      .sort((a, b) => a.position - b.position)
                      .map((option) => (
                        <Checkbox key={option.value} value={option.value} colorScheme="primary">
                          <span>{option.name}</span>
                        </Checkbox>
                      ))
                  : null}
              </CheckboxGroup>
            </Stack>
          </Box>
        </FilterPopoverContent>
      </Portal>
    </Popover>
  );
};

export default CheckboxFilter;
