import {
  Box,
  Container,
  Flex,
  FormControl,
  Heading,
  HStack,
  Input,
  Stack,
  RadioGroup,
  Radio,
  Text,
  Divider,
  Button,
  Skeleton,
  useToast,
  VStack,
  Alert,
  AlertIcon,
  useDisclosure,
  FormErrorMessage,
  useTheme,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import {
  Checkout,
  PaymentIntentData,
  ShippingInfo,
  ShippingRates,
  ShippoShippingRate,
} from 'src/api/v1-api';
import { CheckoutSummary } from 'src/components/atoms';
import { CONTAINER_MAX_WIDTH_1 } from 'src/constants/ui';
import { formatPrice } from 'src/utils/common';
import { useAuth } from 'src/utils/auth';
import { apiRequest } from 'src/utils/fetchUtils';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { LoginModal } from 'src/components/organisms';
import { AddressInput } from 'src/components/atoms';
import { calculateTaxAmount } from 'src/utils/taxUtils';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty, isEqual } from 'lodash';
import { isShippingInfoValid } from './isShippingInfoValid';

const EMAIL_REGEX_PATTERN = /^[\w-\.+\-]+@([\w-]+\.)+[\w-]{2,4}$/g;

export type CheckoutFields = {
  email: string;
  shippingInfo: ShippingInfo;
  shippingMethod: string;
};

interface CheckoutFormProps {
  checkout: Checkout | null;
  checkoutLoading: boolean;
  paymentIntent: PaymentIntentData | null;
  onFieldsChange?: (data: CheckoutFields, valid: boolean) => void;
}

const CheckoutForm = ({
  checkout,
  checkoutLoading,
  paymentIntent,
}: CheckoutFormProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const { getToken, user } = useAuth();
  const { urlBase } = useContext(MarketplaceContext);
  const { control, handleSubmit, watch, setValue } = useForm<CheckoutFields>({
    mode: 'onChange',
  });
  const theme = useTheme();

  // TODO(Sean): Add isShipping logic depending on fulfillment options, see dynamicShipping below
  const [isShipping, setIsShipping] = useState(true);
  const [userExists, setUserExists] = useState(false);
  const [currentShippingInfo, setCurrentShippingInfo] = useState<ShippingInfo | null>(null);
  const [currentEmail, setCurrentEmail] = useState('');
  const [shippingRates, setShippingRates] = useState<ShippingRates>({});
  const [shippingRatesLoading, setShippingRatesLoading] = useState(false);
  const [fulfillmentSubtotal, setFulfillmentSubtotal] = useState<string | null>();
  const [taxSubtotal, setTaxSubtotal] = useState<string | null>(checkout?.tax_subtotal || null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [updatePaymentIntentLoading, setUpdatePaymentIntentLoading] = useState(false);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);

  // TODO(Sean): Check if any fulfillment options are dynamic
  const dynamicShipping =
    checkout &&
    checkout.variant_fulfillment_options &&
    checkout.variant_fulfillment_options[0]?.fulfillment_options &&
    checkout.variant_fulfillment_options[0]?.fulfillment_options[0] !== undefined &&
    checkout.variant_fulfillment_options[0]?.fulfillment_options[0]?.fulfillment_type &&
    checkout.variant_fulfillment_options[0]?.fulfillment_options[0]?.fulfillment_type
      ?.price_type === 'dynamic_api';

  // TODO(Sean): Allow for multiple fulfillment options
  const fulfillmentOption =
    checkout &&
    checkout.variant_fulfillment_options &&
    checkout.variant_fulfillment_options[0].fulfillment_options &&
    checkout.variant_fulfillment_options[0].fulfillment_options[0] !== undefined &&
    checkout.variant_fulfillment_options[0].fulfillment_options[0]
      ? checkout.variant_fulfillment_options[0].fulfillment_options[0]
      : null;

  const { email, shippingMethod, shippingInfo } = watch();
  const isFormChanged =
    !isEqual(shippingInfo, currentShippingInfo) || !isEqual(email, currentEmail);
  const isValid = !isEmpty(email) && isShippingInfoValid(shippingInfo);

  useEffect(() => {
    if (shippingMethod) {
      updatePaymentIntent();
    }
  }, [shippingMethod]);

  const getFulfillmentPrice = () => {
    // TODO(Sean): Sum up all fulfillment prices

    if (dynamicShipping) {
      return fulfillmentSubtotal;
    }

    return checkout?.fulfillment_subtotal;
  };

  const fulfillmentPrice = getFulfillmentPrice();

  useEffect(() => {
    // Update the UI for flat rate shipping
    if (fulfillmentPrice && !dynamicShipping) {
      setFulfillmentSubtotal(fulfillmentPrice);
    }
  }, [fulfillmentPrice]);

  const handlePaymentChange = (event: StripePaymentElementChangeEvent) => {
    if (event.complete) {
      setIsPaymentComplete(true);
    } else {
      setIsPaymentComplete(false);
    }
  };

  const filterShippingRates = (rates: ShippoShippingRate[]): ShippoShippingRate[] => {
    if (rates) {
      const uspsFirstRate = rates.find((rate) => rate.servicelevel.token == 'usps_first');
      const uspsPriorityRate = rates.find((rate) => rate.servicelevel.token == 'usps_priority');
      const cheapestRate = rates.find((rate) => rate.attributes.includes('CHEAPEST'));

      const filtered: ShippoShippingRate[] = [];

      if (uspsFirstRate) {
        filtered.push(uspsFirstRate);
      }

      if (uspsPriorityRate) {
        filtered.push(uspsPriorityRate);
      }

      // If none of the USPS rates were found, use the cheapest rate
      if (filtered.length === 0 && cheapestRate) {
        filtered.push(cheapestRate);
      }

      return filtered;
    }
    return [];
  };

  const filterAllShippingRates = (rates: ShippingRates): ShippingRates => {
    const filteredRates: ShippingRates = {};

    Object.keys(rates).forEach((listingId: string) => {
      filteredRates[listingId] = filterShippingRates(rates[listingId]);
    });

    return filteredRates;
  };

  const fetchShippingRates = async () => {
    if (
      !shippingRates.length &&
      !checkoutLoading &&
      isShipping &&
      shippingInfo &&
      shippingInfo !== null &&
      Object.keys(shippingInfo).length > 0
    ) {
      setShippingRatesLoading(true);
      const token = await getToken();
      const shippingRatesUrl = `${process.env.NEXT_PUBLIC_API_HOST}/shipping/rates/`;
      const body = {
        shipping_address: shippingInfo,
        listing_ids: checkout?.cart_items?.map((cart_item) => cart_item.variant.listing.id),
        contents_subtotal: checkout?.contents_subtotal,
      };

      let filteredRates: ShippingRates = {};
      try {
        const shippingRatesResponse = await apiRequest('POST', shippingRatesUrl, token, body);
        setTaxSubtotal(shippingRatesResponse.tax_subtotal);
        filteredRates = filterAllShippingRates(shippingRatesResponse.rates);
        setShippingRates(filteredRates);
      } catch (error) {
        toast({
          title: 'Error.',
          description: 'Unable to fetch shipping rates. Please contact support.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
        console.error('Error fetching shipping rates: ', error);
      }

      // If there is only one listing in the shipping rates, set the first rate as the chosen shipping method
      const allKeys = Object.keys(filteredRates);
      if (allKeys.length === 1) {
        const firstListingId = allKeys[0];
        const ratesForFirstListing = filteredRates[firstListingId];
        if (ratesForFirstListing.length > 0) {
          const firstRate = ratesForFirstListing[0];
          setValue('shippingMethod', firstRate.object_id);
          setFulfillmentSubtotal(firstRate.amount);
        }
      }

      setShippingRatesLoading(false);
    }
  };

  const handleUpdateShippingInfo = (shippingInfo: ShippingInfo) => {
    setCurrentShippingInfo(shippingInfo);
    setCurrentEmail(email);
    if (shippingInfo && Object.keys(shippingInfo).length > 0) {
      if (dynamicShipping) {
        fetchShippingRates();
      } else {
        setTaxSubtotal(
          calculateTaxAmount(shippingInfo.state, checkout?.contents_subtotal).toString(),
        );
        setValue('shippingMethod', fulfillmentOption?.id ? fulfillmentOption.id.toString() : '');
        updatePaymentIntent();
      }
    }
  };

  const getShippingPrice = (rateId: string) => {
    let result = '';

    if (dynamicShipping) {
      for (const listingId in shippingRates) {
        const rates = shippingRates[listingId];
        for (let j = 0; j < rates.length; j++) {
          const rate = rates[j];
          if (rate.object_id === rateId) {
            result = rate.amount;
            break;
          }
        }
      }
    } else {
      result = fulfillmentPrice || '';
    }

    return result;
  };

  const updatePaymentIntent = async () => {
    if (paymentIntent?.payment_intent_id && !updatePaymentIntentLoading) {
      setUpdatePaymentIntentLoading(true);
      const token = await getToken();
      const paymentIntentUrl = `${process.env.NEXT_PUBLIC_API_HOST}/payments/update_payment_intent/${checkout?.cart_id}/`;
      const body = {
        payment_intent_id: paymentIntent.payment_intent_id,
        dynamic_shipping: dynamicShipping,
        shipping_rate_id: dynamicShipping ? shippingMethod : null,
        shipping_address: shippingInfo,
      };
      try {
        const paymentIntentData = await apiRequest('POST', paymentIntentUrl, token, body);
      } catch (error) {
        toast({
          title: 'Error.',
          description: 'Unable to update payment intent. Please contact support.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
      }
      setUpdatePaymentIntentLoading(false);
    }
  };

  const handleUpdateShippingMethod = () => {
    if (dynamicShipping && shippingMethod && Object.keys(shippingRates).length > 0) {
      const shippingAmount = getShippingPrice(shippingMethod);
      setFulfillmentSubtotal(shippingAmount);
      updatePaymentIntent();
    } else if (!dynamicShipping && fulfillmentPrice) {
      setFulfillmentSubtotal(fulfillmentPrice);
      updatePaymentIntent();
    }
  };

  const checkForUser = async () => {
    if (user) {
      setUserExists(true);
      return true;
    } else {
      // Check if the email already exists in the system
      const emailUrl = `${process.env.NEXT_PUBLIC_API_HOST}/auth/email_exists/`;
      const body = {
        email: email,
      };
      try {
        const emailResponse = await apiRequest('POST', emailUrl, null, body);
        if (emailResponse.exists) {
          setUserExists(true);
          onOpen();
          return true;
        } else {
          setUserExists(false);
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  };

  const createOrder = async ({ email, shippingMethod, shippingInfo }: CheckoutFields) => {
    const token = await getToken();
    const orderUrl = `${process.env.NEXT_PUBLIC_API_HOST}/orders/create/`;
    const body = {
      cart_id: checkout?.cart_id,
      email,
      shipping_address: shippingInfo,
      dynamic_shipping: dynamicShipping,
      shipping_rate_id: dynamicShipping ? shippingMethod : null,
      payment_intent_id: paymentIntent?.payment_intent_id,
    };
    try {
      const orderResponse = await apiRequest('POST', orderUrl, token, body);
      return orderResponse;
    } catch (error) {
      toast({
        title: 'Error.',
        description: 'Unable to create order. Please contact support.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  const onSubmit = async (data: CheckoutFields) => {
    setSubmitLoading(true);
    setSubmitError(null);

    if (!stripe || !elements) {
      setSubmitError('An unknown error occured.');
      setSubmitLoading(false);
      return;
    }

    const validUser = await checkForUser();
    if (validUser && !user) {
      setSubmitError('Please log in to continue.');
      setSubmitLoading(false);
      return;
    }

    const order = await createOrder(data);
    if (!order) {
      setSubmitLoading(false);
      setSubmitError('An unknown error occured.');
      return;
    }

    let newUrl = new URL(`order/${order.id}`, urlBase);
    if (!user) {
      newUrl = new URL(`${urlBase}?order_success=true`);
    }
    const returnUrl = newUrl.toString();

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment (for example, payment details incomplete)
      setSubmitError(error.message || 'An unknown error occured.');
    }
    setSubmitLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container maxW={CONTAINER_MAX_WIDTH_1} my={{ base: 4, md: 6, lg: 8 }}>
          <Box bgGradient="linear(to-l, gray.50 50%, white 50%)">
            <Flex direction={{ base: 'column', md: 'row' }} columnGap={0}>
              <Box flex={3 / 5} bg="white" px={4} py={6}>
                <Stack spacing={6}>
                  {/* ContactInformation */}
                  <Stack spacing={6}>
                    <Heading size="md">Contact Information</Heading>
                    <Stack spacing={{ base: '6', md: '8' }}>
                      {/* Email field */}
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: 'Email is required',
                          pattern: {
                            value: EMAIL_REGEX_PATTERN,
                            message: 'Please enter a valid email',
                          },
                        }}
                        render={({ field, fieldState: { error, isDirty, isTouched } }) => (
                          <FormControl id="email" isInvalid={!isEmpty(error?.message)}>
                            <Input
                              placeholder="Email Address"
                              focusBorderColor="primary.500"
                              disabled={Boolean(user?.email)}
                              {...field}
                              data-test="email-input"
                            />
                            {error?.message && isDirty && isTouched && (
                              <FormErrorMessage>{error?.message}</FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                        data-test="email"
                      />
                    </Stack>
                  </Stack>
                  <Divider />

                  {/* Shipping info form part */}
                  {isShipping ? (
                    <Controller
                      name="shippingInfo"
                      control={control}
                      // rules={{
                      //   validate: (shippingInfo) =>
                      //     shippingInfo ? isShippingInfoValid(shippingInfo) : false,
                      // }}
                      render={({ field: { value, onChange } }) => (
                        <AddressInput
                          addressValue={value}
                          setAddressValue={onChange}
                          formType="checkout"
                        />
                      )}
                    />
                  ) : null}

                  {/* Update shipping info button */}
                  <Button
                    colorScheme="primary"
                    isDisabled={!isValid || shippingRatesLoading}
                    onClick={() => isValid && handleUpdateShippingInfo(shippingInfo)}
                    data-test="update-btn"
                  >
                    Update Shipping Information
                  </Button>

                  <Divider />

                  {/* Shipping method */}
                  <Stack spacing={4}>
                    <Heading size="md">Shipping Method</Heading>
                    {isShipping && shippingRatesLoading && <Skeleton height="100px" />}
                    {isShipping &&
                      dynamicShipping &&
                      !shippingRatesLoading &&
                      (shippingInfo === null ||
                        !shippingInfo ||
                        (shippingInfo && !Object.keys(shippingInfo).length) ||
                        shippingInfo !== currentShippingInfo) && (
                        <Text>
                          Please enter your shipping information to see available shipping methods.
                        </Text>
                      )}
                    {isShipping && !shippingRatesLoading && shippingInfo == currentShippingInfo && (
                      <Controller
                        name="shippingMethod"
                        control={control}
                        render={({ field: { onChange, ...field } }) => (
                          <RadioGroup
                            colorScheme="primary"
                            size="lg"
                            {...field}
                            onChange={(newShippingMethod) => {
                              handleUpdateShippingMethod();
                              onChange(newShippingMethod);
                            }}
                          >
                            <Stack direction={{ base: 'column', lg: 'row' }} spacing="6">
                              {Object.entries(shippingRates).map(([_, rates]: [string, any[]]) => {
                                if (rates.length > 0) {
                                  return rates.map((rate) => (
                                    <Box
                                      flex="1"
                                      border="1px"
                                      borderColor="gray.300"
                                      borderRadius={theme.border_radius.border_radius_1}
                                      px={4}
                                      py={3}
                                      display="flex"
                                      key={rate.object_id}
                                    >
                                      <Radio
                                        key={rate.object_id}
                                        spacing="3"
                                        flex="1"
                                        value={rate.object_id}
                                      >
                                        <Box>
                                          <VStack align="start">
                                            <HStack>
                                              <Text fontWeight="medium">
                                                {rate.servicelevel.name}
                                              </Text>
                                              <Text fontWeight="semibold">
                                                -&nbsp;&nbsp;{formatPrice(rate.amount)}
                                              </Text>
                                            </HStack>
                                            <Text color="gray.600" fontSize="xs">
                                              {rate.duration_terms.length > 50
                                                ? `${rate.duration_terms.substring(0, 50)}...`
                                                : rate.duration_terms}
                                            </Text>
                                          </VStack>
                                        </Box>
                                      </Radio>
                                    </Box>
                                  ));
                                } else {
                                  return (
                                    <Alert status="warning" key="no-rates">
                                      <AlertIcon /> We&apos;re sorry, there were no shipping rates
                                      found. Please contact support.
                                    </Alert>
                                  );
                                }
                              })}
                            </Stack>
                          </RadioGroup>
                        )}
                      />
                    )}

                    {/* TODO(Sean): Show fulfillment price for each item in cart */}
                    {isShipping && !dynamicShipping && (
                      <Box pl={4}>
                        {fulfillmentPrice ? (
                          <Text fontWeight="semibold">
                            {formatPrice(fulfillmentPrice)} -{' '}
                            {checkout?.variant_fulfillment_options &&
                              checkout?.variant_fulfillment_options[0].fulfillment_options &&
                              checkout.variant_fulfillment_options[0].fulfillment_options[0] !==
                                undefined &&
                              checkout?.variant_fulfillment_options[0].fulfillment_options[0]
                                .fulfillment_type &&
                              checkout?.variant_fulfillment_options[0].fulfillment_options[0]
                                .fulfillment_type.name}
                          </Text>
                        ) : (
                          <Flex mb={{ base: 2, md: 4, lg: 6 }} justify="center">
                            <Alert status="warning" key="no-rates">
                              <AlertIcon /> No shipping options available, please contact support.
                            </Alert>
                          </Flex>
                        )}
                      </Box>
                    )}
                  </Stack>
                  <Divider />

                  {/* <PaymentInformation /> */}
                  <Stack spacing={6}>
                    <Heading size="md">Payment Method</Heading>
                    {!isValid || (!paymentIntent?.client_secret && !updatePaymentIntentLoading) ? (
                      <Text>
                        Please enter your shipping information and select a shipping method to see
                        available payment methods.
                      </Text>
                    ) : null}
                    {updatePaymentIntentLoading && <Skeleton height="200px" />}
                    {isValid && paymentIntent?.client_secret && !updatePaymentIntentLoading && (
                      <PaymentElement onChange={handlePaymentChange} />
                    )}
                  </Stack>
                </Stack>
              </Box>
              <Box
                flex={2 / 5}
                bg={{ base: 'white', md: 'inherit' }}
                p={6}
                borderRadius={theme.border_radius.border_radius_2}
              >
                <Box position={{ md: 'sticky' }} top={6}>
                  <CheckoutSummary
                    checkout={checkout}
                    isLoading={checkoutLoading}
                    fulfillmentSubtotal={fulfillmentSubtotal}
                    taxSubtotal={taxSubtotal}
                    submitDisabled={
                      submitLoading ||
                      !isValid ||
                      !((dynamicShipping && shippingMethod) || !dynamicShipping) ||
                      !paymentIntent?.client_secret ||
                      isFormChanged ||
                      updatePaymentIntentLoading
                    }
                    submitLoading={submitLoading}
                    submitError={submitError}
                    isPaymentComplete={isPaymentComplete}
                  />
                </Box>
              </Box>
            </Flex>
          </Box>
        </Container>
      </form>
      <LoginModal isOpen={isOpen} onClose={onClose} email={email} />
    </>
  );
};

export default CheckoutForm;
