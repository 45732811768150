import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Show,
  Stack,
  Text,
  useTheme,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import ImageWithFallback from '../../atoms/image-with-fallback';

type HeroCardProps = {
  title?: string | null;
  description?: string | null;
  imageURL?: string | null;
  cta: {
    label?: string | null;
    link?: string | null;
  };
  text_box_opacity?: number;
  isSimple?: boolean;
  mobileImageURL?: string | null;
};

const HeroCard = ({
  title,
  description,
  imageURL,
  cta,
  text_box_opacity,
  isSimple,
  mobileImageURL,
}: HeroCardProps) => {
  const theme = useTheme();
  const [isMobile] = useMediaQuery('(max-width: 768px)', {
    ssr: true,
    fallback: false,
  });

  if (!imageURL) return <></>;

  const displayImageURL = isMobile && mobileImageURL ? mobileImageURL : imageURL;

  const content = isSimple ? (
    <Image
      src={displayImageURL ?? ''}
      borderRadius={theme.border_radius.border_radius_1}
      width="100%"
      alt="Category Hero Image"
      draggable="false"
    />
  ) : (
    <Box
      position="relative"
      height={{ base: '300px', md: '400px' }}
      border="1px"
      borderColor="gray.200"
      borderRadius={theme.border_radius.border_radius_1}
      overflow="hidden"
      _hover={
        cta.link
          ? {
              cursor: 'pointer',
            }
          : undefined
      }
    >
      <ImageWithFallback src={displayImageURL ?? ''} alt="Category Hero Image" />
      <Box position="absolute" boxSize="full" inset="0" zIndex="1">
        <Flex
          direction="column-reverse"
          height="full"
          maxW="7xl"
          mx="auto"
          px={{ base: '4', md: '8', lg: '12' }}
          py={{ base: '6', md: '8', lg: '12' }}
        >
          <Box
            alignSelf={{ md: 'start' }}
            p={{ base: '5', md: '8' }}
            minW={{ md: 'lg' }}
            color="white"
            border={{ base: 'none', md: '1px', color: '' }}
            borderRadius={theme.border_radius.border_radius_2}
            opacity={0.9}
            bg={`rgba(0,0,0,${text_box_opacity !== undefined ? text_box_opacity / 100 : 1})`}
          >
            <Stack spacing="5" alignItems="flex-start">
              <Stack spacing="1">
                <Heading
                  size={{ base: 'lg', sm: '2xl' }}
                  textTransform="uppercase"
                  fontWeight="extrabold"
                >
                  {title}
                </Heading>
                <Text>{description}</Text>
              </Stack>
              {cta && cta.label && cta.label.length > 0 && cta.link && cta.link.length > 0 && (
                <Button
                  {...(cta.link && !isSimple
                    ? {
                        onClick: (e) => e.stopPropagation(),
                        as: 'button' as const,
                      }
                    : {
                        as: Link as any,
                        href: cta.link,
                      })}
                  colorScheme="primary"
                  size="md"
                  px="8"
                  py="2"
                  fontSize="md"
                  _hover={{ textDecoration: 'none', bgColor: 'primary.600' }}
                >
                  {cta.label}
                </Button>
              )}
            </Stack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );

  return cta.link ? (
    <Link href={cta.link} _hover={{ textDecoration: 'none' }} display="block">
      {content}
    </Link>
  ) : (
    content
  );
};

export default HeroCard;
