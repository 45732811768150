import { Grid, GridItem, FormControl, FormLabel, Switch, Divider } from '@chakra-ui/react';
import React from 'react';

type ListingFeaturedFormProps = {
  isFeatured: boolean;
  setIsFeatured?: (value: boolean) => void;
};

const ListingFeaturedForm = ({ isFeatured, setIsFeatured }: ListingFeaturedFormProps) => {
  return (
    <>
      <Grid width="full" column={2} gap={{ base: 3, md: 5 }} autoColumns="1fr">
        <GridItem colSpan={{ base: 3, md: 3 }}>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Is Featured?</FormLabel>
            <Switch
              size="md"
              isChecked={isFeatured}
              onChange={(e) => setIsFeatured?.(e.target.checked)}
              colorScheme="primary"
            />
          </FormControl>
        </GridItem>
      </Grid>
      <Divider my={{ base: 4, lg: 6 }} />
    </>
  );
};

export default ListingFeaturedForm;
