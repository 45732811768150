import { chakra, HTMLChakraProps } from '@chakra-ui/react';

export const PayPalLogo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    width="11px"
    height="12px"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.53567 11.3747L3.72502 10.1671L3.30322 10.1572H1.28906L2.68883 1.24589C2.69319 1.2189 2.70729 1.19385 2.72788 1.17603C2.74857 1.15821 2.77494 1.14844 2.80252 1.14844H6.19871C7.32627 1.14844 8.10435 1.38397 8.51053 1.84892C8.70099 2.06704 8.82229 2.29503 8.88101 2.54584C8.94257 2.80906 8.94358 3.12351 8.88354 3.5071L8.87918 3.535V3.78082L9.06965 3.88916C9.22999 3.9746 9.35747 4.07235 9.45524 4.18426C9.61812 4.37081 9.72349 4.60787 9.76801 4.88882C9.81405 5.17781 9.79884 5.52179 9.72349 5.91118C9.63657 6.35902 9.49611 6.74913 9.30636 7.06826C9.13192 7.36234 8.90961 7.60633 8.64562 7.79532C8.39359 7.97495 8.09421 8.1113 7.75568 8.19856C7.42759 8.2843 7.05356 8.32758 6.64332 8.32758H6.37903C6.19009 8.32758 6.00652 8.39591 5.8624 8.51841C5.71788 8.64345 5.62235 8.81432 5.59304 9.00118L5.57306 9.10993L5.23848 11.2385L5.22337 11.3166C5.21931 11.3413 5.21242 11.3536 5.20228 11.362C5.19325 11.3696 5.18027 11.3747 5.16759 11.3747H3.53567Z"
      fill="#28356A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25082 3.5625C9.24078 3.62757 9.22912 3.69406 9.21614 3.76239C8.76828 6.07126 7.23595 6.86888 5.27899 6.86888H4.28256C4.04321 6.86888 3.84149 7.04331 3.80427 7.28037L3.14962 11.4498C3.12538 11.6055 3.24485 11.7457 3.40124 11.7457H5.16855C5.37777 11.7457 5.55556 11.593 5.58852 11.3858L5.60586 11.2957L5.93861 9.17551L5.96001 9.05922C5.99257 8.85129 6.17076 8.69854 6.37999 8.69854H6.64428C8.35652 8.69854 9.69696 8.00061 10.0887 5.98073C10.2523 5.13698 10.1676 4.43242 9.73459 3.93692C9.60356 3.78754 9.44098 3.66351 9.25082 3.5625Z"
      fill="#298FC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.78181 3.37429C8.71335 3.35423 8.64277 3.3361 8.57036 3.31971C8.49754 3.30372 8.423 3.28957 8.34622 3.27714C8.07757 3.23356 7.78315 3.21289 7.46784 3.21289H4.80592C4.74031 3.21289 4.67804 3.22776 4.62236 3.25464C4.49954 3.3139 4.40836 3.4306 4.38626 3.57347L3.81994 7.17475L3.80371 7.27973C3.84093 7.04267 4.04265 6.86824 4.282 6.86824H5.27843C7.23539 6.86824 8.76771 6.07021 9.21558 3.76175C9.22896 3.69342 9.24022 3.62693 9.25026 3.56186C9.13698 3.50147 9.01426 3.44985 8.88211 3.40586C8.84946 3.39496 8.81579 3.38447 8.78181 3.37429Z"
      fill="#22284F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.38621 3.57426C4.40832 3.43139 4.4995 3.3147 4.62231 3.25584C4.6784 3.22886 4.74026 3.21399 4.80588 3.21399H7.4678C7.78311 3.21399 8.07752 3.23476 8.34618 3.27834C8.42295 3.29067 8.49749 3.30492 8.57031 3.32091C8.64272 3.3372 8.71331 3.35543 8.78177 3.37539C8.81574 3.38557 8.84941 3.39616 8.88238 3.40665C9.01452 3.45064 9.13734 3.50267 9.25062 3.56265C9.38389 2.70943 9.24951 2.12849 8.79009 1.60244C8.2835 1.02324 7.36932 0.775391 6.19946 0.775391H2.80318C2.56424 0.775391 2.36039 0.949823 2.32347 1.18729L0.908894 10.1903C0.881004 10.3684 1.01782 10.5291 1.19672 10.5291H3.29343L4.38621 3.57426Z"
      fill="#28356A"
    />
  </chakra.svg>
);

export const GooglePayLogo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    width="22px"
    height="9px"
    viewBox="0 0 22 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1087 7.1327V4.61523H11.408C11.9404 4.61523 12.3898 4.43683 12.7562 4.08498L12.8441 3.99578C13.5133 3.2673 13.4693 2.13246 12.7562 1.4585C12.3996 1.10169 11.9111 0.908421 11.408 0.918332H9.32227V7.1327H10.1087ZM10.1088 3.8523V1.68173H11.4278C11.7112 1.68173 11.9799 1.79076 12.1802 1.98898C12.6052 2.40525 12.615 3.09904 12.2046 3.53018C12.0043 3.74328 11.7209 3.86221 11.4278 3.8523H10.1088ZM16.5123 3.21266C16.1753 2.90046 15.7161 2.74188 15.1348 2.74188C14.3875 2.74188 13.8258 3.01939 13.4545 3.56947L14.1481 4.01052C14.4021 3.63389 14.7489 3.44558 15.1886 3.44558C15.467 3.44558 15.7356 3.54964 15.9457 3.73796C16.1508 3.91636 16.2681 4.17405 16.2681 4.44661V4.62997C15.9652 4.46148 15.5842 4.37228 15.1153 4.37228C14.5682 4.37228 14.1286 4.50113 13.8013 4.76377C13.4741 5.02642 13.308 5.37332 13.308 5.81437C13.2982 6.21578 13.4692 6.59736 13.772 6.85505C14.0798 7.13257 14.4705 7.27133 14.9297 7.27133C15.4719 7.27133 15.9017 7.0285 16.229 6.54285H16.2632V7.13257H17.0154V4.51104C17.0154 3.96096 16.8493 3.52487 16.5123 3.21266ZM14.3778 6.34941C14.2166 6.23047 14.1189 6.0372 14.1189 5.82906C14.1189 5.59615 14.2264 5.40288 14.4365 5.24925C14.6514 5.09563 14.9201 5.01634 15.2377 5.01634C15.6773 5.01138 16.0193 5.1105 16.2636 5.30872C16.2636 5.6457 16.1317 5.93809 15.8728 6.18587C15.6383 6.42374 15.3207 6.55754 14.9885 6.55754C14.7687 6.5625 14.5537 6.48816 14.3778 6.34941ZM18.7058 9.00085L21.3337 2.88064H20.4789L19.2627 5.93331H19.248L18.0024 2.88064H17.1476L18.8719 6.86002L17.895 9.00085H18.7058Z"
      fill="#3C4043"
    />
    <path
      d="M7.39383 4.06923C7.39383 3.8264 7.3743 3.58357 7.33522 3.3457H4.01855V4.71841H5.91868C5.84052 5.15947 5.58652 5.55592 5.21529 5.8037V6.69571H6.34852C7.01283 6.07626 7.39383 5.15947 7.39383 4.06923Z"
      fill="#4285F4"
    />
    <path
      d="M4.01777 7.55801C4.96539 7.55801 5.76647 7.24084 6.34774 6.69573L5.21451 5.80371C4.89701 6.02176 4.49158 6.14565 4.01777 6.14565C3.09946 6.14565 2.3228 5.51629 2.04438 4.67383H0.876953V5.59558C1.47288 6.79979 2.68915 7.55801 4.01777 7.55801Z"
      fill="#34A853"
    />
    <path
      d="M2.04501 4.67322C1.89845 4.23217 1.89845 3.75147 2.04501 3.30546V2.38867H0.877398C0.374201 3.39467 0.374201 4.58402 0.877398 5.59001L2.04501 4.67322Z"
      fill="#FBBC04"
    />
    <path
      d="M4.01777 1.83445C4.52089 1.82454 5.00447 2.01781 5.36593 2.36966L6.37216 1.34879C5.73228 0.744207 4.89212 0.412179 4.01777 0.422091C2.68915 0.422091 1.47288 1.18526 0.876953 2.38948L2.04438 3.31123C2.3228 2.46381 3.09946 1.83445 4.01777 1.83445Z"
      fill="#EA4335"
    />
  </chakra.svg>
);

export const MasterCardLogo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    width="20px"
    height="12px"
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91499 10.4843C8.92202 11.3412 7.63398 11.8585 6.22651 11.8585C3.08596 11.8585 0.540039 9.28302 0.540039 6.106C0.540039 2.92899 3.08596 0.353516 6.22651 0.353516C7.63398 0.353516 8.92202 0.870791 9.91499 1.72767C10.908 0.870791 12.196 0.353516 13.6035 0.353516C16.744 0.353516 19.2899 2.92899 19.2899 6.106C19.2899 9.28302 16.744 11.8585 13.6035 11.8585C12.196 11.8585 10.908 11.3412 9.91499 10.4843Z"
      fill="#ED0006"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91504 10.4843C11.1377 9.42923 11.913 7.85921 11.913 6.106C11.913 4.3528 11.1377 2.78278 9.91504 1.72766C10.908 0.870791 12.196 0.353516 13.6035 0.353516C16.7441 0.353516 19.29 2.92899 19.29 6.106C19.29 9.28302 16.7441 11.8585 13.6035 11.8585C12.196 11.8585 10.908 11.3412 9.91504 10.4843Z"
      fill="#F9A000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91493 1.72852C11.1376 2.78363 11.9129 4.35363 11.9129 6.1068C11.9129 7.85998 11.1376 9.42998 9.91493 10.4851C8.69227 9.42998 7.91699 7.85998 7.91699 6.1068C7.91699 4.35363 8.69227 2.78363 9.91493 1.72852Z"
      fill="#FF5E00"
    />
  </chakra.svg>
);

export const VisaLogo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    width="22px"
    height="7px"
    viewBox="0 0 22 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.85438 6.54929H4.08745L2.76246 1.4944C2.69957 1.26187 2.56604 1.05631 2.36962 0.959423C1.87943 0.715945 1.33927 0.522175 0.75 0.424447V0.229833H3.59639C3.98924 0.229833 4.28387 0.522175 4.33297 0.861695L5.02045 4.50796L6.78652 0.229833H8.50435L5.85438 6.54929ZM9.48627 6.54929H7.81755L9.19164 0.229833H10.8604L9.48627 6.54929ZM13.0192 1.98041C13.0684 1.64005 13.363 1.44544 13.7067 1.44544C14.2469 1.39657 14.8353 1.4943 15.3263 1.73694L15.621 0.376326C15.1299 0.181712 14.5898 0.0839844 14.0996 0.0839844C12.4799 0.0839844 11.3014 0.959324 11.3014 2.17418C11.3014 3.09839 12.1362 3.58366 12.7255 3.876C13.363 4.1675 13.6085 4.36211 13.5594 4.65361C13.5594 5.09086 13.0684 5.28547 12.5782 5.28547C11.9889 5.28547 11.3996 5.13972 10.8603 4.89624L10.5657 6.2577C11.155 6.50033 11.7925 6.59806 12.3817 6.59806C14.1978 6.64608 15.3263 5.77158 15.3263 4.459C15.3263 2.80605 13.0192 2.70916 13.0192 1.98041ZM21.1665 6.54929L19.8415 0.229833H18.4183C18.1237 0.229833 17.829 0.424447 17.7308 0.715945L15.2773 6.54929H16.9951L17.338 5.62593H19.4487L19.6451 6.54929H21.1665ZM18.6641 1.93131L19.1543 4.31301H17.7802L18.6641 1.93131Z"
      fill="#172B85"
    />
  </chakra.svg>
);
