export const SITE_MAX_WIDTH = 1096;
export const CONTAINER_MAX_WIDTH_1 = `${SITE_MAX_WIDTH}px`;
export const CONTAINER_MAX_WIDTH_2 = '910px';

// Chakra UI's blue scheme
export const DEFAULT_PRIMARY_COLOR_SCHEME = {
  50: '#ebf8ff',
  100: '#bee3f8',
  200: '#90cdf4',
  300: '#63b3ed',
  400: '#4299e1',
  500: '#3182ce',
  600: '#2b6cb0',
  700: '#2c5282',
  800: '#2a4365',
  900: '#1A365D',
};

// Chakra UI's teal scheme
export const DEFAULT_SECONDARY_COLOR_SCHEME = {
  50: '#E6FFFA',
  100: '#B2F5EA',
  200: '#81E6D9',
  300: '#4FD1C5',
  400: '#38B2AC',
  500: '#319795',
  600: '#2C7A7B',
  700: '#285E61',
  800: '#234E52',
  900: '#1D4044',
};

// Colors
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
