import {
  AspectRatio,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Image,
  Spinner,
  Stack,
  Text,
  useToast,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import useRequest from 'src/utils/hooks/useRequest';
import { StripeConnectConfig } from 'src/api/v1-api';
import { MarketplaceContext } from 'src/contexts/marketplace';
import StripeConnectOnboarding from './stripe-connect-onboarding';
import LomaUploader from 'src/components/molecules/loma-uploader';
import { srcFromCDN } from 'src/utils/files';
import { FilePath } from '../listing-form';

interface Props {
  marketplaceId: number;
  stripeConnectConfig: StripeConnectConfig | undefined;
}

export interface StripeAccount {
  id: string;
  details_submitted: boolean;
}

const StripeConnectForm = ({ marketplaceId, stripeConnectConfig }: Props) => {
  const { api } = useRequest();
  const toast = useToast();
  const { setMarketplace } = useContext(MarketplaceContext);
  const [clientAccountId, setClientAccountId] = useState(stripeConnectConfig?.client_account_id);
  const [clientAccount, setClientAccount] = useState<StripeAccount | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConnectError, setIsConnectError] = useState(false);
  const [iconImage, setIconImage] = useState<string | null>(
    stripeConnectConfig?.icon_image ?? null,
  );
  const [logoImage, setLogoImage] = useState<string | null>(
    stripeConnectConfig?.logo_image ?? null,
  );
  const [primaryColor, setPrimaryColor] = useState(stripeConnectConfig?.primary_color ?? '');
  const [secondaryColor, setSecondaryColor] = useState(stripeConnectConfig?.secondary_color ?? '');

  useEffect(() => {
    const fetchClientAccount = async () => {
      if (marketplaceId && stripeConnectConfig?.client_account_id) {
        const response = await api.stripe.account.get(marketplaceId);
        setClientAccount(response);
      }
    };
    fetchClientAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeConnectConfig?.client_account_id, marketplaceId]);

  const updateBranding = async () => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append('icon_image', iconImage ?? '');
      formData.append('logo_image', logoImage ?? '');
      formData.append('primary_color', primaryColor ?? '');
      formData.append('secondary_color', secondaryColor ?? '');

      // TODO: add the brand settings and files
      await api.stripe.settings.post(marketplaceId, formData);
      toast({
        status: 'success',
        duration: 5000,
        isClosable: true,
        title: `Updated Stripe Connect branding.`,
      });
    } catch (error) {
      console.error(error);
      toast({
        status: 'error',
        duration: 5000,
        isClosable: true,
        title: `There was an error updating your Stripe branding configuration.`,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const setStripeStatus = async (status: string) => {
    if (isSubmitting || !stripeConnectConfig) return;

    setIsSubmitting(true);
    const newStripeConnectConfig = {
      ...stripeConnectConfig,
      status,
    };
    const formData = new FormData();
    formData.append('stripe_connect_config', JSON.stringify(newStripeConnectConfig));
    try {
      const updatedMarketplace = await api.marketplace.update.put(marketplaceId, formData);
      setMarketplace(updatedMarketplace);
      toast({
        status: 'success',
        duration: 5000,
        isClosable: true,
        title:
          status === 'active' ? `Stripe configuration enabled.` : `Stripe configuration disabled.`,
      });
    } catch (error) {
      console.error(error);
      toast({
        status: 'error',
        duration: 5000,
        isClosable: true,
        title: `There was an error updating your Stripe configuration.`,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const goToStripeOAuth = () => {
    const STRIPE_ID = process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID;
    if (typeof window !== 'undefined') {
      const hostname = window.location.hostname;
      window.location.assign(
        `https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=${STRIPE_ID}&scope=read_write&state=${hostname}`,
      );
    }
  };

  const updateClientAccountId = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    const newStripeConnectConfig: StripeConnectConfig = {
      client_account_id: clientAccountId ?? '',
      status: 'inactive',
    };
    const formData = new FormData();
    formData.append('stripe_connect_config', JSON.stringify(newStripeConnectConfig));

    try {
      const updatedMarketplace = await api.marketplace.update.put(marketplaceId, formData);
      setMarketplace(updatedMarketplace);
      toast({
        status: 'success',
        duration: 5000,
        isClosable: true,
        title: `Stripe configuration updated.`,
      });
    } catch (error) {
      console.error(error);
      toast({
        status: 'error',
        duration: 5000,
        isClosable: true,
        title: `There was an error updating your Stripe configuration.`,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onIconFileUpload = async (filePaths: FilePath[]) => {
    if (!setIconImage || !filePaths[0]?.filePath) {
      return;
    }
    setIconImage(filePaths[0]?.filePath);
  };

  const onLogoFileUpload = async (filePaths: FilePath[]) => {
    if (!setLogoImage || !filePaths[0]?.filePath) {
      return;
    }
    setLogoImage(filePaths[0]?.filePath);
  };

  return (
    <Stack w={'full'}>
      <Accordion allowToggle pt={2}>
        <AccordionItem borderBottom={'none'}>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Stripe Connect Configuration
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={4}
              justify="space-between"
              flexDirection="row"
              alignItems="flex-end"
              pt={4}
              pb={2}
            >
              <FormControl>
                <FormLabel>Stripe Account ID</FormLabel>
                <Input
                  placeholder="acct_XXXXXXXX"
                  value={clientAccountId}
                  onChange={(e) => setClientAccountId(e.target.value)}
                />
              </FormControl>

              <Button
                colorScheme="primary"
                onClick={updateClientAccountId}
                isDisabled={
                  !!clientAccountId && clientAccountId == stripeConnectConfig?.client_account_id
                }
              >
                {stripeConnectConfig?.client_account_id ? 'Update' : 'Save'}
              </Button>
            </Flex>

            {clientAccountId && !stripeConnectConfig?.token && (
              <Button colorScheme="primary" onClick={() => goToStripeOAuth()}>
                Connect Account to Loma
              </Button>
            )}

            {clientAccountId &&
              !!stripeConnectConfig?.token &&
              stripeConnectConfig?.client_account_id == clientAccountId && (
                <>
                  <StripeConnectOnboarding
                    marketplaceId={marketplaceId}
                    clientAccountId={clientAccountId}
                    isConnectError={isConnectError}
                    setIsConnectError={setIsConnectError}
                  />
                  <Divider />
                </>
              )}

            {stripeConnectConfig?.status === 'active' &&
              clientAccount &&
              clientAccount.details_submitted && (
                <Box pt={4} pb={4} textAlign="center">
                  <Button maxW={400} colorScheme="red" onClick={() => setStripeStatus('inactive')}>
                    Disable Stripe Connect
                  </Button>
                </Box>
              )}

            {stripeConnectConfig?.status === 'inactive' &&
              !!stripeConnectConfig?.token &&
              clientAccount &&
              clientAccount.details_submitted && (
                <Box pt={4} pb={4} textAlign="center">
                  <Button
                    maxW={400}
                    colorScheme="primary"
                    onClick={() => setStripeStatus('active')}
                  >
                    Enable Stripe Connect
                  </Button>
                </Box>
              )}

            {stripeConnectConfig?.status === 'active' && (
              <div>
                <Text fontWeight="bold" pt={6}>
                  Stripe Connect Custom Branding
                </Text>
                <FormControl mt={4}>
                  <FormLabel>Icon</FormLabel>
                  <Flex justify="space-between">
                    {stripeConnectConfig?.icon_image && (
                      <AspectRatio
                        ratio={1}
                        minWidth="60px"
                        maxWidth="60px"
                        minHeight="60px"
                        maxHeight="60px"
                        m={4}
                      >
                        <Image
                          align="center"
                          src={srcFromCDN(stripeConnectConfig.icon_image, 60)}
                          alt={'marketplace icon'}
                          style={{ objectFit: 'contain' }}
                          draggable="false"
                        />
                      </AspectRatio>
                    )}
                    <LomaUploader
                      maxFiles={1}
                      onFileDelete={() => setIconImage(null)}
                      onFileUpload={onIconFileUpload}
                      filePathsLength={stripeConnectConfig?.icon_image ? 1 : 0}
                    />
                  </Flex>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Logo</FormLabel>
                  <Flex justify="space-between">
                    {stripeConnectConfig?.logo_image && (
                      <AspectRatio
                        ratio={1}
                        minWidth="60px"
                        maxWidth="60px"
                        minHeight="60px"
                        maxHeight="60px"
                        m={4}
                      >
                        <Image
                          align="center"
                          src={srcFromCDN(stripeConnectConfig.logo_image, 60)}
                          alt={'marketplace logo'}
                          style={{ objectFit: 'contain' }}
                          draggable="false"
                        />
                      </AspectRatio>
                    )}
                    <LomaUploader
                      maxFiles={1}
                      onFileDelete={() => setLogoImage(null)}
                      onFileUpload={onLogoFileUpload}
                      filePathsLength={stripeConnectConfig?.logo_image ? 1 : 0}
                    />
                  </Flex>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Primary Color</FormLabel>
                  <Input
                    name="primary_color"
                    size="md"
                    type="text"
                    value={primaryColor}
                    placeholder="#ffffff"
                    onChange={(e) => setPrimaryColor(e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Secondary Color</FormLabel>
                  <Input
                    name="secondary_color"
                    size="md"
                    type="text"
                    value={secondaryColor}
                    placeholder="#000000"
                    onChange={(e) => setSecondaryColor(e.target.value)}
                  />
                </FormControl>
                <Button
                  m={4}
                  maxW={400}
                  colorScheme="primary"
                  onClick={() => updateBranding()}
                  isDisabled={isSubmitting}
                >
                  Update Stripe Branding
                </Button>
                {isSubmitting && <Spinner />}
              </div>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

export default StripeConnectForm;
