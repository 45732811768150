import { Post } from 'src/api/test';

// Saga Action types
export const GET_POSTS = '@test/GET_POSTS';
export const GET_POST = '@test/GET_POST';

// Types and Interfaces
export interface TestState {
  posts: Post[];
  post: Post | undefined;
}
