import { FormControl, HStack, Input, Select, Stack, FormHelperText } from '@chakra-ui/react';
import { STATES } from 'src/constants/states';
import { CANADIAN_PROVINCES } from 'src/constants/canadianProvinces';
import { ShippingInfo } from 'src/api/v1-api';

type AddressProps = {
  addressValue: ShippingInfo | null;
  setAddressValue: (updateFunction: ShippingInfo | null) => void;
  formType?: string;
  enableCanadianAddresses?: boolean;
  skipRequired?: boolean;
};

const defaultAddress: ShippingInfo = {
  name: '',
  street_address1: '',
  city: '',
  state: '',
  zip_code: '',
  country: '',
};

const AddressInput = ({
  addressValue,
  setAddressValue,
  formType = 'listing',
  enableCanadianAddresses = false,
  skipRequired = false,
}: AddressProps): JSX.Element => {
  const isCanadaSelected = addressValue?.country === 'CA';
  const isRequired = !skipRequired;

  return (
    <Stack spacing={{ base: '6', md: '8' }}>
      {formType !== 'listing' && (
        <FormControl id="name">
          <Input
            name="name"
            placeholder="Full Name"
            focusBorderColor="primary.500"
            value={addressValue?.name}
            isRequired={isRequired}
            onChange={(e) =>
              setAddressValue({
                ...defaultAddress,
                ...addressValue,
                name: e.target.value,
              })
            }
            data-test="full-name-input"
          />
        </FormControl>
      )}
      {formType === 'shipping' && (
        <FormControl id="phone">
          <Input
            name="phone"
            placeholder="Phone Number"
            focusBorderColor="primary.500"
            value={addressValue?.phone}
            isRequired={isRequired}
            onChange={(e) =>
              setAddressValue({
                ...defaultAddress,
                ...addressValue,
                phone: e.target.value,
              })
            }
          />
        </FormControl>
      )}
      <FormControl id="country" maxW="64">
        <Select
          name="country"
          placeholder="Country"
          focusBorderColor="primary.500"
          isRequired={formType !== 'listing' && isRequired}
          value={addressValue?.country}
          onChange={(e) =>
            setAddressValue({
              ...defaultAddress,
              ...addressValue,
              country: e.target.value,
            })
          }
          data-test="country-select"
        >
          <option value="US">United States</option>
          {enableCanadianAddresses ? (
            <option value="CA">Canada</option>
          ) : (
            <option disabled value="CA">
              Canada (coming soon)
            </option>
          )}
        </Select>
      </FormControl>
      <FormControl id="street">
        <Input
          name="street_address1"
          placeholder="Street Address"
          focusBorderColor="primary.500"
          value={addressValue?.street_address1}
          isRequired={formType !== 'listing' && isRequired}
          onChange={(e) =>
            setAddressValue({
              ...defaultAddress,
              ...addressValue,
              street_address1: e.target.value,
            })
          }
          data-test="street-input"
        />
        {formType === 'listing' && (
          <FormHelperText fontStyle={'italic'} color={'orange.400'} fontSize={'small'}>
            Optional
          </FormHelperText>
        )}
      </FormControl>
      <FormControl id="street2">
        <Input
          name="street_address2"
          placeholder="Street Address 2"
          focusBorderColor="primary.500"
          value={addressValue?.street_address2}
          onChange={(e) =>
            setAddressValue({
              ...defaultAddress,
              ...addressValue,
              street_address2: e.target.value,
            })
          }
          data-test="street-2-input"
        />
        {formType === 'listing' && (
          <FormHelperText fontStyle={'italic'} color={'orange.400'} fontSize={'small'}>
            Optional
          </FormHelperText>
        )}
      </FormControl>
      <HStack spacing="6">
        <FormControl id="zip" maxW="32">
          <Input
            name="zip"
            placeholder={isCanadaSelected ? 'Postal Code' : 'Zip Code'}
            focusBorderColor="primary.500"
            isRequired={formType !== 'listing' && isRequired}
            value={addressValue?.zip_code}
            onChange={(e) =>
              setAddressValue({
                ...defaultAddress,
                ...addressValue,
                zip_code: e.target.value,
              })
            }
            data-test="zip-input"
          />
        </FormControl>
        <FormControl id="city">
          <Input
            name="city"
            placeholder="City"
            focusBorderColor="primary.500"
            isRequired={isRequired}
            value={addressValue?.city}
            onChange={(e) =>
              setAddressValue({
                ...defaultAddress,
                ...addressValue,
                city: e.target.value,
              })
            }
            data-test="city-input"
          />
        </FormControl>
      </HStack>
      <HStack spacing={6}>
        <FormControl id="state" maxW="64">
          <Select
            name="state"
            placeholder={isCanadaSelected ? 'Province' : 'State'}
            focusBorderColor="primary.500"
            isRequired={isRequired}
            value={addressValue?.state}
            onChange={(e) =>
              setAddressValue({
                ...defaultAddress,
                ...addressValue,
                state: e.target.value,
              })
            }
            data-test="state-select"
          >
            {isCanadaSelected
              ? CANADIAN_PROVINCES.map((province, index) => (
                  <option key={index} value={province.abbreviation}>
                    {province.name}
                  </option>
                ))
              : STATES.map((state, index) => (
                  <option key={index} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
          </Select>
        </FormControl>
      </HStack>
    </Stack>
  );
};

export default AddressInput;
