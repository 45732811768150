export class ApiRoutesV1 {
  static ApiInfo = () => {
    const BASE_URL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1`;
    // const APPLICATION_ID = process.env.APPLICATION_ID;

    return { BASE_URL };
  };

  static getCategoriesList = () => {
    return `${this.ApiInfo().BASE_URL}/categories`;
  };
}
