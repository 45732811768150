import { chakra, Spinner } from '@chakra-ui/react';
import { ReactNode, useContext, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { MarketplaceContext } from 'src/contexts/marketplace';
import Script from 'next/script';
import { MarketplaceInfo } from 'src/api/v1-api';
import { ErrorBoundary } from 'src/components/system';

const DefaultHeader = dynamic(() => import('./header'), {
  loading: () => <Spinner />,
});

const DefaultFooter = dynamic(() => import('./footer'), {
  loading: () => <Spinner />,
});

interface Props {
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  marketplace: MarketplaceInfo;
}

const DefaultLayout = ({ header, footer, children, marketplace, ...rest }: Props): JSX.Element => {
  const { marketplaceSlug, apexDomain } = useContext(MarketplaceContext);

  useEffect(() => {
    // Create a subdomain cookie and set its value to the subdomain
    if (marketplaceSlug) {
      document.cookie = `subdomain=${marketplaceSlug}; path=/; samesite=lax;`;
    }
    if (apexDomain) {
      document.cookie = `apexdomain=${apexDomain}; path=/; samesite=lax;`;
    }
  }, [marketplaceSlug, apexDomain]);

  return (
    <>
      <Head>
        <link rel="preload" href={marketplace?.favicon_image || '/marketplace.ico'} as="image" />
        <link
          rel="icon"
          type="image/png"
          sizes="48x48"
          href={marketplace?.favicon_image || '/marketplace.ico'}
        />
        <link rel="shortcut icon" href={marketplace?.favicon_image || '/marketplace.ico'} />
        <link
          rel="apple-touch-icon"
          sizes="48x48"
          href={marketplace?.favicon_image || '/marketplace.ico'}
        />
        <meta name="apple-mobile-web-app-title" content={marketplace?.name} />
      </Head>
      {marketplace?.functional?.google_tag_manager_id && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtm.js?id=${marketplace.functional.google_tag_manager_id}&l=loma-marketplace`}
          />
          <Script
            id="gtm-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,l){
                  w[l]=w[l]||[];
                  w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                })(window,'loma-marketplace');
              `,
            }}
          />
        </>
      )}
      {marketplace?.functional?.google_analytics_id && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${marketplace.functional.google_analytics_id}`}
          />
          <Script
            id="_gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              window.gaTrackingId = '${marketplace.functional.google_analytics_id}';
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${marketplace.functional.google_analytics_id}', {
                page_path: window.location.pathname
              });`,
            }}
          />
        </>
      )}
      {header || <DefaultHeader {...rest} />}
      <chakra.main px={0}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </chakra.main>
      {footer || <DefaultFooter {...rest} />}
    </>
  );
};

export default DefaultLayout;
