import { Flex } from '@chakra-ui/react';
import { CategoryCard } from 'src/components/molecules';
import { Category, RootCategory } from 'src/api/v1-api';
import CategoryCardAdaptiveGrid from './category-card-adaptive-grid';

interface Props {
  category: Category | RootCategory | null;
  createListing?: boolean;
}

const CategoryCards = ({ category, createListing = false }: Props) => {
  if (
    !category ||
    category.children?.length === 0 ||
    (!category?.functional?.category_cards && !createListing)
  ) {
    return null;
  }

  // Filter out subcategories that have a position below 0
  category.children = category.children?.filter((subcategory) => subcategory.position >= 0);

  // Sort subcategories by position first, then by created_at
  category.children?.sort((a, b) => {
    if (a.position === b.position) {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    }
    return a.position - b.position;
  });

  const items = category?.children.filter((cat) => cat.image) ?? [];

  return items.length !== 0 ? (
    <Flex direction="column" gap={{ base: 3, md: 4, lg: 5 }}>
      <CategoryCardAdaptiveGrid
        items={items}
        onRender={(subcategory) => (
          <CategoryCard
            key={subcategory.id}
            categoryId={subcategory.id.toString()}
            name={subcategory.name}
            description={subcategory.description}
            imageURL={subcategory.image}
            cta={{
              label: subcategory.cta_text,
              link: subcategory.cta_link,
              is_external: subcategory?.cta_link?.length > 0 && subcategory?.cta_new_tab,
            }}
            createListing={createListing}
          />
        )}
      ></CategoryCardAdaptiveGrid>
    </Flex>
  ) : null;
};

export default CategoryCards;
