import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { LoginForm, SignupForm } from 'src/components/molecules';
import { useCallback, useState } from 'react';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  email?: string;
  showSignUpOnLoad?: boolean;
}

const LoginModal: React.FC<LoginModalProps> = ({
  isOpen,
  onClose,
  email,
  showSignUpOnLoad = false,
}) => {
  const [showSignUp, setShowSignUp] = useState(showSignUpOnLoad);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = useCallback((event: any) => {
    const { scrollTop } = event.target;
    setIsScrolled(scrollTop > 0);
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        bg="white"
        maxHeight={{ base: 'calc(100vh - 1rem)', md: '80vh' }}
        margin={{ base: '0.5rem', md: '1rem' }}
      >
        <ModalHeader />
        <ModalCloseButton zIndex="1" position="absolute" top="1rem" right="1rem" />
        <ModalBody
          onScroll={handleScroll}
          overflowY="auto"
          position="relative"
          _after={
            !isScrolled
              ? {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '20px',
                  background: 'linear-gradient(to top, #fff, rgba(255, 255, 255, 0))',
                }
              : undefined
          }
          bg="white"
        >
          {showSignUp ? (
            <SignupForm onClose={onClose} setShowSignUp={setShowSignUp} />
          ) : (
            <LoginForm userEmail={email} onClose={onClose} setShowSignUp={setShowSignUp} />
          )}
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
