import React from 'react';
import { Box, CloseButton, Text, useTheme } from '@chakra-ui/react';
import { TextWithLinks } from 'src/components/atoms';

interface Props {
  text: string;
  onClose: () => void;
}

const Announcement = ({ onClose, text }: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      as="section"
      bg="primary.500"
      color="white"
      position="relative"
      borderRadius={theme.border_radius.border_radius_2}
      py="10px"
    >
      <Text
        wordBreak="break-word"
        fontWeight="medium"
        pr={12}
        pl={6}
        textAlign={{ base: 'left', sm: 'center' }}
      >
        <TextWithLinks text={text} />
      </Text>
      <CloseButton onClick={onClose} position="absolute" right="2" top="1.5" />
    </Box>
  );
};

export default Announcement;
