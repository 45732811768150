import React, { useEffect, useState } from 'react';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  FormControl,
  Select,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { Marketplace, MarketplaceInfo, MarketplacePayout } from 'src/api/v1-api';

interface RegisterPayoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  registerPayoutHandler: (payout: Partial<MarketplacePayout>) => Promise<void>;
  marketplace: MarketplaceInfo | Marketplace | null;
  totalAmount: number;
}

const PAYOUT_METHODS = ['Venmo', 'Cash App', 'PayPal', 'ACH'];

const RegisterPayoutModal = ({
  isOpen,
  registerPayoutHandler,
  onClose,
  totalAmount,
  marketplace,
}: RegisterPayoutModalProps) => {
  const [payoutMethod, setPayoutMethod] = useState(PAYOUT_METHODS[0]);
  const [payoutDate, setPayoutDate] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [notes, setNotes] = useState('');
  const [amount, setAmount] = useState<number>(totalAmount);

  useEffect(() => {
    setPayoutMethod(PAYOUT_METHODS[0]);
    setPayoutDate('');
    setTransactionId('');
    setNotes('');
    setAmount(totalAmount);
  }, [isOpen, totalAmount]);

  const isSubmitButtonDisabled = !payoutMethod || !payoutDate || !transactionId;

  const submitHandler = async () => {
    await registerPayoutHandler({
      payout_method: payoutMethod,
      date_of_payout: payoutDate,
      transaction_id: transactionId,
      marketplace_id: marketplace?.id,
      amount,
      notes,
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Payout Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={4}>
            <FormControl>
              <FormLabel>Amount</FormLabel>
              <Input
                type="number"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Payout Method</FormLabel>
              <Select value={payoutMethod} onChange={(e) => setPayoutMethod(e.target.value)}>
                {PAYOUT_METHODS.map((method) => (
                  <option key={method} value={method}>
                    {method}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Date of payout</FormLabel>
              <Input
                type="date"
                value={payoutDate}
                onChange={(e) => setPayoutDate(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Transaction ID</FormLabel>
              <Input
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter gap="2">
          <Button onClick={onClose}>Cancel</Button>
          <Button colorScheme="primary" onClick={submitHandler} isDisabled={isSubmitButtonDisabled}>
            Register payout
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterPayoutModal;
