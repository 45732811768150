import { Text } from '@chakra-ui/react';
import { calculateTimeLeft, displayTimeLeft } from '../countdown';
import { utcISOToLocalDate } from 'src/utils/common';

interface AuctionTimeLeftProps {
  auctionEndStr: string | null | undefined;
}

const AuctionTimeLeft = ({ auctionEndStr }: AuctionTimeLeftProps) => {
  const auctionEnd = auctionEndStr ? utcISOToLocalDate(auctionEndStr) : null;
  const timeLeft = auctionEnd ? calculateTimeLeft(auctionEnd.getTime()) : null;
  const timeLeftStr = timeLeft ? displayTimeLeft(timeLeft) : 'auction pending';

  return (
    <Text fontSize="0.7rem" color="gray.600">
      {timeLeftStr}
    </Text>
  );
};

export default AuctionTimeLeft;
