import React from 'react';
import { Link, Text } from '@chakra-ui/react';
import { getMediaFileName } from 'src/utils/attributeUtils';

type Props = {
  url: string;
};

const PdfRenderer = ({ url }: Props) => {
  return (
    <Link href={url} isExternal>
      <Text fontSize="md" fontStyle="semibold" textDecoration="underline">
        {getMediaFileName(url)}
      </Text>
    </Link>
  );
};

export default PdfRenderer;
