import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spinner,
  Stack,
  Text,
  Input,
} from '@chakra-ui/react';
import CategorySelector from 'src/components/molecules/category-selector';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { BasicCategory } from 'src/api/v1-api';
import useSingleToast from 'src/utils/hooks/toast/useSingleToast';
import useRequest from 'src/utils/hooks/useRequest';
import { Controller, useForm } from 'react-hook-form';

const CategoryTakeRateForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<BasicCategory | null>(null);
  const showToast = useSingleToast();
  const { api } = useRequest();
  const {
    marketplace,
    categories,
    setCategories,
    fetchCategories,
    isLoading: categoryLoading,
  } = useMarketplaceContext();

  const { control, handleSubmit, reset } = useForm<{ take_rate: number }>({
    defaultValues: {},
  });

  useEffect(() => {
    if (categories.length === 0) {
      fetchCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace?.id, categories]);

  useEffect(() => {
    setIsLoading(categoryLoading);
  }, [categoryLoading]);

  useEffect(() => {
    if (selectedCategory?.take_rate) {
      reset({ take_rate: parseFloat(selectedCategory.take_rate) });
    } else {
      reset();
    }
  }, [selectedCategory]);

  const handleOnCategorySelect = (category: BasicCategory | null) => {
    setSelectedCategory(category);
  };

  const onSubmit = async (data: { take_rate: number }) => {
    if (!marketplace || !selectedCategory || !data.take_rate) {
      return;
    }

    try {
      setIsLoading(true);
      const payload = {
        take_rate: data.take_rate.toString(),
      };
      const response = await api.marketplace.categories.update(
        marketplace.id,
        selectedCategory.id,
        payload,
      );
      const updatedCategories = categories.map((category) => {
        if (category.id === selectedCategory.id) {
          return response;
        }
        return category;
      });

      showToast({
        title: 'Success',
        description: 'Category take rate has been updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setCategories(updatedCategories);
    } catch (error) {
      showToast({
        title: 'Error',
        description:
          'There was a problem updating category take rate. Please try refreshing the page or contact support.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        sentryAlert: {
          error,
          level: 'error',
          extras: {
            marketplaceId: marketplace?.id,
            categoryId: selectedCategory.id,
          },
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex direction="column">
      <Box position="relative" w="100%">
        {isLoading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="rgba(255, 255, 255, 0.7)"
          >
            <Spinner />
          </Box>
        )}
        <Flex direction="column" gap={4}>
          <Text>Category</Text>
          <CategorySelector
            marketplaceId={marketplace?.id}
            onCategorySelect={handleOnCategorySelect}
            mode="provide-categories"
            categories={categories as unknown as BasicCategory[]}
          />
        </Flex>
        {selectedCategory && (
          <Stack as="form" direction={'column'} onSubmit={handleSubmit(onSubmit)} gap={4} pt={4}>
            <Controller
              name="take_rate"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl id="take_rate" isInvalid={Boolean(error)} isRequired>
                    <FormLabel>Take Rate</FormLabel>
                    <Input {...field} type="number" />
                    {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                );
              }}
            />
            <Button type="submit" colorScheme="primary">
              Save
            </Button>
          </Stack>
        )}
      </Box>
    </Flex>
  );
};

export default CategoryTakeRateForm;
