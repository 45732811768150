import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Radio,
  HStack,
  RadioGroup,
  Switch,
} from '@chakra-ui/react';
import { CategoryInList, CategoryType } from 'src/api/v1-api';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  RadioCard,
  RadioCardGroup,
} from 'src/components/chakra-ui-pro/application/form-elements/RadioCardGroup';
import CategorySelector from 'src/components/molecules/category-selector';
import { MarketplaceContext } from 'src/contexts/marketplace';
import AttributeGenerator from 'src/components/molecules/attribute-importer';
import { useAuth } from 'src/utils/auth';

export type CategoryFormFields = {
  name: string;
  parentId: number;
  type: CategoryType;
  multipleVariants: boolean;
  priceFilterInputType: 'slider' | 'input' | 'all';
  shuffle_featured_listings: boolean;
  category_json: object;
};

interface CategoryFormProps {
  defaultCategoryId: number;
  category?: CategoryInList | null;
  onSubmit: (data: CategoryFormFields) => void;
  isSubmitting?: boolean;
}

export default function CategoryForm({
  defaultCategoryId,
  category,
  onSubmit,
  isSubmitting,
}: CategoryFormProps) {
  const defaultValues = {
    name: category?.name,
    parentId: defaultCategoryId ?? category?.parent ?? '',
    type: CategoryType.MARKETPLACE,
    multipleVariants: category?.multiple_variants === true,
    priceFilterInputType: category?.functional.price_filter_input_type || 'all',
    shuffle_featured_listings: category?.shuffle_featured_listings || false,
    category_json: {},
  };
  const { control, handleSubmit, setValue, reset } = useForm<CategoryFormFields>({
    defaultValues: defaultValues,
  });
  const { marketplace } = useContext(MarketplaceContext);
  const { hasStaff } = useAuth();
  const isStaffUser = hasStaff();
  const [missingJson, setMissingJson] = useState(false);

  useEffect(() => {
    reset(defaultValues);
  }, [category]);

  const onFormSubmit = (data: CategoryFormFields) => {
    onSubmit(data);
  };

  const handleSetJson = async (json: object) => {
    if (Object.keys(json).length === 0) {
      setMissingJson(true);
    } else {
      setMissingJson(false);
    }
    setValue('category_json', json);
  };

  return (
    <Stack as="form" onSubmit={handleSubmit(onFormSubmit)} flexDirection="column" gap={2}>
      <Controller
        name="name"
        control={control}
        rules={{
          required: 'Category name is required.',
        }}
        render={({ field }) => (
          <FormControl id="name" isRequired>
            <FormLabel>Category Name</FormLabel>
            <Input {...field} />
          </FormControl>
        )}
      />

      <Controller
        name="parentId"
        control={control}
        rules={{
          required: 'A parent is required.',
        }}
        render={({ field: { onChange, value } }) => (
          <FormControl id="parentId" isRequired>
            <FormLabel>Parent Category</FormLabel>
            <CategorySelector
              marketplaceId={marketplace?.id}
              onCategorySelect={(category) => onChange(category?.id)}
              selectedCategoryId={value.toString()}
              mode="category-admin"
            />
          </FormControl>
        )}
      />

      <Controller
        name="type"
        control={control}
        rules={{
          required: 'Category type is required.',
        }}
        render={({ field }) => (
          <FormControl id="type" isRequired mb={2}>
            <FormLabel>Category Type</FormLabel>
            <RadioCardGroup {...field}>
              <RadioCard value="marketplace">
                <Text color="muted" fontWeight="semibold">
                  Marketplace
                </Text>
                <Text color="muted" fontSize="sm">
                  Buyers pay directly through marketplace. Fees are deducted from seller’s payout.
                </Text>
              </RadioCard>
              <RadioCard value="leads">
                <Text color="muted" fontWeight="semibold">
                  Listing + Leads
                </Text>
                <Text color="muted" fontSize="sm">
                  Sellers create listings, sometimes for a fee. Buyers and sellers negotiate
                  transactions themselves outside of marketplace.
                </Text>
              </RadioCard>
              <RadioCard value="directory">
                <Text color="muted" fontWeight="semibold">
                  Directory
                </Text>
                <Text color="muted" fontSize="sm">
                  Sellers can list their services, sometimes for a fee. Buyers and sellers negotiate
                  transactions themselves outside of marketplace.
                </Text>
              </RadioCard>
              <RadioCard value="auction">
                <Text color="muted" fontWeight="semibold">
                  Auction
                </Text>
                <Text color="muted" fontSize="sm">
                  Sellers can put a single item up for a time-based auction. Requires marketplace
                  admin approval.
                </Text>
              </RadioCard>
            </RadioCardGroup>
          </FormControl>
        )}
      />

      <Controller
        name="multipleVariants"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormControl id="multipleVariants" isRequired>
            <FormLabel>Multiple Variations per Listing</FormLabel>
            <RadioCardGroup
              value={value ? 'true' : 'false'}
              onChange={(e) => onChange(e === 'true')}
              onBlur={onBlur}
            >
              <RadioCard value="false">
                <Text color="muted" fontWeight="semibold">
                  No
                </Text>
                <Text color="muted" fontSize="sm">
                  Each listing page represents exactly 1 individual good or service available.
                </Text>
              </RadioCard>
              <RadioCard value="true">
                <Text color="muted" fontWeight="semibold">
                  Yes
                </Text>
                <Text color="muted" fontSize="sm">
                  Listing page can sometimes have different variations, for example a shirt with
                  different sizes, or a bookable service for different days or times of day.
                </Text>
              </RadioCard>
            </RadioCardGroup>
          </FormControl>
        )}
      />

      <Controller
        name="priceFilterInputType"
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel htmlFor="priceFilterInputType">
              Display price filter as a slider or as an input?
            </FormLabel>
            <RadioGroup
              {...field}
              defaultValue={category?.functional.price_filter_input_type || ''}
            >
              <HStack spacing="64px" my="24px">
                <Radio size="lg" value="slider">
                  Slider
                </Radio>
                <Radio size="lg" value="input">
                  Text Input
                </Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
        )}
      />
      <Controller
        name="shuffle_featured_listings"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormControl id="shuffle_featured_listings" display="flex" alignItems="center">
            <FormLabel htmlFor="shuffle_featured_listings" mb="0">
              Shuffle featured Listings
            </FormLabel>
            <Switch
              id="shuffle_featured_listings"
              onChange={(e) => onChange(e.currentTarget.checked)}
              onBlur={onBlur}
              isChecked={value}
            />
          </FormControl>
        )}
      />

      {isStaffUser && (
        <AttributeGenerator
          category={category}
          disableCreateBtn={true}
          missingJson={missingJson}
          handleSetJson={handleSetJson}
        />
      )}

      <Button colorScheme="primary" type="submit" isLoading={isSubmitting}>
        Create
      </Button>
    </Stack>
  );
}
