import { Link } from '@chakra-ui/react';
import React from 'react';
import { ValueObject } from 'src/api/v1-api/helpers';

const UrlDisplay = (valueObject: ValueObject) => {
  let textLink = valueObject.value as string;
  if (valueObject.text_link) {
    textLink = valueObject.text_link as string;
  }

  return (
    <Link href={valueObject.value as string} isExternal>
      {textLink}
    </Link>
  );
};

export default UrlDisplay;
