import { Listing, MarketplaceEarning } from 'src/api/v1-api';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { getListingUrl } from 'src/utils/marketplaceUtils';
import MarketplaceEarningItem from 'src/components/atoms/marketplace-earning-item';

interface MarketplaceEarningListingProps {
  earning: MarketplaceEarning;
  showImage?: boolean;
}

const MarketplaceEarningListing = ({
  earning,
  showImage = false,
}: MarketplaceEarningListingProps) => {
  const { urlBase } = useMarketplaceContext();
  const { entity_id: listing_id, client_account_id } = earning.metadata;
  const listing: Listing = earning.earning_object;

  const listingImage = (listing?.listing_images ?? [])[0];
  const listingUrl = getListingUrl(urlBase, listing_id);

  return (
    <MarketplaceEarningItem
      earningDate={earning.invoice_date}
      earningTitle="Paid Listing"
      earningAmount={earning.amount}
      earningItemUrl={listingUrl}
      earningImageUrl={listingImage?.image}
      showImage={showImage}
      earningMetadata={[
        {
          title: 'Listing Title',
          value: listing?.title ?? '',
          displayTitle: false,
        },
      ]}
      clientAccountId={client_account_id}
    />
  );
};

export default MarketplaceEarningListing;
