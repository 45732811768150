import { Flex, Heading } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
  title: string;
  cta?: ReactNode;
}

const SectionHeader = ({ title, cta }: Props): JSX.Element => {
  return (
    <Flex
      justify="space-between"
      alignItems={{ base: 'start', md: 'center' }}
      direction={{ base: 'column', md: 'row' }}
    >
      <Heading as="h3" fontSize="2xl" fontWeight="semibold">
        {title}
      </Heading>
      {cta ?? null}
    </Flex>
  );
};

export default SectionHeader;
