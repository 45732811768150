import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useTheme,
} from '@chakra-ui/react';
import React from 'react';
import { SketchPicker as ReactColorPicker } from 'react-color';

interface ColorPickerProps {
  value: string;
  onChange: (color: string) => void;
  showColorHex?: boolean;
}

const ColorPicker = ({ value, onChange, showColorHex }: ColorPickerProps) => {
  const theme = useTheme();
  return (
    <Popover>
      {showColorHex && value}
      <PopoverTrigger>
        <Box
          as="button"
          type="button"
          w="100%"
          h="30px"
          borderRadius={theme.border_radius.border_radius_1}
          backgroundColor={value}
          mb={4}
          cursor="pointer"
          border={value.toLowerCase() === '#ffffff' ? '2px solid #E2E8F0' : 'none'}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <ReactColorPicker
            width="fit-content"
            color={value}
            onChange={(color) => onChange(color.hex)}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPicker;
