import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Post } from 'src/api/test';
import { AppState } from '..';
import { TestState, GET_POSTS, GET_POST } from './test.helpers';

const initialState: TestState = {
  posts: [],
  post: undefined,
};

const { actions: sliceActions, reducer } = createSlice({
  name: 'test',
  initialState,
  reducers: {
    setState: (state, { payload }: PayloadAction<Partial<TestState>>) => ({
      ...state,
      ...payload,
    }),
    setPosts: (state, { payload }: PayloadAction<Post[]>) => {
      state.posts = payload;
    },
  },
});

const actions = {
  ...sliceActions,
  // Saga Actions
  getPosts: () => ({ type: GET_POSTS }),
  getPost: (payload: number) => ({ type: GET_POST, payload }),
};

const selectors = {
  posts: (state: AppState): Post[] => state.test.posts,
  post: (state: AppState): Post | undefined => state.test.post,
};

export { actions as testActions, reducer as testReducer, selectors as testSelectors };
