import {
  Container,
  Stack,
  Text,
  Menu,
  MenuItem,
  Icon,
  Select,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { BLACK, CONTAINER_MAX_WIDTH_1 } from 'src/constants/ui';
import { FaStoreAlt } from 'react-icons/fa';
import {
  MdApproval,
  MdCategory,
  MdOutlineAttachMoney,
  MdYoutubeSearchedFor,
  MdOutlineAssignmentInd,
  MdMan4,
  MdOutlinePersonOutline,
} from 'react-icons/md';
import NextLink from 'next/link';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useAuth } from 'src/utils/auth';
import { useContext, useEffect } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { CoverLoader, LoginPrompt } from 'src/components/atoms';
import CreateMarketplaceButton from 'src/components/molecules/admin/create-marketplace-button';
import CloneMarketplaceButton from 'src/components/molecules/admin/clone-marketplace-button';

interface MartketplaceAdminLayoutProps {
  children: React.ReactNode;
  title: string;
}

const MarketplaceAdminLayout = ({ children, title }: MartketplaceAdminLayoutProps): JSX.Element => {
  const router = useRouter();
  const { hasAdmin, authLoading, isAuthenticated, user, userLoaded } = useAuth();
  const { marketplace, categories, fetchCategories } = useContext(MarketplaceContext);

  const isAdmin = hasAdmin(marketplace?.id);

  useEffect(() => {
    if (isAdmin && categories.length === 0) {
      fetchCategories();
    }
  }, [marketplace?.id, categories, isAdmin]);

  useEffect(() => {
    if (marketplace?.id && !authLoading && !isAdmin && userLoaded) {
      if (!user) {
        router.push('/login');
      } else {
        router.push('/');
      }
    }
  }, [marketplace, isAdmin, authLoading, userLoaded]);

  if (authLoading || !isAdmin || !userLoaded) {
    return <CoverLoader />;
  }

  const hasConciergeCategory = categories.some(
    (category) => !category.isDisabled && category.functional?.lead_concierge === true,
  );

  const handleNavigation = (path: string) => {
    window.location.href = path;
  };

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={title} />
        <link rel="icon" href={marketplace?.favicon_image || '/marketplace.ico'} />
      </Head>
      <Container maxW={CONTAINER_MAX_WIDTH_1} my={{ base: 4, md: 6, lg: 8 }}>
        <Stack justify="flex-start" align="flex-start" spacing="20px">
          <Flex w="100%" flexDirection={{ base: 'column', md: 'row' }}>
            <Text
              lineHeight="1.33"
              fontWeight="semibold"
              fontSize="24px"
              as={'span'}
              wordBreak="break-word"
            >
              Marketplace Admin
            </Text>
            <Spacer />
            <Flex
              alignItems="center"
              gap={4}
              flexDirection={{ base: 'column', md: 'row' }}
              mt={{ base: 6, md: 0 }}
            >
              <CreateMarketplaceButton mb={4} />
              <CloneMarketplaceButton mb={4} />
            </Flex>
          </Flex>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justify="flex-start"
            align="flex-start"
            spacing="20px"
            width="100%"
          >
            <Stack
              justify="flex-start"
              align="flex-start"
              spacing="4px"
              width="259px"
              maxWidth="100%"
            >
              {/* Dropdown for mobile */}
              <Select
                display={{ base: 'block', md: 'none' }}
                onChange={(e) => handleNavigation(e.target.value)}
                value={window.location.pathname}
              >
                <option value="/admin/info">Marketplace Info</option>
                <option value="/admin/social-media">Social Media</option>
                <option value="/admin/categories">Categories</option>
                <option value="/admin/queries">Saved Searches</option>
                <option value="/admin/approvals">Listing Approvals</option>
                <option value="/admin/payout">Payout Info</option>
                <option value="/admin/users">Users</option>
                {hasConciergeCategory && <option value="/admin/concierge">Lead Concierge</option>}
              </Select>
              {/* Regular menu for desktop */}
              <Stack display={{ base: 'none', md: 'block' }} minW="200px">
                <Menu>
                  <NextLink href="/admin/info" prefetch={false}>
                    <MenuItem
                      icon={<Icon as={FaStoreAlt} boxSize={5} />}
                      bg={router.asPath === '/admin/info' ? 'gray.100' : ''}
                      color={router.asPath === '/admin/info' ? BLACK : ''}
                      borderRadius="md"
                    >
                      Marketplace Info
                    </MenuItem>
                  </NextLink>
                  <NextLink href="/admin/social-media" prefetch={false}>
                    <MenuItem
                      icon={<Icon as={MdOutlineAssignmentInd} boxSize={5} />}
                      bg={router.asPath === '/admin/social-media' ? 'gray.100' : ''}
                      color={router.asPath === '/admin/social-media' ? BLACK : ''}
                      borderRadius="md"
                    >
                      Social Media
                    </MenuItem>
                  </NextLink>
                  <NextLink href="/admin/categories" prefetch={false}>
                    <MenuItem
                      icon={<Icon as={MdCategory} boxSize={5} />}
                      bg={router.asPath === '/admin/categories' ? 'gray.100' : ''}
                      color={router.asPath === '/admin/categories' ? BLACK : ''}
                      borderRadius="md"
                    >
                      Categories
                    </MenuItem>
                  </NextLink>
                  <NextLink href="/admin/queries" prefetch={false}>
                    <MenuItem
                      icon={<Icon as={MdYoutubeSearchedFor} boxSize={5} />}
                      bg={router.asPath === '/admin/queries' ? 'gray.100' : ''}
                      color={router.asPath === '/admin/queries' ? BLACK : ''}
                      borderRadius="md"
                    >
                      Saved Searches
                    </MenuItem>
                  </NextLink>
                  <NextLink href="/admin/approvals" prefetch={false}>
                    <MenuItem
                      icon={<Icon as={MdApproval} boxSize={5} />}
                      bg={router.asPath === '/admin/approvals' ? 'gray.100' : ''}
                      color={router.asPath === '/admin/approvals' ? BLACK : ''}
                      borderRadius="md"
                    >
                      Listing Approvals
                    </MenuItem>
                  </NextLink>
                  <NextLink href="/admin/payout" prefetch={false}>
                    <MenuItem
                      icon={<Icon as={MdOutlineAttachMoney} boxSize={5} />}
                      bg={router.asPath === '/admin/payout' ? 'gray.100' : ''}
                      color={router.asPath === '/admin/payout' ? BLACK : ''}
                      borderRadius="md"
                    >
                      Payout Info
                    </MenuItem>
                  </NextLink>
                  <NextLink href="/admin/users" prefetch={false}>
                    <MenuItem
                      icon={<Icon as={MdOutlinePersonOutline} boxSize={5} />}
                      bg={router.asPath === '/admin/users' ? 'gray.100' : ''}
                      color={router.asPath === '/admin/users' ? BLACK : ''}
                      borderRadius="md"
                    >
                      Users
                    </MenuItem>
                  </NextLink>
                  {hasConciergeCategory && (
                    <NextLink href="/admin/concierge" prefetch={false}>
                      <MenuItem
                        icon={<Icon as={MdMan4} boxSize={5} />}
                        bg={router.asPath === '/admin/concierge' ? 'gray.100' : ''}
                        color={router.asPath === '/admin/concierge' ? BLACK : ''}
                        borderRadius="md"
                      >
                        Lead Concierge
                      </MenuItem>
                    </NextLink>
                  )}
                </Menu>
              </Stack>
            </Stack>
            <Stack
              padding={['12px', '12px', '32px']}
              paddingTop={['16px', '16px', '32px']}
              borderRadius="8px"
              justify="flex-start"
              align="flex-start"
              spacing="28px"
              borderColor="gray.300"
              borderStartWidth="1px"
              borderEndWidth="1px"
              borderTopWidth="1px"
              borderBottomWidth="1px"
              width="100%"
            >
              {isAdmin && <>{children}</>}
              {isAuthenticated && !user ? (
                <></>
              ) : (
                !authLoading &&
                !isAdmin && (
                  <LoginPrompt message="to edit settings. Only Admins can modify these settings." />
                )
              )}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default MarketplaceAdminLayout;
