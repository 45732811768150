import { Button, ChakraProps, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdFileCopy } from 'react-icons/md';
import { GlobalRoles } from 'src/api/v1-api';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';
import useRequest from 'src/utils/hooks/useRequest';

export type CloneMarketplaceButtonProps = ChakraProps;

/**
 * Renders a button for cloning the marketplace if the logged in user has the
 * proper permission.
 */
const CloneMarketplaceButton = (props: CloneMarketplaceButtonProps) => {
  const { post } = useRequest();
  const { marketplace } = useMarketplaceContext();
  const { hasRole } = useAuth();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const hasRequiredRole = hasRole(marketplace?.id?.toString() ?? '', GlobalRoles.Configuration);

  const handleClone = async () => {
    try {
      setLoading(true);
      const response = await post<{ name: string; slug: string }>({
        url: `/marketplaces/${marketplace?.id}/admin/clone/`,
      });
      toast({
        status: 'success',
        duration: 5000,
        isClosable: true,
        title: `New marketplace '${response.name}' created (${response.slug})`,
      });
    } catch (error) {
      toast({
        status: 'error',
        duration: 5000,
        isClosable: true,
        title: `There was an error while cloning marketplace`,
      });
    } finally {
      setLoading(false);
    }
  };

  if (!hasRequiredRole) {
    return null;
  }

  return (
    <Button
      colorScheme="primary"
      alignSelf="flex-start"
      leftIcon={<MdFileCopy />}
      onClick={handleClone}
      isLoading={loading}
      isDisabled={loading}
      minWidth={'180px'}
      {...props}
    >
      Clone Marketplace
    </Button>
  );
};

export default CloneMarketplaceButton;
