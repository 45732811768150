import React, { useEffect, useState } from 'react';
import { Box, Checkbox, CheckboxGroup, Popover, Portal, Stack } from '@chakra-ui/react';
import { FilterPopoverButton, FilterPopoverContent } from 'src/components/atoms';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';
import { ListingFilterItemProps } from '.';
import { STATES } from 'src/constants/states';

const sortedStatesByName = STATES.sort((a, b) => a.name.localeCompare(b.name));

const AddressFilter = ({ attributeId, isMobileView = false }: ListingFilterItemProps) => {
  const defaultValue: number[] | string[] | boolean[] = [];
  const [inputValue, setInputValue] = useState<(number | string | boolean)[]>(defaultValue);
  const {
    attributes,
    selectedAttributes,
    setSelectedAttributes,
    stagedAttributes,
    setStagedAttributes,
  } = useAttributeSelector();
  const attribute = attributes.find((a) => a.name === attributeId);

  const handleMobileChange = (value: (number | string)[]) => {
    setInputValue(value);
    setStagedAttributes({
      ...stagedAttributes,
      [attributeId]: value,
    });
  };

  useEffect(() => {
    if (attributeId in selectedAttributes) {
      setInputValue(selectedAttributes[attributeId]);
    }
  }, [selectedAttributes]);

  const noFilter =
    !selectedAttributes[attributeId] ||
    selectedAttributes[attributeId].length === 0 ||
    (selectedAttributes[attributeId].length === 1 && selectedAttributes[attributeId][0] === '');

  return isMobileView ? (
    <Box maxHeight="320px" overflowY="auto" overflowX="hidden">
      <Stack as="fieldset" spacing={2}>
        <CheckboxGroup
          onChange={handleMobileChange}
          value={inputValue as unknown as number[] | string[]}
        >
          {sortedStatesByName.map((state) => (
            <Checkbox key={state.abbreviation} value={state.abbreviation} colorScheme="primary">
              <span>{state.name}</span>
            </Checkbox>
          ))}
        </CheckboxGroup>
      </Stack>
    </Box>
  ) : (
    <Popover placement="bottom-start">
      <FilterPopoverButton label={attribute?.name} hasFilter={!noFilter} />
      <Portal>
        <FilterPopoverContent
          isCancelDisabled={!(defaultValue !== inputValue)}
          onClickApply={() => {
            setSelectedAttributes({
              ...selectedAttributes,
              [attributeId]: inputValue,
            });
          }}
          onClickCancel={() => {
            setInputValue(defaultValue);
            setSelectedAttributes({
              ...selectedAttributes,
              [attributeId]: defaultValue,
            });
          }}
        >
          <Box maxHeight="320px" overflowY="auto" overflowX="hidden">
            <Stack as="fieldset" spacing={2}>
              <CheckboxGroup
                onChange={(v) => setInputValue(v)}
                value={inputValue as unknown as number[] | string[]}
              >
                {sortedStatesByName.map((state) => (
                  <Checkbox
                    key={state.abbreviation}
                    value={state.abbreviation}
                    colorScheme="primary"
                  >
                    <span>{state.name}</span>
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </Stack>
          </Box>
        </FilterPopoverContent>
      </Portal>
    </Popover>
  );
};

export default AddressFilter;
