import { Alert, AlertIcon, Container, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CategoryCards } from 'src/components/atoms';
import { BasicCategory, Category } from 'src/api/v1-api';
import { CONTAINER_MAX_WIDTH_2 } from 'src/constants/ui';
import { apiRequest } from 'src/utils/fetchUtils';

interface Props {
  category: BasicCategory | null;
}

const CreateListingSubCategorySelector: React.FC<Props> = ({ category }) => {
  const [fullCategory, setFullCategory] = useState<Category | null>(null);

  useEffect(() => {
    const fetchCategory = async () => {
      if (category && !category.functional?.can_create_listings) {
        const url = `${process.env.NEXT_PUBLIC_API_HOST}/categories/${category.id}/`;
        try {
          const response = await apiRequest('GET', url, null);
          setFullCategory(response);
        } catch (error) {
          console.error('Error fetching category', error);
        }
      }
    };
    fetchCategory();
  }, [category]);

  const categoryRequiresSubcategorySelection =
    fullCategory &&
    fullCategory.children &&
    fullCategory.children.length > 0 &&
    !fullCategory.functional?.can_create_listings;

  if (!fullCategory) {
    return null;
  }

  if (!categoryRequiresSubcategorySelection) {
    return (
      <Container
        maxW={CONTAINER_MAX_WIDTH_2}
        my={{ base: 4, md: 6, lg: 8 }}
        p={{ base: 4, md: 6, lg: 8 }}
      >
        <Alert status="info">
          <AlertIcon />
          This category does not allow listing creation.
        </Alert>
      </Container>
    );
  }

  return (
    <Container
      maxW={CONTAINER_MAX_WIDTH_2}
      my={{ base: 4, md: 6, lg: 8 }}
      p={{ base: 4, md: 6, lg: 8 }}
    >
      <Heading size="md" pb={4}>
        Select a category
      </Heading>
      <CategoryCards category={fullCategory} createListing={true} />
    </Container>
  );
};

export default CreateListingSubCategorySelector;
