import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Spinner,
  Switch,
  Textarea,
  useToast,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Category, CategoryInList } from 'src/api/v1-api';
import useRequest from 'src/utils/hooks/useRequest';

type Props = {
  category?: CategoryInList | Category | null;
  handleCreateAttributes?: (json: object) => Promise<void>;
  disableCreateBtn?: boolean;
  missingJson: boolean;
  handleSetJson?: (json: object) => Promise<void>;
};

const AttributeGenerator = ({
  category,
  handleCreateAttributes,
  disableCreateBtn,
  missingJson,
  handleSetJson,
}: Props) => {
  const [completeOverwrite, setCompleteOverwrite] = useState(false);
  const [categoryDescription, setCategoryDescription] = useState('');
  const [categoryJson, setCategoryJson] = useState('');
  const [missingDescription, setMissingDescription] = useState(false);
  const [isGeneratingJson, setIsGeneratingJson] = useState(false);
  const { api } = useRequest();
  const toast = useToast();

  const handleGenerateAttributes = async () => {
    setIsGeneratingJson(true);
    if (categoryDescription.length < 10) {
      setMissingDescription(true);
      toast({
        status: 'error',
        duration: 5000,
        isClosable: true,
        title: `Please enter a meaningful description`,
      });
      setIsGeneratingJson(false);
      return;
    } else {
      setMissingDescription(false);
    }
    let body: any = {
      description: categoryDescription,
      complete_overwrite: completeOverwrite,
    };
    try {
      if (category && category.id) {
        body = { ...body, category_id: category.id };
      }
      const response = await api.marketplace.categories.generate(body);
      setIsGeneratingJson(false);
      const jsonString = JSON.stringify(response, undefined, 4);
      if (typeof handleSetJson !== 'undefined') {
        handleSetJson(JSON.parse(jsonString));
      }
      setCategoryJson(jsonString);
      toast({
        status: 'success',
        duration: 5000,
        isClosable: true,
        title: `Attributes Generated correctly`,
      });
    } catch (error) {
      toast({
        status: 'error',
        duration: 5000,
        isClosable: true,
        title: `There was an error while Generating Attributes`,
      });
    } finally {
      setIsGeneratingJson(false);
    }
  };

  const handleJsonParse = () => {
    try {
      const jsonObj = JSON.parse(categoryJson);
      return jsonObj;
    } catch (error) {
      console.error(error);
    }
    return {};
  };

  return (
    <>
      <Text fontSize="lg" fontWeight="semibold">
        Generate Attributes
      </Text>

      <FormControl isInvalid={missingDescription}>
        <FormLabel>Describe the Category</FormLabel>
        <Textarea
          placeholder=""
          value={categoryDescription}
          onChange={(e) => setCategoryDescription(e.target.value)}
        />
      </FormControl>

      <HStack>
        {!disableCreateBtn && (
          <>
            <Text fontSize="md">Only add new attributes</Text>
            <Switch
              onChange={() => setCompleteOverwrite(!completeOverwrite)}
              isChecked={completeOverwrite}
              isDisabled={isGeneratingJson}
            ></Switch>
            <Text fontSize="md">Completely overwrite</Text>
          </>
        )}

        <Button onClick={() => handleGenerateAttributes()} isDisabled={isGeneratingJson}>
          {completeOverwrite ? 'Generate Category' : 'Generate Attributes'} JSON
        </Button>
      </HStack>

      <HStack>
        <FormLabel>Attributes JSON</FormLabel>
        {!disableCreateBtn && handleCreateAttributes && (
          <Button
            onClick={() => handleCreateAttributes(handleJsonParse())}
            isDisabled={isGeneratingJson || !categoryJson}
          >
            {completeOverwrite ? 'Create Category' : 'Create Attributes'}
          </Button>
        )}
      </HStack>
      {isGeneratingJson ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <FormControl isInvalid={missingJson}>
          <Textarea
            placeholder=""
            value={categoryJson}
            onChange={(e) => setCategoryJson(e.target.value)}
          />
        </FormControl>
      )}
    </>
  );
};

export default AttributeGenerator;
