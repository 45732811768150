import { loadConnectAndInitialize } from '@stripe/connect-js/pure';
import { Container, Spinner, Text } from '@chakra-ui/react';
import { ConnectComponentsProvider, ConnectAccountOnboarding } from '@stripe/react-connect-js';
import useRequest from 'src/utils/hooks/useRequest';
import { Dispatch, SetStateAction, useState } from 'react';

interface Props {
  marketplaceId: number;
  clientAccountId: string;
  isConnectError: boolean;
  setIsConnectError: Dispatch<SetStateAction<boolean>>;
}

export interface StripeConnectComponents {
  account_onboarding?: boolean;
  payments?: boolean;
}

const StripeConnectOnboarding = ({
  marketplaceId,
  clientAccountId,
  isConnectError,
  setIsConnectError,
}: Props) => {
  const { api } = useRequest();

  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      const components: StripeConnectComponents = {
        account_onboarding: true,
      };
      try {
        const client_secret = await api.stripe.accountSession.post(marketplaceId, components);
        if (client_secret === 'invalid') {
          setIsConnectError(true);
          return '';
        }
        setIsConnectError(false);
        return client_secret;
      } catch (error) {
        console.error(error);
        setIsConnectError(true);
        return '';
      }
    };

    return loadConnectAndInitialize({
      publishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY || '',
      fetchClientSecret: fetchClientSecret,
      appearance: {
        overlays: 'dialog',
        variables: {
          colorPrimary: '#625afa',
        },
      },
    });
  });

  return (
    <>
      {stripeConnectInstance && clientAccountId && !isConnectError ? (
        <Container pt={2} pb={4}>
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding
              onExit={() => {
                console.error('The account has exited onboarding');
              }}
              // Optional:
              // fullTermsOfServiceUrl="{{URL}}"
              // recipientTermsOfServiceUrl="{{URL}}"
              // privacyPolicyUrl="{{URL}}"
              // skipTermsOfServiceCollection={false}
              // collectionOptions={{
              //   fields: 'eventually_due',
              //   futureRequirements: 'include',
              // }}
            />
          </ConnectComponentsProvider>
        </Container>
      ) : !isConnectError ? (
        <Spinner />
      ) : (
        <Text pl={4} pb={2} color="fg.muted" fontStyle="italic">
          Error: Invalid Stripe Account ID
        </Text>
      )}
    </>
  );
};

export default StripeConnectOnboarding;
