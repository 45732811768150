export type SortOption =
  | 'newest'
  | 'oldest'
  | 'most-recently-updated'
  | 'price-low-to-high'
  | 'price-high-to-low'
  | 'featured'
  | 'source-loma';

export const sortOptions: { [key in SortOption]: string } = {
  newest: 'Newest',
  oldest: 'Oldest',
  'most-recently-updated': 'Most Recently Updated',
  'price-low-to-high': 'Price, Low to High',
  'price-high-to-low': 'Price, High to Low',
  featured: 'Featured',
  'source-loma': 'Source',
};
