import { HStack, VStack, Icon, Text } from '@chakra-ui/react';
import { GoTriangleRight } from 'react-icons/go';
import { FulfillmentEvent } from 'src/api/v1-api';
import { formatDate } from 'src/utils/common';

type FulfillmentEventProps = {
  event: FulfillmentEvent | undefined;
};

const FulfillmentEventView = ({ event }: FulfillmentEventProps) => {
  if (!event) {
    return null;
  }

  return (
    <HStack key={event.id} spacing={1} alignItems="flex-start">
      <HStack spacing={3}>
        <Icon as={GoTriangleRight} />
        <Text textColor="gray.500">{formatDate(event.created_at)}</Text>
      </HStack>
      <Text>-</Text>
      <VStack alignItems="flex-start">
        <Text fontSize="md">{event.event_name}</Text>
        <Text fontSize="md">{event.event_message}</Text>
      </VStack>
    </HStack>
  );
};

export default FulfillmentEventView;
