import { Box, Button, Container, Stack, Text, Icon } from '@chakra-ui/react';
import { FiPlusCircle } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { CONTAINER_MAX_WIDTH_1 } from 'src/constants/ui';

interface Props {
  page: string;
}

export const AddListingBanner = ({ page }: Props) => {
  const router = useRouter();
  const { marketplace } = useMarketplaceContext();
  const addListingBanner = marketplace?.functional?.add_listing_banner;

  if (!addListingBanner || !addListingBanner.title || !addListingBanner.pages.includes(page)) {
    return null;
  }

  return (
    <Box as="section" w="full">
      <Box borderBottomWidth="1px" bg="bg.surface" w="full">
        <Container maxW={CONTAINER_MAX_WIDTH_1} py="10px">
          <Stack
            spacing="4"
            justify={{ base: 'start', md: 'space-between' }}
            direction={{ base: 'column', md: 'row' }}
          >
            <Box pe={{ base: '4', md: '0' }}>
              <Text fontWeight="medium">{addListingBanner?.title}</Text>
              <Text color="fg.muted">{addListingBanner?.description}</Text>
            </Box>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing="3"
              align={{ base: 'stretch', md: 'center' }}
            >
              <Button
                colorScheme="gray"
                onClick={() => router.push(addListingBanner?.cta_link || '/listing/create')}
              >
                <Icon as={FiPlusCircle} mr="2" />
                {addListingBanner?.cta_text}
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default AddListingBanner;
