import {
  Box,
  Popover,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FilterPopoverButton, FilterPopoverContent } from 'src/components/atoms';
// TODO(Sean): Handle currency formatting based on attribute
// import { formatPrice } from 'src/utils/common';
import { ListingFilterItemProps } from '.';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';

const SliderFilter = ({ attributeId, isMobileView }: ListingFilterItemProps): JSX.Element => {
  const {
    attributes,
    selectedAttributes,
    setSelectedAttributes,
    stagedAttributes,
    setStagedAttributes,
  } = useAttributeSelector();
  const attribute = attributes.find((a) => a.name === attributeId);
  const [tempValue, setTempValue] = useState([0, 0]);

  let defaultValue: [number, number] = [0, 0];

  useEffect(() => {
    if (
      attribute?.options &&
      'min_value' in attribute.options &&
      'max_value' in attribute.options
    ) {
      const min = Number(attribute.options.min_value ?? 0);
      const max = Number(attribute.options.max_value ?? 0);
      defaultValue = [min, max];
      setTempValue(defaultValue);
    }
    if (attributeId in selectedAttributes) {
      setTempValue([
        Number(selectedAttributes[attributeId][0]),
        Number(selectedAttributes[attributeId][1]),
      ]);
    }
  }, [attribute, selectedAttributes]);

  const [inputValue, setInputValue] = useState(defaultValue);

  const displayMinValue =
    attribute?.options && 'min_value' in attribute?.options && attribute?.options.min_value !== null
      ? Number(attribute?.options?.min_value)
      : 0;
  const displayMaxValue =
    attribute?.options && 'max_value' in attribute?.options && attribute?.options.max_value !== null
      ? Number(attribute?.options?.max_value)
      : 0;

  const handleMobileChange = () => {
    setInputValue([tempValue[0], tempValue[1]]);
    setStagedAttributes({
      ...stagedAttributes,
      [attributeId]: [tempValue[0].toString(), tempValue[1].toString()],
    });
  };

  const handleChange = (value: [number, number]) => {
    setTempValue(value);
  };

  return isMobileView ? (
    <Box px="2" pt="2">
      <RangeSlider
        colorScheme="primary"
        min={displayMinValue}
        max={displayMaxValue}
        value={tempValue}
        onChange={(value: [number, number]) => handleChange(value)}
        onChangeEnd={() => handleMobileChange()}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        {tempValue?.map((_, index) => (
          <RangeSliderThumb
            w="5"
            h="5"
            borderWidth="1px"
            borderColor="gray.200"
            key={index}
            index={index}
          />
        ))}
      </RangeSlider>
      <Box as="output" mt="2" fontSize="sm">
        {tempValue?.map((v) => v).join(' — ')}
      </Box>
    </Box>
  ) : (
    <Popover placement="bottom-start">
      <FilterPopoverButton label={attribute?.name} />
      <FilterPopoverContent
        isCancelDisabled={!(defaultValue !== tempValue)}
        onClickApply={() => {
          setSelectedAttributes({
            ...selectedAttributes,
            [attributeId]: [tempValue[0].toString(), tempValue[1].toString()],
          });
        }}
        onClickCancel={() => {
          setInputValue(defaultValue);
        }}
      >
        <Box px="2" pt="2">
          <RangeSlider
            colorScheme="primary"
            min={displayMinValue}
            max={displayMaxValue}
            value={tempValue}
            onChange={(value: [number, number]) => handleChange(value)}
            onChangeEnd={() => handleMobileChange()}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            {tempValue?.map((_, index) => (
              <RangeSliderThumb
                w="5"
                h="5"
                borderWidth="1px"
                borderColor="gray.200"
                key={index}
                index={index}
              />
            ))}
          </RangeSlider>
          <Box as="output" mt="2" fontSize="sm">
            {tempValue?.map((v) => v).join(' — ')}
          </Box>
        </Box>
      </FilterPopoverContent>
    </Popover>
  );
};

export default SliderFilter;
