import { Button, Divider, Flex, Heading, Stack, Text, useTheme } from '@chakra-ui/react';
import { CartSummary } from 'src/api/v1-api';
import { formatPrice } from 'src/utils/common';
import { OrderSummaryItem } from 'src/components/atoms';
import NextLink from 'next/link';

export const CartOrderSummary = ({ summary }: { summary: CartSummary }) => {
  const theme = useTheme();
  // TODO(Sean): Add flag for shipping containing dynamic pricing and show/hide shipping price
  const { contents_subtotal, tax_subtotal, fulfillment_subtotal } = summary;

  const shippingPrice = fulfillment_subtotal !== '0.00' ? formatPrice(fulfillment_subtotal) : ' - ';
  const shippingShowIcon = fulfillment_subtotal === '0.00';

  return (
    <Stack
      spacing="8"
      borderWidth="1px"
      borderRadius={theme.border_radius.border_radius_2}
      padding="8"
      width="full"
      shadow="md"
    >
      <Heading size="md">Order Summary</Heading>

      <Stack spacing="6">
        <OrderSummaryItem
          label="Contents Subtotal"
          value={formatPrice(contents_subtotal)}
          showIcon={false}
        />
        <OrderSummaryItem label="Shipping" value={shippingPrice} showIcon={shippingShowIcon} />
        <OrderSummaryItem label="Tax" value={'-'} showIcon />
        {/* TODO(Sean): Add back coupon once we can support them */}
        {/* {coupon?.code == null ? (
          <OrderSummaryItem label="Coupon Code" showIcon={false}>
            <Link href="#" textDecor="underline" fontStyle="italic">
              Add at Checkout
            </Link>
          </OrderSummaryItem>
        ) : (
          <OrderSummaryItem
            label="Coupon Code"
            value={coupon?.code.toUpperCase() || '-'}
            showIcon={false}
          />
        )} */}
        <Divider />
        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            Total
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {formatPrice(
              Number(contents_subtotal) + Number(tax_subtotal) + Number(fulfillment_subtotal),
            )}
          </Text>
        </Flex>
      </Stack>
      <NextLink href={`/checkout`}>
        <Button
          colorScheme="primary"
          size="lg"
          width="full"
          fontSize="md"
          isDisabled={summary?.contents_subtotal === '0.00'}
          data-test="checkout-btn"
        >
          Checkout
        </Button>
      </NextLink>
    </Stack>
  );
};
