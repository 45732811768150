import { HStack, Text, Image, Box } from '@chakra-ui/react';
import {
  BasicAttributeValue,
  Listing,
  MarketplaceInfo,
  ValueObject,
  getValueObjectValue,
} from 'src/api/v1-api';
import UrlDisplay from '../url-display';
import { FilePath } from 'src/components/organisms/listing-form';
import ListingGallery from 'src/components/molecules/listing-gallery';
import InfoTooltip from '../info-tooltip';
import { srcFromCDN } from 'src/utils/files';
import { useAuth } from 'src/utils/auth';
import { SITE_MAX_WIDTH } from 'src/constants/ui';
import { isDisplayAttribute } from 'src/utils/attributeUtils';

interface Props {
  attributeValue: BasicAttributeValue;
  marketplace?: MarketplaceInfo | null;
  listing?: Listing | null;
  isListingCard?: boolean;
  noMedia?: boolean;
  isSummary?: boolean;
}

const SingleAttributeDisplay = ({
  attributeValue,
  marketplace,
  listing,
  isListingCard = false,
  noMedia = false,
  isSummary = false,
}: Props) => {
  const { user, hasAdmin, hasStaff } = useAuth();

  if (!isDisplayAttribute(attributeValue, listing, marketplace, hasAdmin, hasStaff, user))
    return null;

  // Hide these attribute formats for now
  if (['file', 'json', 'phone'].includes(attributeValue?.attribute?.format)) return null;
  if (noMedia && ['media', 'image'].includes(attributeValue?.attribute?.format)) return null;

  let value: string | number | boolean | (string | number)[] | null | JSX.Element | ValueObject =
    null;
  let isComplexObject = false;
  if (attributeValue?.value_object.value) {
    // Simple object values (e.g. { value: 'foo' })
    value = getValueObjectValue(attributeValue?.value_object);
  } else {
    // Complex object values (e.g. { value: { foo: 'bar' } })
    isComplexObject = true;
    value = attributeValue?.value_object;
  }

  // TODO: Handle duration format
  if (value === null) {
    value = 'N/A';
  } else if (attributeValue?.attribute?.format === 'boolean') {
    value = value === true ? 'Yes' : 'No';
  } else if (attributeValue?.attribute?.format === 'date') {
    value = new Date(value as any).toLocaleDateString();
  } else if (attributeValue?.attribute?.format === 'datetime') {
    value = new Date(value as any).toLocaleString();
  } else if (attributeValue?.attribute?.format === 'time') {
    value = new Date(value as any).toLocaleTimeString();
  } else if (attributeValue?.attribute?.format === 'url') {
    value = <UrlDisplay {...attributeValue?.value_object} />;
  } else if (attributeValue?.attribute?.format === 'image') {
    value = (
      <Image
        src={srcFromCDN(value.toString(), SITE_MAX_WIDTH)}
        alt={attributeValue?.attribute?.name}
        width="100%"
        height="auto"
      />
    );
  } else if (attributeValue?.attribute?.format === 'address') {
    value = `${attributeValue.value_object.city}, ${attributeValue.value_object.state}`;
  } else if (attributeValue?.attribute?.format === 'select') {
    if (
      attributeValue &&
      attributeValue.value_object &&
      attributeValue.value_object.values &&
      (attributeValue.value_object.values as ValueObject[]).length > 0
    ) {
      value =
        ((attributeValue.value_object.values as ValueObject[])[0] as ValueObject).name || 'N/A';
    } else {
      value = 'N/A';
    }
  } else if (attributeValue?.attribute?.format === 'multiselect') {
    if (
      attributeValue &&
      attributeValue.value_object &&
      attributeValue.value_object.values &&
      (attributeValue.value_object.values as ValueObject[]).length > 0
    ) {
      value = (attributeValue.value_object.values as ValueObject[])
        .map((obj) => obj.name)
        .join(', ');
    } else {
      value = 'N/A';
    }
  } else if (attributeValue.attribute.format === 'year') {
    value = value.toString().replace(/[,.]/g, '');
  } else if (attributeValue.attribute.format === 'media') {
    const filePaths = ((value as ValueObject).values as FilePath[])
      .map((file) => ({
        filePath: file.value as string,
        position: file.position,
      }))
      .sort((a, b) => a.position - b.position);
  } else {
    if (!isComplexObject) {
      value = value.toString();
    } else {
      value = 'N/A'; // Handle complex objects individually above
    }
  }
  if (isListingCard) {
    return <Text noOfLines={2}>{value as string}</Text>;
  } else if (isSummary) {
    // for display on checkout and order pages
    return (
      <Text color="gray.600">
        {attributeValue?.attribute?.name}: {value as string}
      </Text>
    );
  } else if (attributeValue?.attribute?.format === 'media') {
    if (attributeValue?.attribute?.carousel_display) {
      return (
        <Box maxWidth="250px">
          <Text fontSize="md" fontWeight="medium" mb={2}>
            {attributeValue?.attribute?.name}
            <InfoTooltip description={attributeValue.attribute.buyer_description} />
          </Text>
          <ListingGallery
            listingId={listing?.id}
            rootProps={{ overflow: 'hidden', flex: '1' }}
            aspectRatio={{ base: 1 }}
            mediaFiles={(value as ValueObject).values as FilePath[]}
            isAttributeGallery={true}
          />
        </Box>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <HStack textColor="black" alignItems="flex-start">
        <Text>
          <>
            <b>
              {attributeValue.attribute.name}
              <InfoTooltip description={attributeValue.attribute.buyer_description} />
            </b>
            : {value}
          </>
        </Text>
      </HStack>
    );
  }
};

export default SingleAttributeDisplay;
