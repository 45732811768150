import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Spinner, Stack, Text, useToast } from '@chakra-ui/react';
import PromoAutomationsTable from '../promo-automations-table';
import {
  BasicCategory,
  GlobalPromoMediaType,
  PromoAutomation,
  PromoAutomationTemplateNamespaceMapping,
} from 'src/api/v1-api';
import useRequest from 'src/utils/hooks/useRequest';
import { MarketplaceContext } from 'src/contexts/marketplace';
import PromoAutomationForm from '../promo-automation-form';
import { omit } from 'lodash';

interface SocialSharingProps {
  category?: BasicCategory | null;
  isCategoryBased?: boolean;
}

const SocialSharing = ({ category, isCategoryBased }: SocialSharingProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [promoAutomations, setPromoAutomations] = useState<PromoAutomation[]>([]);
  const [promoAutomationSelected, setPromoAutomationSelected] =
    useState<Partial<PromoAutomation> | null>(null);
  const [globalPromoMediaTypes, setGlobalPromoMediaTypes] = useState<GlobalPromoMediaType[]>([]);
  const [globalPromoMediaTypeNamespaceMapping, setGlobalPromoMediaTypeNamespaceMapping] =
    useState<PromoAutomationTemplateNamespaceMapping>();
  const toast = useToast();
  const { api } = useRequest();
  const promoAutomationFormRef = useRef<HTMLDivElement>(null);
  const {
    marketplace,
    setConnectedSocialProfiles,
    setConnectedSocialProfilesFetched,
    connectedSocialProfiles,
  } = useContext(MarketplaceContext);

  const duplicatePromoAutomation = async (promoAutomation: PromoAutomation, categoryId: number) => {
    if (promoAutomation.entity_id === categoryId || !marketplace) return;

    try {
      setIsLoading(true);
      const response = await api.shareautomation.promoAutomations.duplicate(
        marketplace.id,
        promoAutomation.entity_id,
        promoAutomation.id,
        {
          category_id: categoryId,
        },
      );
      setPromoAutomations([response, ...promoAutomations]);
      toast({
        title: 'Success',
        description: 'Promo Automation has been duplicated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // catch-all refetch to ensure state is up-to-date
      fetchPromoAutomations();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Unable to duplicate Promo Automation. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createPromoAutomation = async (promoAutomation: Partial<PromoAutomation>) => {
    if (!promoAutomation.entity_id || !marketplace) return;
    try {
      setIsLoading(true);
      const response = await api.shareautomation.promoAutomations.create(
        marketplace.id,
        promoAutomation.entity_id,
        promoAutomation,
      );
      setPromoAutomations([response, ...promoAutomations]);
      setPromoAutomationSelected(null);
      toast({
        title: 'Success',
        description: 'Promo Automation has been created.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
      // catch-all refetch to ensure state is up-to-date
      fetchPromoAutomations();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Unable to create Promo Automation. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updatePromoAutomation = async (
    promoAutomation: Partial<PromoAutomation>,
    successMessage: string,
    errorMessage: string,
  ) => {
    if (!marketplace || !promoAutomation?.id || !promoAutomation?.entity_id) return;
    try {
      setIsLoading(true);
      const response = await api.shareautomation.promoAutomations.update(
        marketplace.id,
        promoAutomation.entity_id,
        promoAutomation.id,
        omit(promoAutomation, [
          'entity_type_object',
          'entity_type_name',
          'global_promo_media_type',
        ]),
      );
      setPromoAutomations(promoAutomations.map((pa) => (pa.id === response.id ? response : pa)));
      if (promoAutomationSelected?.id === response.id) {
        setPromoAutomationSelected(null);
      }
      toast({
        title: 'Promo Automation Updated',
        description: successMessage,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
      // catch-all refetch to ensure state is up-to-date
      fetchPromoAutomations();
    } catch (error) {
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deletePromoAutomation = async (promoAutomation: PromoAutomation) => {
    if (!marketplace) return;
    try {
      setIsLoading(true);
      await api.shareautomation.promoAutomations.delete(
        marketplace.id,
        promoAutomation.entity_id,
        promoAutomation.id,
      );
      setPromoAutomations(promoAutomations.filter((pa) => pa.id !== promoAutomation.id));
      if (promoAutomationSelected?.id === promoAutomation.id) {
        setPromoAutomationSelected(null);
      }
      toast({
        title: 'Promo Automation Deleted',
        description: 'Promo Automation has been deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
      // catch-all refetch to ensure state is up-to-date
      fetchPromoAutomations();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Unable to delete Promo Automation. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPromoAutomations = async () => {
    try {
      if (!marketplace?.id || (isCategoryBased && !category?.id)) return;
      setIsLoading(true);
      if (category) {
        const response = await api.shareautomation.promoAutomations.getByCategoryId(
          marketplace.id,
          category.id,
        );
        setPromoAutomations(response);
      } else {
        const response = await api.shareautomation.promoAutomations.getByMarketplaceId(
          marketplace.id,
        );
        setPromoAutomations(response);
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Unable to fetch automations. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getConnectedSocialProfiles = async () => {
    if (!marketplace) return;

    try {
      const response = await api.shareautomation.connectedSocialProfiles.list(marketplace.id);
      setConnectedSocialProfiles(response.connected_accounts);
      setConnectedSocialProfilesFetched(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getGlobalPromoMediaTypes = async () => {
    if (!marketplace) return;

    try {
      const response = await api.shareautomation.globalPromoMediaTypes.list(marketplace.id);
      setGlobalPromoMediaTypes(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getGlobalPromoMediaTypeNamespaceMapping = async () => {
    if (!marketplace) return;

    try {
      const response = await api.shareautomation.globalPromoMediaTypes.namespaceMapping(
        marketplace.id,
      );
      setGlobalPromoMediaTypeNamespaceMapping(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if ((isCategoryBased && category?.id) || marketplace?.id) {
      fetchPromoAutomations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCategoryBased, category?.id, marketplace?.id]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        getGlobalPromoMediaTypes(),
        getConnectedSocialProfiles(),
        getGlobalPromoMediaTypeNamespaceMapping(),
      ]);
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promoAutomationSelected && promoAutomationFormRef.current) {
      promoAutomationFormRef.current.scrollIntoView();
    }
  }, [promoAutomationSelected, promoAutomationFormRef]);

  const newPromoAutomation = () => {
    setPromoAutomationSelected({
      entity_type_name: 'category',
      entity_type_object: category,
      entity_id: category?.id,
    });
  };

  const editPromoAutomation = (promoAutomation: Partial<PromoAutomation>) => {
    setPromoAutomationSelected(promoAutomation);
  };

  return (
    <Stack display="relative">
      <Flex direction="column" gap={12}>
        <Flex direction="column">
          <Flex direction="row" alignItems="center" justifyContent="space-between" pb={4}>
            <Text fontWeight="semibold">Promo Automations</Text>
            <Button onClick={newPromoAutomation}>Create New</Button>
          </Flex>
          <PromoAutomationsTable
            promoAutomations={promoAutomations}
            editPromoAutomation={editPromoAutomation}
            onUpdatePromoAutomation={(promoAutomation) =>
              updatePromoAutomation(
                promoAutomation,
                `Promo Automation ${promoAutomation.status === 'active' ? 'Disabled' : 'Enabled'}`,
                `Error ${
                  promoAutomation.status === 'active' ? 'disabling' : 'enabling'
                } Promo Automation`,
              )
            }
            onDeletePromoAutomation={deletePromoAutomation}
            onDuplicatePromoAutomation={duplicatePromoAutomation}
          />
        </Flex>
        <div ref={promoAutomationFormRef}>
          {promoAutomationSelected && (
            <PromoAutomationForm
              promoAutomation={promoAutomationSelected}
              globalPromoMediaTypes={globalPromoMediaTypes}
              globalPromoMediaTypeNamespaceMapping={globalPromoMediaTypeNamespaceMapping}
              connectedSocialProfiles={connectedSocialProfiles}
              onCancel={() => setPromoAutomationSelected(null)}
              onUpdatePromoAutomation={(promoAutomation) =>
                updatePromoAutomation(
                  promoAutomation,
                  '',
                  'Error updating Promo Automation. Please try again',
                )
              }
              onCreatePromoAutomation={createPromoAutomation}
              isCategoryEditable={!category}
            />
          )}
        </div>
        {isLoading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="rgba(255, 255, 255, 0.7)"
          >
            <Spinner />
          </Box>
        )}
      </Flex>
    </Stack>
  );
};

export default SocialSharing;
