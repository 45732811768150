import {
  Alert,
  AlertIcon,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  Input,
  Select,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { useAuth } from 'src/utils/auth';
import { apiRequest } from 'src/utils/fetchUtils';
import { isUrlValid } from 'src/utils/helper';
import { useRouter } from 'next/router';
import { useMarketplaceContext } from 'src/contexts/marketplace';

export enum ListingImportOption {
  ebay = 'ebay', // Example URL: https://www.ebay.com/itm/134638042371?var=434175605789
  craigslist = 'craigslist', // Example URL: https://sfbay.craigslist.org/eby/tro/d/port-costa-truck-camper/7657688515.html
}

const EBAY_REGEX = /^https:\/\/www.ebay.com\/itm\/.+/;
const CRAIGSLIST_REGEX = /https:\/\/.+.craigslist.org\/.+.html$/;

function checkSourceURL(url: string) {
  if (CRAIGSLIST_REGEX.test(url)) return ListingImportOption.craigslist;
  if (EBAY_REGEX.test(url)) return ListingImportOption.ebay;
}

interface ListingImportProps {
  categoryId: number | undefined;
}

const ListingImport: React.FC<ListingImportProps> = ({ categoryId }) => {
  const { getToken, hasAdmin, hasRole } = useAuth();
  const { marketplace } = useMarketplaceContext();
  const isAdmin = hasAdmin(marketplace?.id);
  const isLomaAdmin = hasRole(marketplace?.id?.toString() ?? '', 'loma_admin');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const router = useRouter();
  const btnRef = useRef<any>();
  const [state, setState] = useState<{
    url: any;
    type: ListingImportOption | string;
    categoryId: number | undefined;
  }>({
    url: null,
    type: '',
    categoryId: categoryId,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<any>({ status: null, content: null });

  const handleImport = useCallback(async () => {
    setMessage({ status: null, content: null });
    const source = checkSourceURL(state.url);
    if (!source) {
      setMessage({ status: 'error', content: 'Invalid URL' });
      return;
    }
    setLoading(true);

    try {
      const token = await getToken();
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/import`;
      const response = await apiRequest('POST', url, token, state);
      const listingId = response.id;
      if (listingId) {
        router.push(
          `/listing/edit/${listingId}?success=true&message=You+can+now+edit+and+publish+your+listing.`,
        );
      }
    } catch (error: any) {
      toast({
        title: 'Error',
        description: 'Unable to import listing. Please check the URL and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
      setLoading(false);
    }
  }, [getToken, state]);

  const isDisabled = !(isUrlValid(state.url) && state.type);

  if (!isAdmin && !isLomaAdmin) return null;

  return (
    <>
      {categoryId ? (
        <Button
          ref={btnRef}
          onClick={() => {
            setState({ url: null, type: '', categoryId: categoryId });
            onOpen();
          }}
        >
          Import
        </Button>
      ) : (
        <Tooltip label="Please select a category before importing">
          <span>
            <Button isDisabled>Import</Button>
          </span>
        </Tooltip>
      )}
      <Drawer
        size={'sm'}
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          if (loading) return;
          onClose();
        }}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Import Listing</DrawerHeader>
          <DrawerBody>
            <Grid gap={30}>
              <div>
                <Text>URL:</Text>
                <Input
                  disabled={loading}
                  isInvalid={state.url && !isUrlValid(state.url)}
                  placeholder="URL"
                  value={_.get(state, 'url', '')}
                  onChange={(e) => setState((prev) => ({ ...prev, url: e.target.value }))}
                />
              </div>
              <div>
                <Text>Select Source</Text>
                <Select
                  disabled={loading}
                  placeholder="Source"
                  value={_.get(state, 'type', '')}
                  onChange={(e) => setState((prev) => ({ ...prev, type: e.target.value }))}
                >
                  <option value={ListingImportOption.craigslist}>
                    Craigslist (Individual Listing URL)
                  </option>
                  <option value={ListingImportOption.ebay}>eBay (Individual Item URL)</option>
                </Select>
              </div>
            </Grid>
            {message.status && (
              <Alert status={message.status} mt={3}>
                <AlertIcon />
                {message.content}
              </Alert>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button isDisabled={loading} variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={handleImport}
              colorScheme="blue"
              isLoading={loading}
              isDisabled={isDisabled}
            >
              Import
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ListingImport;
