import { atom, useAtom, useSetAtom } from 'jotai';

const pageAtom = atom(0);
export const usePageContext = () => useAtom(pageAtom);
export const useSetPageContext = () => useSetAtom(pageAtom);

// allow multiple paging within the same view. Used on lead-approvals.
export interface MultiPage {
  page1: number;
  page2: number;
  page3: number;
}

const multiPageAtom = atom<MultiPage>({ page1: 0, page2: 0, page3: 0 });
export const useMultiPageContext = () => useAtom(multiPageAtom);
