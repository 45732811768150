import { Box, Flex, HStack, Image, Text, Link, Container } from '@chakra-ui/react';
import { Category, RootCategory } from 'src/api/v1-api';
import { CONTAINER_MAX_WIDTH_1, SITE_MAX_WIDTH } from 'src/constants/ui';
import { srcFromCDN } from 'src/utils/files';

type SponsorsCardProps = {
  category: Category | RootCategory | null;
};

const SponsorsCard = ({ category }: SponsorsCardProps) => {
  if (
    !category ||
    !category.functional ||
    Object.keys(category.functional).length <= 0 ||
    !category.functional.sponsor_card ||
    Object.keys(category.functional.sponsor_card).length <= 0 ||
    !category.functional.sponsor_card.sponsors ||
    category.functional.sponsor_card.sponsors.length <= 0
  ) {
    return null;
  }

  return (
    <Container maxW={CONTAINER_MAX_WIDTH_1}>
      <Flex justify="center" align="center" w="100%" direction={{ base: 'column', md: 'row' }}>
        <Text fontSize={{ base: '10px', sm: '10px', md: 'xs' }} mr={{ md: 2 }}>
          Sponsored by
        </Text>
        <HStack spacing={4} py={{ base: 4, md: 5, lg: 6 }}>
          {category.functional.sponsor_card.sponsors.map((sponsor) => (
            <Box key={sponsor.sponsor_title}>
              <Link href={sponsor.sponsor_link} isExternal>
                <Image
                  src={srcFromCDN(sponsor.sponsor_image_url, SITE_MAX_WIDTH)}
                  alt={sponsor.sponsor_description}
                  maxH={sponsor.sponsor_image_height}
                />
              </Link>
            </Box>
          ))}
        </HStack>
      </Flex>
    </Container>
  );
};

export default SponsorsCard;
