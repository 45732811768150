import { StackProps, Container, useTheme, Box } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import Lightbox, { Slide } from 'yet-another-react-lightbox';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import { ImageBanner } from 'src/components/atoms';
import { FACEBOOK, CRAIGSLIST } from 'src/constants/marketplace';
import { srcFromCDN } from 'src/utils/files';
import { DEFAULT_IMAGE } from 'src/constants/config';
import PdfRenderer from 'src/components/atoms/pdf-renderer';
import { markListingAsBroken } from 'src/utils/listingUtils';
import * as Sentry from '@sentry/nextjs';
import { ListingImage } from 'src/api/v1-api';
import { FilePath } from 'src/components/organisms/listing-form';

interface GalleryProps {
  listingId?: number;
  images?: ListingImage[];
  aspectRatio?: number | { base?: number; md?: number; lg?: number };
  rootProps?: StackProps;
  listingSource?: string;
  mediaFiles?: FilePath[];
  isAttributeGallery?: boolean;
}

interface GallerySlide {
  src?: string;
  sources?: { src: string; type: string }[];
  type?: 'image' | 'video' | 'pdf';
  width?: number;
  height?: number;
}

const ListingGallery = (props: GalleryProps): JSX.Element => {
  const { listingId, images, listingSource, mediaFiles, isAttributeGallery } = props;
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const theme = useTheme();

  const toggleCarouselOpen = (state: boolean) => () => setCarouselOpen(state);
  const updateIndex = ({ index: current }: { index: number }) => setCarouselIndex(current);

  const handleImageError = (imgElement: HTMLImageElement, error: Event | string) => {
    if (!listingId) return;

    Sentry.withScope((scope) => {
      scope.setLevel('error');
      scope.setContext('image', {
        src: imgElement.src,
        listingId,
        naturalWidth: imgElement.naturalWidth,
        naturalHeight: imgElement.naturalHeight,
        currentSrc: imgElement.currentSrc,
        loadingState: imgElement.loading,
        errorEvent: error instanceof Event ? error.type : error,
        images: images?.map((img) => ({
          position: img.position,
          image: img.image,
        })),
      });

      Sentry.captureMessage(`Image failed to load for listing ${listingId}`, 'error');
    });

    markListingAsBroken(listingId);
  };

  const processImages = useMemo(() => {
    const defaultSlide = [
      {
        src: DEFAULT_IMAGE,
        type: 'image' as const,
      },
    ];

    if (!images || images.length === 0) {
      return defaultSlide;
    }

    return images
      .sort((a, b) => a.position - b.position)
      .map((image) => {
        if (!image.image) {
          return null;
        }

        const lowerCaseImage = image.image.toLowerCase();
        const urlParts = lowerCaseImage.split('?')[0]; // Removes any query parameters
        const videoRegex = /\.(mp4|mov|avi|wmv|flv|mkv|webm|mpeg|avchd|3gp)$/i;
        const isVideo = videoRegex.test(urlParts);

        if (isVideo) {
          return {
            type: 'video' as const,
            width: 1280,
            height: 720,
            sources: [
              {
                src: srcFromCDN(image.image, 1280, 'generic'),
                type: 'video/mp4',
              },
            ],
          };
        } else {
          return { src: srcFromCDN(image.image, 516), type: 'image' as const };
        }
      })
      .filter((item) => item !== null) as GallerySlide[];
  }, [images]);

  const processMedia = useMemo(() => {
    if (!mediaFiles || mediaFiles.length === 0) {
      return [];
    }

    return mediaFiles
      .map((mediaFile) => {
        if (mediaFile && mediaFile.value) {
          const lowerCaseImage = mediaFile.value.toLowerCase();
          const urlParts = lowerCaseImage?.split('?')[0]; // Removes any query parameters
          const videoRegex = /\.(mp4|mov|avi|wmv|flv|mkv|webm|mpeg|avchd|3gp)$/i;
          const isVideo = videoRegex.test(urlParts);

          const pdfRegex = /\.(pdf)$/i;
          const isPdf = pdfRegex.test(urlParts);

          if (isVideo) {
            return {
              type: 'video' as const,
              width: 1280,
              height: 720,
              sources: [
                {
                  src: srcFromCDN(mediaFile.value, 1280, 'generic'),
                  type: 'video/mp4',
                },
              ],
            };
          } else if (isPdf) {
            return { src: mediaFile.value, type: 'pdf' as const };
          } else {
            return {
              src: srcFromCDN(mediaFile.value, 516),
              type: 'image' as const,
            };
          }
        }
        return null;
      })
      .filter((item) => item !== null) as GallerySlide[];
  }, [mediaFiles]);

  const carouselImages = useMemo(() => {
    const mediaFilesList = processMedia;

    if (isAttributeGallery) {
      return [...mediaFilesList];
    } else {
      return [...processImages, ...mediaFilesList];
    }
  }, [processImages, processMedia, isAttributeGallery]);

  useEffect(() => {
    // Preload images progressively
    const preloadImages = () => {
      const allImages = carouselImages;

      // Only preload first image immediately
      if (allImages[0]?.type === 'image' && allImages[0].src) {
        const img = new Image();
        img.src = allImages[0].src;
        img.onerror = (event: Event | string) => handleImageError(img, event);
      }

      // Defer loading other images
      setTimeout(() => {
        allImages.slice(1).forEach((slide) => {
          if (slide.type === 'image' && slide.src) {
            const img = new Image();
            img.src = slide.src;
            img.onerror = (event: Event | string) => handleImageError(img, event);
          }
        });
      }, 1000); // Delay subsequent image loading by 1 second
    };

    preloadImages();
  }, [carouselImages]);

  return (
    <Container maxW="516px" maxH="516px" px={0} position="relative" overflow="hidden">
      {listingSource === CRAIGSLIST && (
        <ImageBanner text="Spotted on Craigslist" bgColor="#718096" />
      )}
      {listingSource === FACEBOOK && <ImageBanner text="Spotted on Facebook" bgColor="#718096" />}
      <Lightbox
        index={carouselIndex}
        slides={carouselImages as Slide[]}
        plugins={[Inline, Video]}
        on={{
          view: updateIndex,
          click: toggleCarouselOpen(true),
        }}
        carousel={{
          padding: 0,
          spacing: 0,
          finite: true,
          imageFit: 'contain',
        }}
        inline={{
          style: {
            width: '100%',
            margin: '0',
            padding: '0',
            aspectRatio: 1,
          },
        }}
        styles={{
          root: { '--yarl__color_backdrop': '#E2E8F0' },
          container: { borderRadius: theme.border_radius.border_radius_1 },
        }}
        video={{
          autoPlay: false,
          controls: true,
          loop: true,
          muted: true,
          playsInline: true,
        }}
        render={{
          slide: ({ slide }) =>
            slide.type !== 'image' && slide.type !== 'video' ? (
              <PdfRenderer url={slide.src} />
            ) : null,
        }}
      />
      <Lightbox
        open={carouselOpen}
        close={toggleCarouselOpen(false)}
        index={carouselIndex}
        slides={carouselImages as Slide[]}
        on={{ view: updateIndex }}
        animation={{ fade: 0 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        styles={{ root: { '--yarl__color_backdrop': '#E2E8F0' } }}
        plugins={[Zoom, Video]}
        render={{
          iconZoomIn: () => null,
          iconZoomOut: () => null,
          slide: ({ slide }) =>
            slide.type !== 'image' && slide.type !== 'video' ? (
              <PdfRenderer url={slide.src} />
            ) : null,
        }}
        carousel={{
          finite: true,
        }}
        video={{
          autoPlay: false,
          controls: true,
          loop: true,
          muted: true,
        }}
      />
    </Container>
  );
};

export default ListingGallery;
