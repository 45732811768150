import React, { ReactNode } from 'react';
import { Alert, AlertIcon, Box, Flex } from '@chakra-ui/react';

interface ErrorMessageBoxProps {
  children: ReactNode;
}

const ErrorMessageBox: React.FC<ErrorMessageBoxProps> = ({ children }) => {
  return (
    <Flex mb={{ base: 2, md: 4, lg: 6 }} justify="center">
      <Alert status="warning">
        <AlertIcon />
        <Box as="span" display="inline-block">
          {children}
        </Box>
      </Alert>
    </Flex>
  );
};

export default ErrorMessageBox;
