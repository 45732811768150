import {
  Box,
  Button,
  Image,
  Text,
  Heading,
  Link,
  VStack,
  useToast,
  useTheme,
} from '@chakra-ui/react';
import { Listing } from 'src/api/v1-api';
import { SITE_MAX_WIDTH } from 'src/constants/ui';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { srcFromCDN } from 'src/utils/files';

interface InstagramSharePreviewProps {
  listing: Listing | null;
}

const InstagramSharePreview = ({ listing }: InstagramSharePreviewProps) => {
  const toast = useToast();
  const theme = useTheme();
  const { urlBase } = useMarketplaceContext();
  const imageUrl = listing?.listing_images?.[0]?.image ?? '';

  const newUrl = new URL(`listing/${listing?.id}`, urlBase);
  const listingUrl = newUrl.toString();

  const copyPlLink = () => {
    navigator.clipboard.writeText(listingUrl);
    toast({
      title: 'Link copied to clipboard',
      status: 'success',
      duration: 1000,
      isClosable: true,
    });
  };

  return (
    <Box bg="gray.100" py={[0, 0, 'lg']} my="4">
      <Box maxW="container.md" mx="auto" textAlign="center">
        {imageUrl && (
          <Box p="4">
            <Heading as="h3" size="lg" pb={8}>
              Long press the image and save it to your device
            </Heading>
            <Box display="flex" justifyContent="center">
              <Link href={imageUrl} isExternal download>
                <Image
                  src={srcFromCDN(imageUrl, SITE_MAX_WIDTH)}
                  w="auto"
                  h="auto"
                  borderRadius={theme.border_radius.border_radius_1}
                  alt="Listing image"
                />
              </Link>
            </Box>
          </Box>
        )}

        <Box p="4">
          <Heading as="h3" size="lg" pb={8}>
            Copy the link to your clipboard
          </Heading>
          <Box display="flex" justifyContent="center">
            <VStack>
              <Text bg="gray.200" borderWidth="0" textOverflow="ellipsis" color="gray.500">
                {listingUrl}
              </Text>
              <Button variant="outline" colorScheme="primary" onClick={copyPlLink}>
                Copy link
              </Button>
            </VStack>
          </Box>
        </Box>

        <Box p="4">
          <Heading as="h3" size="lg">
            Tap to open Instagram, from there you can share a story using the photo and link
          </Heading>
          <Box p="4" display="flex" justifyContent="center">
            <Link href="instagram://">
              <Button variant="outline" colorScheme="primary">
                Share as Instagram Story
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InstagramSharePreview;
