import { Icon, Tooltip } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';

type InfoTooltipProps = {
  description: string;
};

const InfoTooltip = ({ description }: InfoTooltipProps) => {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);

  return (
    <>
      {description && description !== '' ? (
        <Tooltip label={description} aria-label="A tooltip" isOpen={showToolTip}>
          <span>
            {' '}
            <Icon
              as={MdInfoOutline}
              boxSize={5}
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
            />
          </span>
        </Tooltip>
      ) : null}
    </>
  );
};

export default InfoTooltip;
