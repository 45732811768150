import { Flex } from '@chakra-ui/react';
import OrdersTable from './orders-table';

const SalesOrders = (): JSX.Element => (
  <Flex w="full" direction="column" rowGap={{ base: 12, md: 14, lg: 16 }}>
    <OrdersTable type="new" />
    <OrdersTable type="in_progress" />
    <OrdersTable type="completed" />
  </Flex>
);

export default SalesOrders;
