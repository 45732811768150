import { useMultiPageContext } from 'src/contexts/pagination';
import LeadsList from '../leads-list';
import { LEAD_STATUS } from 'src/api/v1-api';
import { Dispatch, SetStateAction, useEffect } from 'react';

interface Props {
  fetchUrl: string;
  paginationStyle?: string;
  resultsPerPage?: number;
  actionButtons?: string;
  status: LEAD_STATUS;
  version: number;
  setVersion: Dispatch<SetStateAction<number>>;
}

export const getPageName = (status: LEAD_STATUS) => {
  if (status === LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL) return 'page1';
  if (status === LEAD_STATUS.STATUS_ADMIN_APPROVED) return 'page2';
  else return 'page3';
};

const LeadsView = (props: Props): JSX.Element => {
  const [multiPage, setMultiPage] = useMultiPageContext();
  const pageName = getPageName(props.status);

  useEffect(() => {
    if (multiPage[pageName] > 0) return;
    const url = new URL(window.location.href);
    const page = parseInt(url.searchParams.get(pageName) ?? '1');
    setMultiPage({
      ...multiPage,
      [pageName]: page,
    });
  }, [multiPage, pageName]);

  return <LeadsList {...props} />;
};

export default LeadsView;
