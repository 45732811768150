import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  ButtonGroup,
  GridItem,
  Text,
  Box,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AttributeOption, FlatAttributeValuesType, LEAD_STATUS, Lead } from 'src/api/v1-api';
import { AttributeInput } from 'src/components/atoms';
import { BasicInfoType, FilePath } from 'src/components/organisms/listing-form';

interface AdminLeadForm {
  id: number;
  listingId: number;
  recipientName: string;
  recipientEmail: string;
  recipientPhone: string;
  recipientMessage: string;
  notesForSeller?: string;
  adminPrivateNotes?: string;
  attributeValues?: FlatAttributeValuesType;
  status: LEAD_STATUS;
}

interface AdminLeadFormProps {
  lead: Lead | null;
  leadForm: AdminLeadForm;
  setLeadForm: React.Dispatch<React.SetStateAction<AdminLeadForm>>;
  disabled: boolean;
}

const AdminLeadForm = (props: AdminLeadFormProps) => {
  const { lead, leadForm, setLeadForm, disabled } = props;
  const [displayStatus, setDisplayStatus] = useState('Pending Approval');

  useEffect(() => {
    if (leadForm.status === LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL) {
      setDisplayStatus('Pending Approval');
    } else if (leadForm.status === LEAD_STATUS.STATUS_ADMIN_APPROVED) {
      setDisplayStatus('Approved');
    } else if (leadForm.status === LEAD_STATUS.STATUS_ADMIN_REJECTED) {
      setDisplayStatus('Rejected');
    }
  }, [leadForm.status]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setLeadForm({
      ...leadForm,
      [name]: value,
    });
  };

  const handleAttributeChange = (
    attributeId: number | string,
    value: string | number | boolean | FilePath[] | AttributeOption[],
  ) => {
    const updatedAttributeValues = {
      ...leadForm.attributeValues,
      [attributeId]: value,
    };
    setLeadForm({
      ...leadForm,
      attributeValues: updatedAttributeValues,
    });
  };

  const handleApprove = () => {
    setLeadForm({
      ...leadForm,
      status: LEAD_STATUS.STATUS_ADMIN_APPROVED,
    });
  };

  const handlePending = () => {
    setLeadForm({
      ...leadForm,
      status: LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL,
    });
  };

  const handleReject = () => {
    setLeadForm({
      ...leadForm,
      status: LEAD_STATUS.STATUS_ADMIN_REJECTED,
    });
  };

  return (
    <>
      <FormControl id="name" isRequired>
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          name="recipientName"
          placeholder="Enter your name"
          value={leadForm.recipientName}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </FormControl>

      <FormControl id="email" isRequired mt={4}>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          name="recipientEmail"
          placeholder="Enter your email"
          value={leadForm.recipientEmail}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </FormControl>

      <FormControl id="phone" mt={4}>
        <FormLabel>Phone (Optional)</FormLabel>
        <Input
          type="tel"
          name="recipientPhone"
          placeholder="Enter your phone number"
          value={leadForm.recipientPhone}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </FormControl>

      <FormControl id="message" mt={4}>
        <FormLabel>Message (Optional)</FormLabel>
        <Textarea
          name="recipientMessage"
          placeholder="Enter your message"
          value={leadForm.recipientMessage}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </FormControl>

      {/* Lead Category Attributes Fields */}
      {lead?.listing?.category?.attributes
        ?.filter((attr) => attr.nested_content_type_name == 'Submitted Lead')
        ?.map((attribute) => {
          const value = leadForm?.attributeValues?.[attribute.id] ?? undefined;
          return (
            <GridItem colSpan={{ base: 2, md: 1 }} key={attribute.id} pt={4}>
              <AttributeInput<BasicInfoType>
                key={attribute.id}
                attribute={attribute}
                width="full"
                isMobileView
                value={value}
                setChange={(e) => handleAttributeChange(attribute.id, e.target.value)}
                setSelectChange={(e) => handleAttributeChange(attribute.id, e.target.value)}
                setSwitchChange={(checked) => handleAttributeChange(attribute.id, checked)}
                disabled={disabled}
                setMediaChange={(filePaths) => handleAttributeChange(attribute.id, filePaths)}
                setMultiselectChange={(value) => handleAttributeChange(attribute.id, value)}
              />
            </GridItem>
          );
        })}

      <FormControl id="privateNotes" mt={4}>
        <FormLabel>Admin Private Notes (Optional)</FormLabel>
        <Textarea
          name="adminPrivateNotes"
          value={leadForm.adminPrivateNotes}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </FormControl>

      {leadForm.status === LEAD_STATUS.STATUS_ADMIN_APPROVED && (
        <FormControl id="notes" mt={4}>
          <FormLabel>Write a custom message to the listing creator about this lead:</FormLabel>
          <Textarea
            name="notesForSeller"
            value={leadForm.notesForSeller}
            onChange={handleInputChange}
            disabled={disabled}
          />
        </FormControl>
      )}

      <Box py="4">
        <Text fontWeight="normal" noOfLines={1}>
          Active status: <b>{displayStatus}</b>
        </Text>
      </Box>

      {!disabled && (
        <ButtonGroup size="sm" isAttached w="full" pb={2}>
          {leadForm.status !== LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL && (
            <Button colorScheme="gray" onClick={() => handlePending()} w="full">
              Pending Lead
            </Button>
          )}
          {leadForm.status !== LEAD_STATUS.STATUS_ADMIN_APPROVED && (
            <Button colorScheme="green" onClick={() => handleApprove()} w="full">
              Approve Lead
            </Button>
          )}
          {leadForm.status !== LEAD_STATUS.STATUS_ADMIN_REJECTED && (
            <Button colorScheme="red" onClick={() => handleReject()} w="full">
              Reject Lead
            </Button>
          )}
        </ButtonGroup>
      )}
    </>
  );
};

export default AdminLeadForm;
