import { BasicCategory } from 'src/api/v1-api';
import {
  Card,
  Text,
  Button,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
  Box,
  useToast,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { formatPrice } from 'src/utils/common';

interface SiblingToUpdateCardProps {
  category: BasicCategory;
  handleSelectedChild: React.Dispatch<React.SetStateAction<BasicCategory | null>>;
  handleChangeCategory: React.Dispatch<React.SetStateAction<BasicCategory | null>>;
}

const SiblingToUpdateCard = ({
  category,
  handleSelectedChild,
  handleChangeCategory,
}: SiblingToUpdateCardProps) => {
  const toast = useToast();
  const handleUpgrade = () => {
    handleSelectedChild && handleSelectedChild(category);
    handleChangeCategory && handleChangeCategory(category);

    toast({
      title: 'New package selected!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };
  return (
    <Card py={2} px={4} w={'full'} flex={1} minW={{ base: 'full', md: '238px' }}>
      <Text mx={4} my={2}>
        {category.name} -{' '}
        {formatPrice(category?.functional?.pay_to_list?.pricing_card?.price, {
          alwaysCents: false,
        })}
      </Text>
      <Accordion allowToggle>
        <AccordionItem borderBottom={'none'}>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              See Benefits
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <List spacing={3} textAlign="start">
              {category.functional.pay_to_list?.pricing_card?.features?.map(
                (elem: string, index: number) => (
                  <ListItem key={index} fontSize="sm">
                    <ListIcon as={FaCheckCircle} color="green.500" /> {elem}
                  </ListItem>
                ),
              )}
            </List>
            <Button mt={{ base: 4 }} w="full" colorScheme="primary" onClick={handleUpgrade}>
              Upgrade
            </Button>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

export default SiblingToUpdateCard;
