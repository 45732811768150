import { VStack, HStack, Text, Link } from '@chakra-ui/react';
import { useAuth } from 'src/utils/auth';
import { Order, Fulfillment } from 'src/api/v1-api';

type FulfillmentLabelOrTrackingProps = {
  order: Order;
  fulfillment: Fulfillment;
};

const FulfillmentLabelOrTracking = ({ order, fulfillment }: FulfillmentLabelOrTrackingProps) => {
  const { user, hasStaff } = useAuth();
  const isStaffUser = hasStaff();

  if (!order || !fulfillment) {
    return null;
  }

  const isOwner = order.marketplace_account.user.id.toString() === user?.id;

  // Traverse the fulfillment attributes to find the shipping info
  const trackingUrlAttribute = fulfillment.fulfillment_attribute_values.find(
    (fav) => fav.attribute.name === 'Tracking URL',
  );
  const trackingUlrValueObject = trackingUrlAttribute ? trackingUrlAttribute.value_object : null;
  const trackingUrl =
    typeof trackingUlrValueObject?.value === 'string' ? trackingUlrValueObject.value : null;

  const shippingLabelUrlAttribute = fulfillment.fulfillment_attribute_values.find(
    (fav) => fav.attribute.name === 'Shipping Label URL',
  );
  const shippingUrlValueObject = shippingLabelUrlAttribute
    ? shippingLabelUrlAttribute.value_object
    : null;
  const labelUrl =
    typeof shippingUrlValueObject?.value === 'string' ? shippingUrlValueObject.value : null;

  return (
    <VStack spacing={2} alignItems="flex-start" pt={2}>
      {trackingUrl && (
        <>
          <Link href={trackingUrl} isExternal>
            <Text fontSize="md" fontStyle="semibold" textDecoration="underline">
              Tracking URL
            </Text>
          </Link>
        </>
      )}
      {labelUrl && (!isOwner || isStaffUser) && (
        <HStack>
          <Link href={labelUrl} isExternal>
            <Text fontSize="md" fontStyle="semibold" textDecoration="underline">
              Shipping Label
            </Text>
          </Link>
        </HStack>
      )}
    </VStack>
  );
};

export default FulfillmentLabelOrTracking;
