import { useMarketplaceContext } from 'src/contexts/marketplace';
import { Text } from '@chakra-ui/react';
import { Listing } from 'src/api/v1-api';

interface DateSinceAddedProps {
  listing: Listing;
}

export const calculateTimeSinceListed = (created_at: string): string => {
  let timeSinceListed = '';
  const created_at_date = new Date(created_at);
  const now = new Date();
  const diff = Math.abs(now.getTime() - created_at_date.getTime());
  const msToHours = 1000 * 60 * 60;

  // if less than 24h, X hours ago
  if (diff < msToHours * 24) {
    const hs = Math.floor(diff / msToHours);
    if (hs === 0) {
      timeSinceListed = '1 hour ago';
    } else {
      // Otherwise, show the actual hour count
      timeSinceListed = `${hs} hour${hs !== 1 ? 's' : ''} ago`;
    }

    // if less than 14 days, X days ago
  } else if (diff < msToHours * 24 * 14) {
    const ds = Math.floor(diff / (msToHours * 24));
    timeSinceListed = `${ds} day${ds > 1 ? 's' : ''} ago`;

    // else X weeks ago
  } else {
    const ws = Math.floor(diff / (msToHours * 24 * 7));
    timeSinceListed = `${ws} week${ws > 1 ? 's' : ''} ago`;
  }
  return timeSinceListed;
};

const DateSinceAdded = ({ listing }: DateSinceAddedProps): JSX.Element => {
  const { marketplace } = useMarketplaceContext();
  const SourceToListedVerb = marketplace?.functional?.source_to_listed_verb || {};

  if (listing?.created_at) {
    let timeSinceListed = calculateTimeSinceListed(listing?.listed_at ?? listing.created_at);
    if (listing?.source && SourceToListedVerb[listing?.source]) {
      timeSinceListed = `${SourceToListedVerb[listing.source]} ${timeSinceListed}`;
    }
    return (
      <Text fontSize="0.7rem" color="gray.600">
        {timeSinceListed}
      </Text>
    );
  }
  return <></>;
};

export default DateSinceAdded;
