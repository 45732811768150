import { useToast, UseToastOptions } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/nextjs';

interface SingleToastOptions extends UseToastOptions {
  sentryAlert?: {
    error?: Error | string;
    level?: 'error' | 'warning' | 'info';
    tags?: Record<string, string | undefined>;
    extras?: Record<string, any>;
  };
}

type ShowToast = (options: SingleToastOptions) => void;

function useSingleToast(): ShowToast {
  const toast = useToast();
  const toastIdRef = useRef<string | number | undefined>(undefined);

  const showToast: ShowToast = (options) => {
    const { sentryAlert, ...toastOptions } = options;

    // Show the toast
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }

    toastIdRef.current = toast({
      ...toastOptions,
      id: 'unique-error-toast',
    });

    // Send to Sentry if requested
    if (sentryAlert) {
      const { error, level = 'error', tags, extras } = sentryAlert;

      if (error) {
        // Create Sentry scope with additional context
        Sentry.withScope((scope) => {
          // Add toast message as context
          scope.setContext('toast', {
            message: options.title,
            description: options.description,
            status: options.status,
          });

          // Add any custom tags
          if (tags) {
            scope.setTags(tags);
          }

          // Add any extra context
          if (extras) {
            scope.setExtras(extras);
          }

          // Set the severity level
          scope.setLevel(level);

          // Capture the error or message
          if (error instanceof Error) {
            Sentry.captureException(error);
          } else {
            Sentry.captureMessage(error);
          }
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current);
      }
    };
  }, [toast]);

  return showToast;
}

export default useSingleToast;
