import React, { useContext, useEffect, useState } from 'react';
import { Text, Flex, useToast, Box, Spinner } from '@chakra-ui/react';
import { ListingAttributes, ListingsView } from 'src/components/organisms';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { AttributeSelectorProvider } from 'src/contexts/AttributeSelector';
import { Attribute, ThirdPartyQuery } from 'src/api/v1-api';
import useRequest from 'src/utils/hooks/useRequest';
import { useAuth } from 'src/utils/auth';

const ListingApprovals = (): JSX.Element => {
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [queries, setQueries] = useState<ThirdPartyQuery[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { marketplace } = useContext(MarketplaceContext);
  const { api } = useRequest();
  const toast = useToast();
  const { userLoaded } = useAuth();

  useEffect(() => {
    const fetchQueries = async () => {
      if (!marketplace) return;

      try {
        setIsLoading(true);
        const response = await api.marketplace.query.list(marketplace.id);
        setQueries(response);
      } catch (error) {
        toast({
          title: 'Error fetching Saved Searches',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (userLoaded) {
      fetchQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace, userLoaded]);

  useEffect(() => {
    const sourceAttributes = {
      id: 1,
      options: [
        {
          id: 1,
          name: 'Loma',
          value: 'loma',
          position: 0,
        },
        {
          id: 2,
          name: 'Facebook',
          value: 'facebook',
          position: 1,
        },
        {
          id: 3,
          name: 'Craigslist',
          value: 'craigslist',
          position: 2,
        },
        {
          id: 4,
          name: 'eBay',
          value: 'ebay',
          position: 3,
        },
      ],
      format: 'multiselect',
      required: false,
      name: 'source',
      main_section_display: false,
      min_value: '',
      max_value: '',
      content_type_name: 'Listing',
      buyer_description: '',
      seller_description: '',
      position: 1,
      sensitive: false,
      filter_display: true,
      filter_min_value: '',
      filter_max_value: '',
    };

    const queriesOptions = queries.map((query) => ({
      id: query.id ?? 0,
      name: `${query.third_party_platform} - ${query.search_term}`,
      value: `${query.id ?? 0}`,
      position: query.id ?? 0,
    }));

    const queriesAttributes = {
      id: 2,
      options: queriesOptions,
      format: 'multiselect',
      required: false,
      name: 'queries',
      main_section_display: false,
      min_value: '',
      max_value: '',
      content_type_name: 'Listing',
      buyer_description: '',
      seller_description: '',
      position: 2,
      sensitive: false,
      filter_display: true,
      filter_min_value: '',
      filter_max_value: '',
    };

    setAttributes([sourceAttributes, queriesAttributes]);
  }, [queries]);

  return (
    <Flex w="full" direction="column" rowGap={{ base: 6, md: 7, lg: 8 }}>
      <Text fontSize="2xl" data-test="page-title">
        Pending Listings
      </Text>
      {isLoading ? (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor="rgba(255, 255, 255, 0.7)"
        >
          <Spinner />
        </Box>
      ) : (
        <AttributeSelectorProvider
          attributes={attributes}
          useToggle={false}
          defaultViewType="grid"
          sortOptions={['source-loma', 'newest', 'oldest']}
          maxPrice={0}
        >
          <ListingAttributes />
          <ListingsView
            fetchUrl={`${process.env.NEXT_PUBLIC_API_HOST}/pending_listings/${marketplace?.id}`}
            requiresAuth={true}
            inventoryDisplay={'4_2_grid'}
            paginationStyle={''}
            resultsPerPage={38}
            actionButtons="pendingListings"
            propNumDisplayListings={32}
          />
        </AttributeSelectorProvider>
      )}
    </Flex>
  );
};

export default ListingApprovals;
