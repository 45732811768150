import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  FormControl,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

interface DenyMessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  listingId: number;
  handleDeny: (listingId: number, rejectMessage: string) => void;
}

const DenyMessageModal = (props: DenyMessageModalProps) => {
  const { isOpen, onClose, listingId, handleDeny } = props;
  const [rejectMessage, setRejectMessage] = useState<string>('');

  useEffect(() => {
    setRejectMessage('');
  }, [listingId]);

  const onUpdateHandler = () => {
    if (!listingId || !rejectMessage) {
      return;
    }
    handleDeny(listingId, rejectMessage);
    onCloseHandler();
  };

  const onCloseHandler = () => {
    onClose && onClose();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCloseHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Message for Rejected Listing</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="rejectMessage">
            <Textarea
              name="rejectMessage"
              placeholder="Enter your reason for rejection"
              focusBorderColor="primary.500"
              value={rejectMessage}
              onChange={(e) => setRejectMessage(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter gap="2">
          <Button onClick={onCloseHandler}>Close</Button>
          <Button colorScheme="red" onClick={onUpdateHandler}>
            Deny with Message
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DenyMessageModal;
