import { isPlainObject } from 'lodash';
import { DEFAULT_IMAGE } from 'src/constants/config';
import { FilePath } from 'src/components/organisms/listing-form';
import { SortOption } from 'src/constants/sort-options';
import { MINIMUM_BID_INCREMENT } from 'src/constants/marketplace';

// generic type for an unknown JSON object
export type Json = boolean | number | string | null | { [key: string]: Json } | Json[];

export interface User {
  id: number | string;
  email: string;
  first_name: string;
  last_name: string;
}

export interface Parent {
  id: number;
  name: string;
  slug?: string;
  functional?: CategoryFunctional;
  parent?: Parent;
}

export interface Subcategory {
  id: number;
  name: string;
  slug?: string;
  image: string;
  description: string;
  cta_text: string;
  cta_link: string;
  cta_new_tab: boolean;
  is_active: boolean;
  created_at: string;
  position: number;
}

export enum CategoryType {
  MARKETPLACE = 'marketplace',
  LEADS = 'leads',
}

export interface BasicAttributeValue {
  id: number | null;
  attribute: Attribute;
  value_object: ValueObject;
}

export interface AttributeOption {
  id: number;
  name: string;
  value: string | number;
  position: number;
}

export interface AttributeOptionMinMax {
  min_value: string | number;
  max_value: string | number;
}

export interface Attribute {
  id: number;
  name: string;
  content_type_name: string;
  nested_content_type_name?: string;
  buyer_description: string;
  seller_description: string;
  format: string;
  select_display?: string;
  required: boolean;
  icon?: string;
  position: number;
  options?: AttributeOption[] | AttributeOptionMinMax | null;
  min_value: string | number;
  max_value: string | number;
  filter_max_value: string | number;
  filter_min_value: string | number;
  display_attribute?: boolean;
  file_type?: string;
  carousel_display?: boolean;
  carousel_reorder?: boolean;
  main_section_display: boolean;
  category?: string;
  sensitive: boolean;
  filter_display?: boolean;
}

export type FlatAttributeValuesType = Record<
  number | string,
  string | number | ValueObject | boolean | undefined | FilePath[] | FilePath | ValueObject[]
>;

export interface GlobalFulfillmentType {
  id: number;
  name: string;
  description: string;
  price_type: string;
}

export interface FulfillmentType {
  id: number;
  global_fullfillment_type: GlobalFulfillmentType;
  category: number;
  name: string;
  description: string;
  price_type: string;
  price: string;
  deleted: boolean;
  // fulfillment_option_types: FulfillmentOptionType[];
}

export interface FulfillmentOptionType {
  id: number;
  name: string;
  description: string;
  price_type: string;
  price: string;
  category: number;
}

export interface FulfillmentOption {
  id: number;
  price: string;
  fulfillment_type: FulfillmentType;
  listing: number;
}

interface PayToListValue {
  cta?: {
    text: string;
    type: string;
  };
  info?: {
    text: string;
    type: string;
    style: 'info' | 'warning' | 'success' | 'error' | 'loading' | undefined;
  };
  link?: {
    type: string;
    value: string;
    appendages: { client_reference_id: string }[];
  };
  pricing_card?: {
    cta: string;
    price: string;
    features: string[];
    fine_print?: string;
  };
  pay_to_list_text?: string;
}

export enum Currency {
  USD = 'USD',
  CAD = 'CAD',
}

export interface DidYouSellConfig {
  days: number;
  categories: number[];
}

export interface CategoryFunctional {
  announcement?: string;
  map?: boolean;
  map_view?: boolean;
  cart?: boolean;
  multiple_listing_photos: number;
  inventory_display?: string;
  hero_card?: {
    hero_card_image_url: string;
    hero_card_mobile_image_url: string;
    hero_card_title: string;
    hero_card_description: string;
    hero_card_cta: string;
    hero_card_link: string;
    hero_card_is_simple?: boolean;
  } | null;
  category_cards?: boolean;
  results_per_page?: number;
  map_results_per_page?: number;
  pagination_style?: string;
  requires_approval?: boolean;
  requires_payment?: boolean;
  pay_to_list?: PayToListValue;
  max_price?: number;
  filter_max_price?: number;
  computed_filter_max_price?: number;
  sponsor_card?: {
    sponsors: {
      sponsor_title: string;
      sponsor_description: string;
      sponsor_image_url: string;
      sponsor_image_height: string;
      sponsor_link: string;
    }[];
  } | null;
  can_create_listings?: boolean;
  show_create_listing_button?: boolean;
  opacity?: number;
  create_listing_category_select_format?: string;
  show_sold_items_in_listings: boolean;
  sort_options: SortOption[] | null;
  default_sort_options: SortOption[] | null;
  require_location: boolean;
  new_listing_notification_email?: boolean;
  show_more_listings_on_view_listing?: boolean;
  listings_cta_text?: string;
  success_message?: string;
  has_featured_bar: boolean;
  currencies_allowed: Currency[];
  set_listings_as_featured: boolean;
  global_promo_media_type: GlobalPromoMediaType;
  price_filter_input_type?: 'slider' | 'input' | 'all';
  shuffle_featured_listings?: boolean;
  lead_concierge?: boolean;
  hidden_children_categories?: number[];
  did_you_sell_config?: DidYouSellConfig;
  text_overrides?: { [key: string]: string };
  new_listing_custom_content_title: string;
  new_listing_custom_content_body: string;
  new_listing_custom_content_use_parent: boolean;
  monthly_subscription: boolean;
  show_listing_description: boolean;
}

export interface BasicCategory {
  id: number;
  marketplace_id?: number;
  name: string;
  type: string;
  description: string;
  cta_text: string;
  cta_link: string;
  cta_new_tab: boolean;
  image: string;
  functional: CategoryFunctional;
  attributes: Attribute[];
  fulfillment_types: FulfillmentType[];
  parent?: number;
  children?: BasicCategory[];
  isDisabled?: boolean;
  isVisible?: boolean;
  category_card_image_url: string;
  multiple_variants: boolean;
  position: number;
  has_promo: boolean;
  level?: number;
  take_rate?: string;
  create_listing_name: string;
}

export interface AttributeOptions {
  [key: string]: (string | number | boolean)[];
}

export interface Category {
  id: number;
  marketplace_id: number;
  name: string;
  description?: string;
  slug: string;
  parent: Parent;
  children: Subcategory[];
  type: string;
  attributes: Attribute[];
  functional: CategoryFunctional;
  listings?: Listing[];
  position: number;
  multiple_variants: boolean;
  is_active: boolean;
  image?: string;
  create_listing_name: string;
}

export interface RootCategory {
  id: number;
  name: string;
  slug: string;
  children: Subcategory[];
  description?: string;
  cta_text: string;
  multiple_variants: boolean;
  type: string;
  functional: CategoryFunctional;
  attributes: Attribute[];
  is_active: boolean;
  image_url: string;
  marketplace: Marketplace;
  listings?: Listing[];
}

export interface CategoryInList {
  id: number;
  attributes: Attribute[];
  created_at: string;
  updated_at: string;
  slug: string;
  name: string;
  description: string;
  type: string;
  is_active: boolean;
  image: string;
  multiple_variants: boolean;
  lft: number;
  rght: number;
  tree_id: number;
  level: number;
  marketplace: number;
  parent: number;
  functional: CategoryFunctional;
  position: number;
  category_card_image_url: string;
  image_url: string | null;
  cta_text: string | null;
  shuffle_featured_listings: boolean;
}

export interface ValueObject {
  id: number | null;
  name?: string;
  position?: number;
  value?: string | number | ValueObject | FilePath[] | FilePath | ValueObject[];
  values?: string | number | ValueObject | FilePath[] | FilePath | ValueObject[];
  city?: string;
  state?: string;
  text_link?: string;
  street_address1?: string;
}

export interface MarketplaceAccount {
  id: number;
  user: User;
  marketplace_id: number;
  status: string;
  handle: string;
  image_url: string;
  icon_url: string;
  account_attribute_values: BasicAttributeValue[];
}

export interface ListingImage {
  id?: number;
  image: string;
  alt_text?: string;
  is_featured: boolean;
  position: number;
}

export interface PromoMedia {
  id?: number;
  media: string;
  media_url?: string;
  alt_text?: string;
  is_featured: boolean;
  position: number;
}

export interface Variant {
  id?: number;
  regular_price?: string;
  offered_price?: string;
  is_active?: boolean;
  limited_stock?: boolean;
  quantity?: number;
  variant_attribute_values?: BasicAttributeValue[];
}

export interface ShippingInfo {
  id?: number;
  name: string;
  phone?: string;
  street_address1: string;
  street_address2?: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
}

export interface ParcelInfo {
  id?: number;
  distance_unit: string;
  length: number;
  width: number;
  height: number;
  mass_unit: string;
  weight: number;
}

export interface ExternalListingInfo {
  id?: number;
  created_at?: string;
  updated_at?: string;
  lister_handle?: string;
  lister_id?: number;
  lister_profile_url?: string;
  importer?: User;
  cta_text?: string;
  disclaimer_text?: string;
}

export interface Listing {
  id: number;
  category?: Category;
  title: string;
  marketplace_account?: MarketplaceAccount;
  description: string;
  created_at?: string;
  listed_at?: string | null;
  updated_at?: string;
  status: string;
  listing_images: ListingImage[];
  listing_attribute_values: BasicAttributeValue[];
  variants: Variant[];
  lowest_regular_price: string;
  lowest_offered_price: string;
  shipping_info?: ShippingInfo;
  parcel_info?: ParcelInfo;
  external_url?: string;
  lat?: number;
  lon?: number;
  external_listing_info?: ExternalListingInfo;
  source?: string;
  category_type?: string;
  generic_disclaimer_text?: string;
  category_name?: string;
  category_id?: number;
  third_party_query_id?: number;
  is_featured?: boolean;
  currency: Currency;
  auction_info?: AuctionInfo[];
}

export type PaginatedListingsResponse = {
  count: number;
  next: string;
  previous: string;
  results: Listing[];
  page_number: number;
  page_size: number;
};

export type Bid = {
  id: number;
  bid_amount: string;
  marketplace_account: Partial<MarketplaceAccount>;
  created_at: string;
};

export type MaxBid = {
  id: number;
  max_bid_amount: string;
  user_id: number | string;
  created_at: string;
  bid_count: number;
  bid_amount?: string;
};

export type AuctionInfo = {
  id: number | null;
  start: string | null;
  startLocal?: string | null;
  end: string | null;
  endLocal?: string | null;
  reserve_price: string | null;
  show_reserve: boolean;
  bid_increment: number;
  notes_for_admin: string | null;
  notes_for_seller: string | null;
  status: string;
  max_bid?: MaxBid;
  deleted: boolean;
};

export enum LEAD_STATUS {
  STATUS_NEW = 'NEW',
  STATUS_PENDING_ADMIN_APPROVAL = 'pending_admin_approval',
  STATUS_ADMIN_APPROVED = 'admin_approved',
  STATUS_ADMIN_REJECTED = 'admin_rejected',
}

// Other fields are parsed from JSON here but not used
export interface LeadEmailData {
  name: string;
  email: string;
  phone: string;
  listing_title: string;
  message: string;
  attributes: string;
}

export interface LeadData {
  id: number;
  listing: Listing;
  recipient_name: string;
  recipient_email: string;
  recipient_phone: string;
  recipient_message: string;
  json_payload: LeadEmailData;
  logged_in_sender?: number;
  notes_for_seller: string;
  admin_private_notes: string;
  status: LEAD_STATUS;
  lead_attribute_values: BasicAttributeValue[];
  created_at: string;
}

export interface Lead {
  id: number;
  listing: Listing;
  recipientName: string;
  recipientEmail: string;
  recipientPhone: string;
  recipientMessage: string;
  emailData: LeadEmailData;
  loggedInSender?: number;
  notesForSeller?: string;
  adminPrivateNotes?: string;
  status: LEAD_STATUS;
  leadAttributeValues: BasicAttributeValue[];
  createdAt: string;
}

export interface SocialProfile {
  id?: number | null;
  network: string;
  username?: string;
  url: string;
  total_followers?: number;
  show_on_footer_menu: boolean;
  show_on_header_menu: boolean;
}

export type PromoMediaTemplateConfig = {
  primary?: string;
  secondary?: string;
  background_image_url?: string;
  toggle_secondary_color: boolean;
};

// icon_image/logo_image are the URLs where the files are located
// icon/logo are the Stripe identifiers for those images
export type StripeConnectConfig = {
  client_account_id: string;
  status: string;
  token?: string;
  accounts?: Json;
  icon_image?: string;
  icon?: string;
  logo_image?: string;
  logo?: string;
  primary_color?: string;
  secondary_color?: string;
};

export type MarketplaceFeatureConfig = {
  search_bar: boolean;
  category_subheader: boolean;
  cart: boolean;
  favorites: boolean;
  announcement: string;
  custom_header_banner_url: string;
  mailto: string;
  palette?: {
    main?: string;
    nav?: string;
    primary?: string;
    secondary?: string;
  };
  logo_is_circle?: boolean;
  add_listing_banner?: {
    title?: string;
    description?: string;
    cta_text: string;
    cta_link: string;
    pages: string[];
  };
  collect_email_module?: {
    title?: string;
    description?: string;
    cta_text: string;
    pages: string[];
  };
  subscriber_signup_text?: string;
  show_total_social_media_audience?: boolean;
  promo_media_template_config?: PromoMediaTemplateConfig;
  source_to_listed_verb: { [key: string]: string };
  stripe_connect_config?: StripeConnectConfig;
  show_inactive_listings_info: boolean;
  is_seo: boolean;
  google_tag_manager_id?: string;
  google_analytics_id?: string;
  google_search_console_verification?: string;
  show_first_party_listings_first: boolean;
  enable_buyer_digest_emails: boolean;
  navbar_categories?: { id: number; name: string }[];
  theme: LomaTheme;
  text_overrides?: { [key: string]: string };
};

export interface Marketplace {
  id: number;
  name: string;
  slug?: string;
  status: string;
  primary_logo_url: string;
  description: string;
  logo_image?: string;
  functional: MarketplaceFeatureConfig;
  social_profiles: SocialProfile[];
  categories: Category[];
}

export interface ThemeVariables {
  name: string;
  font_1: string;
  font_2: string;
  primary_color: string;
  secondary_color: string;
  nav_background_color: string;
  background_color: string;
  component_background_color: string;
  border_radius_1: string;
  border_radius_2: string;
  text_align_1: string;
  text_align_2: string;
  border_weight: string;
}

export interface ThemeOption {
  type: string;
  enum: string[];
}

export interface ThemeOptions {
  name: ThemeOption;
  font_1: ThemeOption;
  font_2: ThemeOption;
  primary_color: ThemeOption;
  secondary_color: ThemeOption;
  nav_background_color: ThemeOption;
  background_color: ThemeOption;
  component_background_color: ThemeOption;
  border_radius_1: ThemeOption;
  border_radius_2: ThemeOption;
  text_align_1: ThemeOption;
  text_align_2: ThemeOption;
  border_weight: ThemeOption;
}

export interface LomaTheme {
  name: string;
  preview_image_url: string;
  config: ThemeVariables;
}

export interface MarketplaceTheme {
  loma_theme: LomaTheme;
  marketplace: MarketplaceInfo;
  theme_overrides: ThemeVariables;
}

interface TextOverrides {
  id: number;
  name: string;
  config_type: string;
  edit_level: string;
  default_value: string;
  label: string | null;
  description: string | null;
}

export interface MarketplaceInfo {
  id: number;
  name: string;
  slug: string;
  shortened_name: string;
  description: string;
  status: string;
  logo_image: string;
  favicon_image: string;
  functional: MarketplaceFeatureConfig;
  social_profiles: SocialProfile[];
  domain: DomainInfo | null;
  text_overrides: TextOverrides[];
  theme: ThemeVariables;
}

export interface MarketplaceInCategory {
  id: number;
  name: string;
  slug: string;
  description: string;
  status: string;
  logo_image: string;
  favicon_image: string;
  domain: DomainInfo | null;
}

interface MarketplaceInfoSlug {
  slug: string;
}

interface DNSDetails {
  valid: boolean;
  type: string;
  host: string;
  data: string;
}

interface DNSInfo {
  mail_server: DNSDetails;
  subdomain_spf: DNSDetails;
  dkim: DNSDetails;
}

export interface EmailDNSInfo {
  id: number;
  subdomain: string;
  domain: string;
  dns: DNSInfo;
  valid: boolean;
  email_domain: string;
  support_email: string;
  hide_google_auth: boolean;
}

export interface DomainInfo {
  domain: string;
  status: string;
  email_status: string;
  config: EmailDNSInfo | null;
  hide_google_auth: boolean;
  marketplace?: MarketplaceInfoSlug;
}

export interface CategoryGeneratorResponse {
  result: string;
  category_id?: number;
}

export interface VariantWithListing {
  id: number;
  variant_attribute_values: BasicAttributeValue[];
  listing: {
    id: string;
    title: string;
    marketplace_account: {
      id: number;
      handle: string;
      image_url: string;
      icon_url: string;
    };
    listing_images: ListingImage[];
    listing_attribute_values: BasicAttributeValue[];
    category: BasicCategory;
  };
  regular_price: string;
  offered_price: string;
  quantity: number;
}

export interface CartSummary {
  contents_subtotal: string;
  tax_subtotal: string;
  fulfillment_subtotal: string;
  total?: string;
  coupon: {
    code: string;
    contents_adjustments: number[];
    total_adjustment: string;
  };
}

export interface CartItemInterface {
  id: number;
  variant: VariantWithListing;
  quantity: number;
  thumbnail_url: string;
  listing_fulfillment_options: FulfillmentOption[];
}

export interface Cart {
  marketplace_account: MarketplaceAccount;
  items: CartItemInterface[];
  summary: CartSummary;
}

export interface FulfillmentItem {
  id: number;
  quantity: number;
  variant: VariantWithListing;
  fulfillment_type: FulfillmentType;
}

export interface FulfillmentEvent {
  id: number;
  created_at: string;
  event_type: string;
  event_name: string;
  event_message: string;
}

export interface Fulfillment {
  id: number;
  created_at: string;
  updated_at: string;
  completed_at: string | null;
  marketplace_account: MarketplaceAccount;
  status: string;
  contents_subtotal: string;
  tax_subtotal: string;
  fulfillment_subtotal: string;
  fulfillment_payout: string;
  fulfillment_items: FulfillmentItem[];
  fulfillment_attribute_values: BasicAttributeValue[];
  // fulfillment_options: {
  //   id: number;
  //   local_fulfillment_option_type: {
  //     id: number;
  //     name: string;
  //     description: string;
  //     local_fulfillment_type: {
  //       name: string;
  //       global_fulfillment_type?: number;
  //     };
  //   };
  //   price: string;
  // }[];
  fulfillment_events?: FulfillmentEvent[];
}

export interface VariantFulfillmentOptions {
  variant_id: number;
  fulfillment_options: FulfillmentOption[];
}

export interface Checkout {
  cart_id: number;
  contents_subtotal: string;
  tax_subtotal: string;
  fulfillment_subtotal: string;
  total: string;
  // coupon: {
  //   code: string;
  //   contents_adjustments: [];
  //   total_adjustment: string;
  // };
  cart_items: CartItemInterface[];
  variant_fulfillment_options: VariantFulfillmentOptions[];
}

export interface Order {
  id: number;
  created_at: string;
  updated_at: string;
  status: string;
  marketplace_account: MarketplaceAccount;
  marketplace_id: number;
  fulfillments: Fulfillment[];
  summary: {
    contents_subtotal: string;
    tax_subtotal: string;
    fulfillment_subtotal: string;
    total: string;
  };
}

export interface ShippoRate {
  object_id: string;
  amount: string;
}

export interface ShippingRates {
  [listingId: string]: ShippoShippingRate[];
}

export interface PaymentIntentData {
  payment_intent_id: string;
  client_secret: string;
  status: string;
}

export interface ShippoShippingRate {
  amount: string;
  amount_local: string;
  arrives_by?: string;
  attributes: string[];
  carrier_account: string;
  currency: string;
  currency_local: string;
  duration_terms: string;
  estimated_days: number;
  include_insurance_price?: string;
  messages: string[];
  object_created: string;
  object_id: string;
  object_owner: string;
  provider: string;
  provider_image_75: string;
  provider_image_200: string;
  servicelevel: {
    name: string;
    token: string;
  };
  shipment: string;
  test: boolean;
  zone: string;
}

export const defaultShippingInfo: ShippingInfo = {
  name: '',
  street_address1: '',
  city: '',
  state: '',
  zip_code: '',
  country: '',
};

export const defaultParcelInfo = {
  distance_unit: 'in',
  length: 0,
  width: 0,
  height: 0,
  mass_unit: 'lb',
  weight: 0,
};

export const defaultAuctionInfo = {
  id: null,
  start: null,
  end: null,
  reserve_price: null,
  show_reserve: false,
  bid_increment: MINIMUM_BID_INCREMENT,
  notes_for_admin: null,
  notes_for_seller: null,
  status: 'pending_admin_approval',
  deleted: false,
};

export interface ThirdPartyQuery {
  id?: number;
  status?: string;
  marketplace_account?: MarketplaceAccount;
  category: number | string | null;
  third_party_platform: string;
  search_term: string;
  frequency: number;
  search_params: { [key: string]: string } | string;
  result_limit?: number;
  search_context?: string;
  expire_after_days?: number;
}

export interface TaskLog {
  task_id: string;
  task_time: string;
  task_timestamp: string;
  end_task_timestamp: string;
  run_id: string;
  run_timestamp: string;
  run_type: string;
  status: string;
  third_party_platform: string;
  category: number | null;
  third_party_query: number;
  search_term: string;
  search_params: { [key: string]: string } | string;
  listing_status: string;
  dry_run: boolean;
  results: Json;
  errors: Json;
}

export interface SiteAndId {
  site: string;
  id: number;
}

export enum GlobalRoles {
  Admin = 'loma_admin',
  Configuration = 'loma_configuration',
}

export type LoginResponse = {
  refresh: string;
  access: string;
  refresh_expires: number;
  access_expires: number;
  detail: string;
  has_user_roles?: number;
};

export type ListingSelected = {
  listingId: number;
  isSelected: boolean;
};

export enum MarketplacePayoutType {
  PaidListing = 'paid_listing',
  PaidRoleAdmin = 'paid_role_admin',
  PaidTransactionAdmin = 'paid_transaction_admin',
  PaidAffiliateTransactionAdmin = 'paid_affiliate_transaction_admin',
}

export type MarketplaceEarning = {
  id: number;
  marketplace: Marketplace;
  amount: number;
  invoice_date: string;
  payout_type: MarketplacePayoutType;
  metadata: any;
  earning_object: any;
  created_at: string;
};

export type MarketplacePayout = {
  id: number;
  marketplace: Marketplace;
  marketplace_id?: number;
  amount: number;
  payout_method: string;
  date_of_payout: string;
  transaction_id: string;
  notes?: string;
  created_at: string;
};

export type GlobalPromoMediaType = {
  id: number;
  name: string;
  media_type: 'image' | 'video';
  entity_type_name: string;
  sample_image_url: string;
  template: any;
};

export interface MarketplaceRoleFunctional {
  requires_approval?: boolean;
  requires_payment?: boolean;
  pay_to_list?: PayToListValue;
  monthly_subscription?: boolean;
  preauth_only?: boolean;
}

export type MarketplaceRole = {
  id: number;
  marketplace_id: number;
  global_role: string;
  name: string;
  description: string | null;
  status: string;
  functional: MarketplaceRoleFunctional;
  attributes: Attribute[];
};

export interface CategoryRolePermission {
  id: number;
  category: BasicCategory;
  role: MarketplaceRole;
  can_create_listing: boolean;
  can_view_listing: boolean;
  can_edit_listing: boolean;
  can_delete_listing: boolean;
  can_take_action: boolean;
  is_active: boolean;
  listing_limit: number | undefined;
}

export type GlobalRole = {
  id: number;
  name: string;
  description: string;
};

export interface RoleAccount {
  id: number;
  user: User;
  marketplace_role: MarketplaceRole;
  role_attribute_values: BasicAttributeValue[];
  status: string;
}

export interface SocialConnectedProfile {
  platform: string;
  profileUrl: string;
  username?: string;
  pageName?: string;
}

export enum PromoAutomationOtherDestination {
  EMAIL = 'Email File(s) to Admins for Manual Sharing',
  FILES = 'Save Files in Listing "Share" Modal',
}

export enum PromoAutomationEventType {
  CRON_SOCIAL_NETWORK = 'Cron - Social Network',
  EVERY_LISTING_PUBLICATION = 'Every Listing Publication',
}

export interface Schedule {
  time: string;
  days: string[];
}

export interface PromoAutomationTemplate {
  id?: number;
  use_static_image: boolean;
  promo_image_url: string;
  global_promo_media_type_id: number | null;
  global_promo_media_type: GlobalPromoMediaType;
  template_values: any;
}

export interface PromoAutomation {
  id: number;
  created_at: string;
  automation_event_type: PromoAutomationEventType;
  caption_text: string;
  networks: string[];
  other_destinations: PromoAutomationOtherDestination[];
  schedule: Schedule;
  automatically_share_to_networks: boolean;
  metadata: any;
  status: string;
  entity_type_name: string;
  entity_type_object: any;
  entity_type_id: number;
  entity_id: number;
  allow_share_duplicates: boolean;
  templates: PromoAutomationTemplate[];
  nickname: string;
}

export interface CategoryGeneratorBody {
  description: string;
  complete_overwrite: boolean;
}

export interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface PromoAutomationTemplateNamespaceMapping {
  namespace_mapping: string[];
  fields_description: {
    [key: string]: {
      type: string;
      arguments?: string[];
      examples: string[];
    };
  };
}

export interface DigestEmailType {
  id: number;
  name: string;
  template: any;
}

export interface DigestCategoryEmailTemplate {
  id: number;
  category: Category | BasicCategory | null;
  category_id: number;
  digest_email_type: DigestEmailType;
  nickname: string;
  schedule: Schedule;
  status: string;
}

export interface EmailPreference {
  id: number;
  status: 'subscribed' | 'unsubscribed';
  digest_category_email_template: DigestCategoryEmailTemplate;
}

export interface MarketplacePayoutConfig {
  default_paid_listing_admin_payout: number;
  default_paid_role_admin_payout: number;
  default_transaction_admin_payout: number;
  default_affiliate_transaction_admin_payout: number;
}

// =================================================================================== //
// ==================================== Functions ==================================== //
// =================================================================================== //

export const getValueObjectValue = (
  valueObject?: ValueObject | null,
): string | number | boolean | (string | number)[] | null => {
  if (!valueObject) return null;

  if (
    typeof valueObject.value === 'string' ||
    typeof valueObject.value === 'number' ||
    typeof valueObject.value === 'boolean'
  ) {
    return valueObject.value;
  }

  if (valueObject.city !== '' && valueObject.state !== '') {
    return `${valueObject.city}, ${valueObject.state}`;
  }

  if (Array.isArray(valueObject.values)) {
    const values = [];
    for (const value of valueObject.values) {
      value?.value && values.push(value.value?.toString());
    }
    return values;
  }

  if (isPlainObject(valueObject.value)) {
    const childValueObject = valueObject.value as ValueObject;
    if (typeof childValueObject.value === 'string' || typeof childValueObject.value === 'number') {
      return childValueObject.value;
    }
  }

  return null;
};

export const getOrderImage = (order: Order) => {
  let image = '';
  if (order.fulfillments.length > 0) {
    const fulfillment = order.fulfillments[0];
    if (fulfillment.fulfillment_items.length > 0) {
      const fulfillmentItem = fulfillment.fulfillment_items[0];
      if (fulfillmentItem.variant.listing.listing_images.length > 0) {
        image = fulfillmentItem.variant.listing.listing_images[0].image;
      }
    }
  }

  if (!image || image === '') {
    image = DEFAULT_IMAGE;
  }

  return image;
};

export const computeMarketplaceFee = (order: Order | null) => {
  let payout = 0;
  for (const fulfillment of order?.fulfillments || []) {
    for (const item of fulfillment?.fulfillment_items || []) {
      payout +=
        Number(item.variant.offered_price) *
        Number(item.quantity) *
        Number(item.variant.listing.category.take_rate);
    }
  }
  return payout;
};

export const computePayout = ({ order, viewer }: { order: Order | null; viewer: User | null }) => {
  let payout = 0;
  for (const fulfillment of order?.fulfillments || []) {
    if (fulfillment?.marketplace_account?.user?.id === viewer?.id) {
      for (const item of fulfillment?.fulfillment_items || []) {
        if (item.fulfillment_type.price_type !== 'dynamic_api') {
          payout += Number(item?.fulfillment_type.price);
        }
      }
    }
  }

  return order?.summary?.contents_subtotal
    ? payout + Number(order?.summary?.contents_subtotal) - computeMarketplaceFee(order)
    : payout;
};

export const computeOrderPayout = ({
  order,
  viewer,
}: {
  order: Order | null;
  viewer: User | null;
}) => {
  if (!order) return 0;

  let payout = 0;
  for (const fulfillment of order?.fulfillments || []) {
    payout += Number(fulfillment?.fulfillment_payout);
  }

  if (payout > 0) {
    return payout.toFixed(2);
  } else {
    return computePayout({ order, viewer }).toFixed(2);
  }
};
