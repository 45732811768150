import { configureStore, Store } from '@reduxjs/toolkit';
import createSagaMiddleware, { Task } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { createWrapper } from 'next-redux-wrapper';
import { IS_DEV } from 'src/constants/config';
import { globalReducer, globalSaga } from './global';
import { testReducer, testSaga } from './test';

export interface SagaStore extends Store {
  sagaTask: Task;
}

const rootReducer = {
  global: globalReducer,
  test: testReducer,
};

function* rootSaga() {
  yield all([fork(globalSaga)]);
  yield all([fork(testSaga)]);
}

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
    devTools: IS_DEV,
  });

  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export type AppStore = ReturnType<typeof createStore>;
export type AppState = ReturnType<AppStore['getState']>;

export const reduxWrapper = createWrapper<AppStore>(createStore, {
  debug: IS_DEV,
});
