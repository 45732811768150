import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { MarketplaceEarning, MarketplacePayoutType } from 'src/api/v1-api';
import MarketplaceEarningPaidRole from 'src/components/molecules/marketplace-earning-paid-role';
import MarketplaceEarningListing from 'src/components/molecules/marketplace-earning-paid-listing';
import MarketplaceEarningPaidTransaction from 'src/components/molecules/marketplace-earning-paid-transaction';
import useRequest from 'src/utils/hooks/useRequest';
import { Pagination } from 'src/components/atoms';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { useRouter } from 'next/router';
import { useEffectOnce } from 'usehooks-ts';
import { DownloadIcon } from '@chakra-ui/icons';
import { downloadJsonAsCsv } from 'src/utils/files';
import { formatDate } from 'src/utils/common';
import { pick } from 'lodash';

interface MarketplaceEarningsTableProps {
  resultsPerPage?: number;
}

const MarketplaceEarningsTable = ({ resultsPerPage = 10 }: MarketplaceEarningsTableProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [nextLink, setNextLink] = useState<string | null>(null);
  const [prevLink, setPrevLink] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [marketplaceEarnings, setMarketplaceEarnings] = useState<MarketplaceEarning[]>([]);
  const { api } = useRequest();
  const { marketplace } = useMarketplaceContext();
  const toast = useToast();
  const router = useRouter();

  const fetchEarnings = useCallback(async () => {
    try {
      if (!marketplace || page === 0) {
        return;
      }

      setLoading(true);
      const params = {
        page,
        page_size: resultsPerPage,
      };
      const response = await api.marketplace.earnings.list(marketplace.id, params);
      setMarketplaceEarnings(response.results);
      setNextLink(response.next);
      setPrevLink(response.previous);
      setTotalPages(response.count / response.page_size);
      setPageNumber(response.page_number);
    } catch {
      toast({
        title: 'Error fetching Earnings',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, resultsPerPage]);

  useEffect(() => {
    if (page === 0) return;
    fetchEarnings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, resultsPerPage]);

  useEffectOnce(() => {
    const url = new URL(window.location.href);
    const parsedPage = parseInt(url.searchParams.get('page') ?? '1');
    setPage(parsedPage);
  });

  const renderEarning = (earning: MarketplaceEarning) => {
    switch (earning.payout_type) {
      case MarketplacePayoutType.PaidRoleAdmin:
        return <MarketplaceEarningPaidRole key={earning.id} earning={earning} />;
      case MarketplacePayoutType.PaidListing:
        return <MarketplaceEarningListing key={earning.id} earning={earning} />;
      case MarketplacePayoutType.PaidTransactionAdmin:
        return <MarketplaceEarningPaidTransaction key={earning.id} earning={earning} />;
      default:
        return null;
    }
  };

  const updateQueryParamAndContextPage = (link?: string | null) => {
    if (link) {
      const pageNumber = new URL(link).searchParams.get('page') ?? '1';
      const pageQueryName = 'page';
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set(pageQueryName, pageNumber);
      setPage(parseInt(pageNumber));
      router.push(currentUrl.toString(), undefined, { shallow: true });
    }
  };

  const handleNextPage = () => {
    if (nextLink) updateQueryParamAndContextPage(nextLink);
  };

  const handlePrevPage = () => {
    if (prevLink) updateQueryParamAndContextPage(prevLink);
  };

  const getTransactionTypeName = (earning: MarketplaceEarning) => {
    switch (earning.payout_type) {
      case MarketplacePayoutType.PaidRoleAdmin:
        return 'Paid Role';
      case MarketplacePayoutType.PaidListing:
        return 'Paid Listing';
      case MarketplacePayoutType.PaidTransactionAdmin:
        return 'Paid Transaction';
      default:
        return;
    }
  };

  const getRedactedMetadata = (earning: MarketplaceEarning) => {
    switch (earning.payout_type) {
      case MarketplacePayoutType.PaidRoleAdmin:
        return {
          ...pick(earning.metadata, ['role_name', 'user_email_address']),
          role_id: earning.metadata.entity_id,
        };
      case MarketplacePayoutType.PaidListing:
        return { listing_id: earning.metadata.entity_id };
      case MarketplacePayoutType.PaidTransactionAdmin:
        return { order_id: earning.metadata.entity_id };
      default:
        return earning.metadata;
    }
  };

  const exportEarnings = async () => {
    if (!marketplace) return;

    try {
      const response = await api.marketplace.earnings.list(marketplace.id);
      const earnings = response.results.map((earning: MarketplaceEarning) => ({
        Date: formatDate(earning.created_at),
        'Order Type': getTransactionTypeName(earning),
        'Payout Amount': earning.amount,
        Metadata: getRedactedMetadata(earning),
        'Direct Payment': earning.metadata.client_account_id ? 'yes' : 'no',
      }));
      downloadJsonAsCsv(`${marketplace.name.replace(' ', '')}-earnings.csv`)(earnings);
    } catch {
      toast({
        title: 'Error exporting Earnings',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" width="100%">
      <Flex direction="row" alignItems="center" justifyContent="space-between" pb={4}>
        <Text fontWeight="bold">Earning History</Text>
        {marketplaceEarnings.length > 0 && (
          <Button leftIcon={<DownloadIcon />} onClick={exportEarnings}>
            Export
          </Button>
        )}
      </Flex>
      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor="rgba(255, 255, 255, 0.7)"
        >
          <Spinner />
        </Box>
      )}
      <Box>{marketplaceEarnings.map(renderEarning)}</Box>
      <Pagination
        nextLink={nextLink}
        prevLink={prevLink}
        onNext={handleNextPage}
        onPrev={handlePrevPage}
        pageNumber={pageNumber}
        totalPages={totalPages}
      />
    </Flex>
  );
};

export default MarketplaceEarningsTable;
