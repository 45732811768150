import { all, put, takeLatest } from 'redux-saga/effects';
import { globalActions } from './global.slice';
import { HANDLE_ERROR } from './global.helpers';

export default function* globalSaga() {
  yield all([
    takeLatest(HANDLE_ERROR, function* (action: ReturnType<typeof globalActions.handleError>) {
      const { actionType, error } = action.payload;
      /**
       * TODO:: If it's possible, logout user here when 401 error
       */
      yield put(globalActions.setError({ actionType, error }));
    }),
  ]);
}
