import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { MarketplacePayout } from 'src/api/v1-api';
import { formatPrice } from 'src/utils/common';

interface PaymentsHistoryTableProps {
  payouts: MarketplacePayout[];
}

const PaymentsHistoryTable = ({ payouts }: PaymentsHistoryTableProps) => {
  return (
    <Flex direction="column" width="100%">
      <Text fontWeight="bold" pb={4}>
        Payout History
      </Text>
      <Flex
        py={4}
        px={6}
        w="100%"
        borderBottom="1px solid"
        borderColor="gray.200"
        gap={4}
        display={{ base: 'none', md: 'flex' }}
        textTransform="uppercase"
        color="gray.600"
        fontSize="xs"
        fontWeight="bold"
        alignItems="center"
      >
        <Flex w="25%">Payout method</Flex>
        <Flex w="25%">Payout date</Flex>
        <Flex w="25%">Transaction Id</Flex>
        <Flex w="25%">Amount</Flex>
      </Flex>
      {payouts.map((payout: MarketplacePayout) => (
        <Flex
          key={payout.id}
          w="100%"
          borderBottom="1px solid"
          borderColor="gray.200"
          py={{ base: 4, md: 2 }}
          px={6}
          gap={4}
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'flex-start', md: 'center' }}
        >
          <Flex gap={4} alignItems="center" w={{ base: '100%', md: '25%' }}>
            <Text color="gray.600" display={{ base: 'flex', md: 'none' }}>
              Payout method:
            </Text>
            {payout.payout_method}
          </Flex>
          <Flex gap={4} alignItems="center" w={{ base: '100%', md: '25%' }}>
            <Text color="gray.600" display={{ base: 'flex', md: 'none' }}>
              Payout date:
            </Text>
            {payout.date_of_payout}
          </Flex>
          <Flex
            gap={4}
            alignItems="center"
            w={{ base: '100%', md: '25%' }}
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            display={'block'}
          >
            <Text color="gray.600" display={{ base: 'flex', md: 'none' }}>
              Transaction Id:
            </Text>
            {payout.transaction_id}
          </Flex>
          <Flex gap={4} alignItems="center" w={{ base: '100%', md: '25%' }}>
            <Text color="gray.600" display={{ base: 'flex', md: 'none' }}>
              Amount:
            </Text>
            {formatPrice(payout.amount)}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default PaymentsHistoryTable;
