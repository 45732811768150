import axios from 'axios';

/**
 * TODO:: Implement more advanced interceptors including
 * - using accessToken from local storage
 * - if response status is 401, get refreshToken and try to call the api one more time
 * https://dev.to/franciscomendes10866/how-to-use-axios-interceptors-b7d
 */

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
);

export default axiosInstance;
