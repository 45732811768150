import React from 'react';
import CheckboxFilter from './checkbox-filter';
import TextFilter from './text-filter';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';
import SliderFilter from './slider-filter';
import BooleanCheckboxFilter from './boolean-checkbox-filter';
import AddressFilter from './addrress-filter';

export interface ListingFilterItemProps {
  attributeId: number | string;
  isMobileView?: boolean;
}

const ListingFilterItem = ({ attributeId, isMobileView }: ListingFilterItemProps): JSX.Element => {
  const { attributes } = useAttributeSelector();
  const attribute = attributes.find((a) => a.name === attributeId);

  if (attribute?.nested_content_type_name === 'Submitted Lead') {
    return <></>;
  }

  switch (attribute?.format.toLowerCase()) {
    case 'number':
    case 'decimal':
    case 'year':
      return <SliderFilter attributeId={attributeId} isMobileView={isMobileView} />;
    case 'select':
    case 'multiselect':
      return <CheckboxFilter attributeId={attributeId} isMobileView={isMobileView} />;
    case 'boolean':
      return <BooleanCheckboxFilter attributeId={attributeId} isMobileView={isMobileView} />;
    case 'address':
      return <AddressFilter attributeId={attributeId} isMobileView={isMobileView} />;
    case 'phone':
    case 'media':
    case 'url':
    case 'email':
    case 'duration':
    case 'image':
    case 'file':
    case 'json':
      // Hide all address, phone, media, url, email, duration, image, file, json filters for now
      return <></>;
    default:
      return <TextFilter attributeId={attributeId} isMobileView={isMobileView} />;
  }
};

export default ListingFilterItem;
