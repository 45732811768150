export class TestApi {
  static ApiInfo = () => {
    const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

    // const API_VERSION = process.env.API_VERSION;
    // const APPLICATION_ID = process.env.APPLICATION_ID;

    return { BASE_URL };
  };

  static getPosts = () => {
    return `${this.ApiInfo().BASE_URL}/posts`;
  };
}
