import { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Text,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  NumberInput,
  NumberInputField,
  useToast,
  InputGroup,
  InputLeftAddon,
  FormControl,
} from '@chakra-ui/react';
import { useAuth } from 'src/utils/auth';
import { MarketplaceInfo, Listing } from 'src/api/v1-api';
import { FormInputValues } from 'src/components/organisms/listing-form';
import { makeHeaders } from 'src/utils/fetchUtils';
import { MINIMUM_PRICE_ALLOWED } from 'src/constants/marketplace';
interface ISoldThisProps {
  listing: Listing | FormInputValues | null;
  marketplace?: MarketplaceInfo | null;
  afterUpdate?: (listing: Listing | FormInputValues) => void;
  mode: 'edit' | 'form';
}

const ISoldThis = ({ listing, marketplace, mode, afterUpdate }: ISoldThisProps) => {
  const { getToken } = useAuth();
  const [showImSure, setShowImSure] = useState(false);
  const [optionSelected, setOptionSelected] = useState<'loma_sale' | 'non_loma_sale' | ''>('');
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const toast = useToast();

  const buttonLoma = `I Sold to a Buyer from ${marketplace?.name || 'this marketplace'}`;
  const buttonNonLoma = 'I Sold to a Buyer I Found Somewhere Else';

  const { isOpen, onOpen, onClose } = useDisclosure();
  const multipleVariantsExist = (listing?.variants?.length || 0) > 1;
  const buttonText = multipleVariantsExist ? "I'm Sold Out Of These" : 'I Sold This';

  const selectedVariant = listing?.variants?.[0];
  const handleAPICall = async (bodyJSON: any) => {
    setButtonIsLoading(true);
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${listing?.id}/${marketplace?.id}/set_status_and_sub_status/`;

    const token = await getToken();

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: makeHeaders(token),
        body: JSON.stringify(bodyJSON),
      });

      if (!response.ok) {
        throw new Error('An error occurred');
      }

      if (typeof afterUpdate === 'function') {
        const updatedListing = await response.json();
        afterUpdate(updatedListing);
      }

      toast({
        title: 'Success!',
        description: 'Your listing has been updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch {
      toast({
        title: 'Error',
        description: 'An error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setButtonIsLoading(false);
      setOptionSelected('');
    }
  };

  const handleSold = async (type: 'loma_sale' | 'non_loma_sale') => {
    const bodyJSON = {
      status: 'sold',
      sub_status:
        type === 'loma_sale'
          ? 'self_reported_loma_attributed'
          : 'self_reported_non_loma_attributed',
      variants:
        listing?.variants?.map((variant) => {
          if (variant.id === selectedVariant?.id) {
            // if the price is too low or not passed, use existing offered price
            return {
              ...variant,
              offered_price:
                price && price < MINIMUM_PRICE_ALLOWED
                  ? variant.offered_price
                  : price ?? variant.offered_price,
            };
          }
        }) || [],
    };
    if (multipleVariantsExist) {
      setShowImSure(true);
      setOptionSelected(type);
    } else {
      handleAPICall(bodyJSON);
      setOptionSelected(type);
    }
  };

  const handleImSure = (type: 'loma_sale' | 'non_loma_sale' | '') => {
    const bodyJSON = {
      status: 'sold',
      sub_status:
        type === 'loma_sale'
          ? 'self_reported_loma_attributed'
          : 'self_reported_non_loma_attributed',
      variants:
        listing?.variants?.map((variant) => {
          if (variant.id === selectedVariant?.id) {
            return {
              ...variant,
              offered_price: price,
              quantity: 0,
            };
          }
        }) || [],
    };
    handleAPICall(bodyJSON);
  };

  return (
    <>
      {mode === 'edit' ? (
        <Button variant="outline" size="xs" colorScheme="black" onClick={onOpen}>
          {buttonText}
        </Button>
      ) : (
        <Button onClick={onOpen}>{buttonText}</Button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        onCloseComplete={() => {
          setShowImSure(false);
          setOptionSelected('');
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Congratulations on your sale!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>What was your sale price? (Optional)</Text>
            <FormControl>
              <InputGroup>
                <InputLeftAddon>$</InputLeftAddon>
                <NumberInput
                  name="price"
                  min={MINIMUM_PRICE_ALLOWED}
                  precision={2}
                  value={price}
                  onChange={(v) => setPrice(parseFloat(v))}
                >
                  <NumberInputField borderLeftRadius={0} />
                </NumberInput>
              </InputGroup>
            </FormControl>

            <Flex mt={12} mb={6} flexDir="column">
              <Button
                w="full"
                loadingText={buttonLoma}
                isDisabled={buttonIsLoading}
                onClick={() => {
                  handleSold('loma_sale');
                }}
                colorScheme={optionSelected === 'loma_sale' ? 'primary' : 'gray'}
              >
                {buttonLoma}
              </Button>
              <Button
                w="full"
                mt={4}
                loadingText={buttonNonLoma}
                isDisabled={buttonIsLoading}
                onClick={() => {
                  handleSold('non_loma_sale');
                }}
                colorScheme={optionSelected === 'non_loma_sale' ? 'primary' : 'gray'}
              >
                {buttonNonLoma}
              </Button>
            </Flex>
            {multipleVariantsExist && showImSure && (
              <>
                <Text>Are you sure? This will set all your inventory counts to 0.</Text>
                <Flex w={'full'} justifyContent={'center'}>
                  <Button
                    my={2}
                    onClick={() => {
                      handleImSure(optionSelected);
                    }}
                    colorScheme="primary"
                    disabled={buttonIsLoading}
                  >
                    I&apos;m Sure
                  </Button>
                </Flex>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ISoldThis;
