import { Input, Popover } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FilterPopoverButton, FilterPopoverContent } from 'src/components/atoms';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';
import { ListingFilterItemProps } from '.';

const TextFilter = ({ attributeId, isMobileView }: ListingFilterItemProps): JSX.Element => {
  const {
    attributes,
    selectedAttributes,
    setSelectedAttributes,
    stagedAttributes,
    setStagedAttributes,
  } = useAttributeSelector();
  const attribute = attributes.find((a) => a.name === attributeId);

  const defaultValue: number[] | string[] | boolean[] = [''];
  const [inputValue, setInputValue] = useState<(number | string | boolean)[]>(defaultValue);

  const handleMobileChange = (value: (number | string)[]) => {
    setInputValue(value);
    setStagedAttributes({
      ...stagedAttributes,
      [attributeId]: value,
    });
  };

  useEffect(() => {
    if (attributeId in selectedAttributes) {
      setInputValue(selectedAttributes[attributeId]);
    }
  }, [selectedAttributes]);

  const noFilter =
    !selectedAttributes[attributeId] ||
    selectedAttributes[attributeId].length === 0 ||
    (selectedAttributes[attributeId].length === 1 && selectedAttributes[attributeId][0] === '');
  return isMobileView ? (
    <Input
      type="text"
      placeholder={attribute?.name}
      value={inputValue[0].toString()}
      focusBorderColor="primary.500"
      onChange={(e) => handleMobileChange([e.target.value])}
    />
  ) : (
    <Popover placement="bottom-start">
      {/* TODO(Sean): Add 'width' back in to FilterPopoverButton */}
      <FilterPopoverButton label={attribute?.name} hasFilter={!noFilter} />
      <FilterPopoverContent
        isCancelDisabled={defaultValue === inputValue}
        onClickApply={() => {
          setSelectedAttributes({
            ...selectedAttributes,
            [attributeId]: inputValue,
          });
        }}
        onClickCancel={() => {
          setSelectedAttributes({
            ...selectedAttributes,
            [attributeId]: defaultValue,
          });
        }}
      >
        <Input
          type="text"
          placeholder={attribute?.name}
          value={inputValue[0].toString()}
          focusBorderColor="primary.500"
          onChange={(e) => setInputValue([e.target.value])}
        />
      </FilterPopoverContent>
    </Popover>
  );
};

export default TextFilter;
