import {
  Stack,
  Box,
  Icon,
  Text,
  Tag,
  TagLabel,
  HStack,
  Image,
  useToast,
  Spinner,
  Heading,
  useTheme,
} from '@chakra-ui/react';
import { BiDollar } from 'react-icons/bi';
import { useState, useEffect } from 'react';
import { apiRequest } from 'src/utils/fetchUtils';
import { useAuth } from 'src/utils/auth';
import { Pagination } from 'src/components/atoms';
import { Order, getOrderImage } from 'src/api/v1-api';
import { formatDate, getStatusColorScheme, getDisplayStatus } from 'src/utils/common';
import NextLink from 'next/link';
import { srcFromCDN } from 'src/utils/files';
import { useMarketplaceContext } from 'src/contexts/marketplace';

const OrdersTable = () => {
  const toast = useToast();
  const theme = useTheme();
  const { getToken } = useAuth();
  const { marketplace } = useMarketplaceContext();

  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [nextLink, setNextLink] = useState<string | null>(null);
  const [prevLink, setPrevLink] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);

  const fetchOrders = async (url: string) => {
    if (!isLoading) {
      setIsLoading(true);
      const token = await getToken();

      try {
        const response = await apiRequest('GET', url, token);
        setOrders(response.results);
        setNextLink(response.next);
        setPrevLink(response.previous);
        setTotalPages(response.count / response.page_size);
        setPageNumber(response.page_number);
      } catch (error) {
        toast({
          title: 'Error.',
          description: 'Unable to fetch orders. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!marketplace) return;
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/orders/${marketplace.id}/?page_size=10`;
    fetchOrders(url);
  }, [marketplace]);

  const handleNextPage = () => {
    if (nextLink) {
      fetchOrders(nextLink);
    }
  };

  const handlePrevPage = () => {
    if (prevLink) {
      fetchOrders(prevLink);
    }
  };

  const getOrderDate = (order: Order) => {
    const date = new Date(order.created_at);
    return formatDate(date.toString());
  };

  const displayOrderPrice = (order: Order) => {
    return (
      <HStack>
        <Icon as={BiDollar} />
        <Text lineHeight="1.56" fontWeight="semibold" fontSize="18px">
          {order.summary.total}
        </Text>
      </HStack>
    );
  };

  return (
    <>
      <Stack
        justify="flex-start"
        align="flex-start"
        spacing="20px"
        position="relative"
        minHeight="100px"
        width="100%"
      >
        <Heading fontSize="2xl">My Orders</Heading>
        {orders.map((order) => (
          <Stack
            key={order.id}
            direction="row"
            justify="flex-start"
            align="flex-start"
            spacing="20px"
          >
            <HStack key={order.id} spacing={3}>
              <Image
                alt="..."
                minWidth="100px"
                maxWidth="100px"
                height="80px"
                src={srcFromCDN(getOrderImage(order), 100)}
                draggable="false"
                loading="lazy"
                style={{ objectFit: 'cover' }}
                align="center"
                borderRadius={theme.border_radius.border_radius_1}
              />
              <Stack direction="column" justify="flex-start" align="flex-start" spacing="4px">
                <Stack direction="row" justify="flex-start" align="center" spacing="4px">
                  <Text lineHeight="1.33" fontWeight="medium" fontSize="12px" color="gray.600">
                    {getOrderDate(order)}
                  </Text>
                  <Tag size="sm" colorScheme={getStatusColorScheme(order.status)}>
                    <TagLabel>{getDisplayStatus(order.status)}</TagLabel>
                  </Tag>
                </Stack>
                {/* If on small screen, display price below order image */}
                <Box display={['block', 'none', 'none', 'none']}>{displayOrderPrice(order)}</Box>
                <Text lineHeight="1.43" fontWeight="semibold" fontSize="14px" minWidth="190px">
                  Order # {order.id}
                </Text>
                <NextLink href={`/order/${order.id}`}>
                  <Text
                    lineHeight="1.33"
                    fontWeight="medium"
                    fontSize="12px"
                    textDecoration="underline"
                    color="blue.500"
                  >
                    View Order
                  </Text>
                </NextLink>
              </Stack>
            </HStack>
            {/* If on large screen, display price on the right of order image */}
            <Box display={['none', 'block', 'block', 'block']}>{displayOrderPrice(order)}</Box>
          </Stack>
        ))}
        {isLoading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="rgba(255, 255, 255, 0.7)"
          >
            <Spinner />
          </Box>
        )}
      </Stack>
      <Pagination
        nextLink={nextLink}
        prevLink={prevLink}
        onNext={handleNextPage}
        onPrev={handlePrevPage}
        pageNumber={pageNumber}
        totalPages={totalPages}
      />
    </>
  );
};

export default OrdersTable;
