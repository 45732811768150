import React, { ReactNode } from 'react';
import { ThemeProvider } from 'src/theme';
import { ErrorBoundary } from 'src/components/system';

interface SimpleLayoutProps {
  children: ReactNode;
}

const SimpleLayout: React.FC<SimpleLayoutProps> = ({ children }) => {
  return (
    <ThemeProvider>
      <ErrorBoundary>{children}</ErrorBoundary>
    </ThemeProvider>
  );
};

export default SimpleLayout;
