import { Select, useBreakpointValue, useTheme } from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import { SortOption, sortOptions } from 'src/constants/sort-options';

export type SortOptionSelectProps = {
  value: SortOption | '';
  onChange?: (option: SortOption | null) => void;
  onBlur?: () => void;
  options: SortOption[];
};

const SortOptionSelect = ({ options, onChange, ...rest }: SortOptionSelectProps) => {
  const defaultOption = useBreakpointValue({ base: 'Sort', md: 'Default' }, { fallback: 'Sort' });
  const theme = useTheme();
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;

    if (value === '') {
      onChange?.(null);
      return;
    }

    onChange?.(value as SortOption);
  };

  const buildThemeStyles = () => {
    switch (theme.name) {
      case 'Magazine':
        return {
          backgroundColor: 'white',
          color: theme.colors.primary[500],
          borderColor: theme.colors.primary[500],
        };
      default:
        return {};
    }
  };

  const themeStyles = buildThemeStyles();

  return (
    <Select {...rest} onChange={handleChange} cursor="pointer" h="42px" {...themeStyles}>
      <option value="">{defaultOption}</option>
      {options.map((opt) => (
        <option key={opt} value={opt}>
          {sortOptions[opt]}
        </option>
      ))}
    </Select>
  );
};

export default SortOptionSelect;
