import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import { IoIosShareAlt } from 'react-icons/io';
import { InstagramSharePreview } from 'src/components/molecules';
import { Listing, PromoMedia as PromoMediaType } from 'src/api/v1-api';
import { useContext, useEffect, useState } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';
import { PromoMedia } from 'src/components/atoms';
import useRequest from 'src/utils/hooks/useRequest';
import { getListingUrl } from 'src/utils/marketplaceUtils';
import { FiFacebook, FiInstagram, FiTwitter } from 'react-icons/fi';
import { capitalize } from 'lodash';

interface ShareButtonProps {
  listing: Listing | null;
}

const ShareButton = ({ listing }: ShareButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [promoMediaUrl, setPromoMediaUrl] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasAdmin } = useAuth();
  const { marketplace, connectedSocialProfiles, urlBase } = useContext(MarketplaceContext);
  const isAdmin = hasAdmin(marketplace?.id);
  const { get, api } = useRequest();
  const toast = useToast();

  const isWebShareSupported = typeof window !== 'undefined' && 'share' in window.navigator;

  useEffect(() => {
    const fetchPromoMedia = async () => {
      if (!listing) {
        return;
      }

      try {
        const response: PromoMediaType = await get({
          url: `/listings/promo_media/${listing?.id}/`,
          useToken: false,
        });

        if (response.media_url) {
          setPromoMediaUrl(response.media_url);
        }
      } catch (error) {
        console.error('Error fetching promo media:', error);
      }
    };

    fetchPromoMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing]);

  const handleWebShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out this awesome listing!',
          url: window.location.href,
        });
      } catch (error) {
        console.error('Something went wrong with sharing:', error);
      }
    }
  };

  if (!isWebShareSupported && !isAdmin) {
    return null;
  }

  const handleShareToSocialNetwork = async (network: string) => {
    if (!marketplace || !listing || urlBase === '') {
      return null;
    }

    try {
      setIsLoading(true);
      const listingUrl = getListingUrl(urlBase, listing?.id);

      const payload = {
        caption: '',
        location: '',
        networks: [network],
        loma_url: listingUrl,
      };

      await api.shareautomation.listings.sharePromoMedia(marketplace.id, listing.id, payload);
      toast({
        title: 'Success',
        description: `The listing has been shared to ${capitalize(network)}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } catch (error) {
      const errorMessage = error.message.includes('Duplicate or similar content')
        ? `This listing has already been shared to ${capitalize(
            network,
          )} within the same two day period.`
        : 'Something went wrong. Please try again.';
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isNetworkConnected = (network: string) => {
    const connectedNework = connectedSocialProfiles.find((profile) => profile.platform === network);
    return !!connectedNework;
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          ml={2}
          rightIcon={<IoIosShareAlt fontSize="lg" color="black" />}
          aria-label="Share"
          size="xs"
          border={'1px solid'}
          py={0}
          isLoading={isLoading}
          colorScheme="black"
          variant="outline"
        >
          Share
        </MenuButton>

        <MenuList>
          {isAdmin && (
            <>
              {isNetworkConnected('instagram') && promoMediaUrl ? (
                <MenuItem
                  onClick={() => handleShareToSocialNetwork('instagram')}
                  icon={<FiInstagram />}
                >
                  Share to Instagram
                </MenuItem>
              ) : (
                <MenuItem onClick={onOpen} icon={<FiInstagram />}>
                  Share to Instagram Story (Mobile Only)
                </MenuItem>
              )}
              {isNetworkConnected('facebook') && promoMediaUrl && (
                <MenuItem
                  onClick={() => handleShareToSocialNetwork('facebook')}
                  icon={<FiFacebook />}
                >
                  Share to Facebook
                </MenuItem>
              )}
              {isNetworkConnected('twitter') && promoMediaUrl && (
                <MenuItem
                  onClick={() => handleShareToSocialNetwork('twitter')}
                  icon={<FiTwitter />}
                >
                  Share to Twitter
                </MenuItem>
              )}
            </>
          )}
          {isWebShareSupported && <MenuItem onClick={handleWebShare}>Share elsewhere</MenuItem>}
          <PromoMedia promoMediaUrl={promoMediaUrl} />
        </MenuList>
      </Menu>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="gray.100">
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <InstagramSharePreview listing={listing} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareButton;
