import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import CategorySelector from '../category-selector';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { BasicCategory } from 'src/api/v1-api/helpers';

interface CategorySelectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdateCategory: (categoryId: string) => void;
  selectedCategoryId: string | undefined;
}

const CategorySelectorModal: React.FC<CategorySelectorModalProps> = (props) => {
  const { isOpen, onClose, onUpdateCategory, selectedCategoryId } = props;

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
  const [filteredCategories, setFilteredCategories] = useState<BasicCategory[]>([]);

  const { marketplace, categories } = useContext(MarketplaceContext);

  useEffect(() => {
    setSelectedCategory(selectedCategoryId);
  }, [selectedCategoryId]);

  useEffect(() => {
    const filteredCategories = categories.filter(
      (category) => category.cta_link === null || category.cta_link.length === 0,
    );
    setFilteredCategories(filteredCategories);
  }, [categories]);

  const onUpdateHandler = () => {
    if (!selectedCategory) {
      return;
    }

    onUpdateCategory && onUpdateCategory(selectedCategory?.toString());
    onCloseHandler();
  };

  const onCloseHandler = () => {
    onClose && onClose();
    setSelectedCategory(selectedCategoryId);
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCloseHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CategorySelector
            marketplaceId={marketplace?.id}
            categories={filteredCategories}
            mode={'provide-categories'}
            selectedCategoryId={selectedCategory}
            onCategorySelect={(category) => setSelectedCategory(category?.id?.toString())}
          />
        </ModalBody>
        <ModalFooter gap="2">
          <Button onClick={onCloseHandler}>Close</Button>
          <Button colorScheme="primary" onClick={onUpdateHandler}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CategorySelectorModal;
