import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { LEAD_STATUS } from 'src/api/v1-api';
import AdminLeadForm from 'src/components/molecules/admin-lead-form';
import LeadConciergeModal from 'src/components/molecules/lead-concierge-modal';
import { LeadsView } from 'src/components/organisms';
import { MarketplaceContext } from 'src/contexts/marketplace';
import useRequest from 'src/utils/hooks/useRequest';

const EMPTY_LEAD_FORM = {
  id: 0,
  listingId: 0,
  recipientName: '',
  recipientEmail: '',
  recipientPhone: '',
  recipientMessage: '',
  notesForSeller: '',
  adminPrivateNotes: '',
  attributeValues: {},
  status: LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL,
};

const LeadApprovals = (): JSX.Element => {
  const { marketplace } = useContext(MarketplaceContext);
  const toast = useToast();
  const { api } = useRequest();
  // version used to update all fetches when any lead is updated. Necessary to handle changing statuses.
  const [version, setVersion] = useState(0);
  const [addNewLead, setAddNewLead] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leadForm, setLeadForm] = useState<AdminLeadForm>(EMPTY_LEAD_FORM);

  const closeNewLead = () => {
    setAddNewLead(false);
    setLeadForm(EMPTY_LEAD_FORM);
  };

  const addLeadHandler = async () => {
    if (isLoading || !marketplace) return;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('recipient_name', leadForm.recipientName ?? '');
      formData.append('recipient_email', leadForm.recipientEmail ?? '');
      formData.append('recipient_phone', leadForm.recipientPhone ?? '');
      formData.append('recipient_message', leadForm.recipientMessage ?? '');
      formData.append('notes_for_seller', leadForm.notesForSeller ?? '');
      formData.append('admin_private_notes', leadForm.adminPrivateNotes ?? '');
      formData.append('status', leadForm.status ?? LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL);
      const leadAttributeValues = JSON.stringify(leadForm.attributeValues ?? {});
      if (leadAttributeValues !== '{}') {
        formData.append('lead_attribute_values', leadAttributeValues);
      }

      await api.marketplace.leads.create(marketplace.id, leadForm.listingId, formData);

      const newVersion = version + 1;
      setVersion(newVersion);
      closeNewLead();

      toast({
        title: 'Success',
        description: 'Lead added',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch {
      toast({
        title: 'Error adding lead',
        description: '',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box as="section" w="full">
      <Text fontSize="2xl" pb={4}>
        Lead Concierge
      </Text>

      <Box textAlign="right" w="full">
        <Button colorScheme="primary" onClick={() => setAddNewLead(true)} mb={4}>
          Create New Buyer Lead
        </Button>
      </Box>

      <Accordion allowMultiple defaultIndex={[0]}>
        <AccordionItem key="admin-pending">
          <AccordionButton>
            <Box fontWeight="semibold" mb="2" flex="1" textAlign="left">
              Waiting for Admin Action
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel w="full">
            <LeadsView
              fetchUrl={`${process.env.NEXT_PUBLIC_API_HOST}/pending_leads/${marketplace?.id}/?status=${LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL}`}
              paginationStyle={''}
              resultsPerPage={20}
              actionButtons="pendingListings"
              status={LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL}
              version={version}
              setVersion={setVersion}
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem key="admin-approved">
          <AccordionButton>
            <Box fontWeight="semibold" mb="2" flex="1" textAlign="left">
              Admin Approved Leads
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <LeadsView
              fetchUrl={`${process.env.NEXT_PUBLIC_API_HOST}/pending_leads/${marketplace?.id}/?status=${LEAD_STATUS.STATUS_ADMIN_APPROVED}`}
              paginationStyle={''}
              resultsPerPage={8}
              actionButtons="pendingListings"
              status={LEAD_STATUS.STATUS_ADMIN_APPROVED}
              version={version}
              setVersion={setVersion}
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem key="admin-rejected">
          <AccordionButton>
            <Box fontWeight="semibold" mb="2" flex="1" textAlign="left">
              Admin Rejected Leads
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <LeadsView
              fetchUrl={`${process.env.NEXT_PUBLIC_API_HOST}/pending_leads/${marketplace?.id}/?status=${LEAD_STATUS.STATUS_ADMIN_REJECTED}`}
              paginationStyle={''}
              resultsPerPage={8}
              actionButtons="pendingListings"
              status={LEAD_STATUS.STATUS_ADMIN_REJECTED}
              version={version}
              setVersion={setVersion}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <LeadConciergeModal
        isOpen={addNewLead}
        onClose={closeNewLead}
        onUpdateLead={addLeadHandler}
        lead={null}
        leadForm={leadForm}
        setLeadForm={setLeadForm}
        disabled={false}
        marketplaceId={marketplace?.id}
      />
    </Box>
  );
};

export default LeadApprovals;
