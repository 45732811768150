import React, { Dispatch, SetStateAction } from 'react';
import { Grid, GridItem, Text, FormControl, FormLabel, Select, Input } from '@chakra-ui/react';
import { ParcelInfo } from 'src/api/v1-api';

interface ParcelInfoFormProps {
  parcelInfo: ParcelInfo;
  setParcelInfo: Dispatch<SetStateAction<ParcelInfo>>;
}

const ParcelInfoForm: React.FC<ParcelInfoFormProps> = ({ parcelInfo, setParcelInfo }) => {
  return (
    <>
      <Grid width="full" column={3} gap={{ base: 3, md: 5 }} autoColumns="1fr">
        <GridItem colSpan={3}>
          <Text fontSize="md" fontWeight={600}>
            Parcel Info
          </Text>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl>
            <FormLabel>Distance Unit</FormLabel>
            <Select
              name="distance_unit"
              placeholder="Distance Unit"
              value={parcelInfo?.distance_unit || 'in'}
              onChange={(e) => setParcelInfo({ ...parcelInfo, distance_unit: e.target.value })}
              isRequired
            >
              <option value="in">inches</option>
              <option value="ft">feet</option>
              <option value="cm">centimeters</option>
              <option value="m">meters</option>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl>
            <FormLabel>Length</FormLabel>
            <Input
              type="number"
              name="length"
              placeholder="Length"
              value={parcelInfo?.length}
              onChange={(e) =>
                setParcelInfo({
                  ...parcelInfo,
                  length: parseFloat(e.target.value),
                })
              }
              isRequired
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl>
            <FormLabel>Width</FormLabel>
            <Input
              type="number"
              name="width"
              placeholder="Width"
              value={parcelInfo?.width}
              onChange={(e) =>
                setParcelInfo({
                  ...parcelInfo,
                  width: parseFloat(e.target.value),
                })
              }
              isRequired
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl>
            <FormLabel>Height</FormLabel>
            <Input
              type="number"
              name="height"
              placeholder="Height"
              value={parcelInfo?.height}
              onChange={(e) =>
                setParcelInfo({
                  ...parcelInfo,
                  height: parseFloat(e.target.value),
                })
              }
              isRequired
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl>
            <FormLabel>Mass Unit</FormLabel>
            <Select
              name="mass_unit"
              placeholder="Mass Unit"
              value={parcelInfo?.mass_unit || 'lb'}
              onChange={(e) => setParcelInfo({ ...parcelInfo, mass_unit: e.target.value })}
              isRequired
            >
              <option value="lb">pounds</option>
              <option value="oz">ounces</option>
              <option value="kg">kilograms</option>
              <option value="g">grams</option>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl>
            <FormLabel>Weight</FormLabel>
            <Input
              type="number"
              min="0"
              name="weight"
              placeholder="Weight"
              value={parcelInfo?.weight}
              onChange={(e) =>
                setParcelInfo({
                  ...parcelInfo,
                  weight: parseFloat(e.target.value),
                })
              }
              isRequired
            />
          </FormControl>
        </GridItem>
      </Grid>
      <Text fontSize={'sm'} fontWeight={600} my={{ base: 4, lg: 6 }}>
        To ensure the success of your shipment, please add an accurate weight for the item.
      </Text>
    </>
  );
};

export default ParcelInfoForm;
