import React from 'react';
import { MarketplaceEarning } from 'src/api/v1-api';
import MarketplaceEarningItem from 'src/components/atoms/marketplace-earning-item';

interface MarketplaceEarningPaidRoleProps {
  earning: MarketplaceEarning;
}

const MarketplaceEarningPaidRole = ({ earning }: MarketplaceEarningPaidRoleProps) => {
  const { role_name, user_email_address, client_account_id } = earning.metadata;

  return (
    <MarketplaceEarningItem
      earningDate={earning.invoice_date}
      earningTitle="Paid Role"
      earningAmount={earning.amount}
      earningMetadata={[
        {
          title: 'Role Name',
          value: role_name,
          displayTitle: true,
        },
        {
          title: 'User Email Address',
          value: user_email_address,
          displayTitle: true,
        },
      ]}
      clientAccountId={client_account_id}
    />
  );
};

export default MarketplaceEarningPaidRole;
