import { Order } from 'src/api/v1-api';
import { formatDate } from 'src/utils/common';

export type SectionType = 'new' | 'in_progress' | 'completed';

export const getSectionTitle = (sectionType: SectionType): string => {
  if (sectionType === 'new') return 'New Orders';
  if (sectionType === 'in_progress') return 'In Progress';
  if (sectionType === 'completed') return 'Completed Orders';

  return '';
};

export const getFormattedDate = (value: string | number) => {
  return formatDate(new Date(value).toString());
};

export const getStatusText = ({ type, order }: { type: SectionType; order: Order }): string => {
  if (type === 'new') {
    return (order.fulfillments ?? []).some((item) => item.status === 'action_required')
      ? 'Action Required'
      : 'New';
  }
  if (type === 'in_progress') {
    return (order.fulfillments ?? []).some((item) => item.status === 'return_to_sender')
      ? 'Return to Sender'
      : 'In Transit';
  }
  if (type === 'completed') {
    if ((order.fulfillments ?? []).some((item) => item.status === 'fulfilled')) return 'Fulfilled';
    if ((order.fulfillments ?? []).some((item) => item.status === 'returned')) return 'Returned';
    if ((order.fulfillments ?? []).some((item) => item.status === 'failed')) return 'Failed';
    return 'Cancelled';
  }

  return '';
};
