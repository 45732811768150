import React, { useEffect, useState } from 'react';
import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { AttributeInput } from 'src/components/atoms';
import { Attribute, AttributeOption } from 'src/api/v1-api';
import { BasicInfoType, FilePath } from 'src/components/organisms/listing-form';
import useRequest from 'src/utils/hooks/useRequest';
import useSingleToast from 'src/utils/hooks/toast/useSingleToast';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useContext } from 'react';

interface AttributeValueCaptureModalProps {
  attributeIds: number[];
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  modalHeader?: string;
  alertText?: string;
}

const AttributeValueCaptureModal: React.FC<AttributeValueCaptureModalProps> = ({
  attributeIds,
  isOpen,
  onClose,
  onSave,
  modalHeader,
  alertText,
}) => {
  const { api } = useRequest();
  const { marketplace } = useContext(MarketplaceContext);
  const showToast = useSingleToast();
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [values, setValues] = useState<
    Record<number, string | number | boolean | FilePath[] | AttributeOption[]>
  >({});
  const [isSaving, setIsSaving] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    if (!attributeIds.length || attributes.length) return;

    const fetchAttributes = async () => {
      try {
        const responses = await Promise.all(attributeIds.map((id) => api.attributes.get(id)));
        setAttributes(responses);
      } catch (error) {
        showToast({
          title: 'Something went wrong',
          description: 'Please try again. If the problem persists, contact support.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
      }
    };

    fetchAttributes();
  }, [attributeIds, api, showToast]);

  useEffect(() => {
    const allAttributesHaveValues = attributes.every(
      (attr) => values[attr.id] !== undefined && values[attr.id] !== '',
    );
    setIsSaveDisabled(!allAttributesHaveValues);
  }, [attributes, values]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await Promise.all(
        attributes.map((attribute) =>
          api.attributes.createValue(marketplace?.id as number, attribute.id, {
            value: values[attribute.id],
          }),
        ),
      );
      showToast({
        title: "We've updated your info",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
      onSave();
      onClose();
    } catch (error) {
      showToast({
        title: 'Something went wrong',
        description: 'Please try again. If the problem persists, contact support.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleAttributeChange = (
    attributeId: number,
    value: string | number | boolean | FilePath[] | AttributeOption[],
  ) => {
    setValues((prev) => ({
      ...prev,
      [attributeId]: value,
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {alertText && (
            <Alert status="warning" my={6}>
              <AlertIcon />
              {alertText}
            </Alert>
          )}
          {attributes.length ? (
            attributes.map((attribute) => (
              <AttributeInput<BasicInfoType>
                key={attribute.id}
                attribute={attribute}
                width="full"
                isMobileView
                value={values[attribute.id] || ''}
                setChange={(e) => handleAttributeChange(attribute.id, e.target.value)}
                setSelectChange={(e) => handleAttributeChange(attribute.id, e.target.value)}
                setSwitchChange={(checked) => handleAttributeChange(attribute.id, checked)}
                disabled={false}
                setMediaChange={(filePaths) => handleAttributeChange(attribute.id, filePaths)}
                setMultiselectChange={(value) => handleAttributeChange(attribute.id, value)}
                requiredOverride={true}
              />
            ))
          ) : (
            <Center width="100%" display="flex" justifyContent="center" h="100px">
              <Spinner />
            </Center>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSave}
            isLoading={isSaving}
            isDisabled={isSaveDisabled}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AttributeValueCaptureModal;
