import { AuthProvider } from 'src/utils/auth';
import createCache from '@emotion/cache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider as ReduxProvider } from 'react-redux';
import { DefaultLayout } from 'src/components/templates';
import { reduxWrapper } from 'src/store';
import '../scss/index.scss';
import * as gtag from 'src/utils/gtag';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Script from 'next/script';
import { SessionProvider } from 'next-auth/react';
import { ApiRequestProvider } from 'src/utils/hooks/useRequest';
import { ErrorBoundary } from 'src/components/system';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { MarketplaceContextProvider } from 'src/contexts/marketplace';
import { MarketplaceInfo } from 'src/api/v1-api';
import { ThemeProvider } from 'src/theme';

const createEmotionCache = (): EmotionCache => {
  return createCache({ key: 'css' });
};

const clientSideEmotionCache = createEmotionCache();

type ComponentWithPageLayout = AppProps & {
  Component: AppProps['Component'] & {
    PageLayout?: React.ComponentType<{ children: React.ReactNode }>;
  };
  emotionCache: EmotionCache;
  session: any;
  marketplace: MarketplaceInfo;
};

if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
  });
}

const LomaApp = ({ Component, session, ...rest }: ComponentWithPageLayout): JSX.Element => {
  const { store, props } = reduxWrapper.useWrappedStore(rest);
  const marketplaceData = props.pageProps?.marketplace || null;

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.gaPageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (marketplaceData?.id) {
      posthog?.group('marketplace', marketplaceData.id.toString(), {
        name: marketplaceData.name,
        environment: process.env.NEXT_PUBLIC_ENV,
      });
    }
    posthog?.group('environment', process.env.NEXT_PUBLIC_ENV || 'development', {
      name: process.env.NEXT_PUBLIC_ENV,
      marketplace_name: marketplaceData?.name ?? '',
    });
  }, [marketplaceData]);

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <PostHogProvider client={posthog}>
        <SessionProvider session={session}>
          <AuthProvider>
            <ApiRequestProvider value={{ baseUrl: process.env.NEXT_PUBLIC_API_HOST }}>
              <ReduxProvider store={store}>
                <MarketplaceContextProvider marketplaceProvider={marketplaceData}>
                  <ThemeProvider marketplaceProvider={marketplaceData}>
                    <CacheProvider value={props.emotionCache || clientSideEmotionCache}>
                      <Head>
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        {marketplaceData?.functional?.google_search_console_verification && (
                          <meta
                            name="google-site-verification"
                            content={marketplaceData.functional.google_search_console_verification}
                          />
                        )}
                      </Head>

                      <ErrorBoundary>
                        {Component.PageLayout ? (
                          <Component.PageLayout {...props.pageProps}>
                            <Component key={router.asPath} {...props.pageProps} />
                          </Component.PageLayout>
                        ) : (
                          <DefaultLayout {...props.pageProps}>
                            <Component key={router.asPath} {...props.pageProps} />
                          </DefaultLayout>
                        )}
                      </ErrorBoundary>
                    </CacheProvider>
                  </ThemeProvider>
                </MarketplaceContextProvider>
              </ReduxProvider>
            </ApiRequestProvider>
          </AuthProvider>
        </SessionProvider>
      </PostHogProvider>
    </>
  );
};

export default LomaApp;
