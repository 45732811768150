import {
  AspectRatio,
  Box,
  Stack,
  Text,
  Link,
  Flex,
  FlexProps,
  HStack,
  useTheme,
  useToast,
  useBoolean,
  Checkbox,
  IconButton,
  Grid,
  Button,
  GridItem,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from '@chakra-ui/react';
import React, { useState, ReactNode, CSSProperties, useContext } from 'react';
import { PriceTag, ImageWithFallback, CategoryAttributesDisplay } from 'src/components/atoms';
import {
  BasicAttributeValue,
  Category,
  RootCategory,
  Listing,
  ListingSelected,
  BasicCategory,
} from 'src/api/v1-api';
import NextLink from 'next/link';
import { apiRequest, handleNextRevalidation } from 'src/utils/fetchUtils';
import { categoryTypeHasPrice } from 'src/utils/categoryUtils';
import { formatDateTime, formatPrice } from 'src/utils/common';
import AuctionTimeLeft from 'src/components/atoms/auction-time-left';
import { MdMap, MdOutlineTimer } from 'react-icons/md';
import { get_location_from_listing } from 'src/utils/attributeUtils';
import { calculateTimeSinceListed } from 'src/components/atoms/date-since-added';
import { useRouter } from 'next/router';
import { useAuth } from 'src/utils/auth';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { ChevronDownIcon, EditIcon } from '@chakra-ui/icons';
import { FaChevronDown } from 'react-icons/fa';
import DenyMessageModal from './deny-message-modal';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { determineAuctionInfo, getListingImage } from 'src/utils/listingUtils';
import { markListingAsBroken } from 'src/utils/listingUtils';
import * as Sentry from '@sentry/nextjs';
interface Props {
  listing: Listing;
  category?: BasicCategory | Category | RootCategory | null;
  actionButtons?: string;
  removeListing?: (listingId: number) => void;
  loading: boolean;
  listingSelected?: ListingSelected | undefined;
  handleChangeSelected?: (listingId: number, checked: boolean) => void;
  addListingToDisplayedListings?: () => void;
  useNextLink?: boolean;
  onCategoryEditClick?: (listingId: number | undefined, categoryId: number | undefined) => void;
  flexProps?: FlexProps;
  // sizes?: string;
  columns?: number;
}

interface DynamicLinkProps {
  href: string;
  useNextLink: boolean;
  style?: CSSProperties;
  children: ReactNode;
}

const getSoldOutMessage = (category?: BasicCategory | Category | RootCategory | null) => {
  if (category?.multiple_variants) {
    return 'SOLD OUT';
  }
  return 'SOLD';
};

const filterHiddenAttributes = (listing_attribute_value: BasicAttributeValue[] | undefined) => {
  if (listing_attribute_value) {
    const filteredListingAttribs = listing_attribute_value.filter((value) => {
      // Hide location attribute for now since it is handled separately
      if (value?.attribute?.name === 'Location') {
        return false;
      }

      return value?.attribute?.display_attribute;
    });
    return filteredListingAttribs.sort((a, b) => a.attribute.position - b.attribute.position);
  }
  return [];
};

const ListingCard = ({
  listing,
  category,
  actionButtons,
  removeListing,
  loading,
  listingSelected,
  handleChangeSelected,
  addListingToDisplayedListings,
  useNextLink = true,
  onCategoryEditClick,
  flexProps,
  columns,
}: // sizes,
Props): JSX.Element => {
  const router = useRouter();
  const toast = useToast();
  const { getToken } = useAuth();
  const { marketplace, hostname } = useContext(MarketplaceContext);
  const [showDenyMessageModal, setShowDenyMessageModal] = useBoolean(false);
  const auctionInfo = listing.auction_info ? determineAuctionInfo(listing.auction_info) : undefined;
  const missingAuctionDates = !auctionInfo?.start || !auctionInfo?.end;
  const theme = useTheme();

  const [imageError, setImageError] = useState(false);
  const onImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (listing?.source?.toLowerCase() === 'facebook') {
      setImageError(true);
      addListingToDisplayedListings && addListingToDisplayedListings();
      if (listing?.id && listing?.status !== 'active') {
        markListingAsBroken(listing.id);
      }
    }

    if (listing?.id && listing?.status === 'active') {
      const imgElement = event.target as HTMLImageElement;

      // Check if the error was due to abortion
      const wasAborted = !imgElement.complete && imgElement.naturalWidth === 0;

      Sentry.captureException(new Error('Image error - Listing Card'), {
        extra: {
          listingId: listing?.id,
          listingTitle: listing?.title,
          listingSource: listing?.source,
          imageUrl: getListingImage(listing?.listing_images),
          timestamp: new Date().toISOString(),
          loadingState: {
            wasAborted,
            complete: imgElement.complete,
            naturalWidth: imgElement.naturalWidth,
            naturalHeight: imgElement.naturalHeight,
            loadingTime: Date.now() - Number(imgElement.dataset.loadStartTime || Date.now()),
          },
        },
        tags: {
          source: listing?.source?.toLowerCase() || 'unknown',
          errorType: 'image_load_failure',
          errorReason: wasAborted ? 'aborted' : 'failed',
        },
      });
    }
  };

  const featuredImage = getListingImage(listing?.listing_images) ?? '';
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isApproving, setIsApproving] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDenying, setIsDenying] = React.useState(false);
  const [cardSelected, setCardSelected] = useState(listingSelected?.isSelected || false);

  const handleDelete = async (listingId: number) => {
    const confirmed = confirm('Are you sure you want to delete this listing?');
    if (!confirmed) return;

    setIsDeleting(true);
    const token = await getToken();
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${listingId}/delete/`;
    try {
      await apiRequest('DELETE', url, token);

      try {
        await handleNextRevalidation(`/_sites/${hostname}/listing/${listingId}`);
      } catch (error) {
        console.error(error);
      }

      toast({
        title: 'Listing deleted',
        description: 'Your listing has been deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      if (removeListing) removeListing(listingId);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Unable to delete listing.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleApprove = async (listingId: number) => {
    const token = await getToken();
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${listingId}/${marketplace?.id}/approve/`;
    try {
      setIsApproving(true);
      await apiRequest('POST', url, token);

      try {
        await handleNextRevalidation(`/_sites/${hostname}/listing/${listingId}`);
      } catch (error) {
        console.error(error);
      }
      setIsApproving(false);
      toast({
        title: 'Listing approved',
        description: 'The listing will now be visible',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      router.reload();
    } catch (error) {
      setIsApproving(false);
      toast({
        title: 'Error',
        description: 'Unable to approve listing.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeny = async (listingId: number, rejectMessage = '') => {
    const token = await getToken();
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${listingId}/${marketplace?.id}/deny/`;
    try {
      setIsDenying(true);
      const body = { reject_message: rejectMessage };
      await apiRequest('POST', url, token, body);

      try {
        await handleNextRevalidation(`/_sites/${hostname}/listing/${listingId}`);
      } catch (error) {
        console.error(error);
      }

      setIsDenying(false);
      toast({
        title: 'Listing denied',
        description: 'The listing will remain hidden.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      router.reload();
    } catch (error) {
      setIsDenying(false);
      toast({
        title: 'Error',
        description: 'Unable to deny listing, it will remain pending.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const DynamicLink = ({ href, useNextLink, style, children }: DynamicLinkProps) => {
    if (useNextLink) {
      return (
        <NextLink href={href} style={style}>
          {children}
        </NextLink>
      );
    } else {
      return (
        <Link href={href} style={style}>
          {children}
        </Link>
      );
    }
  };

  const LinkWrapper = ({ children }: { children: ReactNode }) => {
    return (
      <DynamicLink
        href={`/listing/${listing.id}`}
        useNextLink={useNextLink}
        style={{ display: 'flex', flexGrow: 1 }}
      >
        {children}
      </DynamicLink>
    );
  };

  const BoxWrapper = ({ children }: { children: ReactNode }) => {
    return <Box style={{ display: 'flex', flexGrow: 1 }}>{children}</Box>;
  };
  if (imageError) {
    return <></>;
  }
  const pendingListings = actionButtons && actionButtons === 'pendingListings';

  let CardWrapper = LinkWrapper;
  if (pendingListings) {
    CardWrapper = BoxWrapper;
  }

  const categoryEditClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onCategoryEditClick && onCategoryEditClick(listing?.id, listing?.category_id);
  };

  const location = get_location_from_listing(listing);

  let timeSinceListed;
  if (listing?.created_at) {
    timeSinceListed = calculateTimeSinceListed(listing?.listed_at ?? listing.created_at);
  }

  let source;
  if (listing.source) {
    source = listing.source;
  }

  return (
    <Flex
      direction="column"
      overflow="hidden"
      {...flexProps}
      data-test="listing-card"
      data-test-id={`${listing.id}`}
    >
      {actionButtons && actionButtons === 'pendingListings' && (
        <Checkbox
          bgColor="white"
          zIndex={2}
          position={'absolute'}
          marginLeft={{ base: 2, md: '9.3rem' }}
          marginTop={'0.4rem'}
          isDisabled={loading}
          isChecked={cardSelected}
          cursor={'default'}
          size="lg"
          onChange={() => {
            setCardSelected(!cardSelected);
            handleChangeSelected && handleChangeSelected(listing.id, !listingSelected?.isSelected);
          }}
        ></Checkbox>
      )}
      <CardWrapper>
        <Stack
          spacing={{ base: '4', md: '5' }}
          justifyContent="space-between"
          style={{ flexGrow: 1, minWidth: 0 }}
        >
          <Box
            position="relative"
            onClick={() => {
              setCardSelected(!cardSelected);
              handleChangeSelected &&
                handleChangeSelected(listing.id, !listingSelected?.isSelected);
            }}
          >
            <AspectRatio ratio={8 / 7}>
              <Box borderRadius={{ base: theme.border_radius.border_radius_1 }} overflow="hidden">
                <ImageWithFallback
                  key={`${listing.id}_${featuredImage}`}
                  src={featuredImage}
                  columns={columns}
                  alt={listing?.title ?? 'Listing Image'}
                  onError={onImageError}
                  onLoadStart={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    (e.target as HTMLImageElement).dataset.loadStartTime = Date.now().toString();
                  }}
                />
              </Box>
            </AspectRatio>

            {/* Sold out tag */}
            {listing.status === 'sold' && (
              <Box
                position="absolute"
                top={3}
                left={3}
                px={2}
                py={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                background="red"
              >
                <Text color="white" fontWeight="bold">
                  {getSoldOutMessage(category)}
                </Text>
              </Box>
            )}
          </Box>
          <Stack height="full">
            <Stack height="full">
              {actionButtons && (
                <Flex alignItems="center" gap="2">
                  <Text fontSize="xs" color="grey" fontWeight="semibold">
                    {listing?.category_name?.toUpperCase()}
                  </Text>
                  <IconButton
                    aria-label="Edit Category Name"
                    icon={<EditIcon />}
                    size="xs"
                    onClick={categoryEditClickHandler}
                  />
                </Flex>
              )}
              <Text
                fontWeight="400"
                noOfLines={2}
                pb={0}
                fontFamily={theme.fonts.font_2}
                fontSize="18px"
                lineHeight="1.5"
                minHeight="54px"
                data-test="card-listing-title"
              >
                {listing.title}
              </Text>
              {categoryTypeHasPrice(listing?.category_type) &&
                (listing.lowest_regular_price || listing.lowest_offered_price) && (
                  <Box pb={0}>
                    <PriceTag
                      regularPrice={listing.lowest_regular_price}
                      offeredPrice={listing.lowest_offered_price}
                      currency={listing.currency}
                      regularPriceProps={{
                        fontSize: 18,
                        fontWeight: 700,
                        fontFamily: theme.fonts.font_2,
                      }}
                      offeredPriceProps={{
                        fontSize: 18,
                        fontWeight: 700,
                        fontFamily: theme.fonts.font_2,
                      }}
                    />
                  </Box>
                )}
              {category?.functional.show_listing_description &&
                listing.source?.toLowerCase() === 'loma' && (
                  <Text
                    fontWeight="400"
                    noOfLines={2}
                    fontSize="14px"
                    fontFamily={theme.fonts.font_2}
                    data-test="card-listing-description"
                  >
                    {listing.description}
                  </Text>
                )}

              {listing?.category_type === 'auction' && (
                <Box mt="auto !important">
                  {auctionInfo?.max_bid?.max_bid_amount ? (
                    <>
                      <Text as="span" fontWeight="medium">
                        {formatPrice(auctionInfo.max_bid.max_bid_amount, {
                          currency: listing.currency,
                          alwaysCents: false,
                        })}
                      </Text>
                      <Text as="span" fontSize="sm">
                        &nbsp;&mdash; current bid
                      </Text>
                    </>
                  ) : (
                    <Text as="span" fontWeight="light">
                      no bids yet
                    </Text>
                  )}
                  <AuctionTimeLeft auctionEndStr={auctionInfo?.end} />
                </Box>
              )}
              <CategoryAttributesDisplay
                attributeValues={filterHiddenAttributes(listing?.listing_attribute_values)}
              />
              <Box pt={2}>
                {location && (
                  <HStack pb={1}>
                    <MdMap />
                    <Text fontSize="12px" fontFamily={theme.fonts.font_2}>
                      {location}
                    </Text>
                  </HStack>
                )}
                {timeSinceListed && (
                  <HStack pb={1}>
                    <MdOutlineTimer />
                    <Text fontSize="12px" fontFamily={theme.fonts.font_2}>
                      {timeSinceListed}
                    </Text>
                  </HStack>
                )}
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </CardWrapper>
      {actionButtons && actionButtons === 'myListings' && (
        <Box mt="4">
          <ButtonGroup size="sm" w="100%" display={'flex'} flexDirection={'row'}>
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={FiEdit} />}
              onClick={() => router.push(`/listing/edit/${listing.id}`)}
              width="75%"
            >
              Edit
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleDelete(listing.id)}
              isLoading={isDeleting}
            >
              <Icon as={FiTrash} />
            </Button>
          </ButtonGroup>
        </Box>
      )}
      {actionButtons && actionButtons === 'pendingListings' && (
        <Box>
          {auctionInfo?.status === 'pending_admin_approval' ? (
            <>
              <Flex>
                <Text mt={4} fontSize="0.7rem">
                  Start:{' '}
                  {auctionInfo?.startLocal ? formatDateTime(auctionInfo.startLocal) : 'Need to add'}
                </Text>
              </Flex>
              <Flex>
                <Text fontSize="0.7rem">
                  End:{' '}
                  {auctionInfo?.endLocal ? formatDateTime(auctionInfo.endLocal) : 'Need to add'}
                </Text>
              </Flex>
              <Flex>
                <Button
                  mt={4}
                  colorScheme="gray"
                  as={NextLink}
                  href={`/listing/edit/${listing.id}?useClient=true`}
                  target="_blank"
                  isLoading={isLoading}
                  size="sm"
                  w="full"
                >
                  Modify Auction
                </Button>
              </Flex>
            </>
          ) : (
            <Flex>
              <Button
                mt={4}
                colorScheme="blue"
                as={NextLink}
                href={`/listing/${listing.id}?useClient=true`}
                target="_blank"
                isLoading={isLoading}
                size="sm"
                w="full"
              >
                View
              </Button>
            </Flex>
          )}
          <Box mt="2">
            <Grid templateColumns="repeat(5, 1fr)" justifyContent="space-between" gap={1}>
              <GridItem colSpan={listing?.third_party_query_id ? 4 : 5}>
                <ButtonGroup size="sm" isAttached w="full">
                  <Button
                    colorScheme="green"
                    isLoading={isApproving}
                    onClick={() => handleApprove(listing.id)}
                    w={listing?.third_party_query_id ? '50%' : 'full'}
                    isDisabled={!!auctionInfo?.id && missingAuctionDates}
                    data-test="listing-approve-btn"
                  >
                    Approve
                  </Button>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      colorScheme="red"
                      w={listing?.third_party_query_id ? '52%' : 'full'}
                      isLoading={isDenying}
                      padding="6px"
                    >
                      Deny
                    </MenuButton>
                    <MenuList zIndex={4}>
                      <MenuItem onClick={() => handleDeny(listing.id)}>Deny</MenuItem>
                      {listing.source === 'loma' && (
                        <MenuItem onClick={setShowDenyMessageModal.on}>Deny with Message</MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </ButtonGroup>
              </GridItem>
              {listing?.third_party_query_id && (
                <GridItem colSpan={1}>
                  <Menu>
                    <MenuButton as={IconButton} icon={<FaChevronDown />} size="sm" />
                    <MenuList zIndex={3}>
                      <MenuItem
                        as={NextLink}
                        href={`/admin/queries/edit/${listing.third_party_query_id}`}
                      >
                        Saved Search
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </GridItem>
              )}
            </Grid>
          </Box>
        </Box>
      )}
      <DenyMessageModal
        isOpen={showDenyMessageModal}
        onClose={setShowDenyMessageModal.off}
        listingId={listing.id}
        handleDeny={handleDeny}
      />
    </Flex>
  );
};

export default ListingCard;
