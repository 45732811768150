import {
  Container,
  Text,
  Heading,
  Flex,
  Divider,
  Button,
  Input,
  Grid,
  GridItem,
  Textarea,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  Select,
  useToast,
  FormControl,
  FormLabel,
  Alert,
  AlertIcon,
  Stack,
  HStack,
  VStack,
  InputGroup,
  InputLeftAddon,
  Spinner,
  NumberInput,
  NumberInputField,
  useTheme,
} from '@chakra-ui/react';
import { FiEdit2 } from 'react-icons/fi';
import { CONTAINER_MAX_WIDTH_1, CONTAINER_MAX_WIDTH_2 } from 'src/constants/ui';
import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import {
  BasicCategory,
  MarketplaceAccount,
  Variant,
  ListingImage,
  BasicAttributeValue,
  ShippingInfo,
  ParcelInfo,
  defaultShippingInfo,
  defaultParcelInfo,
  defaultAuctionInfo,
  FulfillmentType,
  FulfillmentOption,
  ExternalListingInfo,
  Currency,
  FlatAttributeValuesType,
  ValueObject,
  AttributeOption,
  Listing,
  AuctionInfo,
} from 'src/api/v1-api';
import {
  AddressInput,
  AttributeInput,
  ListingFormSubmit,
  LoginPrompt,
  ErrorMessageBox,
} from 'src/components/atoms';
import { VariantForm, ListingImport } from 'src/components/organisms';
import { apiRequest, handleNextRevalidation } from 'src/utils/fetchUtils';
import { useAuth } from 'src/utils/auth';
import { useRouter } from 'next/router';
import { MarketplaceContext } from 'src/contexts/marketplace';
import {
  CategorySelector,
  CreateListingSubCategorySelector,
  ListingLocationForm,
  PriceCard,
  SiblingToUpdateCard,
  ListingImageEditor,
  ISoldThis,
  ParcelInfoForm,
} from 'src/components/molecules';
import ListingFeaturedForm from 'src/components/molecules/listing-featured-form';
import NextLink from 'next/link';
import { capitalizeFirstLetter, utcISOToLocalDate } from 'src/utils/common';
import { CATEGORY_TYPE_MARKETPLACE, MINIMUM_PRICE_ALLOWED } from 'src/constants/marketplace';
import { checkAttributeValidity, flattenAttributeValues } from 'src/utils/attributeUtils';
import { isListingActiveOrAfter, validateAddress } from 'src/utils/listingUtils';
import { categoryTypeHasPrice, fetchCategoryPermissionsRoles } from 'src/utils/categoryUtils';
import { parse, format, parseISO } from 'date-fns';
import ListingAuctionForm from 'src/components/molecules/listing-auction-form';
import { isEmpty } from 'lodash';
import AttributeValueCaptureModal from '../attribute-value-capture-modal';

export type FormInputValues = {
  id: number | null;
  title: string;
  category: BasicCategory | null;
  description: string;
  listing_attribute_values: BasicAttributeValue[];
  listing_images: ListingImage[];
  marketplace_account: MarketplaceAccount | null;
  status: string;
  variants: Variant[];
  shipping_info: ShippingInfo;
  parcel_info: ParcelInfo;
  listing_fulfillment_options: FulfillmentOption[];
  is_featured: boolean;
  external_url?: string | number | readonly string[] | undefined;
  source?: string | number | readonly string[] | undefined;
  external_listing_info?: ExternalListingInfo;
  currency: Currency;
  listed_at?: string;
  auction_info: AuctionInfo;
};

export type FormValues = {
  id: number | null;
  title: string;
  category: BasicCategory | null;
  description: string;
  listing_attribute_values: FlatAttributeValuesType;
  listing_images: ListingImage[];
  status: string;
  variants: Variant[];
  shipping_info: ShippingInfo;
  parcel_info: ParcelInfo;
  is_featured: boolean;
  listed_at: string;
  auction_info: AuctionInfo;
};

export type FlatVariant = {
  id?: number;
  regular_price?: string;
  offered_price?: string;
  is_active?: boolean;
  limited_stock?: boolean;
  quantity?: number;
  variant_attribute_values?: FlatAttributeValuesType;
};

type ListingFormProps = {
  listing: FormInputValues | null;
  writingMode?: 'Add' | 'Edit';
  categoryId?: string;
  updateListing?: (listing: FormInputValues | Listing) => void;
};

export interface BasicInfoType extends Record<string, string | number | string[] | boolean> {
  title: string;
  description: string;
}

export interface FileWithPosition {
  file: File;
  position: number;
  preview?: string;
}

export interface FilePath {
  filePath?: string;
  position: number;
  value?: string;
}

const ListingForm = ({
  listing,
  writingMode = 'Add',
  categoryId,
  updateListing,
}: ListingFormProps) => {
  const router = useRouter();
  const toast = useToast();
  const { getToken, isAuthenticated, authLoading, hasAdmin, hasStaff, hasRole, hasRoles, user } =
    useAuth();
  const { marketplace, hostname } = useContext(MarketplaceContext);
  const toastShownRef = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isAdmin = hasAdmin(marketplace?.id);
  const isLomaAdmin = hasRole(marketplace?.id?.toString() ?? '', 'loma_admin');
  const isStaff = hasStaff();
  const isStaffOrAdmin = isAdmin || isStaff;

  useEffect(() => {
    if (router.isReady && !toastShownRef.current) {
      if (router.query.success === 'true') {
        toast({
          title: 'Success',
          description: router.query.message as string,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else if (router.query.success === 'false') {
        toast({
          title: 'Error',
          description: router.query.message as string,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      toastShownRef.current = true;
    }
  }, [router.isReady, router.query]);

  const [listingId, setListingId] = useState<number | null>(listing?.id ?? null);
  const [title, setTitle] = useState<string>(listing?.title ?? '');
  const [description, setDescription] = useState<string>(listing?.description ?? '');
  const [selectedCategory, setSelectedCategory] = useState<BasicCategory | null>(
    listing?.category || null,
  );
  const takeRate = selectedCategory?.take_rate ? parseFloat(selectedCategory.take_rate) : 0.08;
  const status = listing?.status ?? 'draft';

  const flattenedListingAttributeValues: FlatAttributeValuesType = flattenAttributeValues(
    listing?.listing_attribute_values || [],
  );
  const [listingAttributeValues, setListingAttributeValues] = useState<FlatAttributeValuesType>(
    flattenedListingAttributeValues,
  );

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [submitInfoLoading, setSubmitInfoLoading] = useState<boolean>(false);
  const [submitDraftLoading, setSubmitDraftLoading] = useState<boolean>(false);
  const isLiveAuction =
    selectedCategory?.type === 'auction' && isListingActiveOrAfter(listing?.status);
  const disallowUserEdits = isLiveAuction && !isStaffOrAdmin;

  const flattenedVariants: FlatVariant[] | undefined = listing?.variants.map((variant) => {
    let flattenedVariantAttributeValues: FlatAttributeValuesType = {};
    if (variant.variant_attribute_values) {
      flattenedVariantAttributeValues = flattenAttributeValues(variant.variant_attribute_values);
    }
    return { ...variant, variant_attribute_values: flattenedVariantAttributeValues };
  });

  const [variants, setVariants] = useState<FlatVariant[]>(flattenedVariants || []);
  const [currentVariant, setCurrentVariant] = useState<FlatVariant | null>(null);
  // Check if the selected category has variant attributes to determine if the listing can be multi-variant
  const isMultiVariant =
    selectedCategory?.multiple_variants &&
    selectedCategory.attributes.filter((attr) => attr.nested_content_type_name == 'Variant')
      .length > 0;
  if (!isMultiVariant && variants.length > 0 && !currentVariant) {
    setCurrentVariant(variants[0]);
  }

  const [shippingInfo, setShippingInfo] = useState<ShippingInfo | null>(
    listing?.shipping_info || defaultShippingInfo,
  );
  const [parcelInfo, setParcelInfo] = useState<ParcelInfo>(
    listing?.parcel_info || defaultParcelInfo,
  );
  const locationAttribute = listing?.listing_attribute_values.filter(
    (attr) => attr.attribute.name == 'Location',
  );
  const [location, setLocation] = useState<ShippingInfo | null>(
    locationAttribute && locationAttribute.length > 0
      ? (locationAttribute[0].value_object as ShippingInfo)
      : null,
  );
  const [hasLocation, setHasLocation] = useState<boolean>(!!location);
  const [sameAsShippingInfo, setSameAsShippingInfo] = useState<boolean>(false);

  const [filePaths, setFilePaths] = useState<FilePath[]>([]);
  const [imageUploading, setImageUploading] = useState(false);
  const [selectedChild, setSelectedChild] = useState<BasicCategory | null>(null);
  const [isPayToList, setIsPayToList] = useState<boolean>(false);
  const [payToListChildren, setPayToListChildren] = useState<BasicCategory[]>([]);
  const [payToListParent, setPayToListParent] = useState<BasicCategory | null>(null);
  const [parentCategory, setParentCategory] = useState<BasicCategory | null>(null);
  const [isFeatured, setIsFeatured] = useState(listing?.is_featured ?? false);
  const [externalUrl, setExternalUrl] = useState<string | number | readonly string[] | undefined>(
    listing?.external_url,
  );
  const [source, setSource] = useState<string | number | readonly string[] | undefined>(
    listing?.source,
  );
  const [listerHandle, setListerHandle] = useState<string | number | readonly string[] | undefined>(
    listing?.external_listing_info?.lister_handle,
  );
  const [currency, setCurrency] = useState<string>(listing?.currency ?? Currency.USD);
  const listingImages = listing?.listing_images || [];
  const [hasPermission, setHasPermission] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [listedAt, setListedAt] = useState<string>('');
  const [auctionInfo, setAuctionInfo] = useState<AuctionInfo>(
    listing?.auction_info || defaultAuctionInfo,
  );
  const theme = useTheme();
  const now = new Date();
  const auctionEnd =
    !!listing?.auction_info?.id && listing?.auction_info?.end
      ? utcISOToLocalDate(listing.auction_info.end)
      : null;
  const isNoAuctionOrEnded =
    !listing?.auction_info?.id ||
    (listing?.auction_info?.status === 'admin_approved' && auctionEnd && now > auctionEnd);

  useEffect(() => {
    if (listing?.listing_images) {
      // no CDN here, likely unwanted
      const filePaths = listing?.listing_images
        .map((image) => ({
          filePath: image.image,
          position: image.position,
        }))
        .sort((a, b) => a.position - b.position);
      setFilePaths(filePaths);
    }
  }, [listing?.listing_images]);

  useEffect(() => {
    try {
      if (listing?.listed_at) {
        const date = parseISO(listing?.listed_at);
        setListedAt(format(date, 'M/d/yyyy, h:mm:ss a'));
      }
    } catch (error) {
      console.error(error);
    }
  }, [listing?.listed_at]);

  const formatDate = () => {
    try {
      const inputFormat = 'M/d/yyyy, h:mm:ss a';
      const parsedDate = parse(listedAt, inputFormat, new Date());
      const isoFormatDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
      return isoFormatDate;
    } catch (error) {
      return '';
    }
  };

  const currentCategoryId = selectedCategory?.id ? selectedCategory.id : parseInt(categoryId ?? '');

  const handleAttributeChange = (
    attributeId: number | string,
    value: string | number | boolean | ValueObject | FilePath[] | AttributeOption[],
  ) => {
    setListingAttributeValues((prevValues) => {
      return { ...prevValues, [attributeId]: value };
    });
  };

  const isValueInvalid = (
    value:
      | string
      | number
      | boolean
      | FilePath
      | FilePath[]
      | ValueObject
      | ValueObject[]
      | undefined,
  ) => {
    if (value === undefined) return true;
    if (Array.isArray(value) && value.length == 0) return true;
    if (value === '') return true;
    return false;
  };

  const validateVariant = () => {
    if (!currentVariant) return false;

    if (!currentVariant.quantity || currentVariant.quantity < 0) {
      // default to quantity of 1 if none entered
      currentVariant.quantity = 1;
    }

    if (
      !currentVariant.offered_price ||
      parseFloat(currentVariant.offered_price) < MINIMUM_PRICE_ALLOWED
    ) {
      toast({
        title: `Variant offered price must be more than \$${MINIMUM_PRICE_ALLOWED}.`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
      return false;
    }

    const variantAttributes =
      selectedCategory?.attributes
        ?.filter((attr) => attr.nested_content_type_name === 'Variant')
        ?.sort((a, b) => a.position - b.position) ?? [];
    for (const variantAttribute of variantAttributes) {
      const attributeValue = currentVariant?.variant_attribute_values?.[variantAttribute.id];
      if (variantAttribute.required && isValueInvalid(attributeValue)) {
        toast({
          title: `"${variantAttribute.name}" must have a value.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
        return false;
      }
    }
    return true;
  };

  const handleAddVariantSubmit = () => {
    if (validateVariant()) {
      setVariants((prevValues) => [
        ...prevValues,
        { ...currentVariant, id: prevValues.length + 1 },
      ]);
      setCurrentVariant(null);
    }
  };

  const handleEditVariantSubmit = (id: number | undefined) => {
    if (!id) return;
    if (validateVariant()) {
      setVariants((prevValues) => [
        ...prevValues.filter((val) => val.id != id),
        { ...currentVariant, id },
      ]);
    }
  };

  const deleteVariant = () => {
    if (!currentVariant?.id) return;
    setVariants((prevValues) => prevValues.filter((val) => val.id != currentVariant?.id));
    setCurrentVariant(null);
  };

  const encodeAuctionInfo = (auctionInfo: AuctionInfo) => {
    auctionInfo.startLocal = undefined;
    auctionInfo.endLocal = undefined;
    return JSON.stringify(auctionInfo);
  };

  const showSubmitError = (error: string) => {
    toast({
      title: 'Error.',
      description: error || 'Something went wrong. Please try again.',
      status: 'error',
      duration: 9000,
      isClosable: true,
      position: 'bottom',
    });
    setSubmitLoading(false);
  };

  const checkFormValidity = async () => {
    let isValid = true;
    let warningMessage = '';
    let addressErrorMessage = '';
    if (!selectedCategory) {
      warningMessage = 'Please select a category.';
      isValid = false;
    }
    const error_message = checkAttributeValidity(
      selectedCategory?.attributes,
      listingAttributeValues,
    );
    if (error_message !== '') {
      warningMessage = error_message;
      isValid = false;
    }

    if (!description) {
      warningMessage = 'Please enter a description.';
      isValid = false;
    }

    if (selectedCategory && categoryTypeHasPrice(selectedCategory.type) && variants.length === 0) {
      if (!currentVariant) {
        warningMessage = 'Please add a price.';
        isValid = false;
      } else if (!validateVariant()) {
        return false;
      }
    }

    if (
      selectedCategory &&
      categoryTypeHasPrice(selectedCategory.type) &&
      parseFloat(currentVariant?.offered_price ?? '0') <= 0
    ) {
      warningMessage = 'Offered price must be positive.';
      isValid = false;
    }

    if (fulfillmentPrice && parseFloat(fulfillmentPrice) < 0) {
      warningMessage = 'Shipping price must be positive.';
      isValid = false;
    }

    if (!selectedFulfillment && selectedCategory?.type === CATEGORY_TYPE_MARKETPLACE) {
      // Skip the fulfillment check for external listings
      if (!externalUrl) {
        warningMessage = 'Please select a fulfillment option.';
        isValid = false;
      }
    }

    // TODO(Sean): Revisit this logic for requiring shipping info
    if (
      selectedFulfillment &&
      selectedFulfillment?.price_type === 'dynamic_api' &&
      addressErrorMessage === '' &&
      !externalUrl
    ) {
      let hasAllAddressInfo = true;
      if (
        !shippingInfo?.name ||
        !shippingInfo?.phone ||
        !shippingInfo?.street_address1 ||
        !shippingInfo?.city ||
        !shippingInfo?.state ||
        !shippingInfo?.zip_code ||
        !shippingInfo?.country
      ) {
        warningMessage = 'Please enter all shipping info.';
        hasAllAddressInfo = false;
        isValid = false;
      }

      if (hasAllAddressInfo) {
        addressErrorMessage = await validateAddress(shippingInfo);

        if (addressErrorMessage !== '') {
          isValid = false;
          warningMessage = addressErrorMessage;
        }
      }

      if (
        !parcelInfo ||
        parcelInfo.length <= 0.0 ||
        parcelInfo.width <= 0.0 ||
        parcelInfo.height <= 0.0 ||
        parcelInfo.weight <= 0.0
      ) {
        warningMessage = 'Please enter valid parcel info.';
        isValid = false;
      }
    }
    if (
      selectedCategory?.functional.require_location &&
      !(location?.city && location?.state) &&
      locationAttribute &&
      locationAttribute.length > 0
    ) {
      warningMessage = 'Please enter city and state.';
      isValid = false;
    }

    if (!paymentInfoFound && selectedCategory?.type === CATEGORY_TYPE_MARKETPLACE && !externalUrl) {
      warningMessage =
        'Payment information not found. Please go to your account information page to fill out payout information.';
      isValid = false;
    }

    if (
      listingImages.length === 0 &&
      filePaths.length === 0 &&
      (selectedCategory?.functional.requires_payment || selectedCategory?.has_promo)
    ) {
      isValid = false;
      warningMessage = 'Please upload at least one image or video in order to publish your listing';
    }

    if (listedAt !== '' && formatDate() === '') {
      isValid = false;
      warningMessage = 'Please use a valid Listed Date Time';
    }

    if (
      auctionInfo &&
      auctionInfo.start &&
      auctionInfo.end &&
      new Date(auctionInfo.start) >= new Date(auctionInfo.end)
    ) {
      isValid = false;
      warningMessage = `Auction start date must be before end date.`;
    }

    if (!isValid) {
      toast({
        title: warningMessage,
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }

    return isValid;
  };

  const determineListingStatus = (action: string, status: string | undefined) => {
    let listingStatus = null;
    if (action === 'submitDraft') {
      listingStatus = 'draft';
    } else if (action === 'publish') {
      if (
        selectedCategory?.functional.requires_payment &&
        selectedCategory?.functional.requires_approval
      ) {
        // if approval and payment needed, approval will happen first
        if (status !== 'active' && status !== 'pending_payment') {
          listingStatus = 'pending';
        } else if (status !== 'active') {
          listingStatus = 'pending_payment';
        }
        // Check if the category requires payment
      } else if (selectedCategory?.functional.requires_payment) {
        // If the listing has been paid for, keep its current status when editing
        if (!(status === 'active' || status === 'pending')) {
          listingStatus = 'pending_payment';
        }
        // Check if the category requires approval
      } else if (selectedCategory?.functional.requires_approval && status !== 'active') {
        listingStatus = 'pending';
        // Set the status to pending by default for Loma Admins, keep current status when editing
      } else if (isLomaAdmin) {
        if (!(status === 'active' || status === 'pending')) {
          listingStatus = 'pending';
        }
      } else {
        listingStatus = 'active';
      }
    }
    return listingStatus;
  };

  // @ts-ignore
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // submitting the form will have the action in the first location, saving draft in the other
    const action = e.nativeEvent?.submitter?.dataset?.action ?? e.target.dataset.action;
    if (action === 'publish') {
      setSubmitLoading(true);
      const validForm = await checkFormValidity();
      if (!validForm) {
        setSubmitLoading(false);
        return;
      }
    } else if (action === 'submitDraft') {
      setSubmitDraftLoading(true);
    } else {
      setSubmitInfoLoading(true);
      if (['active', 'pending', 'pending_payment'].includes(listing?.status ?? '')) {
        const validForm = await checkFormValidity();
        if (!validForm) {
          setSubmitInfoLoading(false);
          return;
        }
      }
    }

    const token = await getToken();

    let submitVariants = [];
    if (!isMultiVariant && currentVariant) {
      submitVariants = [currentVariant];
    } else {
      submitVariants = variants;
    }

    const formData = new FormData();

    // const fileNameToPositionMapping: { [fileName: string]: number } = {};
    // files.forEach((fileWithPosition) => {
    //   formData.append('listing_images', fileWithPosition.file);
    //   fileNameToPositionMapping[fileWithPosition.file.name] = fileWithPosition.position;
    // });
    // formData.append('file_name_to_position_mapping', JSON.stringify(fileNameToPositionMapping));

    if (listingId) {
      formData.append('id', listingId.toString());
    }
    if (selectedCategory) {
      formData.append('category', selectedCategory.id.toString());
    }
    formData.append('title', title);
    formData.append('description', description);
    formData.append('listing_attribute_values', JSON.stringify(listingAttributeValues));
    formData.append('existing_listing_images', JSON.stringify(listingImages));
    formData.append('currency', currency);

    if (variants.length === 0 && currentVariant) {
      formData.append('variants', JSON.stringify([currentVariant]));
    } else if (variants.length > 0) {
      formData.append('variants', JSON.stringify(submitVariants));
    }

    formData.append('shipping_info', JSON.stringify(shippingInfo));
    formData.append('parcel_info', JSON.stringify(parcelInfo));
    if (hasLocation || selectedCategory?.functional.require_location) {
      if (sameAsShippingInfo) {
        formData.append('location', JSON.stringify(shippingInfo));
      } else {
        formData.append('location', JSON.stringify(location));
      }
    } else {
      formData.append('location', JSON.stringify({}));
    }

    if (selectedFulfillment) {
      formData.append('selected_fulfillment_type', JSON.stringify(selectedFulfillment));
      formData.append('fulfillment_price', fulfillmentPrice || '');
    }

    const listingStatus = determineListingStatus(action, listing?.status);
    if (listingStatus) {
      formData.append('status', listingStatus);
    } else {
      formData.append('status', status);
    }

    if (isStaffOrAdmin) {
      formData.append('is_featured', String(isFeatured));
    }

    if (listerHandle) {
      formData.append('external_listing_info', JSON.stringify({ lister_handle: listerHandle }));
    }
    if (externalUrl) {
      formData.append('external_url', String(externalUrl));
    }
    if (source) {
      formData.append('source', String(source));
    }
    if (filePaths.length > 0) {
      formData.append('file_paths', JSON.stringify(filePaths));
    }
    if (listedAt !== '') {
      formData.append('listed_at', formatDate());
    }
    if (selectedCategory?.type === 'auction') {
      formData.append('auction_info', encodeAuctionInfo(auctionInfo));
    }

    let response;
    if (!listingId) {
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/create/`;
      try {
        response = await apiRequest('POST', url, token, formData, true);
        setListingId(response.id);
      } catch (err: any) {
        showSubmitError(err.message);
        setSubmitLoading(false);
        setSubmitDraftLoading(false);
        setSubmitInfoLoading(false);
        return;
      }
    } else {
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${listingId}/${marketplace?.id}/update/`;
      try {
        response = await apiRequest('PUT', url, token, formData, true);
      } catch (err: any) {
        console.error(err.message);
        showSubmitError(err.message);
        setSubmitLoading(false);
        setSubmitDraftLoading(false);
        setSubmitInfoLoading(false);
        return;
      }
    }

    if (listingId) {
      try {
        // We need to revalidate the listing page with Next after a listing is edited
        await handleNextRevalidation(`/_sites/${hostname}/listing/${listingId}`);
      } catch (error) {
        console.error('Error during revalidation:', error);
      }
    }

    if (action === 'saveInfo') {
      toast({
        title: 'Info Saved',
        description: `Successfully saved listing info.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
      setSubmitInfoLoading(false);
    } else {
      const routerListingId = listingId ? listingId : response.id;
      const forceDraft = response && response.force_draft ? '&forceDraft=true' : '';
      // Use 'window' instead of router to force a page refresh after Next revalidation
      window.location.href = `/listing/${routerListingId}/?useClient=true${forceDraft}`;
    }
  };

  const [selectedFulfillment, setSelectedFulfillment] = useState<FulfillmentType | null>(
    listing?.listing_fulfillment_options?.length &&
      listing?.listing_fulfillment_options?.length > 0 &&
      listing?.listing_fulfillment_options[0].fulfillment_type
      ? listing?.listing_fulfillment_options[0].fulfillment_type
      : null,
  );
  const [fulfillmentPrice, setFulfillmentPrice] = useState<string | undefined>(
    listing?.listing_fulfillment_options?.length &&
      listing?.listing_fulfillment_options?.length > 0 &&
      listing?.listing_fulfillment_options[0].price
      ? listing?.listing_fulfillment_options[0].price
      : undefined,
  );

  const payToList = selectedCategory?.functional.pay_to_list;
  const payToListExists = payToList ? Object.keys(payToList).length !== 0 : false;
  const categorySelectorDisabled = payToListExists ? true : false;

  const flagIneligibleCategories = (categories: BasicCategory[]): BasicCategory[] => {
    if (writingMode === 'Add') {
      return categories.map((category) => ({
        ...category,
        isDisabled: !category.functional.can_create_listings,
        isVisible:
          category.functional.create_listing_category_select_format !== 'pay_to_list_parent' &&
          category.functional.create_listing_category_select_format !== 'pay_to_list_child',
      }));
    }
    return categories;
  };

  const [renderCreateListing, setRenderCreateListing] = useState(false);
  useEffect(() => {
    const fetchCategoryInformation = async (marketplaceId: number) => {
      // TODO: returns CategoryInList[] not BasicCategory[]
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/marketplaces/${marketplaceId}/categories/`;
      try {
        const response = await apiRequest('GET', url, null);
        const categoryArray = flagIneligibleCategories(response);
        if (currentCategoryId && categoryArray.length > 0) {
          const foundCategory = categoryArray.find((category) => category.id == currentCategoryId);
          const select_format = foundCategory?.functional.create_listing_category_select_format;
          const isPTL =
            select_format === 'pay_to_list_parent' || select_format === 'pay_to_list_child';
          let children: BasicCategory[] = [];

          if (!isPTL) {
            const parentId = foundCategory?.parent;
            const categoryParent = categoryArray.find(
              (category) => category.id == parseInt(parentId?.toString() || ''),
            );
            setParentCategory(categoryParent || null);
          }

          if (
            foundCategory &&
            select_format === 'pay_to_list_parent' &&
            !foundCategory?.children?.length
          ) {
            children = categoryArray.filter((cat) => cat.parent === currentCategoryId);
            foundCategory.children = children;
          } else if (foundCategory && select_format === 'pay_to_list_child') {
            const parent = foundCategory.parent;
            children = categoryArray.filter(
              (cat) => cat.parent === parseInt(parent?.toString() || ''),
            );
          }
          // Only show child categories that are pay_to_list_child
          children = children.filter(
            (child) =>
              child.functional.create_listing_category_select_format === 'pay_to_list_child',
          );

          if (select_format === 'pay_to_list_child') {
            const parentId = foundCategory?.parent;
            const categoryParent = categoryArray.find(
              (category) => category.id == parseInt(parentId?.toString() || ''),
            );
            setPayToListChildren(children || []);
            setPayToListParent(categoryParent || null);
            setParentCategory(categoryParent || null);
            setSelectedChild(foundCategory || null);
            setSelectedCategory(foundCategory || null);
          } else if (select_format === 'pay_to_list_parent') {
            setPayToListParent && setPayToListParent(foundCategory || null);
            setParentCategory(foundCategory || null);
            setPayToListChildren && setPayToListChildren(children || []);
            if (foundCategory?.children && foundCategory.children.length === 1) {
              setSelectedChild && setSelectedChild(foundCategory.children[0]);
            }
          }
          setIsPayToList && setIsPayToList(isPTL || false);
        }
        setRenderCreateListing(true);
      } catch (error) {
        toast({
          title: 'Error fetching categories',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (router.isReady) {
      if (marketplace?.id) {
        fetchCategoryInformation(marketplace?.id);
      }
    }
  }, [categoryId, router.isReady, marketplace]);

  useEffect(() => {
    if (marketplace && user && currentCategoryId) {
      checkCategoryPermissions(currentCategoryId?.toString(), marketplace?.id?.toString());
    }
  }, [currentCategoryId, marketplace, user]);

  const checkCategoryPermissions = async (catId: string, marketplaceId: string) => {
    const categoryRoles = await fetchCategoryPermissionsRoles(catId);
    // Filter roles to only include those that have "can_create_listings" permission as true
    const canCreateListingRoles = categoryRoles.filter((role) => role.can_create_listing);
    const canCreateListingRoleNames = canCreateListingRoles.map((role) => role.role.name);

    let rolesString = '';
    if (hasRoles(marketplaceId, canCreateListingRoleNames)) {
      setHasPermission(true);
    } else {
      if (canCreateListingRoles.length > 1) {
        rolesString = `The Roles [${canCreateListingRoles
          .map((role) => role.role.name)
          .join(', ')}] are required to create listings in this category.`;
      } else if (canCreateListingRoles.length === 1) {
        rolesString = `The Role "${canCreateListingRoles[0].role.name}" is required to create listings in this category.`;
      } else {
        rolesString = `An Admin role is required to create listings in this category.`;
      }
      setErrorMessage(rolesString);
      setHasPermission(false);
    }
  };

  const handleSelectedChild = (child: any) => {
    setSelectedChild(child);
    containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const isPayToListChild =
    selectedCategory?.functional?.create_listing_category_select_format === 'pay_to_list_child';
  const siblingsToUpdate = isPayToListChild
    ? payToListChildren.filter((cat) => {
        const catPrice = cat.functional?.pay_to_list?.pricing_card?.price || 0;
        const selCatPrice = selectedCategory?.functional?.pay_to_list?.pricing_card?.price || 0;
        return Number(catPrice) > Number(selCatPrice) && Number(catPrice) > 0;
      })
    : [];
  const inDraftEditingMode = listing?.status === 'draft' && writingMode === 'Edit';
  const saveInfoButtonText = inDraftEditingMode || !listingId ? 'Save Draft' : 'Save Info';

  const [paymentInfoFound, setPaymentInfoFound] = useState<boolean>(false);
  const [fetchingPaymentInfo, setFetchingPaymentInfo] = useState<boolean>(false);
  const [showAttributeValueCaptureModal, setShowAttributeValueCaptureModal] =
    useState<boolean>(false);
  const [
    showAttributeValueCaptureModalAttributeIds,
    setShowAttributeValueCaptureModalAttributeIds,
  ] = useState<number[]>([]);
  const checkPaymentInfo = useCallback(async () => {
    if (!marketplace?.id || !isAuthenticated || authLoading || fetchingPaymentInfo) return;
    setFetchingPaymentInfo(true);
    const token = await getToken();
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/payout_information_state/${marketplace?.id}`;

    try {
      const response = await apiRequest('GET', url, token);
      setPaymentInfoFound(
        response.payout_information_exists && selectedCategory?.type === CATEGORY_TYPE_MARKETPLACE,
      );
      if (
        !response.payout_information_exists &&
        selectedCategory?.type === CATEGORY_TYPE_MARKETPLACE &&
        !externalUrl
      ) {
        // Open the AttributeValueCaptureModal to prompt the user to fill out their payment information
        setShowAttributeValueCaptureModalAttributeIds(response.attribute_ids);
        setShowAttributeValueCaptureModal(true);
      }
    } catch (error) {
      console.error('Error fetching payment info:', error);
    } finally {
      setFetchingPaymentInfo(false);
    }
  }, [getToken, marketplace, selectedCategory, isAuthenticated, authLoading]);

  useEffect(() => {
    if (marketplace && isAuthenticated && !authLoading) {
      checkPaymentInfo();
    }
  }, [marketplace, checkPaymentInfo, authLoading, isAuthenticated]);

  const renderCustomContent = () => {
    const baseCategory = selectedCategory?.functional?.new_listing_custom_content_use_parent
      ? parentCategory
      : selectedCategory;

    if (
      !baseCategory ||
      !baseCategory.functional.new_listing_custom_content_title ||
      !baseCategory.functional.new_listing_custom_content_body
    ) {
      return null;
    }

    return (
      <Flex
        width="full"
        direction="column"
        p={{ base: 2, md: 4, lg: 8 }}
        border="1px"
        borderColor="gray.200"
        borderRadius={theme.border_radius.border_radius_2}
        mb={3}
        className="custom-content"
      >
        <Text fontSize="md" fontWeight={600}>
          {baseCategory?.functional.new_listing_custom_content_title}
        </Text>
        <span
          dangerouslySetInnerHTML={{
            __html: baseCategory?.functional.new_listing_custom_content_body ?? '',
          }}
        />
      </Flex>
    );
  };

  return renderCreateListing ? (
    <>
      {isPayToList && writingMode === 'Add' && (
        <Container
          maxW={CONTAINER_MAX_WIDTH_1}
          my={{ base: 4, md: 6, lg: 8 }}
          pt={{ base: 2, md: 4, lg: 8 }}
          border="1px"
          borderColor="gray.200"
          borderRadius={theme.border_radius.border_radius_2}
        >
          <VStack spacing={2} textAlign="center">
            <Heading as="h1" fontSize="4xl">
              {!isEmpty(payToListParent?.create_listing_name)
                ? payToListParent?.create_listing_name
                : payToListParent?.name ?? ''}
            </Heading>
            <Text fontSize="lg" color={'gray.500'}>
              {payToListParent?.description ?? ''}
            </Text>
          </VStack>

          <Flex
            width="full"
            direction={{ base: 'column', md: 'row' }}
            gap={6}
            p={{ base: 2, md: 4, lg: 8 }}
          >
            {payToListChildren
              .sort((a, b) => {
                // sort first by position, then by id
                let comparisonRes = a.position - b.position;
                if (comparisonRes === 0) {
                  comparisonRes = a.id - b.id;
                }
                return comparisonRes;
              })
              .map((elem) => {
                return (
                  <PriceCard
                    childCategory={elem}
                    key={elem.id}
                    handleChangeCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                    handleSelectedChild={handleSelectedChild}
                    parentCategory={payToListParent}
                  />
                );
              })}
          </Flex>
          {payToListParent?.functional?.pay_to_list?.pay_to_list_text && (
            <Flex width="full" direction={{ base: 'column' }} gap={4} p={{ base: 2, md: 4, lg: 8 }}>
              <Text fontWeight="500" fontSize="xl" textAlign="left">
                FAQ
              </Text>
              <Text>{payToListParent?.functional?.pay_to_list?.pay_to_list_text}</Text>
            </Flex>
          )}
        </Container>
      )}

      {selectedCategory && !selectedCategory.functional?.can_create_listings ? (
        <CreateListingSubCategorySelector category={selectedCategory} />
      ) : (
        <>
          {hasPermission ? (
            <Container maxW={CONTAINER_MAX_WIDTH_2} ref={containerRef}>
              {!authLoading && !isAuthenticated ? (
                <Container maxW={CONTAINER_MAX_WIDTH_2} my={{ base: 4, md: 6, lg: 8 }}>
                  <Text
                    fontSize={{ base: 'xl', lg: '2xl' }}
                    fontWeight={600}
                    mb={{ base: 2, md: 4, lg: 6 }}
                  >
                    {writingMode === 'Add' ? 'Add new listing' : 'Edit listing'}
                  </Text>
                  <LoginPrompt message="to create or edit a listing." showWithModalOpen={false} />
                </Container>
              ) : (
                <form
                  onSubmit={handleSubmit}
                  style={{ display: !isPayToList || selectedChild?.name ? 'block' : 'none' }}
                >
                  <Container maxW={CONTAINER_MAX_WIDTH_2} my={{ base: 4, md: 6, lg: 8 }}>
                    <Heading
                      fontSize={{ base: 'xl', lg: '2xl' }}
                      fontWeight={600}
                      mb={{ base: 2, md: 4, lg: 6 }}
                    >
                      {writingMode === 'Add' ? 'Add new listing' : 'Edit listing'}
                    </Heading>
                    {renderCustomContent()}
                    <Flex
                      width="full"
                      direction="column"
                      p={{ base: 2, md: 4, lg: 8 }}
                      border="1px"
                      borderColor="gray.200"
                      borderRadius={theme.border_radius.border_radius_2}
                    >
                      {/* Basic Info */}
                      <Grid width="full" column={2} gap={{ base: 3, md: 5 }} autoColumns="1fr">
                        <GridItem colSpan={2}>
                          <Flex justifyContent={'space-between'}>
                            <Text fontSize="md" fontWeight={600}>
                              Basic Info
                            </Text>
                            <Flex gap={4}>
                              {writingMode === 'Add' && (
                                <ListingImport categoryId={currentCategoryId} />
                              )}
                              {writingMode !== 'Add' && isNoAuctionOrEnded && (
                                <ISoldThis
                                  listing={listing}
                                  marketplace={marketplace}
                                  mode="form"
                                  afterUpdate={updateListing}
                                />
                              )}
                            </Flex>
                          </Flex>
                        </GridItem>
                        <GridItem colSpan={{ base: 2, md: 1 }}>
                          <Input
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            isRequired
                            data-test="title-input"
                          />
                        </GridItem>
                        <GridItem colSpan={{ base: 2, md: 1 }}>
                          {!isPayToList && (
                            <CategorySelector
                              marketplaceId={marketplace?.id}
                              selectedCategoryId={currentCategoryId?.toString()}
                              onCategorySelect={setSelectedCategory}
                              disabled={categorySelectorDisabled}
                              disabledReason="You cannot change the category after you have paid to list."
                              isRequired
                              mode="listing-create"
                              isPayToList={isPayToList}
                              handlePayToList={setIsPayToList}
                            />
                          )}
                        </GridItem>

                        {/* Listing Attributes Fields */}
                        {selectedCategory?.attributes
                          ?.filter((attr) => attr.nested_content_type_name == 'Listing')
                          ?.sort((a, b) => a.position - b.position)
                          ?.map((attribute) => {
                            // Find the corresponding attribute value
                            const value = listingAttributeValues[attribute.id];
                            if (attribute.format !== 'address') {
                              return (
                                <GridItem
                                  colSpan={{ base: 2, md: attribute.format === 'media' ? 2 : 1 }}
                                  key={attribute.id}
                                >
                                  <AttributeInput<BasicInfoType>
                                    key={attribute.id}
                                    attribute={attribute}
                                    width="full"
                                    isMobileView
                                    value={value}
                                    setChange={(e) =>
                                      handleAttributeChange(attribute.id, e.target.value)
                                    }
                                    setSelectChange={(e) =>
                                      handleAttributeChange(attribute.id, e.target.value)
                                    }
                                    setSwitchChange={(checked) =>
                                      handleAttributeChange(attribute.id, checked)
                                    }
                                    setUrlChange={(value) =>
                                      handleAttributeChange(attribute.id, value)
                                    }
                                    setMediaChange={(value) =>
                                      handleAttributeChange(attribute.id, value)
                                    }
                                    setMultiselectChange={(value) =>
                                      handleAttributeChange(attribute.id, value)
                                    }
                                  />
                                </GridItem>
                              );
                            }
                          })}

                        <GridItem colSpan={2}>
                          <Textarea
                            name="description"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            data-test="description-input"
                            isRequired
                          />
                        </GridItem>

                        {/* Listing Currency */}
                        {(selectedCategory?.functional?.currencies_allowed?.length ?? 0) > 1 && (
                          <GridItem colSpan={2}>
                            <Flex direction="column">
                              <FormLabel>Currency</FormLabel>
                              <Select
                                name="currency"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                              >
                                {selectedCategory?.functional.currencies_allowed.map((currency) => (
                                  <option key={currency} value={currency}>
                                    {currency.toUpperCase()}
                                  </option>
                                ))}
                              </Select>
                            </Flex>
                          </GridItem>
                        )}

                        {/* Single Variant Form */}
                        {categoryTypeHasPrice(selectedCategory?.type) && (
                          <>
                            {!isMultiVariant && (
                              <Stack>
                                <VariantForm
                                  selectedCategory={selectedCategory}
                                  currentVariant={currentVariant}
                                  setCurrentVariant={setCurrentVariant}
                                  isSingleVariant={
                                    selectedCategory?.type !== CATEGORY_TYPE_MARKETPLACE
                                  }
                                />
                              </Stack>
                            )}

                            {selectedCategory?.type === CATEGORY_TYPE_MARKETPLACE && (
                              <GridItem colSpan={2}>
                                <Text
                                  paddingTop={2}
                                  fontStyle={'italic'}
                                  color={'orange.400'}
                                  fontSize={'small'}
                                >
                                  A marketplace fee of{' '}
                                  <b>
                                    ${(Number(currentVariant?.offered_price) * takeRate).toFixed(2)}
                                  </b>{' '}
                                  will be subtracted from your payout after this sells.
                                </Text>
                                {!paymentInfoFound && (
                                  <Text
                                    paddingTop={1}
                                    fontStyle={'italic'}
                                    color={'gray.500'}
                                    fontSize={'small'}
                                  >
                                    Be sure to enter your payout details (e.g. your Venmo/Cash App
                                    info) in{' '}
                                    <b>
                                      <NextLink href="/account/info">Account Info</NextLink>
                                    </b>
                                    .
                                  </Text>
                                )}
                                <Text
                                  paddingTop={1}
                                  fontStyle={'italic'}
                                  color={'gray.500'}
                                  fontSize={'small'}
                                >
                                  See{' '}
                                  <b>
                                    <NextLink href="/transaction_terms">Transaction Terms</NextLink>
                                  </b>{' '}
                                  for more information.
                                </Text>
                              </GridItem>
                            )}
                          </>
                        )}

                        <GridItem colSpan={2} textAlign="right">
                          {listingId ? (
                            <Button
                              isLoading={submitInfoLoading}
                              colorScheme="gray"
                              variant="solid"
                              px={12}
                              data-action="saveInfo"
                              type="submit"
                              onClick={handleSubmit}
                              isDisabled={imageUploading || !isAuthenticated || disallowUserEdits}
                            >
                              {saveInfoButtonText}
                            </Button>
                          ) : (
                            <Button
                              isLoading={submitDraftLoading}
                              colorScheme="gray"
                              variant="solid"
                              px={12}
                              data-action="saveDraft"
                              type="submit"
                              onClick={handleSubmit}
                              isDisabled={imageUploading || !isAuthenticated || disallowUserEdits}
                            >
                              {saveInfoButtonText}
                            </Button>
                          )}
                        </GridItem>
                      </Grid>
                      <Divider my={{ base: 4, lg: 6 }} />

                      {/* Multi-Variant Form */}
                      {categoryTypeHasPrice(selectedCategory?.type) && (
                        <>
                          {isMultiVariant ? (
                            <>
                              <Grid
                                width="full"
                                column={3}
                                gap={{ base: 3, md: 5 }}
                                autoColumns="1fr"
                              >
                                <GridItem colSpan={3}>
                                  <Text fontSize="md" fontWeight={600}>
                                    Item Variations
                                  </Text>
                                </GridItem>
                                <VariantForm
                                  selectedCategory={selectedCategory}
                                  currentVariant={currentVariant}
                                  setCurrentVariant={setCurrentVariant}
                                  isSingleVariant={false}
                                />
                                <GridItem colSpan={3} textAlign="right">
                                  <Button
                                    colorScheme="gray"
                                    variant="solid"
                                    px={12}
                                    onClick={handleAddVariantSubmit}
                                    data-test="add-variation-btn"
                                  >
                                    Add Variation
                                  </Button>
                                </GridItem>
                              </Grid>
                              <Divider my={{ base: 4, lg: 6 }} />
                              {variants?.length === 0 && (
                                <Alert status="info">
                                  <AlertIcon /> Add at least one variation
                                </Alert>
                              )}
                              <Accordion
                                allowToggle
                                mb={{ base: 4, lg: 6 }}
                                onChange={(expandedIndex) => {
                                  if (expandedIndex == -1) {
                                    setCurrentVariant(null);
                                  }
                                }}
                              >
                                {variants
                                  ?.sort((item1, item2) => {
                                    if (item1.id && item2.id) {
                                      return item1.id - item2.id;
                                    }
                                    return 0;
                                  })
                                  .map((variant) => (
                                    <AccordionItem
                                      key={variant?.id}
                                      border="1px"
                                      borderColor="gray.300"
                                      borderRadius={theme.border_radius.border_radius_2}
                                      mb={4}
                                    >
                                      {({ isExpanded }) => (
                                        <>
                                          <h2>
                                            <AccordionButton
                                              _expanded={{
                                                borderTopRadius:
                                                  theme.border_radius.border_radius_2,
                                                borderBottom: '1px',
                                                borderColor: 'gray.300',
                                                bgColor: 'gray.100',
                                              }}
                                              onClick={() => {
                                                if (!isExpanded) {
                                                  setCurrentVariant(variant);
                                                }
                                              }}
                                            >
                                              <Box as="span" flex="1" textAlign="left">
                                                {variant.offered_price && (
                                                  <span>${variant.offered_price}</span>
                                                )}
                                                {Object.keys(variant.variant_attribute_values || {})
                                                  .length > 0 && ' - '}

                                                {Object.entries(
                                                  variant.variant_attribute_values || {},
                                                )
                                                  .map(([attributeId, attributeValue]) => {
                                                    const attribute =
                                                      selectedCategory?.attributes.find(
                                                        (attr) => attr.id === parseInt(attributeId),
                                                      );
                                                    return attribute
                                                      ? `${attribute.id}: ${attributeValue}`
                                                      : null;
                                                  })
                                                  .filter(Boolean)
                                                  .join(', ')}
                                                <span>&nbsp;-&nbsp;Qty: {variant.quantity}</span>
                                              </Box>
                                              {!isExpanded && (
                                                <Icon
                                                  as={FiEdit2}
                                                  textColor="primary.400"
                                                  fontSize="xl"
                                                />
                                              )}
                                            </AccordionButton>
                                          </h2>
                                          <AccordionPanel
                                            py={4}
                                            borderRadius={theme.border_radius.border_radius_2}
                                            _expanded={{ border: '1px', borderColor: 'gray.300' }}
                                          >
                                            <Grid
                                              width="full"
                                              column={3}
                                              gap={{ base: 3, md: 5 }}
                                              autoColumns="1fr"
                                            >
                                              <VariantForm
                                                selectedCategory={selectedCategory}
                                                currentVariant={currentVariant}
                                                setCurrentVariant={setCurrentVariant}
                                                isSingleVariant={false}
                                              />
                                              <GridItem colSpan={3} textAlign="right">
                                                <Flex
                                                  alignItems="center"
                                                  justifyContent="flex-end"
                                                  flexWrap="wrap"
                                                  gap={4}
                                                >
                                                  <Button
                                                    colorScheme="red"
                                                    variant="outline"
                                                    onClick={deleteVariant}
                                                  >
                                                    <AccordionButton _hover={{}}>
                                                      Delete
                                                    </AccordionButton>
                                                  </Button>

                                                  <Button
                                                    colorScheme="gray"
                                                    variant="solid"
                                                    onClick={() =>
                                                      handleEditVariantSubmit(variant?.id)
                                                    }
                                                  >
                                                    <AccordionButton _hover={{}}>
                                                      Save Edit
                                                    </AccordionButton>
                                                  </Button>
                                                </Flex>
                                              </GridItem>
                                            </Grid>
                                          </AccordionPanel>
                                        </>
                                      )}
                                    </AccordionItem>
                                  ))}
                              </Accordion>
                              <Divider my={{ base: 4, lg: 6 }} />
                            </>
                          ) : null}
                        </>
                      )}

                      {/* Fulfillment Option Types */}
                      {/* TODO(Sean): Allow selecting multiple fulfillment option types */}
                      {selectedCategory?.type === CATEGORY_TYPE_MARKETPLACE && (
                        <>
                          <Grid width="full" column={2} gap={{ base: 3, md: 5 }} autoColumns="1fr">
                            <GridItem colSpan={2}>
                              <Text fontSize="md" fontWeight={600}>
                                Shipping & Fulfillment Options
                              </Text>
                            </GridItem>
                            <GridItem colSpan={2}>
                              <Select
                                name="fulfillment_options"
                                placeholder="Select Option"
                                value={selectedFulfillment?.id}
                                onChange={(e) => {
                                  const selectedFulfillmentId = e.target.value;
                                  const selectedFulfillment =
                                    selectedCategory?.fulfillment_types?.find(
                                      (option) => option.id === parseInt(selectedFulfillmentId),
                                    );
                                  setSelectedFulfillment(selectedFulfillment || null);
                                }}
                                data-test="fullfilment-input"
                              >
                                {selectedCategory?.fulfillment_types?.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </Select>
                            </GridItem>
                          </Grid>
                          <Divider my={{ base: 4, lg: 6 }} />
                        </>
                      )}

                      {selectedFulfillment && (
                        <>
                          <Box pt={4} pl={1}>
                            <HStack>
                              <Text fontSize="sm" fontWeight={500} pb={2}>
                                {selectedFulfillment.name}
                              </Text>
                              {selectedFulfillment.price_type === 'dynamic_api' && (
                                <Text fontSize="sm" fontWeight={500} pb={2}>
                                  &nbsp;-&nbsp;dynamic pricing
                                </Text>
                              )}
                              {selectedFulfillment?.price_type === 'fixed_at_type' && (
                                <Text fontSize="sm" fontWeight={500} pb={2}>
                                  &nbsp;-&nbsp;${selectedFulfillment.price}
                                </Text>
                              )}
                              {selectedFulfillment?.price_type === 'fixed_at_option_type' && (
                                <Text fontSize="sm" fontWeight={500} pb={2}>
                                  &nbsp;-&nbsp;${selectedFulfillment.price}
                                </Text>
                              )}
                              {selectedFulfillment?.price_type === 'fixed_at_option' && (
                                <GridItem colSpan={1}>
                                  <FormControl>
                                    <InputGroup>
                                      <InputLeftAddon>$</InputLeftAddon>
                                      <NumberInput
                                        mb={4}
                                        name="shippingPrice"
                                        placeholder="Price"
                                        precision={2}
                                        min={0}
                                        value={fulfillmentPrice}
                                        onChange={(v) => setFulfillmentPrice(v)}
                                        isRequired
                                      >
                                        <NumberInputField borderLeftRadius={0} />
                                      </NumberInput>
                                    </InputGroup>
                                  </FormControl>
                                </GridItem>
                              )}
                            </HStack>
                            <Text fontSize="sm" fontWeight={300}>
                              {selectedFulfillment?.description}
                            </Text>
                          </Box>
                          <Divider my={{ base: 4, lg: 6 }} />
                        </>
                      )}

                      {/* Shipping */}
                      {selectedFulfillment && selectedFulfillment?.price_type === 'dynamic_api' && (
                        <>
                          <Grid width="full" column={3} gap={{ base: 3, md: 5 }} autoColumns="1fr">
                            <GridItem colSpan={3}>
                              <Text fontSize="md" fontWeight={600}>
                                Shipping
                              </Text>
                            </GridItem>
                            <GridItem colSpan={3}>
                              <AddressInput
                                addressValue={shippingInfo}
                                setAddressValue={setShippingInfo}
                                formType="shipping"
                                skipRequired={!!externalUrl}
                              />
                            </GridItem>
                            <GridItem colSpan={3} textAlign="right">
                              <Button
                                isLoading={submitLoading}
                                colorScheme="gray"
                                variant="solid"
                                px={12}
                                data-action="saveInfo"
                                type="submit"
                                isDisabled={!isAuthenticated}
                              >
                                Save Address
                              </Button>
                            </GridItem>
                          </Grid>

                          <Divider my={{ base: 4, lg: 6 }} />

                          {/* Parcel Info */}
                          <ParcelInfoForm parcelInfo={parcelInfo} setParcelInfo={setParcelInfo} />

                          <Divider my={{ base: 4, lg: 6 }} />
                        </>
                      )}

                      {/* Images */}
                      {selectedCategory?.functional?.multiple_listing_photos ? (
                        <>
                          <Grid width="full" column={2} gap={{ base: 3, md: 5 }} autoColumns="1fr">
                            <GridItem colSpan={2}>
                              <Text fontSize="md" fontWeight={600}>
                                Photos / Videos
                              </Text>
                            </GridItem>
                            <GridItem colSpan={2} data-test="listing-images">
                              <ListingImageEditor
                                maxFiles={
                                  selectedCategory?.functional?.multiple_listing_photos || 24
                                }
                                filePaths={filePaths}
                                setFilePaths={setFilePaths}
                                setImageUploading={setImageUploading}
                              />
                            </GridItem>
                          </Grid>
                          <Divider my={{ base: 4, lg: 6 }} />
                        </>
                      ) : null}

                      {/* Location */}
                      <ListingLocationForm
                        hasLocation={hasLocation}
                        setHasLocation={setHasLocation}
                        location={location}
                        setLocation={setLocation}
                        shippingInfo={shippingInfo}
                        sameAsShippingInfo={sameAsShippingInfo}
                        setSameAsShippingInfo={setSameAsShippingInfo}
                        requiresLocation={selectedCategory?.functional.require_location}
                        categoryType={selectedCategory?.type}
                        attributes={selectedCategory?.attributes}
                      />

                      {selectedCategory?.type === 'auction' && (
                        <ListingAuctionForm
                          auctionInfo={auctionInfo}
                          setAuctionInfo={setAuctionInfo}
                        />
                      )}

                      {/* Featured */}
                      {isStaffOrAdmin && (
                        <ListingFeaturedForm
                          isFeatured={isFeatured}
                          setIsFeatured={setIsFeatured}
                        />
                      )}

                      {(isLomaAdmin || isStaffOrAdmin) && (
                        <>
                          <Divider my={{ base: 4, lg: 6 }} />
                          <Grid
                            width="full"
                            column={2}
                            gap={{ base: 3, md: 5 }}
                            autoColumns="1fr"
                            mb={8}
                          >
                            <GridItem colSpan={2}>
                              <Text fontSize="md" fontWeight={600}>
                                Admin
                              </Text>
                            </GridItem>
                            <GridItem colSpan={2}>
                              <FormControl>
                                <FormLabel>External URL</FormLabel>
                                <Input
                                  type="text"
                                  name="external_url"
                                  placeholder="External URL"
                                  value={externalUrl}
                                  onChange={(e) => setExternalUrl(e.target.value)}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={2}>
                              <FormControl>
                                <FormLabel>Source</FormLabel>
                                <Input
                                  type="text"
                                  name="source"
                                  placeholder="Source"
                                  value={source}
                                  onChange={(e) => setSource(e.target.value)}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={2}>
                              <FormControl>
                                <FormLabel>Lister Handle (optional)</FormLabel>
                                <Input
                                  type="text"
                                  name="lister_handle"
                                  placeholder={`${capitalizeFirstLetter(source as string)} Seller`}
                                  value={listerHandle}
                                  onChange={(e) => setListerHandle(e.target.value)}
                                />
                              </FormControl>
                            </GridItem>

                            {/* Listed At */}
                            <GridItem colSpan={2}>
                              <FormControl>
                                <FormLabel>Listed Date</FormLabel>
                                <Input
                                  type="text"
                                  name="listed_at"
                                  placeholder="Listed Date"
                                  value={listedAt}
                                  onChange={(e) => setListedAt(e.target.value)}
                                />
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </>
                      )}

                      {/* Sibling Categories to Upgrade */}
                      {siblingsToUpdate.length > 0 && isPayToListChild && (
                        <>
                          <Divider my={{ base: 4, lg: 6 }} />
                          <Box mb={{ base: 8 }}>
                            <Text mb={{ base: 2, lg: 4 }}>Reach more buyers:</Text>
                            <Flex wrap={'wrap'} alignItems={'start'} gap={{ base: 8 }} w={'full'}>
                              {siblingsToUpdate.map((cat) => (
                                <SiblingToUpdateCard
                                  category={cat}
                                  key={cat.id}
                                  handleSelectedChild={(cat) => {
                                    setSelectedChild(cat);
                                  }}
                                  handleChangeCategory={setSelectedCategory}
                                />
                              ))}
                            </Flex>
                          </Box>
                        </>
                      )}

                      {/* Submit */}
                      <ListingFormSubmit
                        selectedCategory={selectedCategory}
                        listing={listing}
                        listingId={listingId}
                        submitLoading={submitLoading}
                        setSubmitLoading={setSubmitLoading}
                        submitDraftLoading={submitDraftLoading}
                        handleSubmit={handleSubmit}
                        writingMode={writingMode}
                        isDisabled={imageUploading || disallowUserEdits}
                      />
                    </Flex>
                  </Container>
                </form>
              )}
            </Container>
          ) : (
            <Container maxW={CONTAINER_MAX_WIDTH_2} ref={containerRef} mt={10}>
              <ErrorMessageBox>
                {errorMessage}
                &nbsp;You can add roles to your account from the&nbsp;
                <Button variant="link" onClick={() => router.push('/account/roles')}>
                  <Text as="span" textDecoration="underline">
                    Marketplace Roles
                  </Text>
                </Button>
                &nbsp;page.
              </ErrorMessageBox>
            </Container>
          )}
        </>
      )}
      <AttributeValueCaptureModal
        isOpen={showAttributeValueCaptureModal}
        onClose={() => setShowAttributeValueCaptureModal(false)}
        attributeIds={showAttributeValueCaptureModalAttributeIds}
        onSave={checkPaymentInfo}
        alertText="You must enter your payout information before your listing can go live."
      />
    </>
  ) : (
    <Flex pt={40} justifyContent="center">
      <Spinner size="xl" />
    </Flex>
  );
};

export default ListingForm;
