import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { MarketplacePayoutConfig as MarketplacePayoutConfigType } from 'src/api/v1-api';
import useRequest from 'src/utils/hooks/useRequest';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import useSingleToast from 'src/utils/hooks/toast/useSingleToast';
import { Controller, useForm } from 'react-hook-form';

const MarketplacePayoutConfig = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { marketplace } = useMarketplaceContext();
  const { api } = useRequest();
  const showToast = useSingleToast();

  const { control, handleSubmit, reset } = useForm<MarketplacePayoutConfigType>({
    defaultValues: {},
  });

  const fetchPayoutConfig = async () => {
    try {
      if (!marketplace) {
        return;
      }

      setIsLoading(true);
      const response = await api.marketplace.payoutConfig.get(marketplace.id);
      reset(response);
    } catch (error) {
      showToast({
        title: 'Error',
        description:
          'There was a problem fetching marketplace payout config. Please try refreshing the page or contact support.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        sentryAlert: {
          error,
          level: 'error',
          extras: {
            marketplaceId: marketplace?.id,
          },
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updatePayoutConfig = async (data: MarketplacePayoutConfigType) => {
    try {
      if (!marketplace) {
        return;
      }

      setIsLoading(true);
      await api.marketplace.payoutConfig.update(marketplace.id, data);
      showToast({
        title: 'Success',
        description: 'Marketplace payout config has been updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      showToast({
        title: 'Error',
        description:
          'There was a problem updating marketplace payout config. Please try refreshing the page or contact support.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        sentryAlert: {
          error,
          level: 'error',
          extras: {
            marketplaceId: marketplace?.id,
            data,
          },
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPayoutConfig();
  }, []);

  const onSubmit = async (data: MarketplacePayoutConfigType) => {
    await updatePayoutConfig(data);
  };

  return (
    <Flex direction={'column'}>
      <Box position="relative" w="100%">
        {isLoading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="rgba(255, 255, 255, 0.7)"
          >
            <Spinner />
          </Box>
        )}
        <Stack as="form" direction={'column'} onSubmit={handleSubmit(onSubmit)} gap={4}>
          <Controller
            name="default_paid_listing_admin_payout"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl
                  id="default_paid_listing_admin_payout"
                  isInvalid={Boolean(error)}
                  isRequired
                >
                  <FormLabel>Default Paid Listing Admin Payout</FormLabel>
                  <Input {...field} type="number" />
                  {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            name="default_paid_role_admin_payout"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl
                  id="default_paid_role_admin_payout"
                  isInvalid={Boolean(error)}
                  isRequired
                >
                  <FormLabel>Default Paid Role Admin Payout</FormLabel>
                  <Input {...field} type="number" />
                  {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            name="default_transaction_admin_payout"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl
                  id="default_transaction_admin_payout"
                  isInvalid={Boolean(error)}
                  isRequired
                >
                  <FormLabel>Default Transaction Admin Payout</FormLabel>
                  <Input {...field} type="number" />
                  {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            name="default_affiliate_transaction_admin_payout"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl
                  id="default_affiliate_transaction_admin_payout"
                  isInvalid={Boolean(error)}
                  isRequired
                >
                  <FormLabel>Default Affiliate Transaction Admin Payout</FormLabel>
                  <Input {...field} type="number" />
                  {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Button type="submit" colorScheme="primary">
            Save
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};

export default MarketplacePayoutConfig;
