import {
  Avatar,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useBreakpointValue,
  useDisclosure,
  Slide,
  useTheme,
  Portal,
  Divider,
  Button,
} from '@chakra-ui/react';
import { BiLogIn, BiLogOut } from 'react-icons/bi';
import { FaChevronDown, FaStoreAlt } from 'react-icons/fa';
import { MdMenu, MdClose } from 'react-icons/md';
import { useAuth } from 'src/utils/auth';
import { FiUser } from 'react-icons/fi';
import { RxCounterClockwiseClock } from 'react-icons/rx';
import { IoBagRemoveOutline } from 'react-icons/io5';
import { BsTag } from 'react-icons/bs';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useContext } from 'react';
import { MarketplaceAccount } from 'src/api/v1-api';
import { LoginModal } from 'src/components/organisms';
import NextLink from 'next/link';
import { srcFromCDN } from 'src/utils/files';
import { BLACK, WHITE } from 'src/constants/ui';

interface ProfileMenuProps {
  totalSocialCount: string | number;
}

const ProfileMenu = ({ totalSocialCount }: ProfileMenuProps) => {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isMenuOpen, onToggle: onMenuToggle, onClose: onMenuClose } = useDisclosure();
  const { logout, isAuthenticated, hasAdmin, userLoaded } = useAuth();
  const { marketplace } = useContext(MarketplaceContext);
  const isAdmin = hasAdmin(marketplace?.id);
  const theme = useTheme();

  const handleLogout = async () => {
    const loggedOut = await logout();
    if (loggedOut) {
      window.location.href = '/';
    }
  };

  const getProfilePhoto = () => {
    if (!user || !marketplace) return '';
    const marketplaceAccount = user.marketplace_accounts.find(
      (account: MarketplaceAccount) => account.marketplace_id === marketplace.id,
    );
    return marketplaceAccount?.image_url || '';
  };

  const getProfileName = () => {
    if (!user) return '';
    return user.first_name + ' ' + user.last_name;
  };

  const shouldDisplayFirstName = useBreakpointValue({ base: false, md: true });
  const profilePhoto = getProfilePhoto();

  const isSocialFansEnabled =
    marketplace?.functional?.show_total_social_media_audience &&
    marketplace?.social_profiles &&
    marketplace?.social_profiles.length > 0 &&
    totalSocialCount;

  const ProfileAvatar = () => (
    <Flex cursor="pointer" alignItems="center">
      <Avatar
        size="sm"
        name={getProfileName()}
        src={profilePhoto ? srcFromCDN(profilePhoto, 32) : undefined}
        bgColor="transparent"
      />
      {user && shouldDisplayFirstName && (
        <Flex alignItems="center">
          <Box maxWidth="150px">
            <Text
              ml="2"
              pr="2"
              fontSize="sm"
              fontWeight="medium"
              isTruncated
              data-test="profile-firstname"
            >
              {user.first_name}
            </Text>
          </Box>
          <FaChevronDown size={12} />
        </Flex>
      )}
    </Flex>
  );

  return (
    <>
      <Box style={{ zIndex: 10 }} display={{ base: 'none', lg: 'block' }}>
        <Menu>
          <MenuButton display="flex" alignItems="center">
            <Flex display={{ base: 'block', lg: 'none' }}>
              <MdMenu size="24px" />
            </Flex>
            <Flex display={{ base: 'none', lg: 'block' }}>
              {userLoaded ? isAuthenticated ? ProfileAvatar() : <FiUser size={24} /> : null}
            </Flex>
          </MenuButton>
          <MenuList>
            {isAuthenticated && (
              <>
                <NextLink href="/account/info" prefetch={false}>
                  <MenuItem icon={<FiUser />} fontSize="sm" color="black">
                    Account Info
                  </MenuItem>
                </NextLink>
                <NextLink href="/account/orders" prefetch={false}>
                  <MenuItem icon={<RxCounterClockwiseClock />} fontSize="sm" color="black">
                    My Orders
                  </MenuItem>
                </NextLink>
                <NextLink href="/account/listings" prefetch={false}>
                  <MenuItem icon={<IoBagRemoveOutline />} fontSize="sm" color="black">
                    My Listings
                  </MenuItem>
                </NextLink>
                <NextLink href="/account/sales" prefetch={false}>
                  <MenuItem icon={<BsTag />} fontSize="sm" color="black">
                    My Sales
                  </MenuItem>
                </NextLink>
                {isAdmin && (
                  <NextLink href="/admin/info" prefetch={false}>
                    <MenuItem icon={<FaStoreAlt />} fontSize="sm" color="black">
                      Admin Settings
                    </MenuItem>
                  </NextLink>
                )}
                <MenuItem icon={<BiLogOut />} onClick={handleLogout} fontSize="sm" color="black">
                  Logout
                </MenuItem>
              </>
            )}
            {!isAuthenticated && (
              <MenuItem icon={<BiLogIn />} fontSize="sm" color="black" onClick={onOpen}>
                Login
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Box>
      <Box display={{ base: 'block', lg: 'none' }}>
        {isMenuOpen ? (
          <MdClose size="24px" onClick={onMenuToggle} />
        ) : (
          <MdMenu size="24px" onClick={onMenuToggle} />
        )}
        <Portal>
          <Slide direction="top" in={isMenuOpen} style={{ zIndex: 9, position: 'absolute' }}>
            <Box
              p="22px"
              mt={isSocialFansEnabled ? '78px' : '62px'}
              color={BLACK}
              backgroundColor={WHITE}
              roundedBottom={theme.border_radius.border_radius_2}
              shadow="md"
              display={{ base: 'block', lg: 'none' }}
              onClick={onMenuClose}
            >
              <Flex direction="column">
                {isAuthenticated ? (
                  <>
                    <Flex direction="column" gap="36px">
                      <NextLink href="/account/info" prefetch={false}>
                        Account Info
                      </NextLink>
                      <NextLink href="/account/orders" prefetch={false}>
                        My Orders
                      </NextLink>
                      <NextLink href="/account/listings" prefetch={false}>
                        My Listings
                      </NextLink>
                      <NextLink href="/account/sales" prefetch={false}>
                        My Sales
                      </NextLink>
                      {isAdmin && <NextLink href="/admin/info">Admin Settings</NextLink>}
                    </Flex>
                    <Divider
                      my="12px"
                      orientation="horizontal"
                      borderTop="1/2px"
                      borderColor="gray.300"
                    />
                    <Button
                      variant="outline"
                      onClick={handleLogout}
                      fontSize="sm"
                      leftIcon={<BiLogOut />}
                      colorScheme="primary"
                    >
                      LOGOUT
                    </Button>
                  </>
                ) : (
                  <>
                    <Divider
                      my="12px"
                      orientation="horizontal"
                      borderTop="1/2px"
                      borderColor="gray.300"
                    />
                    <Button
                      variant="outline"
                      colorScheme="primary"
                      onClick={onOpen}
                      fontSize="sm"
                      leftIcon={<BiLogIn />}
                    >
                      LOGIN
                    </Button>
                  </>
                )}
              </Flex>
            </Box>
          </Slide>
        </Portal>
      </Box>
      <LoginModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default ProfileMenu;
