import {
  AspectRatio,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
  Skeleton,
  useTheme,
} from '@chakra-ui/react';
import { HiOutlineMail } from 'react-icons/hi';
import { CartItemInterface, Checkout } from 'src/api/v1-api';
import { AttributeValuesDisplay, OrderSummaryItem } from 'src/components/atoms';
import { formatPrice } from 'src/utils/common';
import { useContext } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { DEFAULT_IMAGE } from 'src/constants/config';
import { srcFromCDN } from 'src/utils/files';

interface CheckoutSummaryProps {
  checkout: Checkout | null;
  isLoading: boolean;
  fulfillmentSubtotal: string | null | undefined;
  taxSubtotal: string | null | undefined;
  submitDisabled: boolean;
  submitLoading: boolean;
  submitError: string | null;
  isPaymentComplete: boolean;
}

export const CheckoutSummary = ({
  checkout,
  isLoading,
  fulfillmentSubtotal,
  taxSubtotal,
  submitDisabled,
  submitLoading,
  submitError,
  isPaymentComplete,
}: CheckoutSummaryProps): JSX.Element => {
  const theme = useTheme();
  const { supportEmail } = useContext(MarketplaceContext);

  let cart_items: CartItemInterface[] = [];
  let contents_subtotal = '0';
  if (checkout) {
    cart_items = checkout?.cart_items;
    contents_subtotal = checkout?.contents_subtotal;
  }

  return (
    <Stack spacing={{ base: '6', md: '10' }}>
      <Heading size="md">Order Summary</Heading>
      <Stack spacing="8">
        <Stack spacing="4">
          {isLoading ? (
            <Skeleton height="100px" />
          ) : (
            cart_items?.map((cart_item: CartItemInterface) => {
              let featuredImage = cart_item.variant.listing.listing_images.find(
                (img) => img.is_featured,
              );
              if (!featuredImage && cart_item.variant.listing.listing_images.length > 0) {
                featuredImage = cart_item.variant.listing.listing_images[0];
              }

              return (
                <>
                  <Flex justify="space-between" key={cart_item?.variant?.id}>
                    <Stack direction="row" spacing="5" key={cart_item?.id}>
                      <AspectRatio
                        ratio={1}
                        minWidth="92px"
                        maxWidth="92px"
                        minHeight="79px"
                        maxHeight="79px"
                      >
                        <Image
                          align="center"
                          src={srcFromCDN(featuredImage?.image, 92)}
                          alt={cart_item?.variant?.listing?.title}
                          style={{ objectFit: 'cover' }}
                          draggable="false"
                          borderRadius={theme.border_radius.border_radius_1}
                          fallback={
                            <Image
                              src={DEFAULT_IMAGE}
                              alt="default"
                              borderRadius={theme.border_radius.border_radius_1}
                            />
                          }
                        />
                      </AspectRatio>
                      <Stack spacing="3">
                        <Stack spacing="1">
                          <Text fontWeight="semibold">{cart_item?.variant?.listing?.title}</Text>
                          <Text color="gray.600">Quantity: {cart_item?.quantity}</Text>

                          {/* Variant Attributes */}
                          <AttributeValuesDisplay
                            noMedia={true}
                            isSummary={true}
                            attributeValues={cart_item?.variant?.variant_attribute_values || []}
                          />

                          {/* Listing Attributes */}
                          <AttributeValuesDisplay
                            noMedia={true}
                            isSummary={true}
                            attributeValues={
                              cart_item?.variant?.listing?.listing_attribute_values || []
                            }
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Text fontWeight="medium">
                      ${cart_item?.variant?.offered_price || cart_item?.variant?.regular_price}
                    </Text>
                  </Flex>
                  <Divider />
                </>
              );
            })
          )}
        </Stack>

        <Stack spacing="4">
          <OrderSummaryItem
            key="contents-subtotal"
            label="Contents Subtotal"
            value={formatPrice(contents_subtotal)}
            showIcon={false}
          />
          <OrderSummaryItem
            key="shipping"
            label="Shipping"
            value={fulfillmentSubtotal ? formatPrice(fulfillmentSubtotal) : '-'}
            showIcon={false}
          />
          <OrderSummaryItem
            key="tax"
            label="Tax"
            value={taxSubtotal ? formatPrice(taxSubtotal) : '-'}
            showIcon={false}
          />

          <Divider />
          <Flex justify="space-between">
            <Text fontSize="lg" fontWeight="semibold">
              Total
            </Text>
            <Text fontSize="xl" fontWeight="extrabold">
              {formatPrice(
                Number(contents_subtotal || 0) +
                  Number(taxSubtotal || 0) +
                  Number(fulfillmentSubtotal || 0),
              )}
            </Text>
          </Flex>
        </Stack>
      </Stack>
      <Stack spacing="8">
        <Button
          colorScheme="primary"
          size="lg"
          py="7"
          type="submit"
          isLoading={submitLoading}
          isDisabled={submitDisabled || !isPaymentComplete}
          data-test="place-order-btn"
        >
          Place Order
        </Button>
        {submitError ? <Text color="red.500" fontSize="sm">{`Error: ${submitError}`}</Text> : null}
        <Stack spacing="3">
          <Text fontSize="sm">Have questions? or Need help to complete your order?</Text>
          <HStack
            spacing="8"
            color={useColorModeValue('blue.500', 'blue.200')}
            fontWeight="semibold"
            justifyContent="center"
          >
            <Link href={`mailto:${supportEmail}`} isExternal>
              <HStack>
                <Icon as={HiOutlineMail} boxSize="5" />
                <Text>Email</Text>
              </HStack>
            </Link>
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};
