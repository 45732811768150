import {
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  usePopoverContext,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import FilterActionButtons, { FilterActionButtonsProps } from './filter-action-buttons';

type FilterPopoverContentProps = FilterActionButtonsProps & {
  header?: string;
  children?: ReactNode;
};

const FilterPopoverContent = (props: FilterPopoverContentProps): JSX.Element => {
  const { header, children, onClickCancel, onClickApply, isCancelDisabled, isApplyDisabled } =
    props;
  const { onClose } = usePopoverContext();
  return (
    <PopoverContent _focus={{ shadow: 'none', outline: 0 }} _focusVisible={{ shadow: 'outline' }}>
      {header && <PopoverHeader srOnly>{header}</PopoverHeader>}
      <PopoverBody padding="6">{children}</PopoverBody>
      <PopoverFooter>
        <FilterActionButtons
          onClickCancel={() => {
            onClickCancel?.();
            onClose();
          }}
          isCancelDisabled={isCancelDisabled}
          onClickApply={() => {
            onClickApply?.();
            onClose();
          }}
          isApplyDisabled={isApplyDisabled}
        />
      </PopoverFooter>
    </PopoverContent>
  );
};

export default FilterPopoverContent;
