import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  Text,
  Flex,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useToast,
  GridItem,
} from '@chakra-ui/react';
import { AttributeOption, FlatAttributeValuesType, Listing } from 'src/api/v1-api';
import { useAuth } from 'src/utils/auth';
import { useEffect, useState } from 'react';
import { apiRequest } from 'src/utils/fetchUtils';
import AttributeInput from 'src/components/atoms/attribute-input';
import { BasicInfoType, FilePath } from 'src/components/organisms/listing-form';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { categoryTypeLeadOrDirectory } from 'src/utils/categoryUtils';

interface ContactSellerModalProps {
  isOpen: boolean;
  onClose: () => void;
  listing: Listing | null;
  marketplace_name?: string;
}

const ContactSellerModal = ({
  isOpen,
  onClose,
  listing,
  marketplace_name,
}: ContactSellerModalProps) => {
  const { user, getToken, hasAdmin } = useAuth();
  const { marketplace } = useMarketplaceContext();
  const isAdmin = hasAdmin(marketplace?.id);
  const toast = useToast();

  const getProfileName = () => {
    if (!user) return '';

    return user.first_name + ' ' + user.last_name;
  };

  const [name, setName] = useState(getProfileName());
  const [email, setEmail] = useState(user?.email || '');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [leadAttributeValues, setLeadAttributeValues] = useState<FlatAttributeValuesType>({});
  const [invalidAttributes, setInvalidAttributes] = useState<number[]>([]);

  let success_message = 'Your message has been sent to the seller.';

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setName(getProfileName());
    }
  }, [user]);

  // conditionally set success message
  if (
    categoryTypeLeadOrDirectory(listing?.category?.type) &&
    listing?.category?.functional?.success_message
  ) {
    success_message = listing?.category?.functional?.success_message;
  }

  const handleAttributeChange = (
    attributeId: number | string,
    value: string | number | boolean | AttributeOption[] | FilePath[],
  ) => {
    setLeadAttributeValues((prevValues) => {
      return { ...prevValues, [attributeId]: value };
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    let isInvalid = false;
    const attribsCheck: number[] = [];
    listing?.category?.attributes.forEach((attr) => {
      if (attr.format !== 'number' && attr.format !== 'decimal') return;
      // check if value is greater than the max value or less than the min value
      const value = leadAttributeValues[attr?.id || ''];
      // the check done above ensures the typing is correct
      //@ts-ignore
      isInvalid =
        (attr.max_value !== '' && value && attr.max_value < value) ||
        (attr.min_value !== '' && value && attr.min_value > value);
      if (isInvalid) {
        attribsCheck.push(attr.id);
      }
    });

    setInvalidAttributes(attribsCheck);
    setIsSubmitting(false);

    if (attribsCheck.length > 0) {
      const errorTextArray = attribsCheck.map((attr) => {
        const attribute = listing?.category?.attributes.find((a) => a.id === attr);
        if (attribute?.max_value && attribute?.min_value) {
          return `"${attribute.name}" must be between ${attribute.min_value} and ${attribute.max_value}`;
        }
        if (attribute?.max_value) {
          return `"${attribute.name}" must be less than ${attribute.max_value}`;
        }
        if (attribute?.min_value) {
          return `"${attribute.name}" must be greater than ${attribute.min_value}`;
        }
      });
      toast({
        title: 'Unable to submit lead, please check the following inputs',
        description: `${errorTextArray.join(', ')}.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const url = `${process.env.NEXT_PUBLIC_API_HOST}/submit_lead/`;
    const body = {
      listing_id: listing?.id,
      name,
      email,
      phone,
      message,
      lead_attribute_values: JSON.stringify(leadAttributeValues),
    };
    const token = await getToken();

    try {
      await apiRequest('POST', url, token, body);
      toast({
        title: 'Info Submitted!',
        description: success_message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (err) {
      toast({
        title: 'Unable to submit lead',
        description: 'Please try again or contact support.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {listing?.category?.functional?.listings_cta_text || 'Contact Seller'}
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <FormControl id="name" isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            <FormControl id="email" isRequired mt={4}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={!!user?.email && !isAdmin}
              />
            </FormControl>

            <FormControl id="phone" mt={4}>
              <FormLabel>Phone (Optional)</FormLabel>
              <Input
                type="tel"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormControl>

            <FormControl id="message" mt={4}>
              <FormLabel>Message (Optional)</FormLabel>
              <Textarea
                placeholder="Enter your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormControl>

            {/* Lead Category Attributes Fields */}
            {listing?.category?.attributes
              ?.filter((attr) => attr.nested_content_type_name == 'Submitted Lead')
              ?.map((attribute) => {
                const value = leadAttributeValues[attribute.id] ?? undefined;
                const invalidAttrib = invalidAttributes.includes(attribute.id);
                return (
                  <GridItem colSpan={{ base: 2, md: 1 }} key={attribute.id} pt={4}>
                    <AttributeInput<BasicInfoType>
                      key={attribute.id}
                      attribute={attribute}
                      isInvalid={invalidAttrib}
                      width="full"
                      isMobileView
                      value={value}
                      setChange={(e) => {
                        if (invalidAttrib) {
                          setInvalidAttributes(
                            invalidAttributes.filter((attr) => attr !== attribute.id),
                          );
                        }
                        handleAttributeChange(attribute.id, e.target.value);
                      }}
                      setSelectChange={(e) => handleAttributeChange(attribute.id, e.target.value)}
                      setSwitchChange={(checked) => handleAttributeChange(attribute.id, checked)}
                      setMediaChange={(filePaths) => handleAttributeChange(attribute.id, filePaths)}
                      setMultiselectChange={(value) => handleAttributeChange(attribute.id, value)}
                    />
                  </GridItem>
                );
              })}
          </ModalBody>

          <ModalFooter flexDirection={'column'}>
            <Flex w={'full'} justifyContent={'end'}>
              <Button colorScheme="primary" mr={3} type="submit" isLoading={isSubmitting}>
                Submit
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Close
              </Button>
            </Flex>
            <Text fontStyle="italic" fontSize={'10px'} color="gray.700" mt={4}>
              By submitting form, a user account will be created for you automatically, if you do
              not already have one, and you consent to receive emails from seller and/or{' '}
              {marketplace_name || ''} (powered by Loma). You may request deletion of your email
              address at any time.
            </Text>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ContactSellerModal;
