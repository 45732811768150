export const setCookie = (name: string, value: string, options: any): void => {
  const cookieOptions = {
    path: '/',
    ...options,
  };
  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;
  for (const optionKey in cookieOptions) {
    updatedCookie += ` ${optionKey}`;
    const optionValue = cookieOptions[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue};`;
    }
  }
  document.cookie = updatedCookie;
};

export const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift() ?? null;
  }
  return null;
};
