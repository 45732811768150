import { useClipboard } from '@chakra-ui/react';
import { useEffect } from 'react';
import { MarketplaceInCategory } from 'src/api/v1-api';

export const getListingUrl = (urlBase: string, listingId: number | undefined) => {
  if (urlBase === '') return '';
  const newUrl = new URL(`listing/${listingId}`, urlBase);
  return newUrl.toString();
};

export const getOrderUrl = (urlBase: string, orderId: number | undefined) => {
  if (urlBase === '') return '';
  const newUrl = new URL(`order/${orderId}`, urlBase);
  return newUrl.toString();
};

export const getRoleAccountUrl = (urlBase: string, roleId: number | undefined) => {
  if (urlBase === '') return '';
  const newUrl = new URL(`account/roles/edit/${roleId}`, urlBase);
  return newUrl.toString();
};

export const isProdEnabled = () => {
  return process.env.NEXT_PUBLIC_ENV !== 'production';
};

export const SUPPORTED_AYRSHARE_SOCIAL_NETWORKS = ['instagram', 'youtube', 'facebook', 'twitter'];
export const INSTAGRAM_POST_NETWORK_TYPES = [
  {
    label: 'post',
    mediaTypes: ['image', 'video'],
  },
  {
    label: 'stories',
    mediaTypes: ['image', 'video'],
  },
  {
    label: 'reels',
    mediaTypes: ['video'],
  },
];
export const FACEBOOK_POST_NETWORK_TYPES = [
  {
    label: 'post',
    mediaTypes: ['image', 'video'],
  },
  {
    label: 'stories',
    mediaTypes: ['image', 'video'],
  },
  {
    label: 'reels',
    mediaTypes: ['video'],
  },
];
export const YOUTUBE_POST_NETWORK_TYPES = [
  {
    label: 'post',
    mediaTypes: ['video'],
  },
  {
    label: 'shorts',
    mediaTypes: ['video'],
  },
];

export const useCustomClipboard = (text: string) => {
  // useClipboard is stupid in the current version of Chakra UI, we can remove this after we upgrade to 2.8
  const { hasCopied, onCopy, setValue } = useClipboard(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  return {
    hasCopied,
    onCopy,
  };
};

export const getMarketplaceUrl = (marketplace: MarketplaceInCategory) => {
  let defaultDomain;
  switch (process.env.NEXT_PUBLIC_ENV) {
    case 'development':
      defaultDomain = 'localhost:3030';
      break;
    case 'staging':
      defaultDomain = 'staging.loma.tech';
      break;
    case 'production':
      defaultDomain = 'loma.tech';
      break;
    default:
      defaultDomain = 'loma.tech';
  }

  let marketplaceUrl = marketplace?.slug + '.' + defaultDomain;
  // Set the marketplace URL to the custom domain included in the marketplace object
  // since apexDomain will get set async and may not be available when the page loads
  if (marketplace?.domain?.domain && marketplace?.domain?.status === 'active') {
    marketplaceUrl = marketplace.domain.domain;
  }

  // Only add https:// if we're not in development
  marketplaceUrl =
    process.env.NEXT_PUBLIC_ENV === 'development'
      ? `http://${marketplaceUrl}`
      : `https://${marketplaceUrl}`;

  return marketplaceUrl;
};
