import { Box, Text, Image, AspectRatio, useTheme } from '@chakra-ui/react';
import NextLink from 'next/link';
import 'mapbox-gl/dist/mapbox-gl.css';
import { PriceTag } from 'src/components/atoms';
import { Listing } from 'src/api/v1-api';
import { srcFromCDN } from 'src/utils/files';
import { markListingAsBroken } from 'src/utils/listingUtils';

interface Props {
  listing: Listing;
  imageHeight: number;
  address: string;
}

const PinCard = ({ listing, imageHeight, address }: Props) => {
  const theme = useTheme();
  const listingImages = listing.listing_images ?? [];
  const listingImage =
    listingImages.find((item) => item.is_featured) ??
    (listingImages.length > 0 ? listingImages[0] : null);

  const onImageError = () => {
    console.error('Image error', listing.id, listing.source?.toLowerCase());
    if (listing?.source?.toLowerCase() === 'facebook') {
      console.error('Removing listing from display list', listing.id);
      markListingAsBroken(listing.id);
    }
  };

  return (
    <NextLink href={`/listing/${listing.id}`} passHref>
      <Box padding={2} position="relative">
        <Text
          fontSize={14}
          fontWeight={500}
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          data-test="map-listing-title"
        >
          {listing.title}
        </Text>
        <Box width="100%" marginTop={1} display="flex" gap={2}>
          {listingImage?.image && (
            <AspectRatio ratio={1} width={imageHeight} flexShrink={0}>
              <Image
                src={srcFromCDN(listingImage?.image, imageHeight * 4)}
                width={imageHeight}
                height={imageHeight}
                alt={listingImage?.alt_text ?? 'Listing Thumbnail'}
                borderRadius={theme.border_radius.border_radius_1}
                onError={onImageError}
              />
            </AspectRatio>
          )}
          <Box
            flexGrow={1}
            minWidth={0}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={1}
          >
            <PriceTag
              regularPrice={listing.lowest_regular_price}
              regularPriceProps={{
                fontSize: 14,
                fontWeight: 400,
              }}
              offeredPrice={listing.lowest_offered_price}
              offeredPriceProps={{
                fontSize: 14,
                fontWeight: 400,
              }}
            />
            {address && (
              <Text fontSize={14} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {address}
              </Text>
            )}
            <Text fontSize={14} color="blue.600" textDecoration="underline">
              See more
            </Text>
          </Box>
        </Box>
      </Box>
    </NextLink>
  );
};

export default PinCard;
