import { basename } from 'path';
import { Parser } from '@json2csv/plainjs';
import * as _ from 'lodash';
import { DEFAULT_IMAGE, TRANSFORM_CDN_DOMAIN } from 'src/constants/config';
import { SITE_MAX_WIDTH } from 'src/constants/ui';

export const toFormData = (body: any) => {
  const formData = new FormData();

  for (const key of Object.keys(body)) {
    formData.append(key, body[key]);
  }

  return formData;
};

export const rawSrcFromCDN = (src: string | null | undefined, cdn = 'transform') => {
  if (!src) return '';
  // loma-files.s3.us-west-1.amazonaws.com is the S3 that holds all files (image/video/PDF/etc.)
  // dyn48y1sx7ytn.cloudfront.net is the image transform CDN
  // dfib8oe3w8azk.cloudfront.net is the generic file CDN
  let newSrc = src.trim();
  if (!newSrc.startsWith('http')) {
    if (newSrc.startsWith('/')) {
      newSrc = 'https://loma-files.s3.us-west-1.amazonaws.com' + newSrc;
    } else {
      newSrc = 'https://loma-files.s3.us-west-1.amazonaws.com/' + newSrc;
    }
  }
  // some S3 URLs don't have us-west-1, add it for consistency
  newSrc = newSrc.replace('loma-files.s3.amazonaws.com', 'loma-files.s3.us-west-1.amazonaws.com');

  if (encodeURIComponent(basename(newSrc)).includes('%')) {
    // skip the image CDN encoding bug for current images
    return newSrc;
  }

  if (cdn === 'transform') {
    newSrc = newSrc.replace(
      'loma-files.s3.us-west-1.amazonaws.com',
      'dyn48y1sx7ytn.cloudfront.net',
    );
  }
  if (cdn === 'generic') {
    newSrc = newSrc.replace(
      'loma-files.s3.us-west-1.amazonaws.com',
      'dfib8oe3w8azk.cloudfront.net',
    );
  }

  return newSrc;
};

export const srcFromCDN = (
  src: string | null | undefined,
  width: number,
  cdn?: string,
  columns?: number,
) => {
  const cdnAction = cdn ? cdn : 'transform';
  const cols = columns ? columns : 1;

  if (!src) return DEFAULT_IMAGE;

  const cdnSrc = rawSrcFromCDN(src, cdnAction);

  if (cdnAction !== 'transform') return src;
  if (!cdnSrc.includes(TRANSFORM_CDN_DOMAIN)) return src;

  // if a width is provided, double the width for Retina displays
  // if we are using default values (site max and 1 column), don't double the image because half-screen is a sufficient default
  const imageWidth =
    width === SITE_MAX_WIDTH && cols === 1 ? SITE_MAX_WIDTH : Math.round((width * 2) / cols);

  return `${cdnSrc}?format=auto&width=${imageWidth}`;
};

const getFields = (data: unknown[]) => _.chain(data).head().keys().value();

export const downloadJsonAsCsv = (fileName: string) => (data: unknown[], fields?: string[]) => {
  const fieldsToExport = fields ?? getFields(data);

  const options = { fields: fieldsToExport };
  const parser = new Parser(options);
  const csv = parser.parse(data);

  const link = document.createElement('a');
  const blob = new Blob(['\ufeff', csv]);
  const url = URL.createObjectURL(blob);

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
