import { SimpleGrid } from '@chakra-ui/react';
import { take } from 'lodash';
import React, { ReactNode } from 'react';
import { Subcategory } from 'src/api/v1-api/helpers';

export type CategoryCardAdaptiveGridProps = {
  items: Subcategory[];
  onRender: (item: Subcategory) => ReactNode;
};

const gap = { base: 4, lg: 6 };

const CategoryCardAdaptiveGrid = ({ items, onRender }: CategoryCardAdaptiveGridProps) => {
  if (items.length === 0) {
    return null;
  }

  if (items.length === 1) {
    return (
      <SimpleGrid columns={{ base: 3 }} gap={gap}>
        {onRender(items[0])}
      </SimpleGrid>
    );
  }

  if (items.length === 2) {
    return (
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={gap}>
        {items.map(onRender)}
      </SimpleGrid>
    );
  }

  if (items.length === 4) {
    return (
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={gap}>
        {items.map(onRender)}
      </SimpleGrid>
    );
  }

  if (items.length === 5) {
    const firstItems = take(items, 2);
    const restItems = items.slice(items.length - 3, items.length);

    return (
      <>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={gap}>
          {firstItems.map(onRender)}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={gap}>
          {restItems.map(onRender)}
        </SimpleGrid>
      </>
    );
  }

  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={gap}>
      {items.map(onRender)}
    </SimpleGrid>
  );
};

export default CategoryCardAdaptiveGrid;
