import React, { useEffect, useState } from 'react';
import useRequest from 'src/utils/hooks/useRequest';
import { Listing, Category, BasicCategory } from 'src/api/v1-api';
import { Fade, Button, Text, Flex, useMediaQuery } from '@chakra-ui/react';
import NextLink from 'next/link';
import ListingCarousel from 'src/components/atoms/listing-carousel';

interface Props {
  listingId: number | undefined;
  categoryId: number | undefined;
  categories: (Category | BasicCategory)[] | undefined;
}

interface PaginatedListingResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Listing[];
}

const checkIfNumber = (value: any): value is number => {
  return typeof value === 'number';
};

const ViewMoreListings = ({ listingId, categoryId, categories }: Props) => {
  const category = categories?.find((category) => category.id === categoryId);
  const { get } = useRequest();
  const [listings, setListings] = useState<Listing[]>([]);

  const columns = 6;

  // 'md' ~ 768px is the breakpoint for mobile https://v2.chakra-ui.com/docs/styled-system/responsive-styles
  const [isMobile] = useMediaQuery('(max-width: 768px)', {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  });

  let categoryIdsForRequest: number[] | undefined = category?.id ? [category?.id] : [];
  const catParentId = checkIfNumber(category?.parent) ? category?.parent : category?.parent?.id;
  const categoryParent = categories?.find((cat) => cat.id === catParentId);
  if (category?.functional.create_listing_category_select_format === 'pay_to_list_child') {
    categoryIdsForRequest = categories
      ?.filter((cat) => {
        const currParentId = checkIfNumber(cat.parent) ? cat.parent : cat.parent?.id;
        return currParentId === categoryParent?.id;
      })
      .map((cat) => cat.id);
  }

  useEffect(() => {
    const fetchListings = async () => {
      const fetchListing = async (catId: number) => {
        if (!catId) return;
        const url = `/listings/category/${catId.toString()}/?page_size=13`;

        try {
          const data = await get<PaginatedListingResponse>({ url: url, useToken: false });
          const fetchedListings = data.results;
          let filteredListings = fetchedListings.filter(
            (listing) => listing.id !== listingId && listing.status !== 'sold',
          );
          filteredListings = filteredListings.slice(0, 12);

          return filteredListings;
        } catch (error) {
          console.error('Error fetching listings:', error);
        }
      };

      const promisesArr = categoryIdsForRequest
        ? categoryIdsForRequest.map((catId) => fetchListing(catId))
        : [];
      const listingsArr = await Promise.all(promisesArr);
      setListings(listingsArr.flat().filter((listing) => listing !== undefined) as Listing[]);
    };
    if (category && listingId && listings.length === 0) {
      fetchListings();
    }
  }, [category, listings.length]);

  const displayCssProp = {
    base: listings.length >= 2 ? 'block' : 'none',
    md: listings.length >= 2 ? 'block' : 'none',
    lg: listings.length >= columns ? 'block' : 'none',
  };

  // dont render if fewer than 2 listings on mobile, or fewer than 4 on desktop
  const shouldRender = (isMobile && listings.length >= 2) || (!isMobile && listings.length >= 4);
  return (
    <>
      {shouldRender && (
        <Fade in={true} style={{ transitionDuration: '1s' }}>
          <Flex direction="column" mb={8} position="relative" display={displayCssProp}>
            <ListingCarousel
              listings={listings}
              columns={columns}
              titleText="You may also like"
              category={category}
            />
          </Flex>
        </Fade>
      )}
    </>
  );
};

export default ViewMoreListings;
