import { HStack, Icon, Text, Th } from '@chakra-ui/react';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';
import { SortModel } from 'src/types';

interface Props {
  field: string;
  label: string;
  sortable?: boolean;
  sort?: SortModel;
  disabled?: boolean;
  onSortChange?: (field: string) => void;
}

const TableHeaderCell = ({
  field,
  label,
  sortable = false,
  sort,
  disabled = false,
  onSortChange,
}: Props): JSX.Element => {
  return (
    <Th
      cursor={disabled ? 'default' : 'pointer'}
      color="inherit"
      textTransform="initial"
      onClick={() => {
        if (!disabled && sortable && field && onSortChange) {
          onSortChange(field);
        }
      }}
    >
      <HStack spacing="1">
        <Text>{label ?? ''}</Text>
        {field && sortable && field === sort?.field && sort.order && (
          <Icon as={sort.order === 'asc' ? IoArrowUp : IoArrowDown} boxSize="4" />
        )}
      </HStack>
    </Th>
  );
};

export default TableHeaderCell;
