import { BasicAttributeValue, Listing, MarketplaceInfo } from 'src/api/v1-api';
import { SingleAttributeDisplay } from 'src/components/atoms/';

interface Props {
  attributeValues: BasicAttributeValue[];
  listing?: Listing | null;
  marketplace?: MarketplaceInfo | null;
  noMedia?: boolean;
  isSummary?: boolean;
}

const AttributeValuesDisplay = ({
  attributeValues,
  listing,
  marketplace,
  noMedia,
  isSummary,
}: Props) => {
  if (!attributeValues || attributeValues.length === 0) return null;
  return (
    <>
      {attributeValues?.map((attributeValue) => (
        <SingleAttributeDisplay
          attributeValue={attributeValue}
          key={attributeValue.id}
          listing={listing}
          marketplace={marketplace}
          noMedia={noMedia}
          isSummary={isSummary}
        />
      ))}
    </>
  );
};

export default AttributeValuesDisplay;
