import { DeleteIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  Code,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {
  GlobalPromoMediaType,
  PromoAutomationTemplate,
  PromoAutomationTemplateNamespaceMapping,
} from 'src/api/v1-api';
import { LomaUploader } from 'src/components/molecules';
import { DEFAULT_IMAGE } from 'src/constants/config';
import { srcFromCDN } from 'src/utils/files';
import * as _ from 'lodash';
import { FilePath } from '../listing-form';
import { BiTrash } from 'react-icons/bi';

interface PromoAutomationTemplateFormProps {
  template: Partial<PromoAutomationTemplate>;
  globalPromoMediaTypeNamespaceMapping: PromoAutomationTemplateNamespaceMapping | undefined;
  globalPromoMediaTypes: GlobalPromoMediaType[];
  updateTemplateValues: (template: Partial<PromoAutomationTemplate>) => void;
  previewPromoMedia: () => void;
  removeTemplate: () => void;
  canRemoveTemplate: boolean;
}

const PromoAutomationTemplateForm = ({
  template,
  globalPromoMediaTypeNamespaceMapping,
  globalPromoMediaTypes,
  updateTemplateValues,
  previewPromoMedia,
  removeTemplate,
  canRemoveTemplate,
}: PromoAutomationTemplateFormProps) => {
  const { use_static_image, promo_image_url, global_promo_media_type_id, template_values } =
    template;

  const selectedGlobalPromoMediaType =
    globalPromoMediaTypes.find((g) => g.id === global_promo_media_type_id) ??
    globalPromoMediaTypes[0];

  const getCustomizableFields: any = (template: any = {}) => {
    if (template.customizable) {
      if (!template.customizable.id) {
        return _.values(template.customizable);
      }
      return template.customizable;
    }

    if (_.isObject(template)) {
      return _.map(template, getCustomizableFields);
    }
  };

  const customizableTemplatebleFields = _.chain(
    getCustomizableFields(selectedGlobalPromoMediaType?.template),
  )
    .flattenDeep()
    .reject(_.isNil)
    .value();

  const onFileUpload = async (filePaths: FilePath[]) => {
    if (!updateTemplateValues || !filePaths[0]?.filePath) {
      return;
    }
    updateTemplateValues({ ...template, promo_image_url: filePaths[0]?.filePath });
  };

  const updateTemplateValue = (fieldId: string, value: string) => {
    updateTemplateValues({
      ...template,
      template_values: { ...template_values, [fieldId]: value },
    });
  };

  return (
    <Stack gap={4}>
      <Flex direction="column" mb={4}>
        <FormControl isRequired>
          <FormLabel m={0} mr={4}>
            Select Media Template
          </FormLabel>
        </FormControl>
      </Flex>
      <RadioGroup
        name="useStaticImage"
        value={use_static_image ? 'Use static image' : 'Use media template'}
        onChange={(value) => {
          updateTemplateValues({ ...template, use_static_image: value === 'Use static image' });
        }}
      >
        <Flex direction="column">
          <Radio value={'Use static image'} ml={{ base: 0, md: '0.5rem' }} mt={2}>
            Use static image
          </Radio>
          <Radio value="Use media template" ml={{ base: 0, md: '0.5rem' }} mt={2}>
            Use media template
          </Radio>
        </Flex>
      </RadioGroup>
      {use_static_image && (
        <Flex direction="column" gap={4}>
          {promo_image_url && (
            <Flex direction="column" gap={4} mb={12}>
              <Image
                src={promo_image_url}
                alt="Background Image"
                maxW="200px"
                fallbackSrc={DEFAULT_IMAGE}
              />
              <Button
                leftIcon={<DeleteIcon />}
                onClick={() => updateTemplateValues({ ...template, promo_image_url: '' })}
              >
                Remove image
              </Button>
            </Flex>
          )}
          <Flex direction="column" gap={4}>
            {promo_image_url && <Text>Upload new image</Text>}
            <LomaUploader
              maxFiles={1}
              onFileDelete={() => updateTemplateValues({ ...template, promo_image_url: '' })}
              onFileUpload={onFileUpload}
              filePathsLength={promo_image_url !== '' ? 1 : 0}
            />
          </Flex>
        </Flex>
      )}
      {!use_static_image && (
        <FormControl
          id="global_promo_media_type_id"
          isRequired
          gap={4}
          display="flex"
          flexDirection="column"
        >
          <Image
            src={srcFromCDN(selectedGlobalPromoMediaType?.sample_image_url, 200)}
            alt="Sample"
            maxW="200px"
            fallbackSrc={DEFAULT_IMAGE}
          />
          <RadioGroup
            name="global_promo_media_type_id"
            value={global_promo_media_type_id?.toString()}
            onChange={(value) => {
              updateTemplateValues({ ...template, global_promo_media_type_id: parseInt(value) });
            }}
          >
            <Stack
              gap={2}
              w="100%"
              flexWrap="wrap"
              direction={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {globalPromoMediaTypes.map((globalPromoMediaType, index) => (
                <Radio
                  w={{ base: '100%', md: '45%' }}
                  key={globalPromoMediaType.id}
                  value={globalPromoMediaType.id.toString()}
                  defaultChecked={index === 0}
                  ml={{ base: 0, md: '0.5rem' }}
                  mt={2}
                >
                  <Flex direction="row" alignItems="center" gap={2}>
                    {globalPromoMediaType.name}
                    <Badge p={1} rounded="lg" colorScheme="cyan">
                      {globalPromoMediaType.media_type}
                    </Badge>
                    <Badge p={1} rounded="lg" colorScheme="blue">
                      {globalPromoMediaType.entity_type_name}
                    </Badge>
                  </Flex>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
          {customizableTemplatebleFields.length > 0 && (
            <Stack>
              <Flex direction={{ base: 'column-reverse', lg: 'row' }} gap={10}>
                <Flex direction="column" gap={4} basis="100%">
                  <Text mt={4} fontSize="md" color="gray.500" fontWeight="bold">
                    Customize the template fields below
                  </Text>
                  {customizableTemplatebleFields.map(
                    (field: { id: string; label: string; type: string }) => (
                      <FormControl key={field.id}>
                        <FormLabel>{field.label}</FormLabel>
                        <Input
                          type={field.type}
                          value={template_values[field.id]}
                          onChange={(e) => updateTemplateValue(field.id, e.target.value)}
                        />
                      </FormControl>
                    ),
                  )}
                </Flex>
                <Flex
                  direction="column"
                  borderLeft={{ base: 'none', lg: '1px solid #D0D0D0' }}
                  paddingLeft={{ base: 0, lg: 8 }}
                  basis="100%"
                >
                  <Text mt={4} fontSize="md" color="gray.500" fontWeight="bold">
                    Dynamic fields
                  </Text>
                  <Text my={4} fontSize="sm">
                    Find below the available dynamic fields that you can use in the template.
                    <br />
                    You can use these fields in the template by wrapping them in double curly
                    braces. For example, if you want to use the title of the listing, you can use{' '}
                    <Code>{'Check this {{listing.title}}'}</Code>
                  </Text>
                  {globalPromoMediaTypeNamespaceMapping?.namespace_mapping && (
                    <ul style={{ marginLeft: '24px' }}>
                      {globalPromoMediaTypeNamespaceMapping.namespace_mapping.map((field) => (
                        <li key={field}>
                          {field}
                          {globalPromoMediaTypeNamespaceMapping.fields_description &&
                            globalPromoMediaTypeNamespaceMapping.fields_description[field] && (
                              <ul style={{ marginLeft: '16px' }}>
                                <li>
                                  Type:{' '}
                                  {
                                    globalPromoMediaTypeNamespaceMapping.fields_description[field]
                                      .type
                                  }
                                </li>
                                {globalPromoMediaTypeNamespaceMapping.fields_description[field]
                                  .type === 'method' &&
                                  globalPromoMediaTypeNamespaceMapping.fields_description[field]
                                    .arguments && (
                                    <li>
                                      Arguments:
                                      <ul style={{ marginLeft: '16px' }}>
                                        {(
                                          globalPromoMediaTypeNamespaceMapping.fields_description[
                                            field
                                          ].arguments ?? []
                                        ).map((arg) => (
                                          <li key={arg}>{arg}</li>
                                        ))}
                                      </ul>
                                    </li>
                                  )}
                                {globalPromoMediaTypeNamespaceMapping.fields_description[field]
                                  .examples && (
                                  <li>
                                    Examples:
                                    <ul style={{ marginLeft: '16px' }}>
                                      {globalPromoMediaTypeNamespaceMapping.fields_description[
                                        field
                                      ].examples.map((example) => (
                                        <li key={example}>
                                          <Code>{example}</Code>
                                        </li>
                                      ))}
                                    </ul>
                                  </li>
                                )}
                              </ul>
                            )}
                        </li>
                      ))}
                    </ul>
                  )}
                </Flex>
              </Flex>
            </Stack>
          )}
        </FormControl>
      )}
      <Flex direction="row" alignItems="center" justify="space-between">
        {selectedGlobalPromoMediaType?.media_type === 'image' && !use_static_image && (
          <Button colorScheme="secondary" type="button" onClick={previewPromoMedia}>
            Preview
          </Button>
        )}
        {canRemoveTemplate && (
          <Button leftIcon={<BiTrash />} onClick={removeTemplate}>
            Remove
          </Button>
        )}
      </Flex>
    </Stack>
  );
};

export default PromoAutomationTemplateForm;
