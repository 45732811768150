import {
  Box,
  Button,
  Input,
  Stack,
  FormControl,
  FormLabel,
  Textarea,
  Spinner,
  Flex,
  Avatar,
  useToast,
  Heading,
} from '@chakra-ui/react';
import { useContext, useState, useEffect } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';
import { apiRequest } from 'src/utils/fetchUtils';
import { Attribute, BasicAttributeValue } from 'src/api/v1-api';
import NextLink from 'next/link';
import AttributeList from 'src/components/molecules/attribute-list';

const MarketplaceAccountForm = () => {
  const toast = useToast();
  const { marketplace } = useContext(MarketplaceContext);
  const { getToken } = useAuth();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [handle, setHandle] = useState('');
  const [profilePhoto, setProfilePhoto] = useState('');
  const [profilePhotoFile, setProfilePhotoFile] = useState<File | null>(null);
  const [bio, setBio] = useState('');
  const [attributeValues, setAttributeValues] = useState<BasicAttributeValue[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  const fetchMarketplaceAccount = async () => {
    if (!isLoading && marketplace) {
      setIsLoading(true);
      const token = await getToken();
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/marketplace_account/${marketplace?.id}/`;

      try {
        const data = await apiRequest('GET', url, token);
        setFirstName(data.user.first_name ?? '');
        setLastName(data.user.last_name ?? '');
        setEmail(data.user.email ?? '');
        setHandle(data.handle ?? '');
        setProfilePhoto(data.image_url ?? '');
        setBio(data.bio ?? '');
        setAttributeValues(data.account_attribute_values);
      } catch (error) {
        toast({
          title: 'Error.',
          description: 'Unable to fetch account information. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
      }
      setIsLoading(false);
    }
  };

  const fetchMarketplaceAccountAttributes = async () => {
    if (marketplace) {
      setIsLoading(true);
      const attributeUrl = `${process.env.NEXT_PUBLIC_API_HOST}/marketplace_account_attributes/${marketplace.id}/`;
      try {
        const attributes = await apiRequest('GET', attributeUrl, null);
        setAttributes(attributes);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMarketplaceAccount();
    fetchMarketplaceAccountAttributes();
  }, [marketplace]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    const token = await getToken();
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/marketplace_account/update/${marketplace?.id}/`;

    const formData = new FormData();
    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
    };

    formData.append('user', JSON.stringify(userData));
    formData.append('handle', handle);
    formData.append('bio', bio);
    if (profilePhotoFile) {
      formData.append('image', profilePhotoFile);
    }
    formData.append('account_attribute_values', JSON.stringify(attributeValues));

    try {
      await apiRequest('PATCH', url, token, formData, true);
      toast({
        title: 'Success',
        description: 'Account information saved.',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'bottom',
      });
    } catch (error) {
      toast({
        title: 'Error.',
        description: 'Unable to save account information. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
    setIsSubmitting(false);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setProfilePhotoFile(file);
    if (file) {
      setProfilePhoto(URL.createObjectURL(file));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack justify="flex-start" align="flex-start" spacing="28px">
        <Heading fontSize="2xl">Personal Information</Heading>
        <Box position="relative">
          <Stack justify="flex-start" align="flex-start" spacing="20px">
            <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px">
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  placeholder="-"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  placeholder="-"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px">
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="e@mail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px">
              <FormControl>
                <FormLabel>Handle</FormLabel>
                <Input placeholder="@" value={handle} onChange={(e) => setHandle(e.target.value)} />
              </FormControl>
            </Stack>
            <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px">
              <FormControl>
                <FormLabel>Profile Photo</FormLabel>
                <Flex align="center">
                  <Box ml={4}>
                    {profilePhoto && <Avatar src={profilePhoto} boxSize={10} objectFit="cover" />}
                  </Box>
                  <Box pl={4}>
                    <Input
                      type="file"
                      id="file-upload"
                      onChange={handleImageUpload}
                      accept="image/*"
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="file-upload">
                      <Button as="span" colorScheme="gray" size="sm">
                        Choose File
                      </Button>
                    </label>
                  </Box>
                </Flex>
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              justify="flex-start"
              align="flex-start"
              spacing="20px"
              width="100%"
            >
              <FormControl>
                <FormLabel>Bio</FormLabel>
                <Textarea
                  placeholder="Say something about yourself"
                  value={bio}
                  onChange={(event) => setBio(event.target.value)}
                  width="100%"
                  data-test="bio-input"
                />
              </FormControl>
            </Stack>

            {/* Loop through attributes and render them here */}
            <AttributeList
              attributes={attributes}
              attributeValues={attributeValues}
              setAttributeValues={setAttributeValues}
              direction="row"
            />
          </Stack>

          {isLoading && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              backgroundColor="rgba(255, 255, 255, 0.7)"
            >
              <Spinner />
            </Box>
          )}
        </Box>
      </Stack>
      <Box display="flex" justifyContent="flex-end" pt={10}>
        <NextLink href="/">
          <Button variant="outline" colorScheme="primary" mr={4}>
            Cancel
          </Button>
        </NextLink>
        <Button isLoading={isSubmitting} colorScheme="primary" type="submit" data-test="submit-btn">
          Save
        </Button>
      </Box>
    </form>
  );
};

export default MarketplaceAccountForm;
