import { Stack, Text, Box, Spinner, Flex, useToast, Button } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { MarketplacePayout } from 'src/api/v1-api';
import useRequest from 'src/utils/hooks/useRequest';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';
import { formatPrice } from 'src/utils/common';
import PaymentsHistoryTable from 'src/components/organisms/payments-history-table';
import MarketplaceEarningsTable from 'src/components/organisms/marketplace-earnings-table';
import RegisterPayoutModal from 'src/components/organisms/register-payout-modal';

const MarketplacePayoutInfo = (): JSX.Element => {
  const toast = useToast();
  const { marketplace } = useMarketplaceContext();
  const { api } = useRequest();
  const { hasStaff, isAuthenticated } = useAuth();
  const [marketplacePayouts, setMarketplacePayouts] = useState<MarketplacePayout[]>([]);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isStaffUser = hasStaff();

  const fetchData = async () => {
    if (!marketplace || !isAuthenticated) {
      return;
    }

    setIsLoading(true);
    try {
      const [responseBalance, responseMarketplacePayouts] = await Promise.all([
        api.marketplace.payouts.balance(marketplace.id),
        api.marketplace.payouts.list(marketplace.id),
      ]);
      setTotalBalance(responseBalance.balance);
      setMarketplacePayouts(responseMarketplacePayouts);
    } catch (err) {
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace?.id, isAuthenticated]);

  const lastPaidDate = marketplacePayouts.length > 0 ? marketplacePayouts[0].date_of_payout : '';

  const registerPayout = async (payout: Partial<MarketplacePayout>) => {
    if (!payout || !marketplace) return;

    try {
      setIsLoading(true);

      const response = await api.marketplace.payouts.create(marketplace.id, payout);
      setMarketplacePayouts([response, ...marketplacePayouts]);
      setTotalBalance(totalBalance - response.amount);

      toast({
        title: 'Payout registered successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch {
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack justify="flex-start" align="flex-start" spacing="28px" maxWidth="100%" width="100%">
      <Box position="relative" w="100%">
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          align="flex-start"
          spacing="20px"
          overflowX="auto"
          w="100%"
        >
          <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px">
            {isLoading && (
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
                backgroundColor="rgba(255, 255, 255, 0.7)"
              >
                <Spinner />
              </Box>
            )}
            <Flex direction="column" justify="flex-start" align="flex-start">
              <Text fontWeight="bold">Amount:</Text>
              <Text>{formatPrice(totalBalance)}</Text>
              <Text fontSize="xs" fontWeight="thin" pt={2} pb={6}>
                Balance may take 1-2 days to update.
              </Text>
            </Flex>
            {lastPaidDate && (
              <Flex direction="column" justify="flex-start" align="flex-start">
                <Text fontWeight="bold">Last Paid:</Text>
                <Text>{lastPaidDate}</Text>
              </Flex>
            )}
          </Stack>
          {isStaffUser && totalBalance > 0 && (
            <Button onClick={() => setShowPayoutModal(true)}>Mark as paid</Button>
          )}
        </Stack>
      </Box>
      <MarketplaceEarningsTable />
      {marketplacePayouts.length > 0 && <PaymentsHistoryTable payouts={marketplacePayouts} />}
      <RegisterPayoutModal
        isOpen={showPayoutModal}
        onClose={() => setShowPayoutModal(false)}
        registerPayoutHandler={registerPayout}
        totalAmount={totalBalance}
        marketplace={marketplace}
      />
    </Stack>
  );
};

export default MarketplacePayoutInfo;
