import { Button, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useRouter } from 'next/router';
import useRequest from 'src/utils/hooks/useRequest';

interface RelistAuctionButtonProps {
  auctionId: number;
}

const RelistAuctionButton = ({ auctionId }: RelistAuctionButtonProps) => {
  const { api } = useRequest();
  const toast = useToast();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const relistAuction = async () => {
    setIsLoading(true);
    try {
      await api.marketplace.auctions.relist.post(auctionId);
      router.reload();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Could not relist the auction.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      colorScheme="red"
      variant="outline"
      size="xs"
      onClick={relistAuction}
      isLoading={isLoading}
    >
      Re-list Auction
    </Button>
  );
};

export default RelistAuctionButton;
