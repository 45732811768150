import { FC, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';

interface ToastComponentProps {
  id?: string;
  title?: string;
  description?: string;
  status?: 'info' | 'warning' | 'success' | 'error';
  duration?: number;
  isClosable?: boolean;
}

const ToastComponent: FC<ToastComponentProps> = ({
  id = 'unique-toast-id',
  title = 'Oops! An Error Occurred',
  description = "We're sorry for the inconvenience. Contact support if the issue persists.",
  status = 'error',
  duration = 5000,
  isClosable = true,
}) => {
  const toast = useToast();

  useEffect(() => {
    const existingToasts = toast.isActive(id);

    if (!existingToasts) {
      toast({
        title,
        description,
        status,
        duration,
        isClosable,
        position: 'top-right',
      });
    }
  }, [toast, title, description, status, duration, isClosable]);

  return null;
};

export default ToastComponent;
