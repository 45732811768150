export type Option = {
  label: string;
  value: string;
};

export const attributeFormats: Option[] = [
  // Note: Some formats are hidden until their functionality is implemented
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Decimal',
    value: 'decimal',
  },
  {
    label: 'Boolean',
    value: 'boolean',
  },
  // {
  //   label: 'Date',
  //   value: 'date',
  // },
  // {
  //   label: 'Date Time',
  //   value: 'datetime',
  // },
  // {
  //   label: 'Time',
  //   value: 'time',
  // },
  // {
  //   label: 'Duration',
  //   value: 'duration',
  // },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'URL',
    value: 'url',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  // {
  //   label: 'Image',
  //   value: 'image',
  // },
  // {
  //   label: 'File',
  //   value: 'file',
  // },
  // {
  //   label: 'JSON',
  //   value: 'json',
  // },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'Select',
    value: 'select',
  },
  {
    label: 'Multi Select',
    value: 'multiselect',
  },
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Media',
    value: 'media',
  },
];

export const attributeSelectFormats: Option[] = [
  // Note: Some formats are hidden until their functionality is implemented
  // {
  //   label: 'Autocomplete',
  //   value: 'autocomplete',
  // },
  {
    label: 'Dropdown',
    value: 'dropdown',
  },
  // {
  //   label: 'Multi-select',
  //   value: 'multiselect',
  // },
  // {
  //   label: 'Radio',
  //   value: 'radio',
  // },
  // {
  //   label: 'Checkbox',
  //   value: 'checkbox',
  // },
];

export const attributeNestedContentTypes: Option[] = [
  {
    label: 'Listing',
    value: 'Listing',
  },
  {
    label: 'Variation',
    value: 'Variant',
  },
];

export const mediaAttributeFileTypes: Option[] = [
  {
    label: 'Image',
    value: 'image',
  },
  {
    label: 'Video',
    value: 'video',
  },
  {
    label: 'PDF',
    value: 'pdf',
  },
];

export const hiddenFilterAttributeFormats = [
  'phone',
  'media',
  'url',
  'email',
  'duration',
  'image',
  'file',
  'json',
];
