import { Flex, HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';

type OrderSummaryItemProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
  showIcon: boolean;
};

export const OrderSummaryItem = (props: OrderSummaryItemProps): JSX.Element => {
  const { label, value, children, showIcon } = props;
  return (
    <Flex justify="space-between" fontSize="sm">
      <HStack fontWeight="medium" color="gray.600" alignItems="center">
        <Text>{label}</Text>
        {showIcon ? (
          <Tooltip
            hasArrow
            label={`${label} will be calculated at Checkout`}
            colorScheme="primary"
            placement="top"
            p={2}
          >
            <span>
              <Icon as={MdInfoOutline} boxSize={4} />
            </span>
          </Tooltip>
        ) : null}
      </HStack>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  );
};
