import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  FormHelperText,
} from '@chakra-ui/react';
import { PromoAutomation } from 'src/api/v1-api';
import CategorySelector from 'src/components/molecules/category-selector';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';

interface DuplicatePromoAutomationModalProps {
  isOpen: boolean;
  onClose: () => void;
  promoAutomation: PromoAutomation | null;
  onDuplicatePromoAutomation: (promoAutomation: PromoAutomation, categoryId: number) => void;
}

const DuplicatePromoAutomationModal = ({
  isOpen,
  onClose,
  promoAutomation,
  onDuplicatePromoAutomation,
}: DuplicatePromoAutomationModalProps) => {
  const [categoryId, setCategoryId] = useState<number | undefined>(promoAutomation?.entity_id);
  const [manualCategoryId, setManualCategoryId] = useState<string>('');
  const { marketplace } = useContext(MarketplaceContext);
  const { hasStaff } = useAuth();
  const toast = useToast();
  const isStaffUser = hasStaff();

  // Reset states when modal opens/closes
  useEffect(() => {
    if (isOpen) {
      setCategoryId(promoAutomation?.entity_id);
      setManualCategoryId('');
    }
  }, [promoAutomation?.entity_id, isOpen]);

  const handleCategorySelect = (category: { id: number } | null) => {
    // Only update categoryId if there's no manual input
    if (!manualCategoryId) {
      setCategoryId(category?.id);
    }
  };

  const handleManualCategoryIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setManualCategoryId(value);

    if (value === '') {
      // If manual input is cleared, revert to selected category
      const selectedId = document
        .querySelector('[data-test="category-selector"]')
        ?.getAttribute('value');
      setCategoryId(selectedId ? parseInt(selectedId, 10) : undefined);
      return;
    }

    // Update categoryId with manual value
    const numValue = parseInt(value, 10);
    if (!isNaN(numValue) && numValue > 0) {
      setCategoryId(numValue);
    }
  };

  // Get the effective category ID (manual takes precedence)
  const effectiveCategoryId = manualCategoryId ? parseInt(manualCategoryId, 10) : categoryId;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Duplicate Automation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="entity_id">
            <FormLabel>Category</FormLabel>
            <CategorySelector
              marketplaceId={marketplace?.id}
              selectedCategoryId={!manualCategoryId ? categoryId?.toString() : undefined}
              onCategorySelect={handleCategorySelect}
            />
          </FormControl>

          {isStaffUser && (
            <>
              <Divider my={8} />
              <FormControl>
                <FormLabel>(Staff Only) Category ID Manual Override</FormLabel>
                <Text>
                  Enter a specific category ID to override the selector. This will work for any
                  category across marketplaces.
                </Text>
                <Input
                  type="number"
                  value={manualCategoryId}
                  onChange={handleManualCategoryIdChange}
                  placeholder="Enter category ID"
                  min={1}
                />
                <FormHelperText>
                  {manualCategoryId
                    ? `Using manual category ID: ${effectiveCategoryId}`
                    : 'Using category selector'}
                </FormHelperText>
              </FormControl>
              <Divider my={8} />
            </>
          )}
        </ModalBody>
        <ModalFooter gap={2}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            colorScheme="primary"
            onClick={() => {
              if (!effectiveCategoryId) {
                toast({
                  title: 'Please select a category or enter a category ID',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }
              if (effectiveCategoryId === promoAutomation?.entity_id) {
                toast({
                  title: 'Please select a different category',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }
              onDuplicatePromoAutomation(promoAutomation!, effectiveCategoryId);
              onClose();
            }}
            isDisabled={!effectiveCategoryId || effectiveCategoryId === promoAutomation?.entity_id}
          >
            Duplicate
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DuplicatePromoAutomationModal;
