import React, { useContext, useEffect, useState } from 'react';
import {
  Stack,
  FormControl,
  FormLabel,
  Button,
  Box,
  Flex,
  Link,
  Image,
  Text,
  Switch,
} from '@chakra-ui/react';
import { LomaUploader } from 'src/components/molecules';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { DeleteIcon } from '@chakra-ui/icons';
import { srcFromCDN } from 'src/utils/files';
import ColorPicker from 'src/components/atoms/color-picker';
import { FilePath } from 'src/components/organisms/listing-form';
import { SITE_MAX_WIDTH } from 'src/constants/ui';

const DEFAULT_PRIMARY_COLOR = '#FFFFFF';
const DEFAULT_SECONDARY_COLOR = '#FFFFFF';

interface PromoMediaTemplateFormProps {
  isSubmitting: boolean;
  updateMarketplaceHandler: (
    payload: object,
    successMessage: string,
    errorMessage: string,
  ) => Promise<void>;
}

const PromoMediaTemplateForm = ({
  isSubmitting,
  updateMarketplaceHandler,
}: PromoMediaTemplateFormProps) => {
  const [primaryColor, setPrimaryColor] = useState(DEFAULT_PRIMARY_COLOR);
  const [secondaryColor, setSecondaryColor] = useState(DEFAULT_SECONDARY_COLOR);
  const [backgroundImage, setBackgroundImage] = useState<string | null | undefined>(null);
  const { marketplace } = useContext(MarketplaceContext);
  const [secondaryColorToggle, setSecondaryColorToggle] = useState<boolean>(false);

  useEffect(() => {
    if (marketplace?.functional?.promo_media_template_config) {
      setPrimaryColor(
        marketplace.functional.promo_media_template_config.primary ?? DEFAULT_PRIMARY_COLOR,
      );
      setSecondaryColor(
        marketplace.functional.promo_media_template_config.secondary ?? DEFAULT_SECONDARY_COLOR,
      );
      setBackgroundImage(
        marketplace.functional.promo_media_template_config.background_image_url ?? '',
      );
      setSecondaryColorToggle(
        marketplace?.functional?.promo_media_template_config?.toggle_secondary_color || false,
      );
    }
  }, [marketplace?.functional?.promo_media_template_config]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const promoMediaTemplateConfig = {
      primary: primaryColor,
      secondary: secondaryColor,
      toggle_secondary_color: secondaryColorToggle,
      background_image_url: srcFromCDN(backgroundImage, SITE_MAX_WIDTH),
    };

    const payload = {
      promo_media_template_config: JSON.stringify(promoMediaTemplateConfig),
    };

    const successMessage = 'Promo media template updated successfully.';
    const errorMessage = 'Unable to save promo media template. Please try again.';
    await updateMarketplaceHandler(payload, successMessage, errorMessage);
  };

  const onFileUpload = async (filePaths: FilePath[]) => {
    if (!setBackgroundImage || !filePaths[0]?.filePath) {
      return;
    }
    setBackgroundImage(filePaths[0]?.filePath);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack position="relative" justify="flex-start" align="flex-start" spacing="28px" w="full">
        <Flex direction="column" gap={8} minW="300px">
          <FormControl>
            <FormLabel>Primary Color</FormLabel>
            <ColorPicker value={primaryColor} onChange={setPrimaryColor} />
          </FormControl>
          <FormControl>
            <FormLabel>Set Secondary Color</FormLabel>
            <Switch
              onChange={() => setSecondaryColorToggle(!secondaryColorToggle)}
              isChecked={secondaryColorToggle}
            ></Switch>
          </FormControl>

          {secondaryColorToggle && (
            <FormControl>
              <FormLabel>Secondary Color</FormLabel>
              <ColorPicker value={secondaryColor} onChange={setSecondaryColor} />
            </FormControl>
          )}
          <FormControl flexDirection="column" display="flex">
            <FormLabel>Background Image</FormLabel>
            {backgroundImage && (
              <Flex direction="column" gap={4} mb={12}>
                <Image
                  src={srcFromCDN(backgroundImage, 300)}
                  alt="Background Image"
                  maxW="300px"
                  maxH="300px"
                />
                <Button leftIcon={<DeleteIcon />} onClick={() => setBackgroundImage(null)}>
                  Remove image
                </Button>
              </Flex>
            )}
            <Flex direction="column" gap={4}>
              {backgroundImage && <Text>Upload new image</Text>}
              <LomaUploader
                maxFiles={1}
                onFileDelete={() => setBackgroundImage(null)}
                onFileUpload={onFileUpload}
                filePathsLength={backgroundImage ? 1 : 0}
              />
            </Flex>
          </FormControl>
        </Flex>
        <Box display="flex" justifyContent="flex-end" pt={10} w="100%">
          <Link href="/">
            <Button variant="outline" colorScheme="primary" mr={4}>
              Cancel
            </Button>
          </Link>
          <Button isLoading={isSubmitting} colorScheme="primary" type="submit">
            Save
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default PromoMediaTemplateForm;
