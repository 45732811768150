import { Box, Button, Center, Heading, Text, useTheme, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

interface ErrorPageProps {
  error?: Error;
  reset?: () => void;
  statusCode?: number;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ error, reset, statusCode }) => {
  const router = useRouter();
  const [isNavigating, setIsNavigating] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const theme = useTheme();

  // Log error details to Sentry
  useEffect(() => {
    if (error) {
      Sentry.withScope((scope) => {
        scope.setExtra('statusCode', statusCode);
        scope.setExtra('pathname', router.pathname);
        scope.setExtra('asPath', router.asPath);
        scope.setExtra('query', router.query);
        scope.setTag('errorPage', 'displayed');
        Sentry.captureException(error);
      });
    }
  }, [error, statusCode, router]);

  const handleReturn = async () => {
    if (isNavigating) return;

    setIsNavigating(true);
    try {
      await router.push('/');
    } catch (error) {
      setIsNavigating(false);
      console.error('Navigation failed:', error);
      Sentry.captureException(error, {
        extra: {
          action: 'returnHome',
          pathname: router.pathname,
        },
      });
    }
  };

  const handleRetry = async () => {
    if (isNavigating) return;

    setIsNavigating(true);
    setRetryCount((prev) => prev + 1);

    try {
      if (reset) {
        reset();
      } else {
        await router.reload();
      }
    } catch (error) {
      console.error('Retry failed:', error);
      Sentry.captureException(error, {
        extra: {
          action: 'retry',
          retryCount,
          pathname: router.pathname,
        },
      });
    } finally {
      setIsNavigating(false);
    }
  };

  return (
    <Center h="100vh" bg="gray.100" flexDirection="column">
      <Box
        borderRadius={theme.border_radius.border_radius_2}
        bg="white"
        p={8}
        shadow="md"
        w={['90%', '85%', '70%', '50%']}
      >
        <VStack spacing={4}>
          <Heading textAlign="center" size="xl">
            {statusCode ? `Error ${statusCode}` : 'Oops! An Error Occurred'}
          </Heading>

          <Text textAlign="center" color="gray.600">
            {statusCode === 404
              ? "We couldn't find the page you're looking for."
              : "We're sorry for the inconvenience. Contact support if the issue persists."}
          </Text>

          {error?.message && process.env.NODE_ENV === 'development' && (
            <Text fontSize="sm" color="red.500" maxW="100%" overflow="auto">
              {error.message}
            </Text>
          )}

          <Center mt={6} gap={4}>
            <Button
              colorScheme="blue"
              onClick={handleReturn}
              isLoading={isNavigating}
              loadingText="Returning..."
            >
              Go Back Home
            </Button>

            {reset && (
              <Button
                variant="outline"
                onClick={handleRetry}
                isLoading={isNavigating}
                loadingText="Retrying..."
                isDisabled={retryCount >= 3}
              >
                Try Again
              </Button>
            )}
          </Center>

          {retryCount >= 3 && (
            <Text fontSize="sm" color="orange.500">
              Multiple retry attempts failed. Please try again later.
            </Text>
          )}
        </VStack>
      </Box>
    </Center>
  );
};

export default ErrorPage;
