import { getTextColor } from 'src/utils/color';

interface Props {
  text: string;
  bgColor: string;
}

export const ImageBanner = ({ text, bgColor }: Props) => {
  if (!text) return null;
  if (text.length > 50) text = text.slice(0, 50) + '...';

  const textColor = getTextColor(bgColor);

  return (
    <div
      style={{
        position: 'absolute',
        top: 140,
        left: -25,
        backgroundColor: bgColor,
        color: textColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        transform: 'rotate(-45deg)',
        transformOrigin: '0 0',
        fontSize: '16px',
        whiteSpace: 'nowrap',
        zIndex: 1,
        minWidth: '230px',
        fontWeight: 500,
      }}
    >
      {text}
    </div>
  );
};

export default ImageBanner;
