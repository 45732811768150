import { Popover, Stack, CheckboxGroup, Checkbox } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FilterPopoverButton, FilterPopoverContent } from 'src/components/atoms';
import { ListingFilterItemProps } from '.';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';

const BooleanCheckboxFilter = ({
  attributeId,
  isMobileView = false,
}: ListingFilterItemProps): JSX.Element => {
  const {
    attributes,
    selectedAttributes,
    setSelectedAttributes,
    stagedAttributes,
    setStagedAttributes,
  } = useAttributeSelector();
  const attribute = attributes.find((a) => a.name === attributeId);
  const [selectedOption, setSelectedOption] = useState<boolean>(false);

  const handleMobileChange = (e: any) => {
    setStagedAttributes({
      ...stagedAttributes,
      [attributeId]: [e.target.checked ? 'true' : ''],
    });
    setSelectedOption(e.target.checked);
  };

  useEffect(() => {
    if (attributeId in selectedAttributes) {
      if (selectedAttributes[attributeId][0] === 'true') {
        setSelectedOption(true);
      } else {
        setSelectedOption(false);
      }
    }
  }, [selectedAttributes]);

  const noFilter =
    !selectedAttributes[attributeId] ||
    selectedAttributes[attributeId].length === 0 ||
    (selectedAttributes[attributeId].length === 1 && selectedAttributes[attributeId][0] === '');
  return isMobileView ? (
    <Stack as="fieldset" spacing={2}>
      <CheckboxGroup>
        <Stack direction="column">
          <Checkbox onChange={handleMobileChange} isChecked={selectedOption} colorScheme="primary">
            Yes
          </Checkbox>
        </Stack>
      </CheckboxGroup>
    </Stack>
  ) : (
    <Popover placement="bottom-start">
      <FilterPopoverButton label={attribute?.name} hasFilter={!noFilter} />
      <FilterPopoverContent
        isCancelDisabled={false}
        onClickApply={() => {
          setSelectedAttributes({
            ...selectedAttributes,
            [attributeId]: [selectedOption ? 'true' : ''],
          });
        }}
        onClickCancel={() => {
          setSelectedOption(false);
          setSelectedAttributes({
            ...selectedAttributes,
            [attributeId]: [''],
          });
        }}
      >
        <Stack as="fieldset" spacing={2}>
          <CheckboxGroup>
            <Stack direction="column">
              <Checkbox
                onChange={(e) => {
                  setSelectedOption(e.target.checked);
                }}
                isChecked={selectedOption}
                colorScheme="primary"
              >
                Yes
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </Stack>
      </FilterPopoverContent>
    </Popover>
  );
};

export default BooleanCheckboxFilter;
