import { useContext, useState, useEffect } from 'react';
import { BasicCategory, Category } from 'src/api/v1-api';
import { apiRequest } from 'src/utils/fetchUtils';
import { Button, Box, Center, Spinner, useToast, Stack, Flex, Text } from '@chakra-ui/react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useRouter } from 'next/router';
import { CategorySelector } from 'src/components/molecules';
import CategoryReorderingCards from 'src/components/molecules/category-reordering-cards';
import { useAuth } from 'src/utils/auth';

const CategoryAdminTable = () => {
  const { getToken } = useAuth();
  const toast = useToast();
  const router = useRouter();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const { marketplace, categories, setCategories, fetchCategories, isLoading } =
    useContext(MarketplaceContext);

  useEffect(() => {
    if (categories.length === 0) {
      fetchCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace?.id, categories]);

  const handleAddCategory = () => {
    router.push('/admin/categories/add');
  };

  const handleEditCategory = () => {
    router.push(`/admin/categories/edit/${selectedCategoryId}`);
  };

  if (isLoading) {
    return (
      <Center width="100%" display="flex" justifyContent="center" h="100px">
        <Spinner />
      </Center>
    );
  }

  const handleOnCategorySelect = (category: BasicCategory | null) => {
    setSelectedCategoryId(category?.id ?? 0);
  };

  // TODO: CategoryReorderingCards is expecting Category[] but we have BasicCategory[]
  const handleOnCategoryReordering = async (updatedCategories: Category[]) => {
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/categories/${marketplace?.id}/update_positions/`;
    const token = await getToken();

    try {
      const categoryReorderData = updatedCategories.map((category) => ({
        categoryId: category.id,
        position: category.position,
      }));
      await apiRequest('POST', url, token, { data: categoryReorderData });
      setCategories((stateCategories) =>
        stateCategories.map((category) => {
          const categoryToUpdate = updatedCategories.find((c) => c.id === category.id);
          if (categoryToUpdate) {
            return {
              ...category,
              position: categoryToUpdate.position,
            };
          }
          return category;
        }),
      );
      toast({
        title: 'Updated categories',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error fetching categories',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack w="full">
      <Text fontSize="2xl" pb={4}>
        Categories
      </Text>
      <Flex
        alignItems={{ base: 'left', md: 'center' }}
        direction={{ md: 'row' }}
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
      >
        <CategorySelector
          marketplaceId={marketplace?.id}
          onCategorySelect={handleOnCategorySelect}
          mode="provide-categories"
          categories={categories as unknown as BasicCategory[]}
        />
        <Flex>
          <Button
            onClick={handleEditCategory}
            colorScheme="primary"
            isDisabled={!selectedCategoryId}
            ml={{ base: 0, md: 2 }}
            mt={{ base: 2, md: 0 }}
          >
            Edit
          </Button>
          <Button onClick={handleAddCategory} colorScheme="primary" ml={2} mt={{ base: 2, md: 0 }}>
            Create New
          </Button>
        </Flex>
      </Flex>

      <Box overflowX="auto">
        <CategoryReorderingCards
          categories={categories as unknown as Category[]}
          selectedCategoryId={selectedCategoryId}
          onReordering={handleOnCategoryReordering}
        />
      </Box>
    </Stack>
  );
};

export default CategoryAdminTable;
