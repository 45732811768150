import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import useRequest from 'src/utils/hooks/useRequest';
import ApexDomainEmail from './apexDomainEmail';

interface ApexDomainProps {
  apexDomain: string;
  setApexDomain: Dispatch<SetStateAction<string>>;
  domainStatus: string;
  emailStatus: string;
}

const ApexDomain = (props: ApexDomainProps) => {
  const { apexDomain, setApexDomain, domainStatus, emailStatus } = props;
  const { marketplace, setMarketplace } = useContext(MarketplaceContext);
  const { api } = useRequest();
  const { onCopy, value, setValue, hasCopied } = useClipboard('');

  useEffect(() => {
    if (marketplace) {
      setValue(`${marketplace.slug}.loma.tech.`);
    }
  }, [marketplace, setValue]);

  const checkDNS = async () => {
    if (!marketplace) return;
    try {
      const response = await api.marketplace.check_dns.get(marketplace.id);
      setMarketplace({
        ...marketplace,
        domain: response,
      });
    } catch (error) {
      console.error('Error checking DNS:', error);
    }
  };

  return (
    <>
      <Divider />

      <Stack w="full">
        <Accordion allowToggle pt={2}>
          <AccordionItem borderBottom={'none'}>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Custom Domain Configuration
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Stack w="100%">
                <Text>
                  To create a more seamless experience for your users, Loma can run on your own
                  domain or on a subdomain of your existing website. For example, you can replace a
                  Loma subdomain of:
                </Text>
                <Flex
                  gap="5"
                  p={{ base: 4, md: 4 }}
                  w="100%"
                  direction={{ base: 'column-reverse', md: 'row' }}
                >
                  <Text fontSize="md" as="u">
                    https://<b>MYSITE</b>.loma.tech/
                  </Text>
                  <Text fontSize="md">with</Text>
                  <Text fontSize="md" as="u">
                    https://market.<b>MYSITE</b>.com/
                  </Text>
                </Flex>
                <FormControl>
                  <FormLabel>Custom Domain</FormLabel>
                  <Input
                    placeholder="market.mysite.com"
                    value={apexDomain}
                    onChange={(e) => setApexDomain(e.target.value)}
                  />
                </FormControl>
                <Text pt="2" fontWeight="medium">
                  Domain Status
                </Text>
                <Flex gap="6" alignItems="center">
                  {domainStatus === 'pending' && (
                    <Text fontWeight="bold" color="gray" pl="2">
                      Pending
                    </Text>
                  )}
                  {domainStatus === 'pending_staff' && (
                    <Text fontWeight="bold" color="green" pl="2">
                      Valid (Pending Staff Approval)
                    </Text>
                  )}
                  {domainStatus === 'active' && (
                    <Text fontWeight="bold" color="green" pl="2">
                      Active
                    </Text>
                  )}
                  {(!domainStatus || domainStatus === 'inactive') && (
                    <Text fontWeight="bold" color="red" pl="2">
                      Inactive
                    </Text>
                  )}
                  {domainStatus !== 'active' && domainStatus !== 'pending_staff' && (
                    <Button onClick={() => checkDNS()} isDisabled={domainStatus === ''}>
                      Check DNS to Activate
                    </Button>
                  )}
                </Flex>

                <Accordion allowToggle pt={2}>
                  <AccordionItem borderBottom={'none'}>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <i>Domain DNS Instructions</i>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Text pt="2" pb="4">
                        To enable a custom domain on Loma, a DNS record must be added to your domain
                        host or name server, such as GoDaddy, Namecheap or Google Domains.
                        Instructions vary between hosting companies, so you may need to adjust the
                        information accordingly. If you have issues, please contact us for guidance.
                      </Text>

                      <Divider />

                      <Text pt="4" fontWeight="bold">
                        Subdomain Instructions
                      </Text>
                      <Text pt="2">
                        If you wish to add a subdomain to your existing website, such as{' '}
                        <u>https://market.mysite.com/</u> to your <u>https://mysite.com/</u>, you
                        need to add a CNAME record as detailed below. After adding the record, you
                        may need to wait a few minutes before our DNS recognizes the change.
                      </Text>

                      <Text pt="2" pb="4">
                        Hostname is the subdomain you want to use on your domain (&quot;market&quot;
                        in this example) and Data/Value is your current Loma web address. Note the
                        trailing period in the Data/Value field - this is required!
                      </Text>
                      <Flex justify="space-evenly">
                        <Stack>
                          <Text fontWeight="semibold">Type</Text>
                          <Text>CNAME</Text>
                        </Stack>
                        <Stack>
                          <Text fontWeight="semibold">Hostname</Text>
                          <Text>market</Text>
                        </Stack>
                        <Stack pb={4}>
                          <Text fontWeight="semibold">Data/Value</Text>
                          <Flex gap="3">
                            <Text>{value}</Text>
                            <Button size="xs" onClick={onCopy}>
                              {hasCopied ? 'Copied!' : 'Copy'}
                            </Button>
                          </Flex>
                        </Stack>
                      </Flex>

                      <Divider />

                      <Text pt="4" fontWeight="bold">
                        Domain Instructions
                      </Text>
                      <Text pt="2">
                        If you wish to add new domain/website that points directly to your Loma
                        marketplace, you need to add an A record as detailed below. After adding the
                        record, you may need to wait a few minutes before our DNS recognizes the
                        change.
                      </Text>

                      <Text pt="2" pb="4">
                        Hostname will be your root domain, which is displayed in various ways by
                        different hosting companies. GoDaddy, for instance, uses an @ symbol to
                        denote your top level domain. Data/Value is your current Loma web address.
                        Note the trailing period in the Data/Value field - this is required!
                      </Text>
                      <Flex justify="space-evenly">
                        <Stack>
                          <Text fontWeight="semibold">Type</Text>
                          <Text>A</Text>
                        </Stack>
                        <Stack>
                          <Text fontWeight="semibold">Hostname</Text>
                          <Text>@</Text>
                        </Stack>
                        <Stack>
                          <Text fontWeight="semibold">Data/Value</Text>
                          <Flex gap="3">
                            <Text>{value}</Text>
                            <Button size="xs" onClick={onCopy}>
                              {hasCopied ? 'Copied!' : 'Copy'}
                            </Button>
                          </Flex>
                        </Stack>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem borderBottom={'none'}>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <i>Custom Domain Email</i>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      {domainStatus === 'active' ? (
                        <ApexDomainEmail apexDomain={apexDomain} emailStatus={emailStatus} />
                      ) : (
                        <Text as="i">
                          Activate your custom domain before setting up custom domain emails.
                        </Text>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>

      <Divider />
    </>
  );
};

export default ApexDomain;
