import { Box, Button, Flex, Spinner, Stack, Switch, Text, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { DigestCategoryEmailTemplate, EmailPreference } from 'src/api/v1-api';
import { MarketplaceContext } from 'src/contexts/marketplace';
import useRequest from 'src/utils/hooks/useRequest';
import { apiRequest } from 'src/utils/fetchUtils';
import { useRouter } from 'next/router';

interface EmailPreferencesProps {
  token?: string;
}

const EmailPreferences = ({ token }: EmailPreferencesProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [digestCategoryEmailTemplates, setDigestCategoryEmailTemplates] = useState<
    DigestCategoryEmailTemplate[]
  >([]);
  const [emailPreferences, setEmailPreferences] = useState<EmailPreference[]>([]);
  const { api } = useRequest();
  const { marketplace } = useContext(MarketplaceContext);
  const toast = useToast();
  const router = useRouter();
  const { success } = router.query;

  const baseUrl = process.env.NEXT_PUBLIC_API_HOST;

  useEffect(() => {
    if (router.isReady && success === 'True') {
      toast({
        title: 'Success',
        description: 'Subscription updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [router.isReady, success]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchDigestCategoryEmailTemplates(), fetchEmailPreferences()]);
      setIsLoading(false);
    };

    fetchData();
  }, [marketplace?.id]);

  const fetchEmailPreferences = async () => {
    if (!marketplace?.id) return;

    try {
      let response: EmailPreference[];
      if (token) {
        const url = `${baseUrl}/marketplaces/${marketplace.id}/email_preferences/`;
        response = (await apiRequest(
          'GET',
          url,
          null,
          null,
          false,
          null,
          token,
        )) as EmailPreference[];
      } else {
        response = await api.marketplace.emailPreferences.list(marketplace.id);
      }
      setEmailPreferences(response);
    } catch (error) {
      toast({
        title: 'Error',
        description:
          'Unable to get Email Preferences. Please try again or contact support if the issue persists.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  const fetchDigestCategoryEmailTemplates = async () => {
    if (!marketplace?.id) return;
    try {
      let response: DigestCategoryEmailTemplate[];
      if (token) {
        const url = `${baseUrl}/marketplaces/${marketplace.id}/digest_category_email_templates/`;
        response = (await apiRequest(
          'GET',
          url,
          null,
          null,
          false,
          null,
          token,
        )) as DigestCategoryEmailTemplate[];
      } else {
        response = await api.marketplace.digestCategoryEmailTemplates.getByMarketplaceId(
          marketplace.id,
        );
      }
      const activeDigestCategoryEmailTemplates = response.filter(
        (digestCategoryEmailTemplate) => digestCategoryEmailTemplate.status === 'active',
      );
      setDigestCategoryEmailTemplates(activeDigestCategoryEmailTemplates);
    } catch (error) {
      toast({
        title: 'Error',
        description:
          'Unable to get Email Templates. Please try again or contact support if the issue persists.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  const getStatusByDigestCategoryEmailTemplate = (
    digestCategoryEmailTemplate: DigestCategoryEmailTemplate,
  ) => {
    return (
      emailPreferences.find(
        (emailPreference) =>
          emailPreference.digest_category_email_template.id === digestCategoryEmailTemplate.id,
      )?.status ?? 'unsubscribed'
    );
  };

  const manageSubscriptions = async (subscribe: boolean) => {
    if (!marketplace?.id) return;

    try {
      setIsLoading(true);
      let response: EmailPreference[];
      if (token) {
        const url = `${baseUrl}/marketplaces/${marketplace.id}/email_preferences/manage_subscriptions/`;
        response = (await apiRequest(
          'POST',
          url,
          null,
          { subscribe },
          false,
          undefined,
          token,
        )) as EmailPreference[];
      } else {
        response = await api.marketplace.emailPreferences.manageAllSubscriptions(marketplace.id, {
          subscribe,
        });
      }
      setEmailPreferences(response);
      toast({
        title: 'Success',
        description: 'Subscriptions updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description:
          'Something went wrong. Please try again or contact support if the issue persists.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscriptionChange =
    (digestCategoryEmailTemplate: DigestCategoryEmailTemplate) =>
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!marketplace?.id) return;
      try {
        setIsLoading(true);
        const payload = {
          subscribe: e.target.checked,
          digest_category_email_template_id: digestCategoryEmailTemplate.id,
        };
        let response: EmailPreference;
        if (token) {
          const url = `${baseUrl}/marketplaces/${marketplace.id}/email_preferences/`;
          response = (await apiRequest(
            'POST',
            url,
            null,
            payload,
            false,
            null,
            token,
          )) as EmailPreference;
        } else {
          response = await api.marketplace.emailPreferences.manageSubscription(
            marketplace.id,
            payload,
          );
        }
        if (
          emailPreferences.find(
            (emailPreference) =>
              emailPreference.digest_category_email_template.id === digestCategoryEmailTemplate.id,
          )
        ) {
          setEmailPreferences(
            emailPreferences.map((emailPreference) => {
              if (
                emailPreference.digest_category_email_template.id === digestCategoryEmailTemplate.id
              ) {
                return response;
              }
              return emailPreference;
            }),
          );
        } else {
          setEmailPreferences([...emailPreferences, response]);
        }
        toast({
          title: 'Success',
          description: 'Subscription updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error',
          description:
            'Something went wrong. Please try again or contact support if the issue persists.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

  return (
    <Stack display="relative" width="100%">
      <Flex direction="column" gap={12}>
        <Flex direction="column">
          <Flex
            direction={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            gap={2}
            justifyContent={{ base: 'flex-start', md: 'space-between' }}
            pb={4}
          >
            <Text fontWeight="semibold">Subscriptions</Text>
            <Flex direction={{ base: 'column', md: 'row' }} gap={2} flexWrap={'wrap'}>
              <Button
                colorScheme={'secondary'}
                variant={'outline'}
                onClick={() => manageSubscriptions(false)}
              >
                Unsubscribe to all
              </Button>
              <Button colorScheme={'primary'} onClick={() => manageSubscriptions(true)}>
                Subscribe to all
              </Button>
            </Flex>
          </Flex>
          <Flex direction="column" width="100%" gap={4}>
            {digestCategoryEmailTemplates.map((digestCategoryEmailTemplate) => (
              <Switch
                key={digestCategoryEmailTemplate.id}
                colorScheme="primary"
                isChecked={
                  getStatusByDigestCategoryEmailTemplate(digestCategoryEmailTemplate) ===
                  'subscribed'
                }
                onChange={handleSubscriptionChange(digestCategoryEmailTemplate)}
              >
                {digestCategoryEmailTemplate.nickname ??
                  digestCategoryEmailTemplate.digest_email_type.name}
              </Switch>
            ))}
          </Flex>
        </Flex>
      </Flex>
      {isLoading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor="rgba(255, 255, 255, 0.7)"
        >
          <Spinner />
        </Box>
      )}
    </Stack>
  );
};

export default EmailPreferences;
