import { Box, Spinner } from '@chakra-ui/react';

interface Props {
  bgcolor?: string;
  size?: string;
}

const CoverLoader = ({ bgcolor = 'rgba(255, 255, 255, 0.7)', size }: Props): JSX.Element => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor={bgcolor}
    >
      <Spinner size={size} />
    </Box>
  );
};

export default CoverLoader;
