import {
  Box,
  HStack,
  FormControl,
  Stack,
  Text,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  Image,
  useToast,
  Button,
  useTheme,
} from '@chakra-ui/react';
import { useState, useContext, useEffect } from 'react';
import { max } from 'lodash';
import { useRouter } from 'next/router';
import { TableHeaderCell, CoverLoader, Pagination } from 'src/components/atoms';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { Order, computeOrderPayout, getOrderImage } from 'src/api/v1-api';
import { SortModel } from 'src/types';
import { useAuth } from 'src/utils/auth';
import { apiRequest } from 'src/utils/fetchUtils';
import { SectionType, getSectionTitle, getFormattedDate, getStatusText } from './helpers';
import { srcFromCDN } from 'src/utils/files';

interface Props {
  type: SectionType;
}

const OrdersTable = ({ type }: Props): JSX.Element => {
  const { getToken, user } = useAuth();
  const toast = useToast();
  const { marketplace } = useContext(MarketplaceContext);
  const router = useRouter();
  const theme = useTheme();

  const [sort, setSort] = useState<SortModel | null>(null);
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [nextLink, setNextLink] = useState<string | null>(null);
  const [prevLink, setPrevLink] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [currentLink, setCurrentLink] = useState<string | null>(null);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const marketplaceId = marketplace?.id;

  const getCompletedDate = (order: Order) => {
    const dateValues: number[] = (order.fulfillments ?? []).reduce<number[]>((acc, cur) => {
      return [
        ...acc,
        ...(cur.fulfillment_events ?? [])
          .filter((item) => item.created_at !== undefined && item.created_at !== null)
          .map((item) => +new Date(item.created_at)),
      ];
    }, []);
    const recentDateValue = max(dateValues);

    return recentDateValue === undefined ? '' : getFormattedDate(recentDateValue);
  };

  const fetchOrders = async (url: string) => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await apiRequest('GET', url, token);
      setOrders(response.results);
      setCurrentLink(url);
      setNextLink(response.next);
      setPrevLink(response.previous);
      setTotalPages(response.count / response.page_size);
      setPageNumber(response.page_number);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Unable to fetch orders. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSortChange = (field: string) => {
    if (!field) return;

    const sortField = sort?.field;
    let newSort: SortModel | null = null;
    if (field !== sortField) {
      newSort = { field, order: 'asc' };
    } else if (sort?.order === 'asc') {
      newSort = { field, order: 'desc' };
    } else {
      newSort = null;
    }
    setSort(newSort);

    if (currentLink) {
      const url = new URL(currentLink);
      if (newSort?.field && newSort?.order) {
        url.searchParams.set('sort', newSort.field);
        url.searchParams.set('order', newSort.order);
      } else {
        url.searchParams.delete('sort');
        url.searchParams.delete('order');
      }
      fetchOrders(url.toString());
    }
  };

  const handleNextPage = () => {
    if (nextLink) {
      const url = new URL(nextLink);
      if (sort?.field && sort?.order) {
        url.searchParams.set('sort', sort.field);
        url.searchParams.set('order', sort.order);
      } else {
        url.searchParams.delete('sort');
        url.searchParams.delete('order');
      }
      fetchOrders(url.toString());
    }
  };

  const handlePrevPage = () => {
    if (prevLink) {
      const url = new URL(prevLink);
      if (sort?.field && sort?.order) {
        url.searchParams.set('sort', sort.field);
        url.searchParams.set('order', sort.order);
      } else {
        url.searchParams.delete('sort');
        url.searchParams.delete('order');
      }
      fetchOrders(url.toString());
    }
  };

  const checkStatusType = (orderElement: Order) =>
    // every fulfillment item in fulfillments have a price_type in the given array
    orderElement.fulfillments.every((or) =>
      or.fulfillment_items.every((fi) =>
        ['fixed_at_type', 'fixed_at_option'].includes(fi.fulfillment_type.price_type),
      ),
    );

  const handleOnChange = (order_id: number) => {
    const orderUrl = `${process.env.NEXT_PUBLIC_API_HOST}/orders/update-fulfillment/`;
    const status = 'fulfilled';
    changeFulfillmentStatus(status, orderUrl, order_id);
  };

  const changeFulfillmentStatus = async (newStatus: string, url: string, order_id: number) => {
    setChangeStatusLoading(true);
    try {
      const token = await getToken();
      const fulfillmentIds = orders
        .find((ord) => ord.id === order_id)
        ?.fulfillments.map((f) => f.id);

      const response = await apiRequest('PATCH', url, token, {
        fulfillmentIds,
        fulfillmentStatus: newStatus,
      });
      window.location.reload();
    } catch (error) {
      toast({
        title: 'Error changing fulfillment status',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setChangeStatusLoading(false);
    }
  };

  // Initial fetching
  useEffect(() => {
    if (type && marketplaceId) {
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/my_sales/${type}/${marketplaceId}/?page_size=5`;
      fetchOrders(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceId, type]);

  const statusDropdownElem = (type: SectionType, order: Order) => {
    const isStatusDropdown = checkStatusType(order);

    if (type === 'new' && isStatusDropdown) {
      return (
        <FormControl
          onClick={(e) => {
            if (isStatusDropdown) {
              e.stopPropagation();
            }
          }}
        >
          <Button
            onClick={() => {
              handleOnChange(order.id);
            }}
            isLoading={changeStatusLoading}
            size="sm"
          >
            Mark as Shipped
          </Button>
        </FormControl>
      );
    }
    return <Text></Text>;
  };

  return (
    <Box
      bg="bg.surface"
      boxShadow={{ base: 'none', md: 'md' }}
      borderRadius={theme.border_radius.border_radius_2}
      position="relative"
    >
      <Stack spacing="5">
        <Box px={{ base: '4', md: '6' }} pt="5">
          <Stack direction={{ base: 'column', md: 'row' }} justify="space-between">
            <Text textStyle="lg" fontWeight="medium">
              {getSectionTitle(type)}
            </Text>
          </Stack>
        </Box>
        <Box overflowX="auto">
          <Table>
            <Thead>
              <Tr>
                <TableHeaderCell
                  field="id"
                  label="Order Number"
                  sortable
                  sort={sort ?? undefined}
                  disabled={loading}
                  onSortChange={handleSortChange}
                />
                <TableHeaderCell
                  field="created_at"
                  label="Order Date"
                  sortable
                  sort={sort ?? undefined}
                  disabled={loading}
                  onSortChange={handleSortChange}
                />
                {type === 'completed' && (
                  <TableHeaderCell
                    field="completed_date"
                    label="Completed Date"
                    sortable
                    sort={sort ?? undefined}
                    disabled={loading}
                    onSortChange={handleSortChange}
                  />
                )}
                <TableHeaderCell
                  field="status"
                  label="Status"
                  sortable
                  sort={sort ?? undefined}
                  disabled={loading}
                  onSortChange={handleSortChange}
                />
                {type === 'completed' && (
                  <TableHeaderCell
                    field="payout_subtotal"
                    label="Payout Subtotal"
                    sortable
                    sort={sort ?? undefined}
                    disabled={loading}
                    onSortChange={handleSortChange}
                  />
                )}
                {type === 'new' && (
                  <TableHeaderCell field="mark_as_shipped" label="" disabled={loading} />
                )}
              </Tr>
            </Thead>
            <Tbody>
              {orders.map((order) => {
                return (
                  <Tr
                    key={order.id}
                    cursor="pointer"
                    onClick={() => {
                      router.push(`/order/${order.id}`);
                    }}
                  >
                    <Td paddingTop={2} paddingBottom={2}>
                      <HStack spacing={2}>
                        <Image
                          alt="..."
                          width="72px"
                          height="53px"
                          borderRadius={theme.border_radius.border_radius_1}
                          src={srcFromCDN(getOrderImage(order), 72)}
                          draggable="false"
                          loading="lazy"
                          style={{ objectFit: 'cover' }}
                          align="center"
                        />
                        <Text>{order.id}</Text>
                      </HStack>
                    </Td>
                    <Td>
                      <Text>{getFormattedDate(order.created_at)}</Text>
                    </Td>
                    {type === 'completed' && (
                      <Td>
                        <Text>{getCompletedDate(order)}</Text>
                      </Td>
                    )}
                    <Td>
                      <Text>{getStatusText({ type, order })}</Text>
                    </Td>
                    {type === 'completed' && (
                      <Td>
                        <Text>${computeOrderPayout({ order, viewer: user })}</Text>
                      </Td>
                    )}
                    {type === 'new' && (
                      <Td
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {statusDropdownElem(type, order)}
                      </Td>
                    )}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <Box px={{ base: '4', md: '6' }} pb="5">
          <Stack direction="row" justify="center" align="center">
            <Pagination
              nextLink={nextLink}
              prevLink={prevLink}
              onNext={handleNextPage}
              onPrev={handlePrevPage}
              pageNumber={pageNumber}
              totalPages={totalPages}
            />
          </Stack>
        </Box>
      </Stack>
      {loading && <CoverLoader />}
    </Box>
  );
};

export default OrdersTable;
