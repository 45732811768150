import { Flex } from '@chakra-ui/react';
import { BasicAttributeValue, Listing, MarketplaceInfo } from 'src/api/v1-api';
import { SingleAttributeDisplay } from 'src/components/atoms/';
interface Props {
  attributeValues: BasicAttributeValue[];
  marketplace?: MarketplaceInfo | null;
  listing?: Listing | null;
}

const CategoryAttributesDisplay = ({
  attributeValues,
  marketplace,
  listing,
}: Props): JSX.Element | null => {
  if (!attributeValues || attributeValues.length === 0) return null;
  return (
    <Flex
      textColor="gray.800"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      fontSize="12px"
      flex={1}
    >
      {attributeValues?.map((attributeValue) => (
        <SingleAttributeDisplay
          isListingCard={true}
          attributeValue={attributeValue}
          key={attributeValue.id}
          marketplace={marketplace}
          listing={listing}
        />
      ))}
    </Flex>
  );
};

export default CategoryAttributesDisplay;
