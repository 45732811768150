import { Divider, Flex, Heading, Stack, Text, theme, useTheme } from '@chakra-ui/react';
import { Order, User, computeMarketplaceFee } from 'src/api/v1-api';
import { formatPrice } from 'src/utils/common';
import { OrderSummaryItem } from 'src/components/atoms';
import { useAuth } from 'src/utils/auth';
import { useMarketplaceContext } from 'src/contexts/marketplace';

interface OrderSummaryProps {
  order: Order | null;
  viewer: User | null;
}

export const OrderSummary = ({ order, viewer }: OrderSummaryProps) => {
  const theme = useTheme();
  const { hasStaff, hasAdmin } = useAuth();
  const isStaffUser = hasStaff();
  const { marketplace } = useMarketplaceContext();
  const isAdmin = hasAdmin(marketplace?.id);
  const isBuyer = viewer?.id === order?.marketplace_account?.user?.id || isStaffUser || isAdmin;

  let isSeller = isStaffUser || isAdmin;
  let payout = 0;
  if (order?.fulfillments) {
    for (const fulfillment of order.fulfillments) {
      if (fulfillment?.marketplace_account?.user?.id === viewer?.id) {
        isSeller = true;
      }
      payout += parseFloat(fulfillment?.fulfillment_payout || '0');
    }
  }

  return (
    <>
      {/* Buyer Summary */}
      {isBuyer && (
        <Stack
          spacing="8"
          borderWidth="1px"
          borderRadius={theme.border_radius.border_radius_2}
          padding="8"
          width="full"
          shadow="md"
          bg={{ base: 'white', md: 'gray.50' }}
          key="buyer-summary"
        >
          <Heading size="md">Summary</Heading>
          <Stack spacing="6">
            <OrderSummaryItem
              key="buyer-contents-subtotal"
              label="Contents Subtotal"
              value={formatPrice(order?.summary?.contents_subtotal) || '-'}
              showIcon={false}
            />
            <OrderSummaryItem
              key="buyer-shipping"
              label="Shipping"
              value={formatPrice(order?.summary?.fulfillment_subtotal) || '-'}
              showIcon={false}
            />
            <OrderSummaryItem
              key="buyer-tax"
              label="Tax"
              value={formatPrice(order?.summary?.tax_subtotal) || '-'}
              showIcon={false}
            />
            {/* TODO(Sean): Add coupon code back in when we have coupons */}
            {/* {coupon?.code ? (
            <OrderSummaryItem
              label="Coupon Code"
              value={coupon?.code.toUpperCase() || '-'}
              showIcon={false}
            />
          ) : null} */}
            <Divider />
            <Flex justify="space-between">
              <Text fontSize="lg" fontWeight="semibold">
                Total
              </Text>
              <Text fontSize="xl" fontWeight="extrabold">
                {formatPrice(Number(order?.summary?.total || 0))}
              </Text>
            </Flex>
          </Stack>
        </Stack>
      )}

      {/* Seller Summary */}
      {isSeller && (
        <Stack
          spacing="8"
          borderWidth="1px"
          borderRadius={theme.border_radius.border_radius_2}
          padding="8"
          width="full"
          shadow="md"
          bg={{ base: 'white', md: 'gray.50' }}
          key="seller-summary"
        >
          <Heading size="md">Seller Summary</Heading>
          <Stack spacing="6">
            <OrderSummaryItem
              key="seller-contents-subtotal"
              label="Contents Subtotal"
              value={formatPrice(order?.summary?.contents_subtotal) || '-'}
              showIcon={false}
            />
            <OrderSummaryItem
              key="seller-shipping"
              label="Shipping"
              value={formatPrice(order?.summary?.fulfillment_subtotal) || '-'}
              showIcon={false}
            />
            <OrderSummaryItem
              key="seller-tax"
              label="Marketplace Fee"
              value={formatPrice(computeMarketplaceFee(order))}
              showIcon={false}
            />
            <Divider />
            <Flex justify="space-between">
              <Text fontSize="lg" fontWeight="semibold">
                Payout
              </Text>
              <Text fontSize="xl" fontWeight="extrabold">
                {formatPrice(payout)}
              </Text>
            </Flex>
          </Stack>
        </Stack>
      )}
    </>
  );
};
