import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Tooltip,
  Link,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { SocialConnectedProfile, SocialProfile } from 'src/api/v1-api';
import { useMarketplaceContext } from 'src/contexts/marketplace';

interface AddSocialProfileFormProps {
  autoPilotHelpText: string;
  handleAddSocialProfile: (profile: SocialProfile, manageNetworks?: boolean) => void;
  supportedNetworksExternalConnector: string[];
  connectedSocialProfiles: SocialConnectedProfile[];
  selectedSocialProfile: SocialProfile;
  setSocialProfile: Dispatch<SetStateAction<SocialProfile>>;
}

const AddSocialProfileForm = ({
  autoPilotHelpText,
  handleAddSocialProfile,
  supportedNetworksExternalConnector,
  connectedSocialProfiles,
  selectedSocialProfile,
  setSocialProfile,
}: AddSocialProfileFormProps) => {
  const [showAutoPilotToolTip, setShowAutoPilotToolTip] = useState<boolean>(false);
  const { marketplace } = useMarketplaceContext();

  const addProfile = () => {
    handleAddSocialProfile(selectedSocialProfile);
    resetStates();
  };

  const resetStates = () => {
    setSocialProfile({
      id: null,
      network: '',
      username: '',
      url: '',
      total_followers: 0,
      show_on_footer_menu: true,
      show_on_header_menu: true,
    });
  };

  const connectedNetworks = connectedSocialProfiles.map((profile) => profile.platform);

  return (
    <Stack justify="flex-start" align="flex-start" spacing="20px" w="100%">
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify="flex-start"
        align="flex-start"
        gap="20px"
        w="100%"
      >
        <FormControl>
          <FormLabel>Network</FormLabel>
          <Select
            placeholder="Select a network"
            onChange={(e) =>
              setSocialProfile({ ...selectedSocialProfile!, network: e.target.value })
            }
            value={selectedSocialProfile.network}
          >
            <option value="instagram">Instagram</option>
            <option value="youtube">Youtube</option>
            <option value="discord">Discord</option>
            <option value="twitter">Twitter</option>
            <option value="facebook">Facebook</option>
            <option value="facebook-group">Facebook Group</option>
            <option value="pinterest">Pinterest</option>
            <option value="tiktok">Tiktok</option>
            <option value="threads">Threads</option>
            <option value="other">Other</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Username</FormLabel>
          <Input
            placeholder="@username"
            value={selectedSocialProfile.username}
            onChange={(e) =>
              setSocialProfile({ ...selectedSocialProfile!, username: e.target.value })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Link</FormLabel>
          <Input
            placeholder="https://..."
            type="url"
            value={selectedSocialProfile.url}
            onChange={(e) => setSocialProfile({ ...selectedSocialProfile!, url: e.target.value })}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Follower Count</FormLabel>
          <Input
            placeholder="10..."
            value={selectedSocialProfile.total_followers}
            onChange={(e) =>
              setSocialProfile({
                ...selectedSocialProfile!,
                total_followers: Number(e.target.value),
              })
            }
          />
        </FormControl>
      </Flex>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify="flex-end"
        align="flex-start"
        gap="20px"
        w="100%"
      >
        <Box pt={{ base: 10, md: 30 }}>
          <Flex
            gap={2}
            direction={{ base: 'column', sm: 'row' }}
            alignItems={{ base: 'flex-start', sm: 'center' }}
          >
            <Button colorScheme="gray" onClick={() => resetStates()}>
              Clear
            </Button>
            <Button colorScheme="gray" onClick={addProfile}>
              Add
            </Button>
            {supportedNetworksExternalConnector.includes(selectedSocialProfile.network) &&
              !connectedNetworks.includes(selectedSocialProfile.network) && (
                <Flex direction="row" alignItems="center">
                  <Button
                    colorScheme="gray"
                    onClick={addProfile}
                    as={Link}
                    href={`/api/marketplace/${marketplace?.id}/manage_social_connected_accounts`}
                    target="_blank"
                  >
                    Add and Connect Autopilot
                  </Button>
                  <Tooltip
                    hasArrow
                    isOpen={showAutoPilotToolTip}
                    label={autoPilotHelpText}
                    bg="gray.100"
                    color="black"
                  >
                    <InfoOutlineIcon
                      ml={4}
                      onMouseEnter={() => setShowAutoPilotToolTip(true)}
                      onMouseLeave={() => setShowAutoPilotToolTip(false)}
                    />
                  </Tooltip>
                </Flex>
              )}
          </Flex>
        </Box>
      </Flex>
    </Stack>
  );
};

export default AddSocialProfileForm;
