import {
  Box,
  Flex,
  Icon,
  Text,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Link,
  useDisclosure,
  Button,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useTheme,
  DrawerOverlay,
} from '@chakra-ui/react';
import { MdFilterList, MdList, MdMap } from 'react-icons/md';
import { IoChevronForwardSharp, IoChevronDownSharp, IoChevronUpSharp } from 'react-icons/io5';
import { ListingFilterItem, PriceRangeFilter } from 'src/components/molecules';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';
import SortOptionSelect from 'src/components/molecules/sort-option-select';
import { isEmpty } from 'lodash';
import { Attribute, Category, RootCategory } from 'src/api/v1-api';
import { categoryTypeHasPrice } from 'src/utils/categoryUtils';
import { useEffect, useState } from 'react';
import { hiddenFilterAttributeFormats } from 'src/constants/attributes';

interface Props {
  category?: Category | RootCategory;
  handleUserChangedPriceRange?: React.Dispatch<React.SetStateAction<boolean>>;
  priceRangeDisplay?: 'slider' | 'input' | 'all';
}

const ListingAttributes = ({
  category,
  handleUserChangedPriceRange,
  priceRangeDisplay = 'all',
}: Props): JSX.Element => {
  const {
    attributes,
    useToggle,
    viewType,
    setViewType,
    setSelectedAttributes,
    setStagedAttributes,
    commitStagedAttributes,
    setPriceRange,
    sortOptions,
    selectedSort,
    setSelectedSort,
    selectedAttributes,
    priceRange,
    maxPrice,
  } = useAttributeSelector();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [filtersAppliedCount, setFiltersAppliedCount] = useState(0);
  const [totalAttributes, setTotalAttributes] = useState<Attribute[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();

  const hasPrice = categoryTypeHasPrice(category?.type);

  const handleClear = () => {
    setSelectedAttributes({});
    setStagedAttributes({});
    setPriceRange([0, 0]);
    onClose();
  };

  const handleApply = () => {
    commitStagedAttributes();
    onClose();
  };

  useEffect(() => {
    const params = [];
    if (priceRange[0] !== 0 || (priceRange[1] !== 0 && priceRange[1] != maxPrice)) {
      params.push({ value: `$${priceRange[0]} - $${priceRange[1]}`, name: 'price' });
    }
    for (const [key, values] of Object.entries(selectedAttributes)) {
      if (Array.isArray(values)) {
        params.push({ value: values.join(', '), name: key });
      }
    }
    let tempFiltersAppliedCount = 0;
    for (const [key, values] of Object.entries(params)) {
      if (values.name !== 'page' && values.name !== 'sort' && values.value !== '') {
        tempFiltersAppliedCount += 1;
      }
    }

    setFiltersAppliedCount(tempFiltersAppliedCount);
  }, [selectedAttributes, priceRange]);

  useEffect(() => {
    if (attributes.length !== 0) {
      const totalAttributes = attributes.filter((attribute) => {
        if (!attribute.filter_display) {
          return false;
        }

        if (attribute?.nested_content_type_name === 'Submitted Lead') {
          return false;
        }

        if (hiddenFilterAttributeFormats.indexOf(attribute?.format.toLowerCase()) > -1) {
          return false;
        }

        return true;
      });
      setTotalAttributes(totalAttributes);
    }
  }, [attributes]);

  const filtersWidth = filtersAppliedCount > 0 ? '68px' : '62px';
  const totalFiltersCount = totalAttributes.length + (hasPrice ? 1 : 0);
  return (
    <Flex direction="column" gap="24px" style={{ fontFamily: theme.fonts.font_1 }}>
      <Flex direction="row" alignItems="flex-start" gap={{ base: '6px', lg: '10px' }}>
        {useToggle && (
          <Flex direction="row" alignItems="center" gap={{ base: '6px', lg: '10px' }}>
            <Text fontWeight={600} hideBelow="md">
              View
            </Text>
            <IconButton
              hideFrom="md"
              aria-label="view-as-list"
              fontSize={24}
              variant={viewType === 'grid' ? 'solid' : 'outline'}
              colorScheme={viewType === 'grid' ? 'primary' : 'gray'}
              icon={<MdList />}
              onClick={() => setViewType('grid')}
              {...(theme.name === 'Magazine' && {
                color: viewType === 'grid' ? 'white' : theme.colors.primary[500],
                bgColor: viewType === 'grid' ? 'primary' : 'white',
                borderColor: viewType === 'grid' ? 'inherit' : theme.colors.primary[500],
              })}
              height="42px"
              width="42px"
            />
            <Button
              hideBelow="md"
              aria-label="view-as-list"
              variant={viewType === 'grid' ? 'solid' : 'outline'}
              colorScheme={viewType === 'grid' ? 'primary' : 'gray'}
              rightIcon={<MdList style={{ fontSize: 24 }} />}
              onClick={() => setViewType('grid')}
              {...(theme.name === 'Magazine' && {
                color: viewType === 'grid' ? 'white' : theme.colors.primary[500],
                bgColor: viewType === 'grid' ? 'primary' : 'white',
                borderColor: viewType === 'grid' ? 'inherit' : theme.colors.primary[500],
              })}
              height="42px"
            >
              List
            </Button>
            <IconButton
              hideFrom="md"
              aria-label="view-as-map"
              fontSize={24}
              variant={viewType === 'map' ? 'solid' : 'outline'}
              colorScheme={viewType === 'map' ? 'primary' : 'gray'}
              icon={<MdMap />}
              onClick={() => setViewType('map')}
              {...(theme.name === 'Magazine' && {
                color: viewType === 'map' ? 'white' : theme.colors.primary[500],
                bgColor: viewType === 'map' ? 'primary' : 'white',
                borderColor: viewType === 'map' ? 'inherit' : theme.colors.primary[500],
              })}
              height="42px"
              width="42px"
            />
            <Button
              hideBelow="md"
              aria-label="view-as-map"
              variant={viewType === 'map' ? 'solid' : 'outline'}
              rightIcon={<MdMap style={{ fontSize: 24 }} />}
              onClick={() => setViewType('map')}
              {...(theme.name === 'Magazine' && {
                color: viewType === 'map' ? 'white' : theme.colors.primary[500],
                bgColor: viewType === 'map' ? 'primary' : 'white',
                borderColor: viewType === 'map' ? 'inherit' : theme.colors.primary[500],
              })}
              height="42px"
            >
              Map
            </Button>
          </Flex>
        )}
        {/* Desktop Filters */}
        <Text fontWeight={600} hideBelow="md" minWidth={filtersWidth} py="8px" whiteSpace="nowrap">
          Filters {filtersAppliedCount > 0 ? ` (${filtersAppliedCount})` : ''}
        </Text>
        <Flex
          hideBelow="md"
          direction="row"
          alignItems={'flex-start'}
          gap={{ base: '6px', lg: '10px' }}
          grow={1}
        >
          <Flex
            alignItems="center"
            gap={{ base: '6px', lg: '10px' }}
            wrap={'wrap'}
            overflow={'hidden'}
            grow={1}
            maxHeight={isExpanded ? 'auto' : '42px'}
          >
            {handleUserChangedPriceRange && hasPrice && (
              <PriceRangeFilter
                isMobileView={false}
                handleUserChangedPriceRange={handleUserChangedPriceRange}
                priceRangeDisplay={priceRangeDisplay}
              />
            )}
            {totalAttributes
              .filter((a) => a.filter_display)
              .map((attribute) => (
                <ListingFilterItem key={attribute.name} attributeId={attribute.name} />
              ))}
          </Flex>
          {totalFiltersCount > 1 && (
            <Flex>
              <Button
                hideBelow="md"
                backgroundColor="transparent"
                onClick={() => setIsExpanded(!isExpanded)}
                alignSelf="center"
                rightIcon={isExpanded ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
                aria-label="expand-collapse"
                variant={'outline'}
                fontWeight={400}
                height={'42px'}
              >
                {isExpanded ? 'Less' : 'More'}
              </Button>
            </Flex>
          )}
        </Flex>

        {/* Mobile Filters */}
        <Flex direction="row" alignItems="center" hideFrom="md" grow={1}>
          <Button
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            colorScheme={filtersAppliedCount ? 'primary' : 'gray'}
            bgColor={filtersAppliedCount ? theme.colors.primary[500] : 'white'}
            maxWidth="200px"
            width="full"
            fontSize="md"
            type="button"
            px="3"
            height="42px"
            onClick={onOpen}
            borderWidth="1px"
            textAlign="left"
            {...(theme.name === 'Magazine' && {
              color: filtersAppliedCount ? 'white' : theme.colors.primary[500],
              borderColor: theme.colors.primary[500],
            })}
          >
            <Text>Filters {filtersAppliedCount ? `(${filtersAppliedCount})` : ''}</Text>
            <Icon as={MdFilterList} fontSize="xl" />
          </Button>
        </Flex>
        {/* Sort */}
        {!isEmpty(sortOptions) && (
          <Flex
            justifyContent="flex-end"
            flexShrink={{ base: 1, lg: 0 }}
            flexDirection="row"
            alignItems="center"
            columnGap="10px"
          >
            <Text fontWeight={600} hideBelow="md" display={'inline-block'} whiteSpace={'nowrap'}>
              Sort by
            </Text>
            <Flex grow={1}>
              <SortOptionSelect
                options={sortOptions ?? []}
                value={selectedSort ?? ''}
                onChange={setSelectedSort}
              />
            </Flex>
          </Flex>
        )}
        <Drawer
          placement="bottom"
          isOpen={isOpen}
          onClose={onClose}
          blockScrollOnMount={false}
          trapFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent roundedTop={theme.border_radius.border_radius_2} p="20px" pt="4 px">
            <DrawerHeader px="4" p="0">
              <Box textAlign="center" position="relative">
                <Text fontWeight="700" fontSize="24px">
                  Filters
                </Text>
                <Box position="absolute" right="0" top="0">
                  <Link
                    textDecor="underline"
                    fontWeight={400}
                    fontSize="16px"
                    onClick={handleClear}
                  >
                    Reset All
                  </Link>
                </Box>
              </Box>
            </DrawerHeader>
            <DrawerBody p="0">
              <Flex direction="column" pt="16px" gap="16px">
                <Accordion allowToggle>
                  {handleUserChangedPriceRange && hasPrice && (
                    <AccordionItem key="price-filter">
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton>
                            <Box fontWeight="600" mb="2" flex="1" textAlign="left">
                              Price
                            </Box>
                            {isExpanded ? <IoChevronDownSharp /> : <IoChevronForwardSharp />}
                          </AccordionButton>
                          <AccordionPanel>
                            <PriceRangeFilter
                              isMobileView
                              handleUserChangedPriceRange={handleUserChangedPriceRange}
                              priceRangeDisplay={priceRangeDisplay}
                            />
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  )}
                  {attributes
                    .filter(
                      (a) => a.filter_display && !hiddenFilterAttributeFormats.includes(a.format),
                    )
                    .map((attribute) => (
                      <AccordionItem key={attribute.name}>
                        {({ isExpanded }) => (
                          <>
                            <AccordionButton>
                              <Box fontWeight="semibold" mb="2" flex="1" textAlign="left">
                                {attribute.name}
                              </Box>
                              {isExpanded ? <IoChevronDownSharp /> : <IoChevronForwardSharp />}
                            </AccordionButton>
                            <AccordionPanel>
                              <ListingFilterItem
                                key={attribute.name}
                                attributeId={attribute.name}
                                isMobileView
                              />
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    ))}
                </Accordion>
                <Flex direction="column" gap="16px" px="16px">
                  <Button colorScheme="primary" onClick={handleApply}>
                    SAVE FILTERS
                  </Button>
                  <Button variant="outline" colorScheme="primary" onClick={onClose}>
                    CANCEL
                  </Button>
                </Flex>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Flex>
  );
};

export default ListingAttributes;
