import React from 'react';
import { Flex, Image, Text, Icon } from '@chakra-ui/react';
import { FaStoreAlt } from 'react-icons/fa';
import { MarketplaceInfo } from 'src/api/v1-api/';
import { srcFromCDN } from 'src/utils/files';

interface LinkInBioHeaderProps {
  marketplace: MarketplaceInfo | null;
}

const LinkInBioHeader = ({ marketplace }: LinkInBioHeaderProps) => {
  if (!marketplace) return null;
  return (
    <Flex alignSelf="center" flexDirection="column" alignItems="center" mb="5">
      <Image
        src={marketplace?.logo_image ? srcFromCDN(marketplace?.logo_image, 94) : undefined}
        alt="Logo"
        boxSize="94px"
        objectFit="cover"
        fallback={<Icon as={FaStoreAlt} color="black" boxSize="6" />}
        borderRadius={'full'}
      />
      <Text mt={4} fontWeight="bold" fontSize="xl">
        {marketplace?.name || ''}
      </Text>
    </Flex>
  );
};

export default LinkInBioHeader;
