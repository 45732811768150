import { Select, Tooltip } from '@chakra-ui/react';
import { useEffect, useState, memo } from 'react';
import { BasicCategory } from 'src/api/v1-api';
import { buildCategoryTree } from 'src/utils/common';
import { ReactElement } from 'react';
import useRequest from 'src/utils/hooks/useRequest';
import { isEmpty } from 'lodash';
import useSingleToast from 'src/utils/hooks/toast/useSingleToast';
interface CategorySelectorProps {
  marketplaceId: number | undefined;
  selectedCategoryId?: string | undefined;
  onCategorySelect: (category: BasicCategory | null) => void;
  disabled?: boolean;
  disabledReason?: string;
  isRequired?: boolean;
  mode?: 'listing-create' | 'category-admin' | 'provide-categories' | 'save-search';
  isPayToList?: boolean;
  handlePayToList?: React.Dispatch<React.SetStateAction<boolean>>;
  categories?: BasicCategory[];
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
  marketplaceId,
  selectedCategoryId,
  onCategorySelect,
  disabled = false,
  disabledReason = '',
  isRequired,
  mode,
  isPayToList = false,
  handlePayToList,
  categories: providedCategories,
}) => {
  // Hooks
  const showToast = useSingleToast();
  const { get } = useRequest();

  const [categories, setCategories] = useState<BasicCategory[]>(providedCategories ?? []);
  const [categoryTree, setCategoryTree] = useState<BasicCategory[]>([]);
  const [categoriesLoading, setCategoriesLoading] = useState(
    isEmpty(providedCategories) || mode !== 'provide-categories',
  );
  const [selectedCategory, setSelectedCategory] = useState<BasicCategory | null>(null);

  const flagIneligibleCategories = (categories: BasicCategory[]): BasicCategory[] => {
    if (mode === 'listing-create') {
      return categories.map((category) => ({
        ...category,
        isDisabled: !category.functional.can_create_listings,
        isVisible:
          category.functional.create_listing_category_select_format !== 'pay_to_list_parent' &&
          category.functional.create_listing_category_select_format !== 'pay_to_list_child',
      }));
    }
    if (mode === 'save-search') {
      return categories.map((category) => ({
        ...category,
        isDisabled: !category.functional.can_create_listings,
      }));
    }
    return categories;
  };

  const handleCategorySelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value);
    const category = categories?.find((c) => c.id === value);
    setSelectedCategory(category || null);
    onCategorySelect(category || null);
  };

  const fetchCategories = async () => {
    if (!marketplaceId) return;

    try {
      setCategoriesLoading(true);
      const fetchUrl = `/marketplaces/${marketplaceId}/${
        mode === 'category-admin' ? 'all_categories' : 'categories'
      }/`;
      const shouldUseToken = mode === 'category-admin' ? true : false;
      // TODO: /categories/ returns CategoryInList, not BasicCategory
      const response = await get<BasicCategory[]>({
        url: fetchUrl,
        useToken: shouldUseToken,
      });
      const flaggedCategories = flagIneligibleCategories(response);

      setCategories(flaggedCategories || []);
    } catch (error) {
      showToast({
        title:
          'There was a problem fetching categories. Please try refreshing the page or contact support.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        sentryAlert: {
          error: new Error('Error fetching categories'),
          level: 'error',
          extras: {
            marketplaceId,
            mode,
          },
        },
      });
    } finally {
      setCategoriesLoading(false);
      if (categories.length === 1) {
        setSelectedCategory(categories[0]);
        onCategorySelect(categories[0]);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(providedCategories) || mode === 'provide-categories') {
      return;
    }

    fetchCategories();
  }, [marketplaceId, providedCategories, mode]);

  useEffect(() => {
    if (selectedCategoryId && categories.length > 0) {
      const selectedCategory = categories.find(
        (category) => category.id == parseInt(selectedCategoryId || ''),
      );
      setSelectedCategory(selectedCategory || null);
      onCategorySelect(selectedCategory || null);
      if (handlePayToList && isPayToList) {
        handlePayToList(true);
      }
    }
  }, [selectedCategoryId, categories]);

  useEffect(() => {
    setCategoryTree(buildCategoryTree(categories || []));
  }, [categories]);

  useEffect(() => {
    if (mode === 'provide-categories' && !isEmpty(providedCategories)) {
      setCategories(providedCategories ?? []);
      setCategoriesLoading(false);
    }
  }, [providedCategories]);

  const renderCategoryOptions: (
    categoryTree: BasicCategory[],
    prefix?: string,
  ) => ReactElement[] = (categoryTree, prefix = '') => {
    return categoryTree.flatMap((cat) => {
      if (cat.isVisible === undefined || cat.isVisible) {
        return [
          <option key={cat.id} value={cat.id} disabled={cat.isDisabled}>
            {prefix + cat.name}
          </option>,
          ...renderCategoryOptions(cat.children || [], prefix + '\u00A0\u00A0'),
        ];
      }
      return renderCategoryOptions(cat.children || [], prefix + '\u00A0\u00A0');
    });
  };

  useEffect(() => {
    if (selectedCategoryId && categories.length > 0) {
      const selectedCategory = categories.find(
        (category) => category.id == parseInt(selectedCategoryId || ''),
      );
      setSelectedCategory(selectedCategory || null);
      onCategorySelect(selectedCategory || null);
    }
  }, [selectedCategoryId, categories]);
  return (
    <Tooltip label={disabled ? disabledReason : ''} isDisabled={!disabled}>
      <Select
        id="category-select"
        value={selectedCategory ? selectedCategory.id.toString() : ''}
        onChange={handleCategorySelectChange}
        placeholder="Select Category"
        disabled={disabled}
        isRequired={isRequired}
        isDisabled={categoriesLoading || disabled}
        data-test="category-selector"
      >
        {categoriesLoading ? (
          <option value="" disabled>
            Loading...
          </option>
        ) : (
          renderCategoryOptions(categoryTree)
        )}
      </Select>
    </Tooltip>
  );
};

export default memo(CategorySelector);
