import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '..';
import { GlobalState, HANDLE_ERROR } from './global.helpers';

const initialState: GlobalState = {
  loading: {},
  errors: {},
};

const { actions: sliceActions, reducer } = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<{ actionType: string; loading: boolean }>) => {
      state.loading = { ...state.loading, [payload.actionType]: payload.loading };
    },
    resetLoading: (state) => {
      state.loading = {};
    },
    setError: (state, { payload }: PayloadAction<{ actionType: string; error: string }>) => {
      state.errors = { ...state.errors, [payload.actionType]: payload.error };
    },
    clearErrors: (state) => {
      state.errors = {};
    },
  },
});

const actions = {
  ...sliceActions,
  // Saga Actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError: (payload: { actionType: string; error: any }) => ({
    type: HANDLE_ERROR,
    payload,
  }),
};

const selectors = {
  loading:
    (actionType: string) =>
    (state: AppState): boolean =>
      state.global.loading[actionType],
  error:
    (actionType: string) =>
    (state: AppState): string =>
      state.global.errors[actionType],
};

export { actions as globalActions, reducer as globalReducer, selectors as globalSelectors };
