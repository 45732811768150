import { Stack, StackDirection } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { Attribute, BasicAttributeValue, ValueObject } from 'src/api/v1-api';
import { AttributeInput } from 'src/components/atoms';
import { FilePath } from 'src/components/organisms/listing-form';
import { handleAttributeChange } from 'src/utils/attributeUtils';

interface AttributeListProps {
  attributes: Attribute[] | undefined;
  attributeValues: BasicAttributeValue[];
  setAttributeValues: Dispatch<SetStateAction<BasicAttributeValue[]>>;
  direction: StackDirection;
}

const AttributeList = ({
  attributes,
  attributeValues,
  setAttributeValues,
  direction,
}: AttributeListProps) => {
  return (
    <Stack direction={direction} justify="flex-start" align="flex-start" spacing="20px">
      {attributes &&
        attributes.map((attribute) => {
          const attributeValue = attributeValues.find(
            (attrValue) => attrValue.attribute.name === attribute.name,
          );
          let value = null;
          if (attributeValue?.attribute.format === 'url') {
            value = attributeValue?.value_object;
          } else if (attributeValue?.attribute.format === 'media') {
            value = attributeValue?.value_object.values as FilePath[];
          } else if (attributeValue?.value_object?.value) {
            value = attributeValue.value_object.value;
          } else if (attributeValue?.value_object?.values) {
            value = (attributeValue.value_object.values as ValueObject[])[0];
          }
          value = value ?? '';

          return (
            <AttributeInput<Attribute>
              key={attribute.name}
              attribute={attribute}
              value={value}
              setChange={(e) =>
                handleAttributeChange(
                  attributeValues,
                  setAttributeValues,
                  attributes,
                  attribute.name,
                  e.target.value,
                )
              }
              setSelectChange={(e) =>
                handleAttributeChange(
                  attributeValues,
                  setAttributeValues,
                  attributes,
                  attribute.name,
                  e.target.value,
                )
              }
              setUrlChange={(value) =>
                handleAttributeChange(
                  attributeValues,
                  setAttributeValues,
                  attributes,
                  attribute.name,
                  value,
                )
              }
              setMediaChange={(filePaths) =>
                handleAttributeChange(
                  attributeValues,
                  setAttributeValues,
                  attributes,
                  attribute.name,
                  filePaths,
                )
              }
              setMultiselectChange={(value) =>
                handleAttributeChange(
                  attributeValues,
                  setAttributeValues,
                  attributes,
                  attribute.name,
                  value,
                )
              }
            />
          );
        })}
    </Stack>
  );
};

export default AttributeList;
