import { Box, ButtonGroup, Container, HStack, IconButton, useTheme, Text } from '@chakra-ui/react';
import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface Props {
  nextLink: string | null;
  prevLink: string | null;
  onNext: () => void;
  onPrev: () => void;
  pageNumber: string | null;
  totalPages: number | null;
}

const Pagination = ({
  nextLink,
  prevLink,
  onNext,
  onPrev,
  pageNumber,
  totalPages,
}: Props): JSX.Element => {
  const theme = useTheme();
  if (!nextLink && !prevLink) return <></>;

  return (
    <Box bg="bg-surface">
      <Container>
        <Text textAlign="center" fontSize="16px">{`Page ${pageNumber} of ${Math.ceil(
          totalPages ?? 0,
        )}`}</Text>
        <HStack justify="center">
          <ButtonGroup isAttached variant="ghost">
            <IconButton
              value="left"
              aria-label="Show previous"
              px="14px"
              _hover={{
                background: 'gray.100',
              }}
              icon={<ChevronLeftIcon fontSize="16px" />}
              color={prevLink === null ? theme.colors.text[100] : theme.colors.mainText}
              pointerEvents={prevLink === null ? 'none' : 'auto'}
              onClick={onPrev}
            />
            <IconButton
              borderColor="gray.200"
              value="right"
              aria-label="Show next"
              px="14px"
              _hover={{
                background: 'gray.100',
              }}
              icon={<ChevronRightIcon fontSize="16px" />}
              backgroundColor={nextLink === null ? 'gray.100' : 'none'}
              color={nextLink === null ? theme.colors.text[100] : theme.colors.mainText}
              pointerEvents={nextLink === null ? 'none' : 'auto'}
              onClick={onNext}
            />
          </ButtonGroup>
        </HStack>
      </Container>
    </Box>
  );
};

export default Pagination;
