import { Button, ButtonGroup, VisuallyHidden } from '@chakra-ui/react';
import { FaGithub, FaGoogle, FaTwitter } from 'react-icons/fa';

const providers = [
  { name: 'Google', icon: <FaGoogle fontSize="1.25rem" /> },
  { name: 'Twitter', icon: <FaTwitter fontSize="1.25rem" /> },
  { name: 'GitHub', icon: <FaGithub fontSize="1.25rem" /> },
];

const OAuthButtonGroup = () => (
  <ButtonGroup variant="outline" spacing="4" width="full">
    {providers.map(({ name, icon }) => (
      <Button key={name} width="full">
        <VisuallyHidden>Log in with {name}</VisuallyHidden>
        {icon}
      </Button>
    ))}
  </ButtonGroup>
);

export default OAuthButtonGroup;
