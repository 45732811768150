import { Box, Flex, Spinner, Stack, Table, Tbody, Td, Tr, Text, useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { LeadData, Lead, LEAD_STATUS } from 'src/api/v1-api';
import { Pagination } from 'src/components/atoms';
import AdminLeadForm from 'src/components/molecules/admin-lead-form';
import LeadConciergeModal from 'src/components/molecules/lead-concierge-modal';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useMultiPageContext } from 'src/contexts/pagination';
import { flattenAttributeValues } from 'src/utils/attributeUtils';
import { useAuth } from 'src/utils/auth';
import { formatDateTime } from 'src/utils/common';
import { apiRequest } from 'src/utils/fetchUtils';
import { getPageName } from '../leads-view';

interface LeadsListProps {
  fetchUrl: string;
  paginationStyle?: string;
  resultsPerPage?: number;
  actionButtons?: string;
  status: LEAD_STATUS;
  version: number;
  setVersion: Dispatch<SetStateAction<number>>;
}

// the next/prev page logic produces an URL like `?page=2&page1=1`
// this reformats it to `?page1=2` for consistent logic on the frontend
const getMultiPageLink = (link: string | null, pageName: string) => {
  if (!link) return link;
  try {
    const urlParts = link.split('?');
    const queryParts = urlParts[1].split('&');
    let newLink = urlParts[0] + '?';
    let pageVal = '1';
    for (const queryPart of queryParts) {
      // isolate the target page number
      if (queryPart.includes('page=')) {
        pageVal = queryPart.split('=')[1];
        continue;
      }
      // isolate the target page
      if (queryPart.includes(pageName + '=')) {
        continue;
      }
      newLink += queryPart + '&';
    }
    newLink += `${pageName}=${pageVal}`;

    return newLink;
  } catch (error) {
    console.error('MultiPage error parsing ', link);
  }
  return link;
};

// only pass the page number for the current status to the backend
const cleanMultiPageUrl = (link: string, pageName: string) => {
  return link.replace(pageName, 'page');
};

const LeadsList = (props: LeadsListProps) => {
  const { fetchUrl, paginationStyle, resultsPerPage, status, version, setVersion } = props;
  const router = useRouter();
  const [multiPage, setMultiPage] = useMultiPageContext();
  const { getToken } = useAuth();
  const toast = useToast();
  const [rerouting, setRerouting] = useState<boolean>(false);
  const { marketplace } = useContext(MarketplaceContext);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [haveFetched, setHaveFetched] = useState<boolean>(false);
  const [nextLink, setNextLink] = useState<string | null>(null);
  const [prevLink, setPrevLink] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [leadForm, setLeadForm] = useState<AdminLeadForm>({
    id: 0,
    listingId: 0,
    recipientName: '',
    recipientEmail: '',
    recipientPhone: '',
    recipientMessage: '',
    notesForSeller: '',
    adminPrivateNotes: '',
    attributeValues: {},
    status: LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL,
  });

  const pageName = getPageName(status);

  useEffect(() => {
    // clear the form when we select a different lead
    if (selectedLead?.id === leadForm.id) return;
    setLeadForm({
      id: selectedLead?.id ?? 0,
      listingId: selectedLead?.listing.id ?? 0,
      recipientName: selectedLead?.recipientName ?? '',
      recipientEmail: selectedLead?.recipientEmail ?? '',
      recipientPhone: selectedLead?.recipientPhone ?? '',
      recipientMessage: selectedLead?.recipientMessage ?? '',
      notesForSeller: selectedLead?.notesForSeller ?? '',
      adminPrivateNotes: selectedLead?.adminPrivateNotes ?? '',
      attributeValues: selectedLead ? flattenAttributeValues(selectedLead.leadAttributeValues) : {},
      status: selectedLead?.status ?? LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL,
    });
  }, [selectedLead?.id]);

  const processLeadData = (val: LeadData) => {
    return {
      id: val.id,
      listing: val.listing,
      recipientName: val.recipient_name ?? '',
      recipientEmail: val.recipient_email ?? '',
      recipientPhone: val.recipient_phone ?? '',
      recipientMessage: val.recipient_message ?? '',
      loggedInSender: val.logged_in_sender,
      status: val.status,
      emailData: val.json_payload,
      notesForSeller: val.notes_for_seller ?? '',
      adminPrivateNotes: val.admin_private_notes ?? '',
      leadAttributeValues: val.lead_attribute_values ?? [],
      createdAt: val.created_at,
    } as Lead;
  };

  const fetchLeads = useCallback(
    async (url: string) => {
      if (!isLoading) {
        setIsLoading(true);

        try {
          const params = new URLSearchParams();
          const paramsString = params.toString();
          const fullUrl = paramsString ? `${url}&${paramsString}` : url;
          const cleanUrl = cleanMultiPageUrl(fullUrl, pageName);

          const token = await getToken();

          const response = await apiRequest('GET', cleanUrl, token);
          const leadData: Lead[] = response.results.map((val: LeadData) => {
            return processLeadData(val);
          });
          setLeads(leadData);
          setNextLink(getMultiPageLink(response.next, pageName));
          setPrevLink(getMultiPageLink(response.previous, pageName));
          setTotalPages(response.count / response.page_size);
          setPageNumber(response.page_number);
          setHaveFetched(true);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [getToken, pageName, isLoading],
  );

  useEffect(() => {
    // We need to avoid loading when page is 0. This means that the app is
    // currently loading and waiting the page to be updated from query string.
    // After this is set to page = 1 or page = query params page, it should
    // request the data from the api.
    if (multiPage[pageName] === 0) {
      return;
    }
    setRerouting(false);
    const url = `${fetchUrl}&page_size=${resultsPerPage}&${pageName}=${multiPage[pageName]}`;
    if (!url.includes('undefined')) {
      fetchLeads(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUrl, multiPage[pageName], version]);

  const updateQueryParamAndContextPage = (link?: string | null) => {
    if (link) {
      const pageNumber = new URL(link).searchParams.get(pageName) ?? '1';
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set(pageName, pageNumber);
      setMultiPage({
        ...multiPage,
        [pageName]: parseInt(pageNumber),
      });
      router.push(currentUrl.toString());
    }
  };

  const handleNextPage = () => {
    if (nextLink) {
      setRerouting(true);
      updateQueryParamAndContextPage(nextLink);
    }
  };

  const handlePrevPage = () => {
    if (prevLink) {
      setRerouting(true);
      updateQueryParamAndContextPage(prevLink);
    }
  };

  const updateLeadHandler = async () => {
    const lead = leads.find((lead) => lead.id === leadForm.id);
    if (!lead) return;

    setIsLoading(true);
    const token = await getToken();

    try {
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/leads/${lead.id}/${marketplace?.id}/update/`;

      const formData = new FormData();

      formData.append('id', leadForm.id.toString());
      if (leadForm.recipientName !== (lead.recipientName ?? '')) {
        formData.append('recipient_name', leadForm.recipientName ?? '');
      }
      if (leadForm.recipientEmail !== (lead.recipientEmail ?? '')) {
        formData.append('recipient_email', leadForm.recipientEmail ?? '');
      }
      if (leadForm.recipientPhone !== (lead.recipientPhone ?? '')) {
        formData.append('recipient_phone', leadForm.recipientPhone ?? '');
      }
      if (leadForm.recipientMessage != (lead.recipientMessage ?? '')) {
        formData.append('recipient_message', leadForm.recipientMessage ?? '');
      }
      if (leadForm.notesForSeller != (lead.notesForSeller ?? '')) {
        formData.append('notes_for_seller', leadForm.notesForSeller ?? '');
      }
      if (leadForm.adminPrivateNotes != (lead.adminPrivateNotes ?? '')) {
        formData.append('admin_private_notes', leadForm.adminPrivateNotes ?? '');
      }
      if (leadForm.status !== lead.status) {
        formData.append('status', leadForm.status ?? LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL);
      }
      const leadAttributeValues = JSON.stringify(leadForm.attributeValues ?? {});
      if (leadAttributeValues !== '{}') {
        formData.append('lead_attribute_values', leadAttributeValues);
      }
      // nothing to update
      if (Array.from(formData.keys()).length === 1) {
        return;
      }

      const updatedLead: LeadData = await apiRequest('PATCH', url, token, formData, true);
      const updatedLeads = leads.map((lead: Lead) =>
        lead.id === updatedLead.id ? processLeadData(updatedLead) : lead,
      );
      setLeads(updatedLeads);
      const newVersion = version + 1;
      setVersion(newVersion);

      toast({
        title: 'Success',
        description: 'Lead updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch {
      toast({
        title: 'Error updating lead',
        description: '',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex direction="column" width="100%" gap={{ base: 6, md: 10 }} pb={4}>
      <Box position="relative">
        {!isLoading && haveFetched && leads.length === 0 && (
          <Box>
            <i>No leads yet.</i>
          </Box>
        )}

        {leads.length > 0 && (
          // w=0px is spooky behavior, but allows the nowrap to work nicely with Chakra
          <Box whiteSpace="nowrap" w="0px">
            <Table>
              <Tbody fontSize="sm">
                {leads.map((lead) => (
                  <Tr
                    key={lead.id}
                    onClick={() => {
                      setSelectedLead(lead);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <Td>{formatDateTime(lead.createdAt)}</Td>
                    <Td>{lead.recipientEmail}</Td>
                    <Td>{lead.listing?.title}</Td>
                    <Td>{lead.emailData?.message}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
        {(isLoading || rerouting) && (
          <Box
            pt={8}
            pb={8}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={'rgba(255,255,255,0.7)'}
          >
            <Spinner />
          </Box>
        )}
      </Box>
      <Pagination
        nextLink={nextLink}
        prevLink={prevLink}
        onNext={handleNextPage}
        onPrev={handlePrevPage}
        pageNumber={pageNumber}
        totalPages={totalPages}
      />
      <LeadConciergeModal
        isOpen={!!selectedLead?.id}
        onClose={() => setSelectedLead(null)}
        onUpdateLead={updateLeadHandler}
        lead={selectedLead}
        leadForm={leadForm}
        setLeadForm={setLeadForm}
        disabled={status !== LEAD_STATUS.STATUS_PENDING_ADMIN_APPROVAL}
      />
    </Flex>
  );
};

export default LeadsList;
