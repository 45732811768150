import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useAuth } from 'src/utils/auth';

function LogoutButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logout } = useAuth();
  const router = useRouter();

  const handleLogout = async () => {
    const loggedOut = await logout();
    if (loggedOut) {
      router.push('/');
    }
  };

  return (
    <>
      <Button onClick={onOpen}>
        <Text color="gray.500">Logout</Text>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Logout</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure?</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleLogout}>
              Yes
            </Button>
            <Button variant="ghost" onClick={onClose}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default LogoutButton;
