// @ts-nocheck

import { CircularProgress, Flex, useTheme } from '@chakra-ui/react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { apiRequest } from 'src/utils/fetchUtils';
import { useAuth } from 'src/utils/auth';
import { v4 as uuidv4 } from 'uuid';
import { useState, useEffect } from 'react';
import * as path from 'path';
import * as Sentry from '@sentry/nextjs';
import { FilePath } from 'src/components/organisms/listing-form';
import useSingleToast from 'src/utils/hooks/toast/useSingleToast';
import isEqual from 'lodash/isEqual';
interface LomaUploaderProps {
  maxFiles: number;
  onFileDelete?: (fileToDelete: string) => void;
  onFileUpload?: (filePaths: FilePath[]) => void;
  file_type?: string | undefined;
  filePathsLength: number;
  filePaths?: FilePath[];
  updatedFilePaths?: FilePath[];
  setFilePaths?: ((filePaths: FilePath[]) => void) | undefined;
  uploadingFiles?: string[];
  setUploadingFiles?: Dispatch<SetStateAction<string[]>>;
  parentName?: string;
}

const convertHeicToPng = async (imageToConvert: File) => {
  const heic2any = (await import('heic2any')).default;

  try {
    const convertedBlob = await heic2any({
      blob: imageToConvert,
      toType: 'image/png',
      quality: 0.8,
    });

    const fileExt = path.extname(imageToConvert.name);
    const fileNameNoExt = path.basename(imageToConvert.name, fileExt);

    const convertedFile = new File([convertedBlob as Blob], `${fileNameNoExt}.png`, {
      type: 'image/png',
    });

    return convertedFile;
  } catch (error) {
    return imageToConvert;
  }
};

const cleanForS3 = (fileName) => {
  /* Image transform CDN chokes on encoded characters, so first encode the filename and replace all
  encoded characters with dashes */
  const noPercentFileName = fileName.replace('%', '-');
  const encodedFileName = encodeURIComponent(noPercentFileName);
  const cleanFileName = encodedFileName.replace(/\%../g, '-');
  return cleanFileName;
};

const LomaUploader = ({
  maxFiles,
  onFileDelete,
  onFileUpload,
  file_type,
  filePathsLength,
  filePaths,
  updatedFilePaths,
  setFilePaths,
  uploadingFiles,
  setUploadingFiles,
}: LomaUploaderProps) => {
  const showToast = useSingleToast();
  const { getToken } = useAuth();
  const [isDisabled, setIsDisabled] = useState(false);
  const [filePathList, setFilePathList] = useState(filePaths ? filePaths : []);
  const theme = useTheme();

  useEffect(() => {
    // update file paths from changes within uploader
    if (setFilePaths && !isEqual(filePaths, filePathList)) {
      setFilePaths(filePathList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePathList, setFilePaths]);

  useEffect(() => {
    // update file paths from changes in image editor
    if (!isEqual(updatedFilePaths, filePaths)) {
      setFilePathList(updatedFilePaths);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedFilePaths]);

  const customStyles = {
    dropzone: {
      overflow: 'auto',
      borderRadius: theme.border_radius.border_radius_1,
    },
    inputLabel: { color: 'rgb(113, 128, 150)', fontFamily: theme.fonts.font_2 },
    inputLabelWithFiles: {
      color: 'rgb(113, 128, 150)',
      fontFamily: theme.fonts.font_2,
      marginBottom: '18px',
    },
    input: {
      borderRadius: theme.border_radius.border_radius_1,
    },
    previewStatusContainer: { color: '#68D391' },
  };

  const getPresignedUploadParams = async (fileName: string, retries = 2, delay = 1000) => {
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/generate_presigned_url/`;
    const token = await getToken();

    const body = {
      file_name: fileName,
    };

    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        const response = await apiRequest('POST', url, token, body);
        const uploadUrl = response.url;
        const fileUrl = `${uploadUrl.split('?')[0]}` + fileName;
        const fields = response.fields;

        return { fields, uploadUrl, fileUrl };
      } catch (error) {
        if (attempt < retries - 1) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        } else {
          showToast({
            title: 'There was an error uploading your file.',
            description: 'Please try again. Contact support if the problem persists.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          Sentry.captureException(
            new Error(`Photo upload: Error fetching presigned URL: ${fileName}`),
            {
              extra: { fileName },
            },
          );
          throw error;
        }
      }
    }
  };

  const getUploadParams = async ({ file, meta: { name } }) => {
    const convertedName = name.replace(/\.heic$/i, '.png');
    const body = new FormData();
    let newFile;
    if (typeof window !== 'undefined') {
      newFile = await convertHeicToPng(file);
    }

    // Prepend the filename with a unique uuid to prevent collisions
    const s3CleanFileName = cleanForS3(convertedName);
    const uniqueFileName = `${uuidv4()}_${s3CleanFileName}`;

    uploadingFiles !== undefined &&
      setUploadingFiles &&
      setUploadingFiles([...uploadingFiles, uniqueFileName]);

    const { fields, uploadUrl, fileUrl } = await getPresignedUploadParams(uniqueFileName);
    if (newFile) {
      for (const field of Object.keys(fields)) body.append(field, fields[field]);
      // the file has to be added after the other fields
      body.append('file', newFile);
      return { meta: { fileUrl }, url: uploadUrl, body };
    }
    return { fields, meta: { fileUrl }, url: uploadUrl };
  };

  const handleChangeStatus = (file: IFileWithMeta, status, allFiles) => {
    const meta = file.meta;

    const fileName = meta.fileUrl ? meta.fileUrl?.split('/').pop() : null;

    if (status === 'rejected_file_type') {
      showToast({
        title: 'File type not supported',
        description: 'Please upload an image or video.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      uploadingFiles !== undefined &&
        setUploadingFiles &&
        fileName &&
        setUploadingFiles((prevFiles) => prevFiles.filter((file) => file != fileName));
    }

    if (status === 'rejected_max_files') {
      showToast({
        title: 'Maximum number of files reached',
        description: 'Please remove a file before adding another.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      uploadingFiles !== undefined &&
        setUploadingFiles &&
        setUploadingFiles((prevFiles) => prevFiles.filter((file) => file != meta.name));
    }

    if (['error_upload', 'exception_upload', 'error_upload_params'].includes(status)) {
      showToast({
        title: 'There was an error uploading your file.',
        description: 'Please try again. Contact support if the problem persists.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      Sentry.captureException(new Error(`Photo upload: Error uploading file: ${meta.name}`), {
        extra: { meta, status },
      });
      uploadingFiles !== undefined &&
        setUploadingFiles &&
        fileName &&
        setUploadingFiles((prevFiles) => prevFiles.filter((file) => file != fileName));
    }

    if (status === 'done') {
      // remove the file from uploading list
      uploadingFiles !== undefined &&
        setUploadingFiles &&
        fileName &&
        setUploadingFiles((prevFiles) => prevFiles.filter((file) => file != fileName));

      setFilePathList((prevPathList) => [
        ...prevPathList,
        { filePath: meta.fileUrl, position: prevPathList.length },
      ]);
    }

    if (status === 'removed') {
      // remove the file from uploading list
      uploadingFiles !== undefined &&
        setUploadingFiles &&
        fileName &&
        setUploadingFiles((prevFiles) => prevFiles.filter((file) => file != fileName));

      const updatedFilePathList = filePathList.filter(
        (filePath) => filePath.filePath !== meta.fileUrl,
      );
      updatedFilePathList.forEach((filePath, index) => {
        filePath.position = index;
      });

      setFilePathList(updatedFilePathList);
      onFileDelete && onFileDelete(meta.fileUrl);
    }
  };

  useEffect(() => {
    onFileUpload && onFileUpload(filePathList);
  }, [filePathList, onFileUpload]);

  const getAcceptedFileTypes = (fileType: string | undefined) => {
    if (fileType && fileType === 'image') {
      return 'image/*';
    } else if (fileType && fileType === 'video') {
      return 'video/*';
    } else if (fileType && fileType === 'image/video') {
      return 'image/*,video/*';
    }
    if (fileType && fileType === 'pdf') {
      return '.pdf';
    } else {
      return 'image/*,video/*,image/heic';
    }
  };

  useEffect(() => {
    if (filePathsLength >= maxFiles) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [filePathsLength, maxFiles, setIsDisabled]);

  return (
    <>
      {uploadingFiles && uploadingFiles.length > 0 && (
        <Flex justifyContent="center" alignItems="center" pb={2}>
          <CircularProgress isIndeterminate color="green.300" />
        </Flex>
      )}
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept={getAcceptedFileTypes(file_type)}
        maxFiles={maxFiles}
        maxSizeBytes={25_000_000}
        multiple={true}
        canCancel={true}
        inputWithFilesContent="Add more"
        styles={customStyles}
        disabled={isDisabled}
      />
    </>
  );
};

export default LomaUploader;
