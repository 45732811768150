import React from 'react';
import { Box, Image, Text, Flex, useTheme } from '@chakra-ui/react';
import { srcFromCDN } from 'src/utils/files';

interface PromoMediaProps {
  promoMediaUrl: string | null;
}

const PromoMedia = ({ promoMediaUrl }: PromoMediaProps) => {
  const theme = useTheme();
  return (
    <>
      {promoMediaUrl && (
        <Flex alignContent="center" direction="column" px={2}>
          <Text fontSize="xs" py={2}>
            (Optional) Long press image to save for sharing:
          </Text>
          <Box
            borderWidth="1px"
            borderRadius={theme.border_radius.border_radius_1}
            overflow="hidden"
            height={300}
            width={300}
          >
            <Image src={srcFromCDN(promoMediaUrl, 300)} alt="Promo Media" />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default PromoMedia;
