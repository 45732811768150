import { Order, MarketplaceEarning, getOrderImage } from 'src/api/v1-api';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { getOrderUrl } from 'src/utils/marketplaceUtils';
import MarketplaceEarningItem from 'src/components/atoms/marketplace-earning-item';
import { DEFAULT_IMAGE } from 'src/constants/config';

interface MarketplaceEarningPaidTransactionProps {
  earning: MarketplaceEarning;
  showImage?: boolean;
}

const MarketplaceEarningPaidTransaction = ({
  earning,
  showImage = false,
}: MarketplaceEarningPaidTransactionProps) => {
  const { urlBase } = useMarketplaceContext();
  const { entity_id: order_id, client_account_id } = earning.metadata;
  const order: Order = earning.earning_object;

  const orderImage = order.fulfillments ? getOrderImage(order) : DEFAULT_IMAGE;
  const orderUrl = getOrderUrl(urlBase, order_id);

  return (
    <MarketplaceEarningItem
      earningDate={earning.invoice_date}
      earningTitle="Paid Transaction"
      earningAmount={earning.amount}
      earningItemUrl={orderUrl}
      earningImageUrl={orderImage}
      showImage={showImage}
      earningMetadata={[
        {
          title: 'Order Number',
          value: `Order #${order_id}`,
          displayTitle: false,
        },
      ]}
      clientAccountId={client_account_id}
    />
  );
};

export default MarketplaceEarningPaidTransaction;
