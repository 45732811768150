import React, { useCallback } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Flex,
  VStack,
  Link,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useAuth } from 'src/utils/auth';
import { apiRequest, handleNextRevalidation } from 'src/utils/fetchUtils';
import { BasicCategory } from 'src/api/v1-api';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { FormInputValues } from 'src/components/organisms/listing-form';
import { getListingUrl } from 'src/utils/marketplaceUtils';
import useRequest from 'src/utils/hooks/useRequest';
import { useState } from 'react';

interface Props {
  selectedCategory?: BasicCategory | null;
  listingId?: number | null;
  listing?: FormInputValues | null;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
  submitDraftLoading: boolean;
  writingMode: 'Add' | 'Edit';
  handleSubmit: (e: any) => Promise<void>;
  isDisabled: boolean;
}

const ListingFormSubmit = ({
  selectedCategory,
  listing,
  listingId,
  submitLoading,
  setSubmitLoading,
  submitDraftLoading,
  writingMode,
  handleSubmit,
  isDisabled,
}: Props) => {
  const router = useRouter();
  const { api } = useRequest();
  const { getToken, isAuthenticated } = useAuth();
  const { hostname, marketplaceSlug, urlBase } = useMarketplaceContext();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const is_pending_or_active =
    listing?.status === 'active' || listing?.status === 'pending_approval';
  const isRejected = listing?.status === 'candidate_rejected';
  const inDraftEditingMode = listing?.status === 'draft' && listingId !== null;
  const inCreateMode = listing?.status === 'draft' && listingId === null;
  const saveButtonText = inDraftEditingMode
    ? 'Save Draft'
    : inCreateMode
    ? 'Save Draft'
    : 'Unpublish (Change to Draft)';
  const deleteButtonText = inDraftEditingMode ? 'Delete Draft' : 'Delete';

  const handleDelete = async () => {
    if (!listingId) return;

    setDeleteLoading(true);
    const token = await getToken();
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${listingId}/delete/`;
    try {
      await apiRequest('DELETE', url, token);
    } catch (err: any) {
      console.error('Error deleting listing', err);
      setDeleteLoading(false);
      return;
    }
    router.push('/?success=true&message=Listing+deleted');
  };

  let buttonText = '';

  if (listingId) {
    if (isRejected) {
      buttonText = 'Re-Submit for Approval';
    } else {
      buttonText = 'Save';
    }
  } else if (selectedCategory?.functional.requires_approval) {
    buttonText = 'Submit for Approval';
  } else if (!selectedCategory) {
    buttonText = 'Select Category Before Saving';
  } else {
    buttonText = 'Publish';
  }
  if (writingMode === 'Edit' && listing?.status === 'draft') {
    buttonText = 'Publish';
  }

  let publishButton = (
    <Button
      isLoading={submitLoading}
      colorScheme="primary"
      variant="solid"
      data-action="publish"
      type="submit"
      isDisabled={!isAuthenticated || isDisabled || !selectedCategory}
      data-test="publish-btn"
    >
      {buttonText}
    </Button>
  );
  if (
    selectedCategory?.functional.requires_payment &&
    selectedCategory?.functional.pay_to_list &&
    !selectedCategory?.functional.requires_approval
  ) {
    const payToList = selectedCategory?.functional.pay_to_list;
    let publishButtonText = 'Pay and Publish';
    // TODO(Sean): Add types for payToList
    // @ts-ignore
    if (payToList?.cta?.text) {
      // @ts-ignore
      publishButtonText = payToList.cta.text;
    }

    if (listing?.status === 'active') {
      publishButtonText = 'Save';
    }

    publishButton = (
      <Button
        isLoading={submitLoading}
        colorScheme="primary"
        variant="solid"
        data-action="publish"
        type="submit"
        isDisabled={!isAuthenticated || isDisabled || !selectedCategory}
      >
        {publishButtonText}
      </Button>
    );
  }

  const fetchClientSecret = useCallback(() => {
    if (!listing?.category?.id || !listing?.id || !marketplaceSlug || urlBase === '')
      return Promise.resolve('');
    const listingUrl = getListingUrl(urlBase, listing.id);
    const payload = {
      return_url: listingUrl,
      client_reference_id: `${listing.id}-listing_id`,
    };
    return api.marketplace.categories.createCheckoutSession(listing?.category?.id, payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing?.category?.id]);

  const paymentInfoBox = () => {
    if (!selectedCategory?.functional.requires_payment || is_pending_or_active) return null;

    // @ts-ignore
    const alertStyle = selectedCategory?.functional?.pay_to_list?.info?.style ?? 'info';
    // @ts-ignore
    const alertTitle = selectedCategory?.functional?.pay_to_list?.info?.title ?? 'Payment Required';

    const alertDescription =
      // @ts-ignore
      selectedCategory?.functional?.pay_to_list?.info?.text ??
      'After clicking Publish you will be redirected to a payment page. Your listing will be live soon after payment is received.';

    return (
      <>
        <Alert status={alertStyle} variant="left-accent">
          <AlertIcon />
          <AlertTitle mr={2}>{alertTitle}</AlertTitle>
          <AlertDescription>{alertDescription}</AlertDescription>
        </Alert>
      </>
    );
  };

  return (
    <VStack alignItems="flex-end" spacing={4}>
      {paymentInfoBox()}

      <Flex alignItems="center" justifyContent="flex-end" flexWrap="wrap" gap={4}>
        {listingId && (
          <>
            <Button
              colorScheme="red"
              variant="outline"
              onClick={handleDelete}
              isDisabled={!isAuthenticated || isDisabled}
              data-test="listing-delete-btn"
              isLoading={deleteLoading}
            >
              {deleteButtonText}
            </Button>
            <Button colorScheme="primary" variant="outline">
              <NextLink href={`/listing/${listingId}`}>View Listing</NextLink>
              <Link as={NextLink} href={`/listing/${listingId}`} isExternal>
                <ExternalLinkIcon marginBottom={1} marginLeft={1} />
              </Link>
            </Button>
          </>
        )}
        {!!selectedCategory && (
          <Button
            isLoading={submitDraftLoading}
            colorScheme="primary"
            variant="outline"
            data-action="submitDraft"
            type="button"
            isDisabled={!isAuthenticated || isDisabled}
            onClick={handleSubmit}
          >
            {saveButtonText}
          </Button>
        )}
        {publishButton}
      </Flex>
    </VStack>
  );
};

export default ListingFormSubmit;
