import {
  Box,
  Popover,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  NumberInput,
  NumberInputField,
  HStack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FilterPopoverButton, FilterPopoverContent } from 'src/components/atoms';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';

type PriceRangeFilterProps = {
  isMobileView?: boolean;
  handleUserChangedPriceRange: React.Dispatch<React.SetStateAction<boolean>>;
  priceRangeDisplay: 'slider' | 'input' | 'all';
};

const PriceRangeFilter = ({
  isMobileView,
  handleUserChangedPriceRange,
  priceRangeDisplay,
}: PriceRangeFilterProps): JSX.Element => {
  const { priceRange, setPriceRange, maxPrice, setStagedPriceRange } = useAttributeSelector();
  const defaultValue: [number, number] = [0, 0];

  const [inputValue, setInputValue] = useState(priceRange ?? defaultValue);
  const [tempPrice, setTempPrice] = useState(priceRange ?? defaultValue);

  const displayMinValue = 0;
  const displayMaxValue = maxPrice ?? 0;

  const handleMobileChange = () => {
    handleUserChangedPriceRange(true);
    setInputValue([tempPrice[0], tempPrice[1]]);
    setPriceRange([tempPrice[0], tempPrice[1]]);
  };

  const handleChange = (value: [number, number]) => {
    setTempPrice(value);
  };

  const setMinInputValue = (e: string) => {
    const input = e;
    if (input) {
      const value = parseInt(input);
      setInputValue([value, inputValue[1]]);
      setStagedPriceRange([value, inputValue[1]]);
    } else {
      setInputValue([0, inputValue[1]]);
      setStagedPriceRange([0, inputValue[1]]);
    }
  };

  const setMaxInputValue = (e: string) => {
    const input = e;
    if (input) {
      const value = parseInt(input);
      setInputValue([inputValue[0], value]);
      setStagedPriceRange([inputValue[0], value]);
    } else {
      setInputValue([inputValue[0], 0]);
      setStagedPriceRange([inputValue[0], 0]);
    }
  };

  const format = (val: number | string) => {
    if (val === '') {
      return '$0';
    }
    return `$${val}`;
  };
  const parse = (val: string) => val.replace(/^\$/, '');

  useEffect(() => {
    // we need to wait for PriceRange to load or
    // inputValue will stay [0,0] as it is initialized
    setInputValue(priceRange ?? defaultValue);
    setTempPrice(priceRange ?? defaultValue);
  }, [priceRange]);

  useEffect(() => {
    setTempPrice(inputValue ?? defaultValue);
  }, [inputValue]);

  const handleMaxInputValue = (value: string) => {
    const parsedValue = parse(value);
    setMaxInputValue(parse(parsedValue));
    if (parsedValue !== maxPrice.toString() && parsedValue !== '0') {
      handleUserChangedPriceRange(true);
    }
  };

  const handleMinInputValue = (value: string) => {
    const parsedValue = parse(value);
    setMinInputValue(parse(parsedValue));
    if (parsedValue !== '0') {
      handleUserChangedPriceRange(true);
    }
  };

  return isMobileView ? (
    <Box px="2" pt="2">
      <RangeSlider
        colorScheme="primary"
        min={displayMinValue}
        max={displayMaxValue}
        value={tempPrice}
        onChangeEnd={handleMobileChange}
        onChange={handleChange}
        step={1}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        {tempPrice?.map((_, index) => (
          <RangeSliderThumb
            w="5"
            h="5"
            borderWidth="1px"
            borderColor="gray.200"
            key={index}
            index={index}
          />
        ))}
      </RangeSlider>
      <HStack>
        <NumberInput
          onChange={handleMinInputValue}
          placeholder={'0'}
          value={format(tempPrice[0])}
          keepWithinRange={false}
          min={0}
        >
          <NumberInputField />
        </NumberInput>

        <NumberInput
          onChange={handleMaxInputValue}
          value={format(tempPrice[1])}
          keepWithinRange={false}
          max={maxPrice}
        >
          <NumberInputField />
        </NumberInput>
      </HStack>
    </Box>
  ) : (
    <Popover placement="bottom-start">
      <FilterPopoverButton
        label="Price"
        hasFilter={!(defaultValue[0] === priceRange[0] && defaultValue[1] === priceRange[1])}
      />
      <FilterPopoverContent
        isCancelDisabled={!(defaultValue !== tempPrice)}
        isApplyDisabled={tempPrice[1] < tempPrice[0]}
        onClickApply={() => setPriceRange([tempPrice[0], tempPrice[1]])}
        onClickCancel={() => {
          setInputValue(defaultValue);
          setPriceRange(defaultValue);
        }}
      >
        <Box px="2" pt="2">
          {(priceRangeDisplay === 'all' || priceRangeDisplay === 'slider') && (
            <>
              <RangeSlider
                colorScheme="primary"
                min={displayMinValue}
                max={displayMaxValue}
                value={tempPrice}
                onChangeEnd={handleMobileChange}
                onChange={handleChange}
                step={1}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                {tempPrice.map((_, index) => (
                  <RangeSliderThumb
                    w="5"
                    h="5"
                    borderWidth="1px"
                    borderColor="gray.200"
                    key={index}
                    index={index}
                  />
                ))}
              </RangeSlider>
              {priceRangeDisplay === 'slider' && (
                <Box>
                  <Text>{`$${priceRange[0]} - $${priceRange[1]}`}</Text>
                </Box>
              )}
            </>
          )}

          {(priceRangeDisplay === 'all' || priceRangeDisplay === 'input') && (
            <HStack>
              <NumberInput
                onChange={handleMinInputValue}
                value={format(tempPrice[0])}
                precision={0}
                placeholder="0"
                keepWithinRange={false}
                min={0}
              >
                <NumberInputField />
              </NumberInput>

              <NumberInput
                onChange={handleMaxInputValue}
                precision={0}
                value={format(tempPrice[1])}
                keepWithinRange={false}
                max={maxPrice}
              >
                <NumberInputField />
              </NumberInput>
            </HStack>
          )}
        </Box>
      </FilterPopoverContent>
    </Popover>
  );
};

export default PriceRangeFilter;
