import { Flex, Text } from '@chakra-ui/react';
import { Category } from 'src/api/v1-api';
import CategoryCard from '../category-card';
import { isNull, sortBy } from 'lodash';
import { useState } from 'react';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';

export type CategoryReorderingCardsProps = {
  /**
   * Obtained categories corresponding for this marketplace
   */
  categories: Category[];
  /**
   * Used for presenting categories in cards to be reordered
   */
  selectedCategoryId: number | null;
  onReordering: (categories: Category[]) => Promise<void> | void;
};

export type CardsDraggingState = {
  categoryId: number;
  dragging: boolean;
};

const CategoryReorderingCards: React.FC<CategoryReorderingCardsProps> = ({
  categories,
  selectedCategoryId,
  onReordering,
}) => {
  const [cachedCategories, setCachedCategories] = useState<Category[]>(categories);

  // parent will be default if selected category id is not provided
  const parentCategory = !selectedCategoryId
    ? cachedCategories?.find((category) => isNull(category.parent))
    : cachedCategories?.find((category) => category.id === selectedCategoryId);
  const rootCategories = cachedCategories.filter(
    (category) => category?.parent?.id === parentCategory?.id,
  );
  const rootOrderedCategories = sortBy(rootCategories, ['position', 'id']);

  const handleOnReorder = async (sourceIndex: number, targetIndex: number) => {
    const newOrderedCategories = arrayMove<Category>(
      rootOrderedCategories,
      sourceIndex,
      targetIndex,
    ).map((category, index) => ({ ...category, position: index } as Category));

    setCachedCategories(
      cachedCategories.map((category) => {
        const foundCategory = newOrderedCategories.find(
          (orderedCategory) => orderedCategory.id === category.id,
        );
        if (foundCategory) {
          return foundCategory;
        }
        return category;
      }),
    );
    onReordering(newOrderedCategories);
  };

  return (
    <Flex direction="column" gap={{ base: 3, md: 4, lg: 5 }}>
      {rootOrderedCategories.length > 0 && <Text fontSize="sm">Drag and drop to reorder</Text>}

      <SortableList
        onSortEnd={handleOnReorder}
        className="list"
        draggedItemClassName="dragged"
        allowDrag
      >
        {rootOrderedCategories.map((category: Category) => (
          <SortableItem key={category.id}>
            <div className="item" style={{ alignItems: 'stretch' }}>
              <CategoryCard
                name={category.name}
                imageURL={category.image ?? category.image}
                cta={{
                  // label: category.cta_text,
                  link: `/admin/categories/edit/${category.id}`,
                }}
                mr={2}
              />
            </div>
          </SortableItem>
        ))}
      </SortableList>
    </Flex>
  );
};

export default CategoryReorderingCards;
