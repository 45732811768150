import {
  Breadcrumb as BreadcrumbContainer,
  BreadcrumbItem,
  BreadcrumbLink,
  Tooltip,
} from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { useRef } from 'react';
import { Parent } from 'src/api/v1-api';

interface BreadcrumbItem {
  id: number;
  name: string;
  slug?: string;
}

const getAllItems = (parent: Parent, items: BreadcrumbItem[] = []) => {
  const r = items;
  if (!parent) return r;
  Object.keys(parent).forEach((k) => {
    const value = parent[k as keyof Parent];
    if (typeof value !== 'object' && k === 'name') {
      r.unshift({ id: parent.id, name: parent.name, slug: parent.slug });
    } else if (typeof value === 'object' && value !== null && 'id' in value && 'name' in value) {
      getAllItems(value as Parent, r);
    }
  });
  return r;
};

interface Props {
  parent?: Parent | null;
  current: string | undefined;
}

const Breadcrumb = ({ parent, current }: Props) => {
  const breadcrumbRef = useRef<HTMLDivElement>(null);
  const breadcrumbWidth = (useSize(breadcrumbRef) ?? { width: 10 }).width;
  const parentItems = getAllItems(parent ?? { id: 0, name: 'Home', slug: '/' }, []).filter(
    (item) => item.id && item.name,
  );
  const parentItemsWithURL = parentItems.map((item, index) => {
    const url = index === 0 ? '/' : `/category/${item.id}`;
    return {
      ...item,
      url,
    };
  });

  // give half or more of the space for the current category
  const maxLastItemWidth = current
    ? parentItemsWithURL.length > 1
      ? breadcrumbWidth * 0.5
      : breadcrumbWidth * 0.65
    : 0;
  const totalWidth = breadcrumbWidth - maxLastItemWidth;
  const SEPARATOR_WIDTH = 30;
  const maxWidth =
    Math.round(totalWidth / parentItemsWithURL.length - SEPARATOR_WIDTH).toString() + 'px';
  const lastItemMaxWidth = maxLastItemWidth.toString() + 'px';

  return (
    <BreadcrumbContainer w="full" ref={breadcrumbRef}>
      {parentItemsWithURL.map((item) => {
        return (
          <BreadcrumbItem key={item?.id}>
            <Tooltip label={item?.name}>
              <BreadcrumbLink
                href={item?.url || '/'}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: maxWidth,
                  display: 'block',
                }}
              >
                {item?.name}
              </BreadcrumbLink>
            </Tooltip>
          </BreadcrumbItem>
        );
      })}
      {current && (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            key="current"
            href="#"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: lastItemMaxWidth,
              display: 'block',
            }}
          >
            {current}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
