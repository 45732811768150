import { Button, HStack } from '@chakra-ui/react';

export type FilterActionButtonsProps = {
  onClickCancel?: VoidFunction;
  onClickApply: () => void;
  isCancelDisabled?: boolean;
  isApplyDisabled?: boolean;
};

const FilterActionButtons = (props: FilterActionButtonsProps): JSX.Element => {
  const { onClickApply, onClickCancel, isCancelDisabled, isApplyDisabled } = props;
  return (
    <HStack spacing="2" justify="space-between">
      <Button size="md" variant="ghost" onClick={onClickCancel} isDisabled={isCancelDisabled}>
        Clear
      </Button>
      <Button size="md" colorScheme="primary" onClick={onClickApply} isDisabled={isApplyDisabled}>
        Apply
      </Button>
    </HStack>
  );
};

export default FilterActionButtons;
