'use client';

import { useEffect, useState } from 'react';
import { TimeLeft } from 'src/api/v1-api';

export const calculateTimeLeft = (targetDateTime: number | null | undefined): TimeLeft => {
  let timeLeft: TimeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  if (!targetDateTime) return timeLeft;

  const now = new Date().getTime();
  const difference = targetDateTime - now;

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const displayTimeLeft = (timeLeft: TimeLeft) => {
  if (timeLeft.days > 0) {
    return `${timeLeft.days} day${timeLeft.days !== 1 ? 's' : ''} left`;
  }
  if (timeLeft.hours > 0) {
    return `${timeLeft.hours} hour${timeLeft.hours !== 1 ? 's' : ''} left`;
  }
  if (timeLeft.minutes > 0) {
    return `${timeLeft.minutes} minute${timeLeft.minutes !== 1 ? 's' : ''} left`;
  }
  if (timeLeft.seconds > 0) {
    return 'ending soon';
  }
  return 'auction closed';
};

const Countdown = (props: { endTime: number; format: string; extra: string }) => {
  const { endTime, format, extra } = props;
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(endTime));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endTime));
    }, 1000);

    return () => clearInterval(timer);
  }, [endTime]);

  let timerString = '';
  if (timeLeft.days > 0) {
    timerString = `${timeLeft.days.toString()}:${timeLeft.hours
      .toString()
      .padStart(2, '0')}:${timeLeft.minutes.toString().padStart(2, '0')}:${timeLeft.seconds
      .toString()
      .padStart(2, '0')}`;
  } else {
    timerString = `${timeLeft.hours.toString().padStart(2, '0')}:${timeLeft.minutes
      .toString()
      .padStart(2, '0')}:${timeLeft.seconds.toString().padStart(2, '0')}`;
  }

  if (format == 'descriptive' || (format === 'mixed' && timeLeft.days > 0)) {
    timerString = `${timeLeft.days.toString()} days, ${timeLeft.hours.toString()} hours, ${timeLeft.minutes.toString()} minutes`;
  }

  if (timerString === '00:00:00') {
    return (
      <span>
        <i>auction closed</i>
      </span>
    );
  }
  return (
    <span>
      {timerString}
      {extra}
    </span>
  );
};

export default Countdown;
