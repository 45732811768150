import React, { ReactElement } from 'react';
import { Card, Flex, Text, LinkBox, LinkOverlay } from '@chakra-ui/react';

interface LinkInBioProps {
  linkText: string;
  linkUrl: string;
  linkIcon?: ReactElement;
  isExternalLink?: boolean;
}

const LinkInBioLink = ({ linkIcon, linkText, linkUrl, isExternalLink }: LinkInBioProps) => {
  return (
    <LinkBox>
      <Card paddingY={3} flexDirection={'row'} alignItems="center" pl={2} pr={linkIcon ? 8 : 2}>
        {linkIcon}
        <Flex flex={1} justifyContent="center">
          <LinkOverlay href={linkUrl} isExternal={isExternalLink}>
            <Text mr="14px" fontWeight={500} casing="capitalize">
              {linkText}
            </Text>
          </LinkOverlay>
        </Flex>
      </Card>
    </LinkBox>
  );
};

export default LinkInBioLink;
