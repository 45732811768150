import React from 'react';
import { AspectRatio, Flex, Image, Link, Text } from '@chakra-ui/react';
import { formatPrice } from 'src/utils/common';
import { DEFAULT_IMAGE } from 'src/constants/config';
import { srcFromCDN } from 'src/utils/files';

interface EarningMetadata {
  title: string;
  value: string;
  displayTitle: boolean;
}

interface MarketplaceEarningItemProps {
  earningDate: string;
  earningTitle: string;
  earningAmount: number;
  earningItemUrl?: string;
  earningImageUrl?: string;
  earningMetadata?: EarningMetadata[];
  showImage?: boolean;
  clientAccountId?: string;
}

const MarketplaceEarningItem = ({
  earningDate,
  earningTitle,
  earningAmount,
  earningItemUrl,
  earningImageUrl,
  earningMetadata = [],
  showImage = false,
  clientAccountId = undefined,
}: MarketplaceEarningItemProps) => {
  const maybeWrapInLink = (children: React.ReactNode) => {
    if (earningItemUrl) {
      return (
        <Link href={earningItemUrl} target="_blank">
          {children}
        </Link>
      );
    } else {
      return children;
    }
  };

  return (
    <Flex
      direction="column"
      borderTop="1px solid"
      borderColor="gray.200"
      py={4}
      px={6}
      w="100%"
      alignItems="flex-start"
    >
      <Text fontSize="sm">{earningDate}</Text>
      <Text fontWeight="bold" color="gray.600" pb={2}>
        {earningTitle}
      </Text>
      <Flex
        gap={2}
        direction={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        w="100%"
      >
        {maybeWrapInLink(
          <Flex gap={2} direction="column">
            {showImage && earningImageUrl && (
              <AspectRatio ratio={1} width={24} flexShrink={0}>
                <Image
                  src={srcFromCDN(earningImageUrl, 24)}
                  alt={'Thumbnail'}
                  borderRadius={{ base: 'md', md: 'xl' }}
                  fallback={
                    <Image
                      src={DEFAULT_IMAGE}
                      alt="Default"
                      borderRadius={{ base: 'md', md: 'xl' }}
                    />
                  }
                />
              </AspectRatio>
            )}
            {earningMetadata.map((metadata) => (
              <Flex
                key={metadata.title}
                gap={{ base: 0, md: 2 }}
                alignItems={{ base: 'flex-start', md: 'center' }}
                direction={{ base: 'column', md: 'row' }}
                w="100%"
              >
                {metadata.displayTitle && <Text color="gray.600">{metadata.title}:</Text>}
                {metadata.value}
              </Flex>
            ))}
          </Flex>,
        )}
        <Flex
          gap={2}
          direction={{ base: 'row', md: 'column' }}
          justifyContent={{ base: 'space-between', md: 'flex-end' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
        >
          <Flex
            gap={{ base: 0, md: 2 }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            direction={{ base: 'column', md: 'row' }}
            w="100%"
          >
            {clientAccountId ? (
              <>
                <Text color="green.600" fontSize="xs" pr={4}>
                  direct via Stripe Connect
                </Text>
                <Text color="gray.600">Amount:</Text>
                <Text color="gray.600" fontWeight="semibold" fontStyle={'italic'}>
                  {formatPrice(earningAmount)}
                </Text>
              </>
            ) : (
              <>
                <Text color="gray.600">Amount:</Text>
                <Text fontWeight="semibold">{formatPrice(earningAmount)}</Text>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MarketplaceEarningItem;
