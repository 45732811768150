import chroma from 'chroma-js';
import { BLACK, WHITE } from 'src/constants/ui';

export const isLightColor = ([r, g, b]: [number, number, number]) => {
  // Counting the perceptive luminance
  // human eye favors green color...
  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return a < 0.5;
};

export const getTextColor = (bgColor: any): string => {
  if (chroma.valid(bgColor)) {
    return isLightColor(chroma(bgColor).rgb()) ? BLACK : WHITE;
  }
  return BLACK;
};

const LIGHTNESS_MAP = [0.95, 0.85, 0.75, 0.65, 0.55, 0.45, 0.35, 0.25, 0.15, 0.05];
const SATURATION_MAP = [0.32, 0.16, 0.08, 0.04, 0, 0, 0.04, 0.08, 0.16, 0.32];

export const getColorSwatch = (baseColor: any) => {
  const colorChroma = chroma.valid(baseColor) ? chroma(baseColor) : chroma('#000000');
  const lightnessGoal = colorChroma.get('hsl.l');
  const closestLightness = LIGHTNESS_MAP.reduce((prev, curr) =>
    Math.abs(curr - lightnessGoal) < Math.abs(prev - lightnessGoal) ? curr : prev,
  );
  const baseColorIndex = LIGHTNESS_MAP.findIndex((l) => l === closestLightness);

  return LIGHTNESS_MAP.map((l) => colorChroma.set('hsl.l', l))
    .map((color) => chroma(color))
    .map((color, i) => {
      const saturationDelta = SATURATION_MAP[i] - SATURATION_MAP[baseColorIndex];
      return saturationDelta >= 0
        ? color.saturate(saturationDelta)
        : color.desaturate(saturationDelta * -1);
    })
    .reduce((acc, cur, index) => {
      return { ...acc, [index === 0 ? 50 : index * 100]: cur.hex() };
    }, {});
};
