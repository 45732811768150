/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPlainObject } from 'lodash';
import { BasicCategory } from 'src/api/v1-api';

export const removeTrailingSlash = (value: string): string =>
  value.endsWith('/') ? value.slice(0, -1) : value;

export const getStringValue = (value: any): string | null => {
  if (typeof value === 'string') return value;
  if (typeof value === 'boolean' || typeof value === 'number') return value.toString();
  if (isPlainObject(value)) return JSON.stringify(value);
  return null;
};

export const getNumberValue = (value: any): number | null => {
  if (typeof value === 'number') return value;
  if (typeof value === 'string') return Number(value);
  return null;
};

export const formatPrice = (
  value: any,
  opts: { locale?: string; currency?: string; alwaysCents?: boolean; asDecimal?: boolean } = {},
) => {
  const { locale = 'en-US', currency = 'USD', alwaysCents = true, asDecimal = false } = opts;
  const price = getNumberValue(value) ?? 0;

  // Ensure we're dealing with a valid number
  if (!Number.isFinite(price)) {
    return '0';
  }

  const hasCents = Math.abs(price - Math.floor(price)) > 0;
  const fractionDigits = hasCents || alwaysCents ? 2 : 0;

  try {
    const formatter = new Intl.NumberFormat(locale, {
      currency,
      style: asDecimal ? 'decimal' : 'currency',
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    });
    return formatter.format(price);
  } catch (error) {
    // Fallback formatting
    return asDecimal
      ? price.toFixed(fractionDigits)
      : `${currency} ${price.toFixed(fractionDigits)}`;
  }
};

export const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const formatDateTime = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  };

  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const utcISOToLocalDate = (utcDateString: string): Date => {
  const localDate = new Date(utcDateString);
  return localDate;
};

export const utcISOToLocalDateISO = (utcDateString: string): string => {
  const utcDate = new Date(utcDateString);
  const offset = utcDate.getTimezoneOffset();
  utcDate.setTime(utcDate.getTime() - offset * 60000);
  return utcDate.toISOString().slice(0, 19);
};

export const localToUtcISO = (localDateString: string): string => {
  const localDate = new Date(localDateString);
  return localDate.toISOString().slice(0, 19);
};

export const formatToLocalDateTime = (utcDateString: string): string => {
  const localizedDate = utcISOToLocalDate(utcDateString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  };
  return localizedDate.toLocaleTimeString(undefined, options);
};

export const getTimezoneAbbreviation = (): string => {
  const intl = new Intl.DateTimeFormat(undefined, { timeZoneName: 'short' });
  const tz =
    intl.formatToParts(new Date()).find((part) => part.type === 'timeZoneName')?.value ?? '';
  return tz;
};

export const formatDateTimeLocal = (
  utcDateString: string | null | undefined,
): string | undefined => {
  // convert from Postgres UTC string to what datetime-local expects
  if (!utcDateString) {
    return undefined;
  }
  return utcDateString.slice(0, -1);
};

export const getStatusColorScheme = (status: string | undefined) => {
  switch (status) {
    case 'active':
      return 'green';
    case 'inactive':
      return 'yellow';
    case 'pending':
      return 'gray';
    case 'candidate':
      return 'gray';
    case 'candidate_rejected':
      return 'red';
    case 'sold':
      return 'green';
    case 'draft':
      return 'gray';
    case 'payment_pending':
      return 'gray';
    case 'pending_payment':
      return 'gray';
    case 'paid':
      return 'green';
    case 'payment_failed':
      return 'red';
    case 'cancelled':
      return 'red';
    case 'action_required':
      return 'red';
    case 'in_transit':
      return 'yellow';
    case 'fulfilled':
      return 'green';
    case 'return_to_sender':
      return 'red';
    case 'returned':
      return 'yellow';
    case 'failed':
      return 'red';
    case 'admin_deactivated':
      return 'red';
    case 'deleted':
      return 'red';
    default:
      return 'gray';
  }
};

export const buildCategoryTree = (categories: BasicCategory[]): BasicCategory[] => {
  const tree: BasicCategory[] = [];
  const lookup: { [key: number]: BasicCategory } = {};

  categories
    .filter((cat): cat is BasicCategory => cat !== undefined)
    .forEach((cat) => {
      lookup[cat.id] = cat;
      cat.children = [];
    });

  categories.forEach((cat) => {
    // @ts-ignore
    const catParent = typeof cat.parent === 'number' ? cat?.parent : cat?.parent?.id;
    if (catParent !== null && catParent !== undefined && lookup[catParent]) {
      lookup[catParent].children?.push(cat);
    } else {
      tree.push(cat);
    }
  });

  return tree;
};

export const capitalizeFirstLetter = (string: string | undefined) => {
  if (!string || typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getDisplayStatus = (status: string | undefined) => {
  switch (status) {
    case 'active':
      return 'Active';
    case 'inactive':
      return 'Inactive';
    case 'pending':
      return 'Pending';
    case 'candidate':
      return 'Candidate Pending';
    case 'candidate_rejected':
      return 'Rejected';
    case 'sold':
      return 'Sold';
    case 'draft':
      return 'Draft';
    case 'payment_pending':
      return 'Payment Pending';
    case 'pending_payment':
      return 'Pending Payment';
    case 'paid':
      return 'Paid';
    case 'payment_failed':
      return 'Payment Failed';
    case 'cancelled':
      return 'Cancelled';
    case 'action_required':
      return 'Action Required';
    case 'in_transit':
      return 'Shipped';
    case 'fulfilled':
      return 'Complete';
    case 'return_to_sender':
      return 'Return to Sender';
    case 'returned':
      return 'Returned';
    case 'failed':
      return 'Failed';
    case 'admin_deactivated':
      return 'Admin Deactivated';
    case 'expired':
      return 'Expired';
    case 'deleted':
      return 'Deleted';
    default:
      return 'Unknown';
  }
};

export const DAILY_OPTION = 'Daily';

export const DAYS_OPTIONS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const TIME_OPTIONS = [
  '12am ET, 9pm PT',
  '12:30am ET, 9:30pm PT',
  '1am ET, 10pm PT',
  '1:30am ET, 10:30pm PT',
  '2am ET, 11pm PT',
  '2:30am ET, 11:30pm PT',
  '3am ET, 12am PT',
  '3:30am ET, 12:30am PT',
  '4am ET, 1am PT',
  '4:30am ET, 1:30am PT',
  '5am ET, 2am PT',
  '5:30am ET, 2:30am PT',
  '6am ET, 3am PT',
  '6:30am ET, 3:30am PT',
  '7am ET, 4am PT',
  '7:30am ET, 4:30am PT',
  '8am ET, 5am PT',
  '8:30am ET, 5:30am PT',
  '9am ET, 6am PT',
  '9:30am ET, 6:30am PT',
  '10am ET, 7am PT',
  '10:30am ET, 7:30am PT',
  '11am ET, 8am PT',
  '11:30am ET, 8:30am PT',
  '12pm ET, 9am PT',
  '12:30pm ET, 9:30am PT',
  '1pm ET, 10am PT',
  '1:30pm ET, 10:30am PT',
  '2pm ET, 11am PT',
  '2:30pm ET, 11:30am PT',
  '3pm ET, 12pm PT',
  '3:30pm ET, 12:30pm PT',
  '4pm ET, 1pm PT',
  '4:30pm ET, 1:30pm PT',
  '5pm ET, 2pm PT',
  '5:30pm ET, 2:30pm PT',
  '6pm ET, 3pm PT',
  '6:30pm ET, 3:30pm PT',
  '7pm ET, 4pm PT',
  '7:30pm ET, 4:30pm PT',
  '8pm ET, 5pm PT',
  '8:30pm ET, 5:30pm PT',
  '9pm ET, 6pm PT',
  '9:30pm ET, 6:30pm PT',
  '10pm ET, 7pm PT',
  '10:30pm ET, 7:30pm PT',
  '11pm ET, 8pm PT',
  '11:30pm ET, 8:30pm PT',
];

export const parseUnderscoreName = (input: string): string => {
  const words = input.split('_');
  const formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );
  return formattedWords.join(' ');
};

export const getAbsoluteValueString = (value: string | number | null | undefined): string => {
  try {
    const parsedValue = parseFloat(value?.toString() ?? '');
    if (isNaN(parsedValue)) {
      return value?.toString() ?? '';
    }

    return Math.abs(parsedValue).toString();
  } catch {
    return value?.toString() ?? '';
  }
};
